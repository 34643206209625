<template>
	<v-checkbox
		:input-value="value"
		:label="checkBox.label"
		:append-icon="checkBox['append-icon']"
		:prepend-icon="checkBox['prepend-icon']"
		:color="checkBox.color"
		:dark="checkBox.dark"
		:dense="checkBox.dense"
		:disabled="checkBox.disabled"
		:outlined="checkBox.outlined"
		:error="checkBox.error"
		:error-count="checkBox['error-count']"
		:error-messages="checkBox['error-messages']"
		:hide-details="checkBox['hide-details']"
		:hint="checkBox.hint"
		:id="checkBox.id"
		:light="checkBox.light"
		:messages="checkBox.messages"
		:readonly="checkBox.readonly"
		:reverse="checkBox.reverse"
		:rounded="checkBox.rounded"
		:rules="checkBox.rules"
		:success="checkBox.success"
		:success-messages="checkBox['success-messages']"
		:validate-on-blur="checkBox['validate-on-blur']"
		:counter="checkBox.counter"
		:counter-value="checkBox['counter-value']"
		:ripple="checkBox.ripple"
		:indeterminate="checkBox.indeterminate"
		@change="$emit('change', $event)"
		@click="$emit('click', $event)"
		@click:append="$emit('click:append', $event)"
		@click:prepend="$emit('click:prepend', $event)"
		@mousedown="$emit('mousedown', $event)"
		@mouseup="$emit('mouseup', $event)"
		@update:error="$emit('update:error', $event)"
		@update:indeterminate="$emit('update:indeterminate', $event)"
	/>
</template>

<script>
export default {
	name: 'protect-checkBox',
	props: {
		value: {
			type: Boolean,
			default() {
				return false
			},
		},
		checkBox: {
			type: Object,
			default() {
				return {
					label: 'save Email or ID',
					['append-icon']: '',
					['prepend-icon']: '',
					color: '#5680e9',
					dark: false,
					dense: undefined,
					disabled: false,
					outlined: false,
					error: false,
					['error-count']: undefined,
					['error-messages']: '',
					['hide-details']: false,
					hint: '',
					id: undefined,
					light: undefined,
					messages: '',
					readonly: false,
					reverse: undefined,
					rounded: false,
					rules: undefined,
					success: false,
					['success-messages']: '',
					['validate-on-blur']: undefined,
					counter: undefined,
					['counter-value']: undefined,
					ripple: false,
					indeterminate: false,
				}
			},
		},
	},
}
</script>
<style scoped></style>
