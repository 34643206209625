import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import store from '../store'
Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		themes: {
			light: {
				MainColor1: store.state.MainColor1,
				MainColor2: store.state.MainColor2,
				PointColor1: store.state.PointColor1,
				PointColor2: store.state.PointColor2,
				PointColor3: store.state.PointColor3,
				FontColor1: store.state.FontColor1,
				FontColor2: store.state.FontColor2,
				// MainColor3: '',
				// MainColor4: '',
			},
		},
	},
})
