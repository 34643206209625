<template>
	<v-card class="qr" elevation="10">
		<v-app-bar dark :color="systemData.color.PointColor2" flat>
			<v-toolbar-title>Protect Company </v-toolbar-title>
			<v-spacer></v-spacer>
			<v-img max-width="35" contain src="/image/QRIcon.png"> </v-img>
		</v-app-bar>
		<div :class="$vuetify.breakpoint.xs ? 'mx-12 my-7' : 'mx-16 my-7'">
			<div class="text-left mb-7">
				<h2 :style="nowWidth < 425 ? 'font-size: 1em' : 'font-size: 1.2em'" class="QRuser-data">
					<div>
						<span class="QR-title">
							이름
						</span>
						<span class="data">
							{{ myData.Name }}
						</span>
					</div>
				</h2>
			</div>
			<div class="qr_img">
				<vue-qrcode :value="$route.params.id" class="qr-code" />
			</div>
		</div>
		<div class="QR-dash mb-7"></div>
		<h2 :style="nowWidth < 425 ? 'font-size: 1em' : 'font-size: 1.2em'" class="QR-bottomText">
			QR리더기에 스캔 후 '출근 되었습니다.'<br />
			메세지를 확인 후 업무를 시작해주세요.
		</h2>
		<div class="qr_logo my-2">
			<v-img v-if="systemData.logo" :src="backServer + systemData.logo.url"></v-img>
		</div>
		<v-footer height="25" :color="systemData.color.PointColor2"> </v-footer>
	</v-card>
</template>

<script>
import VueQrcode from 'vue-qrcode'
export default {
	components: {
		VueQrcode,
	},
	data() {
		return {
			backServer: process.env.VUE_APP_BACKEND_URL,
			screenWidth: 0,
			myData: {},
			systemData: {
				color: {
					PointColor2: 'black',
				},
			},
		}
	},
	computed: {
		nowWidth() {
			window.addEventListener('resize', () => {
				this.screenWidth = window.innerWidth
			})
			return this.screenWidth
		},
	},
	methods: {
		systems() {
			this.$store.state.loading = true
			this.$store
				.dispatch('systems')
				.then(res => {
					this.$store.state.loading = false
					this.systemData = res.systems[0]
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		QRUser() {
			this.$store.state.loading = true
			const data = {
				interID: this.$route.params.id,
			}
			this.$store
				.dispatch('QRUser', data)
				.then(res => {
					this.$store.state.loading = false
					this.myData = res.users[0]
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
	},
	created() {
		this.screenWidth = window.innerWidth
		this.systems()
		this.QRUser()
	},
}
</script>
<style lang="scss">
.QR-dash {
	width: 100%;
	height: 3px;
	background-image: linear-gradient(to right, #ccc 0%, #ccc 50%, transparent 50%);
	background-size: 10px 2px;
	background-repeat: repeat-x;
}
.QRuser-data {
	letter-spacing: 0.4px !important;
	.QR-title {
		margin-right: 12px;
		font-weight: 400;
		color: #aeaeae !important;
	}
	.data {
		font-weight: 400;
		color: #656565 !important;
	}
}
.v-application--wrap {
	background-color: white !important;
}
.qr {
	border-radius: 16px !important;
	background-color: white;
	max-width: 440px;
	width: 93%;
	margin: 2% auto 2% auto;
	height: auto;
	text-align: center;
}
.qr h1 {
	font-size: 1.7em;
	color: #090909;
	line-height: 2.8em;
	font-weight: 500;
}
.qr h2 {
	font-size: 1.2em;
	color: #898989;
	line-height: 1.5em;
	font-weight: 500;
}
.qr_img {
	width: 100%;
}
.qr_img img {
	transform: scale(1.2);
	width: 100%;
}
.qr_logo {
	max-width: 150px;
	width: 100%;
	margin: 0 auto 0 auto;
}
.qr_logo img {
	width: 100%;
}
.QR-bottomText {
	color: #aeaeae !important;
	letter-spacing: 0.4px !important;
}
</style>
<style lang="scss" scoped>
html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
legend,
input,
textarea,
button {
	font-family: 'Noto Sans KR', 'Apple SD Gothic Neo';
	margin: 0;
	padding: 0;
	letter-spacing: -1px;
}
li {
	list-style: none;
}
img,
fieldset {
	border: none;
	margin: 0;
	padding: 0;
}
</style>
