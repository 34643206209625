<template>
	<div>
		<v-navigation-drawer class="search-drawer" permanent expand-on-hover>
			<v-list class="search-list">
				<v-list-item class="search-list-item">
					<div class="search-data">
						<div class="d-flex">
							<v-icon class="search-icon" color="white">mdi-magnify</v-icon>
							<input type="text" class="search-input" v-model="inputData" @input="search" placeholder="검색" />
						</div>
						<div class="search-body" v-for="(searchcard, idx) in searchdata" :key="idx" @click.prevent="clickData(searchcard)">
							<h3 class="search-title">{{ searchcard[2] }} ...</h3>
							<div class="search-content"><span v-html="searchcard[3]"></span>...</div>
							<hr class="search-br mt-3" />
						</div>
					</div>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		<div v-if="isdialog">
			<QuillEditor @onclose="isdialog = false" :propdata="propdata" @updataCard="updataCard" />
		</div>
	</div>
</template>

<script>
import QuillEditor from '@/components/memo/QuillEditor'
export default {
	components: {
		QuillEditor,
	},
	data() {
		return {
			inputData: '',
			boards: '',
			cards: '',
			carditems: [],
			searchdata: [],
			isdialog: false,
			propdata: '',
		}
	},
	created() {
		this.fetchData()
	},
	computed: {},

	methods: {
		clickData(data) {
			this.isdialog = true
			this.propdata = data
		},
		fetchData() {
			this.$store.dispatch('USER_BOARD').then(res => {
				if (res != undefined) {
					this.boards = res
					this.cards = res.cards
				}
			})
		},
		search() {
			this.fetchData()
			this.searchdata = []
			if (this.inputData) {
				for (var i = 0; i < this.cards.length; i++) {
					this.carditems = []
					if (this.cards[i].carditem) {
						this.carditems = this.cards[i].carditem
					}
					if (this.carditems) {
						for (var j = 0; j < this.carditems.length; j++) {
							if (this.carditems[j] && this.carditems[j].iscard == true) {
								var data = []
								if (this.carditems[j].title.indexOf(this.inputData) >= 0 || this.carditems[j].content.indexOf(this.inputData) >= 0) {
									const titleI = this.carditems[j].title.indexOf(this.inputData)
									const contentI = this.carditems[j].content.indexOf(this.inputData)
									if (titleI >= 0) {
										data.push(this.carditems[j].title)
										data.push(this.carditems[j].content)
										data.push(this.carditems[j].title.substr(0, 20))
										data.push(this.carditems[j].title.substr(titleI, 40))
									} else if (contentI >= 0) {
										data.push(this.carditems[j].title)
										data.push(this.carditems[j].content)
										data.push(this.carditems[j].title.substr(0, 20))
										data.push(this.carditems[j].content.substr(contentI, 40))
									}
								}
								if (data[0]) {
									this.searchdata.push(data)
								}
							}
						}
					}
				}
			}
		},
		updataCard() {
			this.fetchData()
			this.inputData = ''
			this.searchdata = []
			this.isdialog = false
			this.$emit('updataCard')
		},
	},
}
</script>
<style>
.search-data {
	background: #424141;
	width: 16rem;
	height: 59rem;
	overflow: hidden;
}

.search-input {
	border: 5px;
	height: 30px;
	color: white;
	width: 14.3rem;
	margin: 40px 30px 13px 7px;
	background-color: #787777;
}
.search-br {
	/* color: white; */
	border-top: 0.5px solid #bbb;
}
.search-body {
	height: auto;
	overflow: auto;
	padding: 10px;
	width: 16rem;
	font-size: 13px;
	border: 0.5px;
	color: aliceblue;
	white-space: wrap;
	cursor: pointer;
}

.search-body:hover {
	background: black;
}
.search-content {
	width: auto;
	white-space: normal;
	overflow-x: hidden;
	margin-top: 5px;
}
.modal-card-header {
	display: flex;
}
.v-list-item.search-list-item {
	padding: 0px;
}
.v-list.search-list {
	padding: 0px;
}
.v-icon.search-icon {
	font-size: 30px;
	margin: 30px 10px 0px 10px;
}
</style>
