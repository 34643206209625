<template>
	<div :data-card-title="card.title" :data-card-pos="card.pos">
		<div class="card-item" :data-card-title="card.title" :data-card-pos="card.pos">
			<div class="card-item-title" @click.prevent="isUpdataCard = true">{{ card.title }}</div>
			<div class="card-item-meta" v-if="card.content" @click.prevent="isUpdataCard = true">&equiv;</div>
			<span href="" class="delete-card-btn" @click.prevent="cardDelete">&times;</span>
		</div>
		<div v-if="isUpdataCard">
			<QuillEditor @onclose="isUpdataCard = false" :card="card" @updataCard="updataCard" />
		</div>
	</div>
</template>

<script>
import QuillEditor from './QuillEditor.vue'
export default {
	components: {
		QuillEditor,
	},
	data() {
		return {
			isUpdataCard: false,
			loading: false,
		}
	},
	props: {
		card: {
			type: Object,
		},
	}, // 카드의 기본정보
	created() {},
	computed: {
		boardId() {
			return this.$store.state.board.id
		},
	},
	updated() {},
	methods: {
		// 카드 삭제, List로 deleteCard 에밋
		cardDelete() {
			this.$store.state.loading = true
			if (!window.confirm(`Delete this ${this.card.title}`)) {
				this.$store.state.loading = false
				return
			}
			this.$store.dispatch('DETAILBOARD', this.$store.state.board.id).then(res => {
				var data = res.memos[0].cards
				for (var i = 0; i < data.length; i++) {
					if (data[i].carditem) {
						for (var j = 0; j < data[i].carditem.length; j++) {
							if (data[i].carditem[j].title == this.card.title) {
								data[i].carditem[j].iscard = false
								break
							}
						}
					}
				}

				const input = {
					id: this.$store.state.board.id,
					cards: data,
				}
				this.$store
					.dispatch('UPDATEBOARD', input)
					.then(() => {
						this.$store.state.loading = false
						this.$emit('deleteCard')
					})
					.catch(err => {
						console.log(err)
					})
			})
		},
		updataCard(emitData) {
			this.isUpdataCard = false
			this.card.title = emitData
			this.$emit('updataCard')
		},
	},
}
</script>

<style scoped>
.card-item {
	background-color: #fff;
	border-radius: 3px;
	margin: 8px;
	padding: 6px 20px 2px 8px;
	box-shadow: 0 1px 0 #ccc;
	position: relative;
}
.card-item-title {
	white-space: pre-wrap;
}
.card-item a {
	text-decoration: none;
	color: #444;
	word-wrap: break-word;
	white-space: normal;
	overflow: hidden;
	display: block;
}
.card-item:hover,
.card-item:focus {
	background-color: rgba(0, 0, 0, 0.1);
	cursor: pointer;
}
.card-item-meta {
	font-size: 26px;
	padding: 5px 0 0 3px;
	color: #8c8c8c;
}
.delete-card-btn {
	position: absolute;
	right: 10px;
	top: 4px;
	text-decoration: none;
	font-size: 20px;
	color: #aaa;
}
.loading-bar {
	position: absolute;
	top: 45%;
	right: 50%;
}
</style>
