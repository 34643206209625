<template>
	<div class="blog-body">
		<div class="blog-header">
			<h1 class="blog-header-title">Blog</h1>
		</div>
		<div class="boxContainer">
			<table class="elementsContainer">
				<tr>
					<td width="90%">
						<input type="text" class="velog-search-input" v-model="search" placeholder="search" @input="searchText" />
					</td>
					<td width="3%">
						🔎
					</td>
				</tr>
			</table>
		</div>
		<div class="blog-card-wrapper">
			<VelogCard v-for="(card, idx) in cards" :key="idx" :card="card" @onClick="Review" />
		</div>
		<v-app>
			<div class="text-center">
				<v-pagination class="pagination" v-model="page" :length="leng" :total-visible="6" @input="change"></v-pagination>
			</div>
		</v-app>
		<CardReview v-if="review" @cancleClick="cancleClick" :cardreview="cardreview" />
	</div>
</template>

<script>
import VelogCard from '@/components/memo/VelogCard'
import CardReview from '@/components/memo/CardReview'

export default {
	name: 'velog',
	components: { VelogCard, CardReview },
	data() {
		return {
			origincards: [],
			cards: [],
			cardreview: '',
			review: false,
			search: '',
			value: 0,
			leng: 0,
			page: 1,
			loading: false,
		}
	},
	created() {
		this.fetchBoard()
	},
	watch: {},
	computed: {},
	methods: {
		async fetchBoard() {
			this.origincards = []
			this.$store.state.loading = true
			const input = {
				start: 0,
				limit: 100,
			}
			await this.$store
				.dispatch('Boards', input)
				.then(res => {
					console.log(res)
					for (var i = 0; i < res.length; i++) {
						if (res[i].cards) {
							for (var j = 0; j < res[i].cards.length; j++) {
								if (res[i].cards[j].carditem && res[i].cards[j].blog == true && res[i].cards[j].islist == true) {
									for (var k = 0; k < res[i].cards[j].carditem.length; k++) {
										if (res[i].cards[j].carditem[k].iscard == true) {
											this.origincards.push(res[i].cards[j].carditem[k])
											this.cards.push(res[i].cards[j].carditem[k])
										}
									}
								}
							}
						}
					}

					this.origincards.sort((a, b) => {
						return new Date(b.update_at) - new Date(a.update_at)
					})

					this.leng = Math.ceil(this.origincards.length / 3)
					this.cards = this.origincards.slice(0, 3)
					this.$store.state.loading = false
				})
				.catch(err => {
					console.log(err)
					this.error = err
				})
		},
		Review(emitData) {
			this.cardreview = emitData
			this.review = true
		},
		cancleClick() {
			this.review = false
		},
		searchText() {
			if (this.search) {
				let title = this.origincards.filter(e => {
					const a = e.title.toLowerCase().includes(this.search.toLowerCase())
					return a === true
				})
				this.cards = title
				const index = (this.page - 1) * 3
				this.cards = this.cards.slice(index, index + 3)

				if (this.cards.length <= 3) {
					this.leng = 1
				} else {
					this.leng = Math.ceil(this.origincards.length / 3)
				}
			} else {
				this.fetchBoard()
			}
		},
		change() {
			this.cards = []
			const index = (this.page - 1) * 3
			this.cards = this.origincards.slice(index, index + 3)
		},
	},
}
</script>

<style>
.blog-body {
	background-color: rgb(12, 59, 108);
}

.blog-header-title {
	text-align: center;
	padding-top: 1rem;
	color: rgb(250, 250, 250);
	background-color: rgb(12, 59, 108);
}

.blog-card-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto 0 auto;
	justify-content: center;
	/* width: 80%; */
}
.boxContainer {
	margin: auto;
	margin-top: 15px;
	position: relative;
	width: 53%;
	height: 42px;
	border: 2px solid #2980b9;
	padding: 0px 10px;
	border-radius: 50px;
}
.elementsContainer {
	width: 100%;
	height: 100%;
	vertical-align: middle;
}
.search {
	border: none;
	height: 100%;
	width: 100%;
	padding: 0, 5px;
	border-radius: 50px;
	font-size: 18x;
	font-family: 'Nunito';
	color: #424242;
	font-weight: 500;
}
.search:focus {
	outline: none;
}
.material-icon {
	font-size: 26;
	color: #2980b9;
}
.velog-search-input {
	width: 100%;
	color: #ffffff;
}

input::placeholder {
	color: #ffffff;
}
input:focus {
	outline: none;
}
.v-application--wrap {
	min-height: 0;
}
.pagination {
	height: auto;
	background: rgb(12, 59, 108);
	padding-bottom: 15px;
}
.theme--light.v-application {
	background: rgb(12, 59, 108);
}
.loading-bar {
	position: absolute;
	top: 45%;
	right: 50%;
}
</style>
