<template>
	<v-dialog dark v-model="data.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog">
		<v-layout justify-center style="max-width:1200px; margin:0 auto; height:100vh;" wrap align-center class="px-2 pb-2">
			<v-flex xs12>
				<div>
					<v-layout align-center class="my-2">
						<h2 class="white--text" v-if="data.user">
							{{ data.user.Name }} - Money List
							<v-btn
								color="black"
								dark
								:class="$vuetify.breakpoint.xs ? '' : 'ml-2'"
								@click="editPaymentDays"
								v-if="$store.state.myData.cls === '1'"
							>
								<v-icon class="mr-2">mdi-account-edit</v-icon>
								edit wage Payment
							</v-btn>
						</h2>
						<v-spacer></v-spacer>
						<v-btn icon dark @click="data.dialog = false" color="#5680e9ff">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-layout>
					<div v-if="$store.state.myData.cls === '1'">
						<div class="white--text mb-1" v-if="data.user">
							<v-layout align-center>
								{{ data.user.salaryPaymentDay ? 'salary Payment Day' : 'wage Payment Day' }}:&nbsp;
								<strong class="PointColor1--text" v-if="data.user.salaryPaymentDay">
									{{ data.user.salaryPaymentDay }}
								</strong>
								<strong class="PointColor1--text" v-else>
									{{ datesText(data.user.wagePaymentDay) }}
								</strong>
								<v-spacer></v-spacer>
								<div style="max-width:82px;">
									<v-select
										@change="languageChange"
										hideDetails
										item-color="PointColor2"
										class="ml-2"
										:items="language"
										v-model="languageSelect"
										value="All"
										solo
										dense
									></v-select>
								</div>
							</v-layout>
						</div>
						<v-layout v-if="$vuetify.breakpoint.smAndUp">
							<CommaTextField class="mr-2" v-model="salary" :textField="moneyTextField1" @input="moneyTextField1Input" />
							<CommaTextField class="mr-2" v-model="wage" :textField="moneyTextField2" @input="moneyTextField2Input" />
							<CommaTextField class="mr-2" v-model="bonus" :textField="moneyTextField3" @input="moneyTextField3Input" />
							<div style="max-width:82px;">
								<v-select
									@change="familyChange"
									hideDetails
									item-color="PointColor2"
									background-color="PointColor2"
									class="mr-2"
									:items="familys"
									v-model="family"
									outlined
									dense
									label="Family"
									height="48"
								></v-select>
							</div>
							<DatepickerRange :picker="paymentPicker" @input="paymentDate"></DatepickerRange>
							<btn class="ml-2" :btn="moneyBtn" :btn_txt="type === 'edit' ? 'edit' : 'save'" @click="createMoney" />
							<btn class="ml-2" :btn="moneyBtnNew" btn_txt="new" @click="newMoney" v-if="type === 'edit'" />
						</v-layout>
						<div v-else>
							<v-layout>
								<CommaTextField class="mr-2" v-model="salary" :textField="moneyTextField1" @input="moneyTextField1Input" />
								<CommaTextField class="mr-2" v-model="wage" :textField="moneyTextField2" @input="moneyTextField2Input" />
								<CommaTextField class="mr-2" v-model="bonus" :textField="moneyTextField3" @input="moneyTextField3Input" />
							</v-layout>
							<v-layout class="mt-2">
								<div style="max-width:82px;">
									<v-select
										@change="familyChange"
										hideDetails
										item-color="PointColor2"
										background-color="PointColor2"
										class="mr-2"
										:items="familys"
										v-model="family"
										label="Family"
										outlined
										dense
										height="48"
									></v-select>
								</div>
								<DatepickerRange :picker="paymentPicker" @input="paymentDate"></DatepickerRange>
								<btn class="ml-2" :btn="moneyBtn" :btn_txt="type === 'edit' ? 'edit' : 'save'" @click="createMoney" />
								<btn class="ml-2" :btn="moneyBtnNew" btn_txt="new" @click="newMoney" v-if="type === 'edit'" />
							</v-layout>
						</div>
						<datatable :datatable="taxDatatable" class="mb-2" />
					</div>
					<v-layout align-center class="my-2">
						<DatepickerRange :picker="moneyStartpicker" @input="moneyDate"></DatepickerRange>
						<span class="mx-3 white--text"> ~ </span>
						<DatepickerRange :picker="moneyEndpicker" :allowed_dates="moneyCouselor" @input="moneyDate"></DatepickerRange>
						<v-spacer></v-spacer>
						<v-btn small class="excel_btn mr-2" @click="clickExport()"><img src="@/assets/excel-img2.png" />엑셀파일 다운로드</v-btn>
						<textField v-model="search" :textField="SearchTextField" />
					</v-layout>
					<datatable :datatable="moneyDatatable" :search="search" @editTax="editTax" @showTax="showTax" />
				</div>
			</v-flex>
		</v-layout>
		<v-dialog v-model="daysDialog.dialog" max-width="500px">
			<v-card>
				<v-card-title v-if="data.user"> {{ data.user.Name }} - Payment Days </v-card-title>
				<v-card-text
					><v-chip-group v-model="daysDialog.type" column @change="daysDialogType" active-class="PointColor1--text">
						<v-chip filter outlined>
							Salary
						</v-chip>
						<v-chip filter outlined>
							Wage
						</v-chip>
					</v-chip-group></v-card-text
				>
				<v-layout justify-center class="mb-2" v-show="daysDialog.type === 0">
					<textField v-model="daysDialog.salary" :textField="daysTextField1" class="mx-6" />
				</v-layout>
				<v-layout justify-center class="mb-2" v-show="daysDialog.type === 1">
					<v-chip-group v-model="daysDialog.wage" column @change="daysDialogWage" active-class="PointColor1--text">
						<v-chip filter outlined v-for="data in dates" :key="data">
							{{ data }}
						</v-chip>
					</v-chip-group>
				</v-layout>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="PointColor2" text @click="daysDialog.dialog = false">
						cancel
					</v-btn>
					<v-btn color="error" @click="updateUser" dark>
						edit
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="tax.dialog" max-width="1200px" dark>
			<v-card>
				<v-card-title v-if="data.user"> {{ data.user.Name }} - {{ tax.day }} Taxs </v-card-title>
				<v-card-text>
					<datatable :datatable="taxShowDatatable" class="mb-2" />
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" @click="tax.dialog = false" dark>
						ok
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<download-excel
			class="btn btn-default"
			id="moneyExport"
			:data="exportDatas"
			style="display:none"
			:fields="json_fields"
			type="text/csv;charset=utf8"
			worksheet="My Worksheet"
			:name="data.user.Name"
		>
		</download-excel>
	</v-dialog>
</template>
<script>
import datatable from './datatable.vue'
import textField from './textField'
import DatepickerRange from './DatepickerRange'
import btn from './button.vue'
import downloadExcel from 'vue-json-excel'
import CommaTextField from './CommaTextField'
import koTax from '../utils/koTax'
export default {
	components: {
		downloadExcel,
		datatable,
		textField,
		DatepickerRange,
		btn,
		CommaTextField,
	},
	data() {
		return {
			tax: {
				dialog: false,
				day: '',
			},
			languageSelect: 'ko',
			dates: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
			daysDialog: {
				dialog: false,
				type: 0,
				salary: '',
				wage: null,
			},
			salary: null,
			wage: null,
			bonus: null,
			paymentPicker: {
				date: null,
				errorMessage: '',
				menu: false,
				backgroundColor: 'PointColor2',
				height: 48,
				placeholder: 'payment',
				width: 150,
			},
			moneyStartpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'start',
				height: 48,
				width: 200,
			},
			moneyEndpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'end',
				height: 48,
				width: 200,
			},
			search: '',
			SearchTextField: {
				dark: true,
				width: '200',
				placeholder: 'Search',
				['prepend-inner-icon']: 'mdi-magnify',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			moneyDatatable: {
				headers: [
					{ text: 'No', value: 'No', sortable: false },
					{ text: 'Payment', value: 'payment' },
					{ text: 'User', value: 'users_permissions_user.Name' },
					{ text: 'Salary', value: 'salary' },
					{ text: 'Wage', value: 'wage' },
					{ text: 'Bonus', value: 'bonus' },
					{ text: 'Tax', value: 'tax.items.taxSum' },
					{ text: 'Actual Payment', value: 'ActualPayment' },
					{ text: 'Action', value: 'taxAction', sortable: false },
				],
				items: [],
				select: [],
				hidedefaultfooter: false,
				hidedefaultheader: false,
				singleExpand: true,
				showselect: false,
				singleSelect: true,
				key: 'id',
			},
			taxDatatable: {
				headers: [
					{ text: '건강보험', value: 'tax1', percent: 0.03545 },
					{ text: '국민연금', value: 'tax2', percent: 0.045 },
					{ text: '요양보험', value: 'tax3', percent: 0.1281, relationship: 'tax1' },
					{ text: '고용보험', value: 'tax4', percent: 0.009 },
					{ text: '소득세', value: 'tax5', utils: 'koTax' },
					{ text: '지방세', value: 'tax6', utils: 'koTax', utilsPercent: 0.1 },
					{ text: '공제총액', value: 'taxSum' },
					{ text: '실지급액', value: 'money' },
					// { text: '퇴직금적립', value: 'retirementpay' },
				],
				items: [],
				hidedefaultfooter: true,
				hidedefaultheader: false,
			},
			taxShowDatatable: {
				headers: [
					{ text: '건강보험', value: 'tax1' },
					{ text: '국민연금', value: 'tax2' },
					{ text: '요양보험', value: 'tax3' },
					{ text: '고용보험', value: 'tax4' },
					{ text: '소득세', value: 'tax5' },
					{ text: '지방세', value: 'tax6' },
					{ text: '공제총액', value: 'taxSum' },
					{ text: '실지급액', value: 'money' },
					// { text: '퇴직금적립', value: 'retirementpay' },
				],
				items: [],
				hidedefaultfooter: true,
				hidedefaultheader: false,
			},
			money: '',
			moneyTextField1: {
				['background-color']: 'PointColor2',
				dark: true,
				autofocus: false,
				width: '100%',
				['error-messages']: '',
				placeholder: 'salary',
				solo: true,
				type: 'text',
				maxlength: 100,
			},
			moneyTextField2: {
				['background-color']: 'PointColor2',
				dark: true,
				autofocus: false,
				width: '100%',
				['error-messages']: '',
				placeholder: 'wage',
				solo: true,
				type: 'text',
				maxlength: 100,
			},
			moneyTextField3: {
				['background-color']: 'PointColor2',
				dark: true,
				autofocus: false,
				width: '100%',
				['error-messages']: '',
				placeholder: 'bonus',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			moneyBtn: {
				dark: true,
				color: 'PointColor1',
				height: 48,
			},
			moneyBtnNew: {
				dark: true,
				color: 'success',
				height: 48,
			},
			daysTextField1: {
				dark: false,
				autofocus: false,
				placeholder: 'salary',
				solo: true,
				type: 'Number',
				maxlength: 255,
				max: 31,
			},
			daysTextField2: {
				dark: false,
				autofocus: false,
				placeholder: 'wage',
				solo: true,
				type: 'Number',
				maxlength: 255,
				max: 31,
			},
			language: ['ko'],
			taxList: {},
			familys: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
			family: 0,
			editId: null,
			type: '',
			exportDatas: [],
			json_fields: {
				날짜: 'payment',
				세전월급: 'salary',
				성과급: 'bonus',
				건강보험: 'tax.items.tax1',
				국민연금: 'tax.items.tax2',
				요양보험: 'tax.items.tax3',
				고용보험: 'tax.items.tax4',
				소득세: 'tax.items.tax5',
				지방세: 'tax.items.tax6',
				공제총액: 'tax.items.taxSum',
				실지급액: 'tax.items.money',
			},
		}
	},
	props: {
		data: {
			type: Object,
		},
	},
	watch: {
		data: {
			deep: true,
			handler() {
				this.monies(this.moneyStartpicker.date, this.moneyEndpicker.date)
			},
		},
	},
	methods: {
		clickExport() {
			document.getElementById('moneyExport').click()
		},
		showTax(val, item) {
			val
			this.tax.dialog = true
			this.tax.day = item.payment
			const setArray = []
			setArray.push(item.tax.items)
			this.taxShowDatatable.items = setArray
		},
		newMoney() {
			this.type = 'create'
			this.salary = null
			this.wage = null
			this.bonus = null
			this.paymentPicker.date = null
			this.taxDatatable.items = []
			this.moneyDatatable.select = []
			this.family = 0
		},
		editPaymentDays() {
			if (this.data.user.salaryPaymentDay) {
				this.daysDialog.type = 0
				this.daysDialog.salary = this.data.user.salaryPaymentDay
				this.daysDialog.wage = null
			} else {
				this.daysDialog.type = 1
				this.daysDialog.salary = ''
				this.daysDialog.wage = this.data.user.wagePaymentDay
			}
			this.daysDialog.dialog = true
		},
		editTax(val, item) {
			val
			this.moneyDatatable.select = []
			this.moneyDatatable.select.push(item)
			this.type = 'edit'
			this.editId = item.id
			if (item.salary === 0) {
				this.salary = null
				this.wage = item.wage
			} else {
				this.wage = null
				this.salary = item.salary
			}
			this.bonus = item.bonus
			this.paymentPicker.date = item.payment
			this.family = item.tax.items.family
			this.taxDatatable.items = []
			this.taxDatatable.items.push(item.tax.items)
		},
		familyChange() {
			this.moneyTextField1Input(this.salary)
		},
		languageChange(val) {
			console.log(val)
		},
		datesText(data) {
			return this.dates[data]
		},
		daysDialogWage(val) {
			this.daysDialog.wage = val
		},
		daysDialogType(val) {
			this.daysDialog.type = val
		},
		updateUser() {
			if (this.daysDialog.type === undefined) {
				return alert('reqired salary or wage type')
			}
			if (this.daysDialog.salary === '' && this.daysDialog.wage === null) {
				return alert('reqired salary or wage')
			}
			let data
			if (this.daysDialog.type === 0) {
				data = { id: this.data.user.id, salaryPaymentDay: Number(this.daysDialog.salary), wagePaymentDay: null }
			} else {
				data = { id: this.data.user.id, wagePaymentDay: this.daysDialog.wage, salaryPaymentDay: null }
			}
			this.$store
				.dispatch('updateUser', data)
				.then(() => {
					if (this.daysDialog.type === 0) {
						this.data.user.salaryPaymentDay = Number(this.daysDialog.salary)
						this.data.user.wagePaymentDay = null
					} else {
						this.data.user.salaryPaymentDay = null
						this.data.user.wagePaymentDay = this.daysDialog.wage
					}
					this.daysDialog.dialog = false
				})
				.catch(err => {
					console.log({ err })
				})
		},
		moneyTextField1Input(val) {
			this.moneyTextField1['error-messages'] = ''
			this.wage = null
			const salary = this.salary ? Number(this.salary.replace(/,/g, '')) : 0
			const bonus = this.bonus ? Number(this.bonus.replace(/,/g, '')) : 0
			const setArray = [{}]
			if (Number(salary) > 1060000) {
				setArray[0].taxSum = 0
				this.taxDatatable.headers.forEach(element => {
					if (element.percent) {
						if (element.relationship) {
							setArray[0][element.value] = this.numberFormat(
								Math.floor((Number(setArray[0][element.relationship].replace(/,/g, '')) * element.percent) / 10) * 10,
							)
							setArray[0].taxSum += Math.floor((Number(setArray[0][element.relationship].replace(/,/g, '')) * element.percent) / 10) * 10
						} else {
							setArray[0][element.value] = this.numberFormat(Math.floor(((salary + bonus) * element.percent) / 10) * 10)
							setArray[0].taxSum += Math.floor(((salary + bonus) * element.percent) / 10) * 10
						}
					}
					if (element.utils) {
						const value = Math.round((salary + bonus) / 10000) * 10
						if (value > 14000) {
							const tax10000 = this.taxList[element.utils][this.taxList[element.utils].length - 1][10000]
							if (element.utilsPercent) {
								setArray[0][element.value] = this.numberFormat(
									Math.floor(((tax10000[this.family] + 1372 + (value - 14000) * 0.98 * 0.38 * 10) * element.utilsPercent) / 10) * 10,
								)
								setArray[0].taxSum +=
									Math.floor(((tax10000[this.family] + 1372 + (value - 14000) * 0.98 * 0.38 * 10) * element.utilsPercent) / 10) * 10
							} else {
								setArray[0][element.value] = this.numberFormat(tax10000[this.family] + 1372 + (value - 10000) * 0.98 * 0.38 * 10)
								setArray[0].taxSum += tax10000[this.family] + 1372 + (value - 10000) * 0.98 * 0.38 * 10
							}
						} else if (value > 10000) {
							const tax10000 = this.taxList[element.utils][this.taxList[element.utils].length - 1][10000]
							if (element.utilsPercent) {
								setArray[0][element.value] = this.numberFormat(
									Math.floor(((tax10000[this.family] + (value - 10000) * 0.98 * 0.35 * 10) * element.utilsPercent) / 10) * 10,
								)
								setArray[0].taxSum +=
									Math.floor(((tax10000[this.family] + (value - 10000) * 0.98 * 0.35 * 10) * element.utilsPercent) / 10) * 10
							} else {
								setArray[0][element.value] = this.numberFormat(tax10000[this.family] + (value - 10000) * 0.98 * 0.35 * 10)
								setArray[0].taxSum += tax10000[this.family] + (value - 10000) * 0.98 * 0.35 * 10
							}
						} else {
							this.taxList[element.utils].forEach(tax => {
								if (Number(Object.keys(tax)[0]) === value) {
									if (element.utilsPercent) {
										setArray[0][element.value] = this.numberFormat(Math.floor((tax[value][this.family] * element.utilsPercent) / 10) * 10)
										setArray[0].taxSum += Math.floor((tax[value][this.family] * element.utilsPercent) / 10) * 10
									} else {
										setArray[0][element.value] = this.numberFormat(Math.floor(tax[value][this.family] / 10) * 10)
										setArray[0].taxSum += Math.floor(tax[value][this.family] / 10) * 10
									}
								}
							})
						}
					}
				})
				setArray[0].taxSum = this.numberFormat(setArray[0].taxSum)
				setArray[0].money = this.numberFormat(salary + bonus - Number(setArray[0].taxSum.replace(/,/g, '')))
				this.taxDatatable.items = setArray
			} else {
				this.taxDatatable.items = []
			}
			return (this.salary = this.numberFormat(val.replace(/,/g, '')))
		},
		moneyTextField2Input(val) {
			this.moneyTextField2['error-messages'] = ''
			this.salary = null
			const wage = this.wage ? Number(this.wage.replace(/,/g, '')) : 0
			const bonus = this.bonus ? Number(this.bonus.replace(/,/g, '')) : 0
			const setArray = [{}]
			if (Number(wage) > 1060000 / 4) {
				setArray[0].taxSum = 0
				this.taxDatatable.headers.forEach(element => {
					if (element.percent) {
						if (element.relationship) {
							setArray[0][element.value] = this.numberFormat(
								Math.floor((Number(setArray[0][element.relationship].replace(/,/g, '')) * element.percent) / 10) * 10,
							)
							setArray[0].taxSum += Math.floor((Number(setArray[0][element.relationship].replace(/,/g, '')) * element.percent) / 10) * 10
						} else {
							setArray[0][element.value] = this.numberFormat(Math.floor(((wage * 4 + bonus) * element.percent) / 10) * 10)
							setArray[0].taxSum += Math.floor(((wage * 4 + bonus) * element.percent) / 10) * 10
						}
					}
					if (element.utils) {
						const value = Math.round((wage * 4 + bonus) / 10000) * 10
						this.taxList[element.utils].forEach(tax => {
							if (Number(Object.keys(tax)[0]) === value) {
								if (element.utilsPercent) {
									setArray[0][element.value] = this.numberFormat(Math.floor((tax[value][this.family] * element.utilsPercent) / 10) * 10)
									setArray[0].taxSum += Math.floor((tax[value][this.family] * element.utilsPercent) / 10) * 10
								} else {
									setArray[0][element.value] = this.numberFormat(Math.floor(tax[value][this.family] / 10) * 10)
									setArray[0].taxSum += Math.floor(tax[value][this.family] / 10) * 10
								}
							}
						})
					}
				})
				setArray[0].taxSum = this.numberFormat(setArray[0].taxSum)
				setArray[0].money = this.numberFormat(wage * 4 + bonus - Number(setArray[0].taxSum.replace(/,/g, '')))
				this.taxDatatable.items = setArray
			} else {
				this.taxDatatable.items = []
			}
			return (this.wage = this.numberFormat(val.replace(/,/g, '')))
		},
		moneyTextField3Input(val) {
			this.moneyTextField3['error-messages'] = ''
			if (this.salary !== null) {
				this.moneyTextField1Input(this.salary)
			} else if (this.wage !== null) {
				this.moneyTextField3Input(this.wage)
			}
			return (this.bonus = this.numberFormat(val.replace(/,/g, '')))
		},
		numberFormat(inputNumber) {
			return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		paymentDate() {
			this.paymentPicker.errorMessage = ''
			this.paymentPicker.menu = false
		},
		moneyDate() {
			this.moneyStartpicker.menu = false
			this.moneyEndpicker.menu = false
			this.monies(this.moneyStartpicker.date, this.moneyEndpicker.date)
		},
		moneyCouselor(val) {
			let start = this.$moment(this.moneyStartpicker.date).format('YYMMDD')

			let startDate = new Date(this.moneyStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 3650))
			let endDate = this.$moment(end).format('YYMMDD')

			val = this.$moment(val).format('YYMMDD') < endDate && this.$moment(val).format('YYMMDD') > start
			return val
		},
		monies(moneyday_gte, moneyday_lte) {
			const data = {
				payment_gte: moneyday_gte,
				payment_lte: moneyday_lte,
				user: this.data.user.id,
			}
			this.$store
				.dispatch('monies', data)
				.then(res => {
					this.exportDatas = res.monies
					this.moneyDatatable.items = []
					res.monies.forEach(element => {
						this.moneyDatatable.items.push({
							id: element.id,
							payment: element.payment,
							users_permissions_user: element.users_permissions_user,
							salary: this.numberFormat(element.salary),
							wage: this.numberFormat(element.wage),
							bonus: this.numberFormat(element.bonus),
							tax: element.tax,
							ActualPayment: this.numberFormat(
								element.salary + element.wage * 4 + element.bonus - Number(element.tax.items.taxSum.replace(/,/g, '')),
							),
						})
					})
				})
				.catch(err => {
					console.log({ err })
				})
		},
		updateMoney() {
			if (this.salary === null && this.wage === null) {
				return alert('reqired salary or wage')
			}
			if (this.salary !== null && this.wage !== null) {
				return alert('reqired salary or wage required just One')
			}
			this.taxDatatable.items[0].family = this.family
			const tax = {
				headers: this.taxDatatable.headers,
				items: this.taxDatatable.items[0],
			}
			const salary = this.salary ? Number(this.salary.replace(/,/g, '')) : 0
			const wage = this.wage ? Number(this.wage.replace(/,/g, '')) : 0
			const bonus = this.bonus ? Number(this.bonus.replace(/,/g, '')) : 0
			const data = {
				salary: salary,
				wage: wage,
				bonus: bonus,
				user: this.data.user.id,
				payment: this.paymentPicker.date,
				tax: tax,
				id: this.editId,
			}
			this.$store
				.dispatch('updateMoney', data)
				.then(() => {
					alert('update money')
					this.monies(this.moneyStartpicker.date, this.moneyEndpicker.date)
				})
				.catch(err => {
					console.log({ err })
				})
		},
		createMoney() {
			if (this.type === 'edit') {
				this.updateMoney()
			} else {
				if (this.salary === null && this.wage === null) {
					return alert('reqired salary or wage')
				}
				if (this.salary !== null && this.wage !== null) {
					return alert('reqired salary or wage required just One')
				}
				this.taxDatatable.items[0].family = this.family
				const tax = {
					headers: this.taxDatatable.headers,
					items: this.taxDatatable.items[0],
				}
				const salary = this.salary ? Number(this.salary.replace(/,/g, '')) : 0
				const wage = this.wage ? Number(this.wage.replace(/,/g, '')) : 0
				const bonus = this.bonus ? Number(this.bonus.replace(/,/g, '')) : 0
				const data = {
					salary: salary,
					wage: wage,
					bonus: bonus,
					user: this.data.user.id,
					payment: this.paymentPicker.date,
					tax: tax,
				}
				this.$store
					.dispatch('createMoney', data)
					.then(() => {
						alert('created money')
						this.monies(this.moneyStartpicker.date, this.moneyEndpicker.date)
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
	},
	created() {
		if (this.$route.name === 'Operating') {
			this.moneyDatatable.headers = [
				{ text: 'No', value: 'No', sortable: false },
				{ text: 'Payment', value: 'payment' },
				{ text: 'User', value: 'users_permissions_user.Name' },
				{ text: 'Salary', value: 'salary' },
				{ text: 'Wage', value: 'wage' },
				{ text: 'Bonus', value: 'bonus' },
				{ text: 'Tax', value: 'tax.items.taxSum' },
				{ text: 'Actual Payment', value: 'ActualPayment' },
				{ text: 'Action', value: 'taxAction', sortable: false },
			]
		} else {
			this.moneyDatatable.headers = [
				{ text: 'No', value: 'No', sortable: false },
				{ text: 'Payment', value: 'payment' },
				{ text: 'User', value: 'users_permissions_user.Name' },
				{ text: 'Salary', value: 'salary' },
				{ text: 'Wage', value: 'wage' },
				{ text: 'Bonus', value: 'bonus' },
				{ text: 'Tax', value: 'tax.items.taxSum' },
				{ text: 'Actual Payment', value: 'ActualPayment' },
			]
		}
		this.taxList.koTax = koTax
		this.moneyStartpicker.date = this.$moment(new Date()).format('YYYY-') + '01-01'
		this.moneyEndpicker.date = this.$moment(new Date())
			.add(90, 'days')
			.format('YYYY-MM-DD')
	},
}
</script>
