<template>
	<v-card class="pa-4 report-card">
		<v-layout v-for="(qus, i) in data.qusList" :key="i" class="mb-2" wrap>
			<v-flex xs12>
				<h3 class="FontColor1--text">
					<span class="PointColor1--text"> Qus {{ i + 1 }}.</span> {{ qus.title }}
				</h3>
				<p class="FontColor2--text mb-1">{{ qus.subtitle }}</p>
				<v-radio-group hide-details style="margin-top:0px;" v-model="qus.answer" v-if="qus.type === 'radio'">
					<v-radio
						v-for="(radio, radioIndex) in qus.data"
						:label="radio.name"
						:key="radioIndex"
						:ripple="false"
						class="mr-4"
						:value="radio.name"
						color="PointColor1"
					></v-radio>
				</v-radio-group>
				<v-text-field
					v-else-if="qus.type === 'textfiled'"
					v-model="qus.answer"
					hide-details
					solo
					:placeholder="qus.title"
					max-length="255"
					class="pa-0 ma-0"
					clearable
					autocomplete="off"
					light
					color="PointColor1"
				></v-text-field>
				<v-textarea
					v-else-if="qus.type === 'textarea'"
					v-model="qus.answer"
					hide-details
					solo
					height="100"
					autocomplete="off"
					:placeholder="qus.title"
					max-length="25"
					class="pa-0 ma-0"
					clearable
					light
					noResize
					color="PointColor1"
				></v-textarea>
			</v-flex>
		</v-layout>
		<div v-if="data.tab === 2">
			<h3 class="FontColor1--text">
				<span class="PointColor1--text"> Rank.</span>
			</h3>
			<v-radio-group row hide-details style="margin-top:0px;" v-model="data.rank">
				<v-radio
					v-for="(radio, radioIndex) in ranks"
					:label="radio"
					:key="radioIndex"
					:ripple="false"
					class="mr-4"
					:value="radioIndex"
					color="PointColor1"
				></v-radio>
			</v-radio-group>
		</div>
		<div class="mt-4">
			<v-badge color="PointColor1" :content="submitBage" overlap block style="width:100%;">
				<v-btn :disabled="data.tab === 2 && !data.rank" block color="PointColor2" @click="$emit('create', $event)"> submit</v-btn>
			</v-badge>
		</div>
	</v-card>
</template>
<script>
export default {
	data() {
		return {
			ranks: ['D', 'C', 'B', 'A', 'S'],
		}
	},
	props: {
		data: {
			type: Object,
		},
		submitBage: {
			type: String,
			default() {
				return '0'
			},
		},
	},
}
</script>
<style lang="scss">
.report-card {
	p {
		margin-bottom: 0px;
		font-weight: 500;
		font-size: 12px;
	}
}
</style>
