<template>
	<div v-if="WebViewCards.length > 0" id="web-view-main">
		<v-app-bar
			fixed
			dark
			tile
			flat
			height="64"
			class="main-toolbar"
			:color="WebViewCards[0].backgroundColor === '#000000' ? 'transparent' : WebViewCards[0].backgroundColor"
		>
			<v-toolbar-title>
				<v-img :src="WebViewCards[0].logo" contain :max-height="WebViewCards[0].LogoSize" :max-width="80"></v-img>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<div v-show="$vuetify.breakpoint.mdAndUp">
				<v-btn
					v-for="(list, listIndex) in WebViewCards"
					:key="listIndex"
					text
					:color="WebViewCards[0].menusColor"
					v-show="list.active && list.type !== 'header' && list.type !== 'Footer'"
					@click="scrolling(list.type)"
				>
					{{ list.type }}
				</v-btn>
				<v-btn v-show="$vuetify.breakpoint.mdAndUp" icon v-for="sns in activeSns" :key="sns" :color="WebViewCards[0].menusColor">
					<v-icon>mdi-{{ sns }}</v-icon>
				</v-btn>
			</div>
			<v-app-bar-nav-icon v-show="$vuetify.breakpoint.mdAndDown" @click="showMenus = !showMenus"></v-app-bar-nav-icon>
			<template v-slot:extension v-if="showMenus">
				<v-layout wrap :style="`background-color:${WebViewCards[0].backgroundColor};`">
					<v-flex xs12 v-for="(list, listIndex) in WebViewCards" :key="listIndex">
						<v-divider :color="WebViewCards[0].menusColor" v-show="list.active"></v-divider>
						<v-btn
							text
							:color="WebViewCards[0].menusColor"
							v-show="list.active && list.type !== 'header' && list.type !== 'Footer'"
							@click="scrolling(list.type)"
							block
						>
							{{ list.type }}
						</v-btn>
						<v-btn
							v-show="$vuetify.breakpoint.mdAndUp"
							icon
							v-for="sns in activeSns"
							:key="sns"
							:color="WebViewCards[0].color_picker_header.menusColor"
						>
							<v-icon>mdi-{{ sns }}</v-icon>
						</v-btn>
					</v-flex>
				</v-layout>
			</template>
		</v-app-bar>
		<v-layout wrap class="main">
			<v-flex xs12>
				<WebViewCards :data="WebViewCards" />
			</v-flex>
		</v-layout>
		<!-- <form name="KB_AUTHMARK_FORM" method="get">
			<input type="hidden" name="page" value="B009111" />
			<input type="hidden" name="cc" value="b010807:b008491" />
			<input type="hidden" name="mHValue" value="321ac94de6659e0b4879a32fa33ff156" />
		</form>
		<a href="#" onclick="javascript:onPopKBAuthMark();return false;">
			<img src="http://img1.kbstar.com/img/escrow/escrowcmark.gif" border="0" />
		</a> -->
	</div>
</template>
<script>
import { WebViewCards } from '@/components/index.js'
export default {
	components: {
		WebViewCards,
	},
	name: 'main',
	metaInfo: {
		title: 'Protect',
	},
	data() {
		return { showMenus: false, WebViewCards: [], activeSns: [] }
	},
	methods: {
		onPopKBAuthMark() {
			window.open('', 'KB_AUTHMARK', 'height=604, width=648, status=yes, toolbar=no, menubar=no,location=no')
			document.KB_AUTHMARK_FORM.action = 'http://escrow1.kbstar.com/quics'
			document.KB_AUTHMARK_FORM.target = 'KB_AUTHMARK'
			document.KB_AUTHMARK_FORM.submit()
		},
		scrolling(type) {
			this.showMenus = false
			document.getElementById(type).scrollIntoView({ block: 'start', behavior: 'smooth' })
		},
		showWeb() {
			this.$store.state.loading = true
			const data = {
				show: true,
			}
			this.$store
				.dispatch('showWeb', data)
				.then(res => {
					this.$store.state.loading = false
					this.WebViewCards = res.webs[0].html
					setTimeout(() => {
						this.FontStyle(res.webs[0].html[0].font)
					}, 100)
				})
				.catch(err => {
					console.log({ err })
					this.$store.state.loading = false
				})
		},
		FontStyle(val) {
			const link = document.createElement('link')
			if (val === 'Noto Sans KR') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap'
				document.getElementById('web-view-main').style = "font-family: 'Noto Sans KR', sans-serif;"
			} else if (val === 'Roboto') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap'
				document.getElementById('web-view-main').style = "font-family: 'Roboto', sans-serif;"
			} else if (val === 'Nanum Gothic') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap'
				document.getElementById('web-view-main').style = "font-family: 'Nanum Gothic', sans-serif;"
			} else if (val === 'Gothic A1') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap'
				document.getElementById('web-view-main').style = "font-family: 'Gothic A1', sans-serif;"
			} else if (val === 'Nanum Myeongjo') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap'
				document.getElementById('web-view-main').style = "font-family: 'Nanum Myeongjo', serif;"
			} else if (val === 'Noto Serif KR') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@200;300;400;500;600;700;900&display=swap'
				document.getElementById('web-view-main').style = "font-family: 'Noto Serif KR', serif;"
			} else if (val === 'Black Han Sans') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap'
				document.getElementById('web-view-main').style = "font-family: 'Black Han Sans', sans-serif;"
			}
			document.getElementsByTagName('head')[0].appendChild(link)
		},
	},
	created() {
		window.open('https://protectx.imweb.me', '_self')
		// this.showWeb()
	},
}
</script>
<style lang="scss">
.main-toolbar {
	.v-toolbar__extension {
		padding: 0 !important;
		height: auto !important;
	}
}
.main {
	margin-top: 64px;
	height: 94vh;
	overflow: auto;
}
</style>
