<template>
	<v-layout wrap class="pa-4" justify-center>
		<v-flex xs12>
			<v-hover v-slot="{ hover }">
				<v-card :elevation="hover ? 12 : 2" class="cursor" dark height="200" @click="$emit('click', $event, data)">
					<v-layout wrap align-center justify-center style="height:200px;">
						<div class="text-center" v-if="data.html.filter(y => y.active).map(x => x.type).length === 0">
							<v-icon large color="error">mdi-code-not-equal-variant</v-icon><br />
							<h3>we need html</h3>
						</div>
						<v-chip-group column class="mx-4" v-else>
							<v-chip
								outlined
								v-for="(list, i) in data.html.filter(y => y.active).map(x => x.type)"
								:key="i"
								dark
								:value="list"
								class="viewport-item"
								v-show="list !== 'header'"
							>
								{{ list }}
							</v-chip>
						</v-chip-group>
					</v-layout>
				</v-card>
			</v-hover>
			<v-layout align-center class="mt-2">
				<div class="white--text">
					<h3>
						{{ data.name }}
					</h3>
					<div>
						<v-layout class="mmt-2" align-center>
							<span class="mx-1 created">
								{{ DateChange(data.created_at) }}
							</span>
							<span>
								<v-icon v-if="data.active" color="success" size="12">mdi-eye-check</v-icon>
								<v-icon v-else color="warning" size="12">mdi-eye-off</v-icon>
							</span>
						</v-layout>
					</div>
				</div>
				<v-spacer></v-spacer>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on" class="mmt-2" small>
							<v-icon color="grey">mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item
							v-for="(item, i) in items"
							:key="i"
							class="cursor"
							@click="$emit('list', $event, i, data)"
							v-show="data.active ? i !== 0 : i !== 1"
						>
							<v-list-item-title
								><v-icon class="mr-2">{{ item.icon }}</v-icon> {{ item.title }}</v-list-item-title
							>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	props: {
		cardClick: Function,
		mainSrc: String,
		data: Object,
	},
	data() {
		return {
			items: [
				{ title: '공개', icon: 'mdi-eye-check' },
				{ title: '비공개', icon: 'mdi-eye-off' },
				{ title: '수정', icon: 'mdi-pencil' },
				{ title: '삭제', icon: 'mdi-delete' },
			],
		}
	},
	methods: {
		DateChange(date) {
			var today = new Date()
			var dateString = date.substring(0, 10)
			var dateArray = dateString.split('-')
			var dateObj = new Date(dateArray[0], Number(dateArray[1]) - 1, dateArray[2])
			var betweenDay = (today.getTime() - dateObj.getTime()) / 1000 / 60 / 60 / 24
			var betweenMonth = (today.getTime() - dateObj.getTime()) / 1000 / 60 / 60 / 24 / 30
			var betweenYear = (today.getTime() - dateObj.getTime()) / 1000 / 60 / 60 / 24 / 30 / 12

			if (betweenDay < 32) {
				return Math.floor(betweenDay) + ' days ago'
			} else if (betweenDay > 32 && betweenDay < 365) {
				return Math.floor(betweenMonth) + ' months ago'
			} else {
				return Math.floor(betweenYear) + ' years ago'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.card_title {
	color: black;
	font-weight: bold;
}

.box {
	overflow: hidden;
}
.main_Img:hover {
	opacity: 0.5;
	height: 100%;
	width: 100%;
	object-fit: cover;
	transform: scale(1.1);
	transition: all 0.2s linear;
}
.card_content {
	font-size: 14px;
	font-weight: 500;
	line-height: 25px;
	.card_Subtitle {
		font-size: 12px;
	}
}
</style>
<style></style>
