<template>
	<div class="add-list">
		<input
			v-if="isAddList"
			type="text"
			class="form-control"
			v-model="inputTitle"
			ref="inputTitle"
			@blur="onCancle"
			@keyup.esc="onCancle"
			@keyup.enter.prevent.stop="onsubmitTitle"
		/>
		<a href="" v-else @click.prevent="onAddList">&plus; Add another list</a>
	</div>
</template>
<script>
export default {
	data() {
		return {
			isAddList: false, // add list 클릭시 인풋창 boolean
			inputTitle: '', // list 타이틀
			updata: '', // 새롭게 생성된 list 의 정보(title, pos)
			loading: false,
		}
	},
	methods: {
		// 리스트 인풋창 포커스
		onAddList() {
			this.isAddList = true
			this.$nextTick(() => this.$refs.inputTitle.focus())
		},
		// 리스트 타이트 수정 취소 (blur, esc)
		onCancle() {
			this.isAddList = false
			this.inputTitle = ''
		},
		// 리스트 생성, Board에 리스트 생성에밋 addList
		async onsubmitTitle() {
			this.inputTitle = this.inputTitle.trim()
			if (this.$store.state.board.cards) {
				var lastList = this.$store.state.board.cards[this.$store.state.board.cards.length - 1]
			}
			const Title = this.inputTitle
			this.$store.state.loading = true
			await this.$store.dispatch('DETAILBOARD', this.$store.state.board.id).then(res => {
				const data = res.memos[0].cards
				if (data) {
					data.push({
						title: Title,
						pos: lastList ? lastList.pos * 2 : 65535,
						islist: true,
					})
					const updata = {
						id: this.$store.state.board.id,
						cards: data,
					}
					this.updata = updata
				} else {
					const updata = {
						id: this.$store.state.board.id,
						cards: [
							{
								title: Title,
								pos: lastList ? lastList.pos * 2 : 65535,
								islist: true,
							},
						],
					}
					this.updata = updata
				}
			})

			this.$store.dispatch('UPDATEBOARD', this.updata).then(() => {
				this.$store.state.loading = false
				this.$emit('addList')
				this.isAddList = false
				this.inputTitle = ''
			})
		},
	},
}
</script>
<style>
.add-list {
	background-color: rgba(0, 0, 0, 0.1);
	padding: 12px;
	color: #ddd;
	transition: all 0.3s;
}
.add-list a {
	color: #ddd;
}
.add-list:hover,
.add-list:focus {
	background-color: rgba(0, 0, 0, 0.3);
	cursor: pointer;
}
.form-control {
	color: aliceblue;
}
.loading-bar {
	position: absolute;
	top: 45%;
	right: 50%;
}
</style>
