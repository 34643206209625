<template>
	<v-dialog v-model="data.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog" dark>
		<v-app-bar dense fixed>
			<v-menu open-on-hover bottom offset-y class="ml-8">
				<template v-slot:activator="{ on, attrs }">
					<v-btn depressed v-bind="attrs" v-on="on">
						edit
					</v-btn>
				</template>
				<v-list>
					<v-list-item class="cursor" @click="saveDialog">
						<v-list-item-title class="success--text"> Save</v-list-item-title>
					</v-list-item>
					<v-list-item class="cursor" @click="data.dialog = false">
						<v-list-item-title class="error--text">Dont Changes</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-spacer></v-spacer>
			<v-toolbar-title class="text-center">{{ data.name }}</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" dark @click="displayValue">
						<v-icon>mdi-cellphone</v-icon>
					</v-btn>
				</template>
				<span class="ma-4 text-white">Mobile Preview</span>
			</v-tooltip>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on" dark @click=";(styleDrawer = !styleDrawer), (optionType = 'Site Style')">
						<v-icon>mdi-broom</v-icon>
					</v-btn>
				</template>
				<span class="ma-4  text-white">Site Style</span>
			</v-tooltip>
		</v-app-bar>
		<v-navigation-drawer v-model="styleDrawer" dark right app clipped class="pt-2 px-4" width="500">
			<div class="text-right">
				<v-btn icon @click="styleDrawer = !styleDrawer">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
			<h1 class="white--text pt-4 style-title">
				{{ optionType }}
			</h1>
			<div v-show="optionType === 'Header Style'">
				<div>
					<h3 class="white--text">
						Main Logo
					</h3>
					<v-card dark class="mb-2">
						<div class="pt-4 mx-4">
							<v-img style="margin:0 auto" :src="editLogo ? editLogo : server + logoUrl" max-height="200" min-height="200" contain></v-img>
						</div>
						<div class="text-center px-4 pb-4" block>
							<v-btn block color="PointColor2" @click="LogoUpload">Logo Change</v-btn>
						</div>
					</v-card>
				</div>
				<div v-show="editLogo ? editLogo : logoUrl">
					<h3 class="white--text">
						Logo Size
					</h3>
				</div>
				<v-slider
					v-show="editLogo ? editLogo : logoUrl"
					hideDetails
					v-model="LogoSize"
					color="black"
					min="12"
					max="120"
					step="1"
					thumb-label
					ticks
				></v-slider>
				<colorPicker v-model="color_picker_header.headerColor" :color_picker="color_picker_header" />
				<colorPicker v-model="color_picker_header.menusColor" :color_picker="color_picker_h1" />
				<combobox class="mt-2" v-model="lists" text="Compoent List" clearable v-show="false" />
				<div class="mt-2">
					<h3 class="white--text">
						Socal Link
					</h3>
					<v-card dark class="mb-2 px-2">
						<v-chip-group v-model="activeSns" column multiple>
							<v-chip filter outlined v-for="socal in sns" :key="socal" :value="socal">
								<v-icon class="mr-1">mdi-{{ socal }}</v-icon>
								{{ socal }}
							</v-chip>
						</v-chip-group>
						<textField
							class="pb-2"
							v-show="activeSns.includes('instagram')"
							:textField="snsTextField.instagram"
							v-model="snsLinks.instagram"
						/>
						<textField
							class="pb-2"
							v-show="activeSns.includes('facebook')"
							:textField="snsTextField.facebook"
							v-model="snsLinks.facebook"
						/>
						<textField class="pb-2" v-show="activeSns.includes('youtube')" :textField="snsTextField.youtube" v-model="snsLinks.youtube" />
					</v-card>
				</div>
			</div>
			<div v-show="optionType === 'Site Style'">
				<div class="mb-2">
					<h3 class="white--text">
						Site Name
					</h3>
					<textField class="" :textField="siteNameTextfiled" v-model="siteName" />
				</div>
				<div class="mb-2">
					<h3 class="white--text">
						Site Favicon
					</h3>
					<v-card dark>
						<div class="pt-4 mx-4">
							<v-img style="margin:0 auto" :src="editLogo ? editLogo : server + logoUrl" max-height="100" min-height="100"></v-img>
						</div>
						<div class="text-center px-4 pb-4" block>
							<v-btn block color="PointColor2" @click="LogoUpload('favicon')">Favicon Change</v-btn>
						</div>
					</v-card>
				</div>
				<div>
					<h3 class="white--text">
						Web Font Style
					</h3>
					<v-select
						@change="FontStyle"
						hideDetails
						item-color="PointColor2"
						:items="FontList"
						solo
						dense
						full-width
						v-model="Font"
						color="PointColor2"
					></v-select>
				</div>
				<div class="mt-2">
					<h3 class="white--text">
						Active Compoents (Change order by dragging)
					</h3>
					<v-chip-group v-model="activeCompoent" column multiple active-class="PointColor1--text">
						<draggable v-model="WebViewCards" draggable=".viewport-item" ghostClass="sortable-ghost" animation="150">
							<v-chip
								filter
								outlined
								v-for="(list, i) in WebViewCards"
								:key="i"
								dark
								:value="list.type"
								class="viewport-item"
								v-show="list.type !== 'header'"
							>
								{{ list.type }}
							</v-chip>
						</draggable>
					</v-chip-group>
				</div>
			</div>
			<div v-for="(webView, index) in WebViewCards" :key="index" class="mb-4">
				<div v-if="optionType === webView.type">
					<div>
						<h3 class="white--text">
							Layout-max-width
						</h3>
					</div>
					<v-slider hideDetails v-model="webView.maxWidth" color="black" min="400" max="2580" step="10" thumb-label ticks></v-slider>
					<div>
						<h3 class="white--text">
							Layout-max-height
						</h3>
					</div>
					<v-slider hideDetails v-model="webView.height" color="black" min="100" max="2580" step="10" thumb-label ticks></v-slider>
					<colorPicker v-model="webView.titleColor" :color_picker="color_picker_L2" />
					<colorPicker v-model="webView.backgroundColor" :color_picker="color_picker_L1" />
					<div class="mt-2">
						<h3 class="white--text">
							Layout-Background-Img
						</h3>
					</div>
					<v-file-input
						background-color="#000000"
						label="Img input"
						filled
						prepend-icon=""
						prepend-inner-icon="mdi-camera"
						accept="image/*"
						:value="webView.file"
						@change="backgroundImg($event, index)"
						hideDetails
					></v-file-input>
					<div class="mt-2">
						<h3 class="white--text">
							Layout-Background-Img-None
						</h3>
					</div>
					<v-btn class="mb-4" color="PointColor1" @click="backgroundNone(index)">
						None
					</v-btn>
					<v-divider class="mb-4"></v-divider>
				</div>
				<div v-if="optionType === 'About' && optionType === webView.type">
					<div v-for="(card, i) in webView.cards" :key="i">
						<div class="mt-2">
							<h3 class="white--text">
								Align Title
							</h3>
						</div>
						<v-chip-group mandatory active-class="PointColor1--text" v-model="card.titleAlign">
							<v-chip v-for="align in aligns" :key="align" :value="align">
								{{ align }}
							</v-chip>
						</v-chip-group>
						<colorPicker v-model="card.titleColor" :color_picker="color_picker_f1" />
						<div class="mt-2">
							<h3 class="white--text">
								Title Size
							</h3>
						</div>
						<v-slider hideDetails v-model="card.titleSize" color="black" min="4" max="200" step="1" thumb-label ticks></v-slider>
						<div class="mt-2">
							<h3 class="white--text">
								Title
							</h3>
						</div>
						<textField class="" :textField="serviceNameTextfiled" v-model="card.title" />
						<div class="mt-2">
							<h3 class="white--text">
								Align subTitle
							</h3>
						</div>
						<v-chip-group mandatory active-class="PointColor1--text" v-model="card.subTitleAlign">
							<v-chip v-for="align in aligns" :key="align" :value="align">
								{{ align }}
							</v-chip>
						</v-chip-group>
						<colorPicker v-model="card.subTitleColor" :color_picker="color_picker_f2" />
						<div class="mt-2">
							<h3 class="white--text">
								subTitle Size
							</h3>
						</div>
						<v-slider hideDetails v-model="card.subTitleSize" color="black" min="4" max="200" step="1" thumb-label ticks></v-slider>
						<div class="mt-2">
							<h3 class="white--text">
								subTitle
							</h3>
						</div>
						<v-textarea v-model="card.subTitle" solo background-color="#000000" color="#f7f7f7ff" no-resize />
					</div>
				</div>
				<div v-if="optionType === 'Footer' && optionType === webView.type">
					<div v-for="(card, i) in webView.cards" :key="i">
						<div class="mt-2">
							<h3 class="white--text">
								Align Content
							</h3>
						</div>
						<v-chip-group mandatory active-class="PointColor1--text" v-model="card.subTitleAlign">
							<v-chip v-for="align in aligns" :key="align" :value="align">
								{{ align }}
							</v-chip>
						</v-chip-group>
						<colorPicker v-model="card.subTitleColor" :color_picker="color_picker_f2" />
						<div class="mt-2">
							<h3 class="white--text">
								Content Size
							</h3>
						</div>
						<v-slider hideDetails v-model="card.subTitleSize" color="black" min="4" max="200" step="1" thumb-label ticks></v-slider>
						<div class="mt-2">
							<h3 class="white--text">
								Content
							</h3>
						</div>
						<v-textarea v-model="card.subTitle" solo background-color="#000000" color="#f7f7f7ff" no-resize />
						<colorPicker v-model="card.menusColor" :color_picker="color_picker_f4" />
						<colorPicker v-model="card.reservedColor" :color_picker="color_picker_f3" />
						<div class="mt-2">
							<h3 class="white--text">
								All right reserved
							</h3>
						</div>
						<textField class="" :textField="serviceNameTextfiled" v-model="card.title" />
					</div>
				</div>
				<div v-if="optionType === 'Contact' && optionType === webView.type">
					<div>
						<h3 class="white--text">
							Fields Dark Mode
						</h3>
					</div>
					<v-checkbox
						hideDetails
						class="mt-0 mb-2"
						v-model="webView.fields.fieldsDark"
						:label="webView.fields.fieldsDark ? 'Active' : 'None'"
					></v-checkbox>
					<colorPicker v-model="webView.fields.color" :color_picker="color_picker_c1" />
					<div>
						<h3 class="white--text">
							Btn Dark Mode
						</h3>
					</div>
					<v-checkbox
						hideDetails
						class="mt-0 mb-2"
						v-model="webView.fields.dark"
						:label="webView.fields.dark ? 'Active' : 'None'"
					></v-checkbox>
					<colorPicker v-model="webView.fields.btnColor" :color_picker="color_picker_b2" />
				</div>
				<div v-if="optionType === 'Service' && optionType === webView.type">
					<v-layout align-center class="mb-2">
						<v-sheet elevation="5" class="px-2" max-width="280px" v-show="webView.cards.length > 0">
							<v-chip-group mandatory active-class="success--text" v-model="tag">
								<v-chip v-for="tag in webView.cards.length" :key="tag">
									{{ tag }}
								</v-chip>
							</v-chip-group>
						</v-sheet>
						<v-spacer></v-spacer>
						<div>
							<v-btn color="success" @click="addServices(index, webView.type)">
								add
							</v-btn>
							<v-btn color="error" class="ml-2" v-if="webView.cards.length > 0" @click="deleteServices(index, tag, webView.type)">
								delete
							</v-btn>
						</div>
					</v-layout>
					<div v-for="(card, i) in webView.cards" :key="i" v-show="tag === i">
						<div>
							<h3 class="white--text">
								Dark Mode
							</h3>
						</div>
						<v-checkbox hideDetails class="mt-0 mb-2" v-model="card.dark" :label="card.dark ? 'Active' : 'None'"></v-checkbox>
						<div>
							<h3 class="white--text">
								Width
							</h3>
						</div>
						<v-slider hideDetails v-model="card.width" color="black" min="200" max="500" step="10" thumb-label ticks></v-slider>
						<colorPicker v-model="card.backgroundColor" :color_picker="color_picker_m1" />
						<div class="mt-2">
							<h3 class="white--text">
								Icon
							</h3>
						</div>
						<v-select
							hideDetails
							background-color="#000000"
							item-color="gray"
							:items="icons"
							solo
							dense
							full-width
							v-model="card.icon"
							color="black"
						>
							<template v-slot:selection="{ item }">
								<div class="text-center" style="width:100%;">
									<v-icon>mdi-{{ item.name }}</v-icon>
								</div>
							</template>
							<template v-slot:item="{ item }">
								<div class="text-center" style="width:100%;">
									<v-icon>mdi-{{ item.name }}</v-icon>
								</div>
							</template>
						</v-select>
						<div class="mt-2">
							<h3 class="white--text">
								Title
							</h3>
						</div>
						<textField class="" :textField="serviceNameTextfiled" v-model="card.title" />
						<div class="mt-2">
							<h3 class="white--text">
								SubTitle
							</h3>
						</div>
						<v-textarea v-model="card.subTitle" solo background-color="#000000" color="#f7f7f7ff" no-resize />
					</div>
				</div>
				<div v-else-if="optionType === 'Portfolio' && optionType === webView.type">
					<v-layout align-center class="mb-2">
						<v-sheet elevation="5" class="px-2" max-width="280px" v-show="webView.cards.length > 0">
							<v-chip-group mandatory active-class="success--text" v-model="tag">
								<v-chip v-for="tag in webView.cards.length" :key="tag">
									{{ tag }}
								</v-chip>
							</v-chip-group>
						</v-sheet>
						<v-spacer></v-spacer>
						<div>
							<v-btn color="success" @click="addServices(index, webView.type)">
								add
							</v-btn>
							<v-btn color="error" class="ml-2" v-if="webView.cards.length > 0" @click="deleteServices(index, tag, webView.type)">
								delete
							</v-btn>
						</div>
					</v-layout>
					<div v-for="(card, i) in webView.cards" :key="i" v-show="tag === i">
						<div>
							<h3 class="white--text">
								Flat Mode
							</h3>
						</div>
						<v-checkbox hideDetails class="mt-0 mb-2" v-model="card.flat" :label="card.flat ? 'Active' : 'None'"></v-checkbox>
						<div>
							<h3 class="white--text">
								Width
							</h3>
						</div>
						<v-slider hideDetails v-model="card.width" color="black" min="200" max="500" step="10" thumb-label ticks></v-slider>
						<div>
							<h3 class="white--text">
								Height
							</h3>
						</div>
						<v-slider hideDetails v-model="card.height" color="black" min="200" max="500" step="10" thumb-label ticks></v-slider>
						<div class="mt-2">
							<h3 class="white--text">
								Title
							</h3>
						</div>
						<textField class="" :textField="serviceNameTextfiled" v-model="card.title" />
						<upload-images :uploadMsg="uploadImagesOptions.uploadMsg" clearAll=" " class="my-4" @changed="handleImages($event, index, i)" />
						<!-- <input id="uploadBtn" style="display:none;" type="file" accept="image/*" @change="uploadMultiple($event)" multiple /> -->
						<!-- <div v-for="(a, idx) in $store.state.reserveusersData.fileUpload" :key="idx">
						<span class="visitexplain" @click="imgdialog(idx)">
							{{ a.name }}
						</span>
						<v-icon size="15" @click="userimgdelete(idx)">mdi-close</v-icon>
					</div> -->
						<v-btn block @click="multifileUpload(index, i)" color="PointColor2">이미지 적용</v-btn>
					</div>
				</div>
				<div v-if="(optionType === 'Team' || optionType === 'Client') && optionType === webView.type">
					<v-layout align-center class="mb-2">
						<v-sheet elevation="5" class="px-2" max-width="280px" v-show="webView.cards.length > 0">
							<v-chip-group mandatory active-class="success--text" v-model="tag">
								<v-chip v-for="tag in webView.cards.length" :key="tag">
									{{ tag }}
								</v-chip>
							</v-chip-group>
						</v-sheet>
						<v-spacer></v-spacer>
						<div>
							<v-btn color="success" @click="addServices(index, webView.type)">
								add
							</v-btn>
							<v-btn color="error" class="ml-2" v-if="webView.cards.length > 0" @click="deleteServices(index, tag, webView.type)">
								delete
							</v-btn>
						</div>
					</v-layout>
					<div v-for="(card, i) in webView.cards" :key="i" v-show="tag === i">
						<div>
							<h3 class="white--text">
								Dark Mode
							</h3>
						</div>
						<v-checkbox hideDetails class="mt-0 mb-2" v-model="card.dark" :label="card.dark ? 'Active' : 'None'"></v-checkbox>
						<div>
							<h3 class="white--text">
								Width
							</h3>
						</div>
						<v-slider hideDetails v-model="card.width" color="black" min="100" max="500" step="10" thumb-label ticks></v-slider>
						<colorPicker v-model="card.backgroundColor" :color_picker="color_picker_m1" />
						<div class="mt-2">
							<h3 class="white--text">
								Team-Img
							</h3>
						</div>
						<v-file-input
							background-color="#000000"
							label="Img input"
							filled
							prepend-icon=""
							prepend-inner-icon="mdi-camera"
							accept="image/*"
							:value="card.file"
							hideDetails
							class="mb-2"
							@change="teamImg($event, index, i)"
						></v-file-input>
						<div class="mt-2">
							<h3 class="white--text">
								Title
							</h3>
						</div>
						<textField class="" :textField="serviceNameTextfiled" v-model="card.title" />
						<div class="mt-2">
							<h3 class="white--text">
								SubTitle
							</h3>
						</div>
						<v-textarea v-model="card.subTitle" solo background-color="#000000" color="#f7f7f7ff" no-resize />
					</div>
				</div>
				<div v-if="optionType === 'Review' && optionType === webView.type">
					<v-layout align-center class="mb-2">
						<v-sheet elevation="5" class="px-2" max-width="280px" v-show="webView.cards.length > 0">
							<v-chip-group mandatory active-class="success--text" v-model="tag">
								<v-chip v-for="tag in webView.cards.length" :key="tag">
									{{ tag }}
								</v-chip>
							</v-chip-group>
						</v-sheet>
						<v-spacer></v-spacer>
						<div>
							<v-btn color="success" @click="addServices(index, webView.type)">
								add
							</v-btn>
							<v-btn color="error" class="ml-2" v-if="webView.cards.length > 0" @click="deleteServices(index, tag, webView.type)">
								delete
							</v-btn>
						</div>
					</v-layout>
					<div v-for="(card, i) in webView.cards" :key="i" v-show="tag === i">
						<div>
							<h3 class="white--text">
								Dark Mode
							</h3>
						</div>
						<v-checkbox hideDetails class="mt-0 mb-2" v-model="card.dark" :label="card.dark ? 'Active' : 'None'"></v-checkbox>
						<div>
							<h3 class="white--text">
								Width
							</h3>
						</div>
						<v-slider hideDetails v-model="card.width" color="black" min="100" max="500" step="10" thumb-label ticks></v-slider>
						<colorPicker v-model="card.backgroundColor" :color_picker="color_picker_m1" />
						<div class="mt-2">
							<h3 class="white--text">
								Team-Img
							</h3>
						</div>
						<v-file-input
							background-color="#000000"
							label="Img input"
							filled
							prepend-icon=""
							prepend-inner-icon="mdi-camera"
							accept="image/*"
							:value="card.file"
							hideDetails
							class="mb-2"
							@change="teamImg($event, index, i)"
						></v-file-input>
						<div class="mt-2">
							<h3 class="white--text">
								Title
							</h3>
						</div>
						<textField class="" :textField="serviceNameTextfiled" v-model="card.title" />
						<div class="mt-2">
							<h3 class="white--text">
								SubTitle
							</h3>
						</div>
						<v-textarea v-model="card.subTitle" solo background-color="#000000" color="#f7f7f7ff" no-resize />
						<div class="mt-2">
							<h3 class="white--text">
								Rating
							</h3>
						</div>
						<v-rating v-model="card.rating" background-color="orange lighten-3" color="orange" large></v-rating>
					</div>
				</div>
				<div v-if="optionType === 'Price' && optionType === webView.type">
					<v-layout align-center class="mb-2">
						<v-sheet elevation="5" class="px-2" max-width="280px" v-show="webView.cards.length > 0">
							<v-chip-group mandatory active-class="success--text" v-model="tag">
								<v-chip v-for="tag in webView.cards.length" :key="tag">
									{{ tag }}
								</v-chip>
							</v-chip-group>
						</v-sheet>
						<v-spacer></v-spacer>
						<div>
							<v-btn color="success" @click="addServices(index, webView.type)">
								add
							</v-btn>
							<v-btn color="error" class="ml-2" v-if="webView.cards.length > 0" @click="deleteServices(index, tag, webView.type)">
								delete
							</v-btn>
						</div>
					</v-layout>
					<div v-for="(card, i) in webView.cards" :key="i" v-show="tag === i">
						<div>
							<h3 class="white--text">
								Dark Mode
							</h3>
						</div>
						<v-checkbox hideDetails class="mt-0 mb-2" v-model="card.dark" :label="card.dark ? 'Active' : 'None'"></v-checkbox>
						<div>
							<h3 class="white--text">
								Width
							</h3>
						</div>
						<v-slider hideDetails v-model="card.width" color="black" min="200" max="500" step="10" thumb-label ticks></v-slider>
						<colorPicker v-model="card.backgroundColor" :color_picker="color_picker_m1" />
						<v-divider class="my-4"></v-divider>
						<div class="mt-2">
							<h3 class="white--text">
								Type Align
							</h3>
						</div>
						<v-chip-group mandatory active-class="PointColor1--text" v-model="card.typeAlign">
							<v-chip v-for="align in aligns" :key="align" :value="align">
								{{ align }}
							</v-chip>
						</v-chip-group>
						<colorPicker v-model="card.typeColor" :color_picker="color_picker_t1" />
						<div class="mt-2">
							<h3 class="white--text">
								Type Size
							</h3>
						</div>
						<v-slider hideDetails v-model="card.typeSize" color="black" min="4" max="200" step="1" thumb-label ticks></v-slider>
						<div class="mt-2">
							<h3 class="white--text">
								Type
							</h3>
						</div>
						<textField class="" :textField="serviceNameTextfiled" v-model="card.type" />
						<div>
							<h3 class="white--text">
								Type bottom-padding
							</h3>
						</div>
						<v-slider hideDetails v-model="card.typePadding" color="black" min="0" max="100" step="1" thumb-label ticks></v-slider>
						<v-divider class="my-4"></v-divider>
						<div class="mt-2">
							<h3 class="white--text">
								Align Price
							</h3>
						</div>
						<v-chip-group mandatory active-class="PointColor1--text" v-model="card.titleAlign">
							<v-chip v-for="align in aligns" :key="align" :value="align">
								{{ align }}
							</v-chip>
						</v-chip-group>
						<colorPicker v-model="card.titleColor" :color_picker="color_picker_p1" />
						<div class="mt-2">
							<h3 class="white--text">
								Price Size
							</h3>
						</div>
						<v-slider hideDetails v-model="card.titleSize" color="black" min="4" max="200" step="1" thumb-label ticks></v-slider>
						<div class="mt-2">
							<h3 class="white--text">
								Price
							</h3>
						</div>
						<textField class="" :textField="serviceNameTextfiled" v-model="card.title" />
						<div>
							<h3 class="white--text">
								Price bottom-padding
							</h3>
						</div>
						<v-slider hideDetails v-model="card.pricePadding" color="black" min="0" max="100" step="1" thumb-label ticks></v-slider>
						<v-divider class="my-4"></v-divider>
						<div class="mt-2">
							<h3 class="white--text">
								Align Price
							</h3>
						</div>
						<v-chip-group mandatory active-class="PointColor1--text" v-model="card.subTitleAlign">
							<v-chip v-for="align in aligns" :key="align" :value="align">
								{{ align }}
							</v-chip>
						</v-chip-group>
						<colorPicker v-model="card.subTitleColor" :color_picker="color_picker_p2" />
						<div class="mt-2">
							<h3 class="white--text">
								SubPrice Size
							</h3>
						</div>
						<v-slider hideDetails v-model="card.subTitleSize" color="black" min="4" max="200" step="1" thumb-label ticks></v-slider>
						<div class="mt-2">
							<h3 class="white--text">
								SubPrice
							</h3>
						</div>
						<textField :textField="serviceNameTextfiled" v-model="card.subTitle" />
						<div>
							<h3 class="white--text">
								SubPrice bottom-padding
							</h3>
						</div>
						<v-slider hideDetails v-model="card.subPricePadding" color="black" min="0" max="100" step="1" thumb-label ticks></v-slider>
						<v-divider class="my-4"></v-divider>
						<div class="mt-2">
							<h3 class="white--text">
								Price List
							</h3>
						</div>
						<v-layout align-center class="mb-2">
							<v-sheet elevation="5" class="px-2" max-width="280px" v-show="card.list.length > 0">
								<v-chip-group mandatory active-class="PointColor1--text" v-model="priceTag">
									<v-chip v-for="tag in card.list.length" :key="tag">
										{{ tag }}
									</v-chip>
								</v-chip-group>
							</v-sheet>
							<v-spacer></v-spacer>
							<div>
								<v-btn color="success" @click="addPriceList(index, i)">
									add
								</v-btn>
								<v-btn color="error" class="ml-2" v-if="webView.cards.length > 0" @click="deletePriceList(index, i, priceTag)">
									delete
								</v-btn>
							</div>
						</v-layout>
						<div v-for="(list, i) in card.list" :key="i" v-show="priceTag === i">
							<div class="mt-2">
								<h3 class="white--text">
									Align Label
								</h3>
							</div>
							<v-chip-group mandatory active-class="PointColor1--text" v-model="list.labelAlign">
								<v-chip v-for="align in aligns" :key="align" :value="align">
									{{ align }}
								</v-chip>
							</v-chip-group>
							<colorPicker v-model="list.labelColor" :color_picker="color_picker_pl1" />
							<div class="mt-2">
								<h3 class="white--text">
									Label Size
								</h3>
							</div>
							<v-slider hideDetails v-model="list.labelSize" color="black" min="4" max="200" step="1" thumb-label ticks></v-slider>
							<div class="mt-2">
								<h3 class="white--text">
									List Label
								</h3>
							</div>
							<textField :textField="serviceNameTextfiled" v-model="list.label" />
							<div>
								<h3 class="white--text">
									List Label bottom-padding
								</h3>
							</div>
							<v-slider hideDetails v-model="list.labelPadding" color="black" min="0" max="100" step="1" thumb-label ticks></v-slider>
							<div class="mt-2">
								<h3 class="white--text">
									List Price Label
								</h3>
							</div>
							<v-chip-group mandatory active-class="PointColor1--text" v-model="list.priceAlign">
								<v-chip v-for="align in aligns" :key="align" :value="align">
									{{ align }}
								</v-chip>
							</v-chip-group>
							<colorPicker v-model="list.priceColor" :color_picker="color_picker_pl2" />
							<div class="mt-2">
								<h3 class="white--text">
									List Price Size
								</h3>
							</div>
							<v-slider hideDetails v-model="list.priceSize" color="black" min="4" max="200" step="1" thumb-label ticks></v-slider>
							<div class="mt-2">
								<h3 class="white--text">
									List Price
								</h3>
							</div>
							<textField :textField="serviceNameTextfiled" v-model="list.price" />
							<div>
								<h3 class="white--text">
									List Price bottom-padding
								</h3>
							</div>
							<v-slider hideDetails v-model="list.pricePadding" color="black" min="0" max="100" step="1" thumb-label ticks></v-slider>
						</div>
						<v-divider class="my-4"></v-divider>
						<colorPicker v-model="card.btnColor" :color_picker="color_picker_b2" />
						<div class="mt-2">
							<h3 class="white--text">
								Outlined Mode
							</h3>
						</div>
						<v-checkbox hideDetails class="mt-0 mb-2" v-model="card.outlined" :label="card.dark ? 'Active' : 'None'"></v-checkbox>
						<div>
							<h3 class="white--text">
								Rounded Mode
							</h3>
						</div>
						<v-checkbox hideDetails class="mt-0 mb-2" v-model="card.rounded" :label="card.dark ? 'Active' : 'None'"></v-checkbox>
						<div>
							<h3 class="white--text">
								Dark Mode
							</h3>
						</div>
						<v-checkbox hideDetails class="mt-0 mb-2" v-model="card.btnDark" :label="card.dark ? 'Active' : 'None'"></v-checkbox>
					</div>
				</div>
			</div>
			<!-- <template v-slot:append>
				<div class="pb-2">
					<v-btn block color="PointColor2" @click="Reset">
						Reset
					</v-btn>
				</div>
			</template> -->
		</v-navigation-drawer>
		<v-main>
			<v-layout class="webLayout" justify-center>
				<v-card class="webView" light :class="{ card_lay_1: mobile }" tile>
					<v-app-bar
						dark
						tile
						flat
						height="64"
						:color="color_picker_header.headerColor === '#000000' ? 'transparent' : color_picker_header.headerColor"
						@click=";(styleDrawer = !styleDrawer), (optionType = 'Header Style')"
						class="cursor"
					>
						<v-toolbar-title>
							<v-img :src="editLogo ? editLogo : server + logoUrl" contain :max-height="LogoSize" :max-width="80"></v-img>
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<div v-show="!mobile">
							<v-btn
								v-for="(list, listIndex) in WebViewCards"
								:key="listIndex"
								text
								:color="color_picker_header.menusColor"
								v-show="list.active && list.type !== 'header' && list.type !== 'Footer'"
							>
								{{ list.type }}
							</v-btn>
							<v-btn icon v-for="sns in activeSns" :key="sns" :color="color_picker_header.menusColor">
								<v-icon>mdi-{{ sns }}</v-icon>
							</v-btn>
						</div>
						<v-app-bar-nav-icon v-show="mobile"></v-app-bar-nav-icon>
					</v-app-bar>
					<v-layout wrap id="web-view">
						<v-flex xs12>
							<WebViewCards :mobile="mobile" :data="WebViewCards" @click="CardClick" />
						</v-flex>
					</v-layout>
				</v-card>
			</v-layout>
		</v-main>
		<input type="file" style="display:none;" id="pic-logo" @change="fileupload" accept="image/*" />
	</v-dialog>
</template>
<script>
import draggable from 'vuedraggable'
import UploadImages from 'vue-upload-drop-images'
import combobox from './combobox'
import colorPicker from './color-picker'
import textField from './textField.vue'
import WebViewCards from './WebViewCards'
import icons from '../utils/icons'
export default {
	components: {
		combobox,
		colorPicker,
		textField,
		WebViewCards,
		UploadImages,
		draggable,
	},
	metaInfo() {
		return {
			title: this.siteName ? this.siteName : 'Protect',
			link: [{ rel: 'icon', href: this.faviconPath ? this.faviconPath : '/protect.ico' }],
		}
	},
	data() {
		return {
			aligns: ['left', 'center', 'right'],
			uploadImagesOptions: {
				max: 10,
				uploadMsg: '이미지를 등록해주세요.',
				fileError: '이미지 파일만 등록 할 수 있습니다.',
				classOn: false,
			},
			color_picker_c1: {
				text: 'Field-Color',
				title: 'Field-Color',
			},
			color_picker_b2: {
				text: 'Btn-Color',
				title: 'Btn-Color',
			},
			color_picker_t1: {
				text: 'Type-Color',
				title: 'Type-Color',
			},
			color_picker_p1: {
				text: 'Price-Color',
				title: 'Price-Color',
			},
			color_picker_p2: {
				text: 'SubPrice-Color',
				title: 'SubPrice-Color',
			},
			color_picker_pl1: {
				text: 'Label-Color',
				title: 'Label-Color',
			},
			color_picker_pl2: {
				text: 'Price-List-Color',
				title: 'Price-List-Color',
			},
			color_picker_f1: {
				text: 'Title-Color',
				title: 'Title-Color',
			},
			color_picker_f2: {
				text: 'Content-Color',
				title: 'Content-Color',
			},
			color_picker_f3: {
				text: 'All right reserved-Color',
				title: 'All right reserved-Color',
			},
			color_picker_f4: {
				text: 'Menus-Color',
				title: 'Menus-Color',
			},
			color_picker_m1: {
				text: 'Background-Color',
				title: 'Background-Color',
			},
			color_picker_L1: {
				text: 'Layout-Background-Color',
				title: 'Layout-Background-Color',
			},
			color_picker_L2: {
				text: 'Title-Color',
				title: 'Title-Color',
			},
			services: [],
			tag: 0,
			WebViewCards: [
				{
					active: true,
					type: 'About',
					height: '200',
					maxWidth: '1200',
					backgroundImg: '',
					cards: [{ titleSize: '32', subTitleSize: '18' }],
				},
				{ active: true, type: 'Service', height: '200', maxWidth: '1200', backgroundImg: '', cards: [] },
				{ active: true, type: 'Review', height: '200', maxWidth: '1200', backgroundImg: '', cards: [] },
				{ active: true, type: 'Price', height: '200', maxWidth: '1200', backgroundImg: '', cards: [] },
				{ active: true, type: 'Portfolio', height: '200', maxWidth: '1200', backgroundImg: '', cards: [] },
				{ active: true, type: 'Team', height: '200', maxWidth: '1200', backgroundImg: '', cards: [] },
				{ active: true, type: 'Client', height: '200', maxWidth: '1200', backgroundImg: '', cards: [] },
				{
					active: true,
					type: 'Contact',
					height: '200',
					maxWidth: '1200',
					backgroundImg: '',
					fields: { color: '#000000', btnColor: '#000000', dark: true, fieldsDark: false },
				},
				{
					active: true,
					type: 'Footer',
					height: '200',
					maxWidth: '1200',
					backgroundImg: '',
					cards: [{ titleSize: '32', subTitleSize: '18', headers: { sns: {} } }],
				},
			],
			faviconPath: '',
			serviceContent: '',
			serviceName: '',
			serviceNameTextfiled: {
				width: '100%',
				['append-icon']: '',
				clearable: true,
				color: '#f7f7f7ff',
				['background-color']: '#000000',
				height: 40,
				['hide-details']: true,
				placeholder: '',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			siteName: '',
			siteNameTextfiled: {
				width: '100%',
				['append-icon']: '',
				clearable: true,
				color: '#f7f7f7ff',
				['background-color']: '#000000',
				height: 40,
				['hide-details']: true,
				placeholder: 'Site Name',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			Font: 'Roboto',
			FontList: ['Roboto', 'Noto Sans KR', 'Noto Serif KR', 'Nanum Gothic', 'Gothic A1', 'Nanum Myeongjo', 'Black Han Sans'],
			optionType: '',
			snsLinks: {},
			color_picker_header: {
				headerColor: '#272727',
				menusColor: '#FFFFFF',
				text: 'Background Color',
				title: 'Header BackgroundColor',
			},
			color_picker_h1: {
				text: 'Menus Color',
				title: 'Header MenusColor',
			},
			lists: [],
			styleDrawer: false,
			mobile: false,
			items: [
				{ title: 'Home', icon: 'mdi-view-dashboard' },
				{ title: 'About', icon: 'mdi-forum' },
			],
			logoId: null,
			logoUrl: null,
			server: process.env.VUE_APP_BACKEND_URL,
			sns: ['instagram', 'facebook', 'youtube'],
			activeSns: [],
			snsTextField: {
				youtube: {
					width: '100%',
					['append-icon']: 'mdi-youtube',
					clearable: true,
					color: '#f7f7f7ff',
					['background-color']: '#000000',
					height: 40,
					['hide-details']: true,
					placeholder: 'Youtube Link',
					solo: true,
					type: 'text',
					maxlength: 255,
				},
				instagram: {
					width: '100%',
					['append-icon']: 'mdi-instagram',
					clearable: true,
					color: '#f7f7f7ff',
					['background-color']: '#000000',
					height: 40,
					['hide-details']: true,
					placeholder: 'Instagram Link',
					solo: true,
					type: 'text',
					maxlength: 255,
				},
				facebook: {
					width: '100%',
					['append-icon']: 'mdi-facebook',
					clearable: true,
					color: '#f7f7f7ff',
					['background-color']: '#000000',
					height: 40,
					['hide-details']: true,
					placeholder: 'Facebook Link',
					solo: true,
					type: 'text',
					maxlength: 255,
				},
				icons: [],
				files: [],
			},
			LogoSize: 48,
			priceTag: 0,
			activeCompoent: [],
			editLogo: '',
		}
	},
	props: {
		data: {
			type: Object,
		},
	},
	watch: {
		data: {
			deep: true,
			handler() {
				this.WebViewCards = this.data.html
				if (this.data.html[0].logo) {
					this.editLogo = this.data.html[0].logo
				} else {
					this.editLogo = ''
				}
				if (this.data.html[0].LogoSize) {
					this.LogoSize = this.data.html[0].LogoSize
				}
				if (this.data.html[0].font) {
					this.Font = this.data.html[0].font
					setTimeout(() => {
						this.FontStyle(this.data.html[0].font)
					}, 500)
				}
				this.color_picker_header.headerColor = this.data.html[0].backgroundColor
				if (this.data.html[0].sns) {
					this.snsLinks = this.data.html[0].sns
					this.activeSns = Object.keys(this.data.html[0].sns)
				}
				this.activeCompoent = this.WebViewCards.filter(x => x.active === true).map(y => y.type)
			},
		},
		activeCompoent(val) {
			const setComponent = this.WebViewCards.map(y => y.type).filter(x => !val.includes(x))
			this.WebViewCards.forEach(element => {
				if (setComponent.indexOf(element.type) > -1) {
					element.active = false
				} else {
					element.active = true
				}
			})
		},
		logoUrl() {
			const setData = this.WebViewCards[this.WebViewCards.length - 1].cards[0].headers
			setData.logo = this.server + this.logoUrl
			setData.LogoSize = this.LogoSize
			setData.sns = this.snsLinks
			setData.backgroundColor = this.color_picker_header.headerColor
			setData.type = 'header'
		},
	},
	methods: {
		backgroundNone(index) {
			this.WebViewCards[index].file = null
			this.WebViewCards[index].backgroundImg = ''
		},
		deletePriceList(index, i, priceTag) {
			this.WebViewCards[index].cards[i].list.splice(priceTag, 1)
		},
		addPriceList(index, i) {
			this.WebViewCards[index].cards[i].list.push({})
		},
		teamImg(val, index, i) {
			if (val) {
				const data = {
					file: val,
				}
				this.$store
					.dispatch('fileUpload', data)
					.then(res => {
						console.log(res)
						this.WebViewCards[index].cards[i].file = val
						this.WebViewCards[index].cards[i].img = this.server + res[0].url
					})
					.catch(err => {
						console.log({ err })
					})
			} else {
				this.WebViewCards[index].cards[i].img = ''
			}
		},
		backgroundImg(val, index) {
			if (val) {
				const data = {
					file: val,
				}
				this.$store
					.dispatch('fileUpload', data)
					.then(res => {
						this.WebViewCards[index].file = val
						this.WebViewCards[index].backgroundImg = this.server + res[0].url
					})
					.catch(err => {
						console.log({ err })
					})
			} else {
				this.WebViewCards[index].backgroundImg = ''
			}
		},
		multifileUpload(index, i) {
			if (this.WebViewCards[index].cards[i].files.length > 0) {
				const data = {
					files: this.WebViewCards[index].cards[i].files,
				}
				this.$store
					.dispatch('multifileUpload', data)
					.then(res => {
						if (res.multipleUpload.length > 0) {
							const images = []
							res.multipleUpload.forEach((element, FileIndex) => {
								images.push(this.server + element.url)
								if (FileIndex === res.multipleUpload.length - 1) {
									this.WebViewCards[index].cards[i].images = images
								}
							})
						}
						this.$store.state.fileUploadProgress = 0
					})
					.catch(err => {
						console.log({ err })
					})
			} else {
				this.WebViewCards[index].cards[i].images = []
			}
		},
		handleImages(files, index, i) {
			this.WebViewCards[index].cards[i].files = files
		},
		addServices(index, optionType) {
			if (optionType === 'Service') {
				this.WebViewCards[index].cards.push({})
			} else if (optionType === 'Portfolio') {
				this.WebViewCards[index].cards.unshift({ images: [] })
			} else if (optionType === 'Team' || optionType === 'Price' || optionType === 'Client' || optionType === 'Review') {
				this.WebViewCards[index].cards.push({ list: [], img: '', file: null })
			}
		},
		deleteServices(index, i, optionType) {
			if (optionType === 'Service') {
				this.WebViewCards[index].cards.splice(i, 1)
			} else if (optionType === 'Portfolio') {
				this.WebViewCards[index].cards.splice(i, 1)
			} else if (optionType === 'Team' || optionType === 'Price' || optionType === 'Client' || optionType === 'Review') {
				this.WebViewCards[index].cards.splice(i, 1)
			}
		},
		CardClick(val, type) {
			val
			this.styleDrawer = !this.styleDrawer
			this.optionType = type
		},
		FontStyle(val) {
			const link = document.createElement('link')
			if (val === 'Noto Sans KR') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap'
				document.getElementById('web-view').style = "font-family: 'Noto Sans KR', sans-serif;"
			} else if (val === 'Roboto') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap'
				document.getElementById('web-view').style = "font-family: 'Roboto', sans-serif;"
			} else if (val === 'Nanum Gothic') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap'
				document.getElementById('web-view').style = "font-family: 'Nanum Gothic', sans-serif;"
			} else if (val === 'Gothic A1') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&display=swap'
				document.getElementById('web-view').style = "font-family: 'Gothic A1', sans-serif;"
			} else if (val === 'Nanum Myeongjo') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap'
				document.getElementById('web-view').style = "font-family: 'Nanum Myeongjo', serif;"
			} else if (val === 'Noto Serif KR') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@200;300;400;500;600;700;900&display=swap'
				document.getElementById('web-view').style = "font-family: 'Noto Serif KR', serif;"
			} else if (val === 'Black Han Sans') {
				link.rel = 'stylesheet'
				link.href = 'https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap'
				document.getElementById('web-view').style = "font-family: 'Black Han Sans', sans-serif;"
			}
			if (this.WebViewCards[0].type === 'header') {
				this.WebViewCards[0].font = val
			}
			document.getElementsByTagName('head')[0].appendChild(link)
		},
		LogoUpload() {
			document.getElementById('pic-logo').click()
		},
		fileupload(event) {
			if (event.target.files.length > 0) {
				const data = {
					file: event.target.files[0],
				}
				this.$store
					.dispatch('fileUpload', data)
					.then(res => {
						this.editLogo = ''
						this.logoId = res.upload.id
						this.logoUrl = res[0].url
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		Reset() {},
		allEdit() {},
		displayValue() {
			this.mobile = !this.mobile
		},
		saveDialog() {
			this.$store.state.loading = true
			const headers = {
				backgroundColor: this.color_picker_header.headerColor,
				menusColor: this.color_picker_header.menusColor,
				type: 'header',
			}
			if (this.logoUrl) {
				headers.logo = this.server + this.logoUrl
				headers.LogoSize = this.LogoSize
			} else if (this.editLogo) {
				headers.logo = this.editLogo
				headers.LogoSize = this.LogoSize
			}
			headers.font = this.Font
			if (this.snsLinks.facebook || this.snsLinks.youtube || this.snsLinks.instagram) {
				headers.sns = this.snsLinks
			}
			if (this.WebViewCards[0].type === 'header') {
				this.WebViewCards[0] = headers
			} else {
				this.WebViewCards.unshift(headers)
			}
			this.updateWeb()
		},
		updateWeb() {
			this.WebViewCards.forEach((element, index) => {
				delete element.file
				if (element.cards) {
					element.cards.forEach(card => {
						delete card.files
					})
				}
				if (index === this.WebViewCards.length - 1) {
					const data = {
						id: this.data.id,
						type: '1',
						html: this.WebViewCards,
					}
					console.log(data)
					this.$store
						.dispatch('updateWeb', data)
						.then(() => {
							this.$store.state.loading = false
							this.data.dialog = false
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				}
			})
		},
	},
	created() {
		this.activeCompoent = this.WebViewCards.map(x => x.type)
		this.icons = icons
	},
}
</script>
<style lang="scss" scoped></style>
<style lang="scss">
.style-title {
	text-decoration: underline;
}
.card_lay_1 {
	margin: 0 auto;
	width: 360px !important;
}
.webLayout {
	margin: 0 auto;
	margin-top: 48px;
	padding: 16px;
	width: 100%;
	min-height: 94vh;
	height: auto;
}
.webView {
	transition: width 1s;
	overflow: auto;
	box-shadow: 0 0 25px rgb(0 0 0 / 11%) !important;
	width: 100%;
	background-color: white !important;
}
</style>
