import gql from 'graphql-tag'

export const login = gql`
	mutation login($identifier: String!, $password: String!) {
		login(input: { identifier: $identifier, password: $password }) {
			jwt
			user {
				id
				email
				username
				blocked
				position
				cls
			}
		}
	}
`
export const updateUser = gql`
	mutation updateUser(
		$id: ID!
		$password: String
		$name: String
		$birthday: String
		$phone: String
		$position: String
		$startdate: Date
		$retirement: Date
		$blocked: Boolean
		$contract: ID
		$vacationsDays: Float
		$salaryPaymentDay: Int
		$wagePaymentDay: Int
	) {
		updateUser(
			input: {
				where: { id: $id }
				data: {
					password: $password
					Name: $name
					birthday: $birthday
					phone: $phone
					position: $position
					startdate: $startdate
					retirement: $retirement
					blocked: $blocked
					contract: $contract
					vacationsDays: $vacationsDays
					salaryPaymentDay: $salaryPaymentDay
					wagePaymentDay: $wagePaymentDay
				}
			}
		) {
			user {
				id
			}
		}
	}
`
export const Register = gql`
	mutation Register(
		$username: String!
		$email: String!
		$password: String!
		$Name: String
		$birthday: String
		$phone: String
		$position: String
		$cls: Int
		$startdate: Date
		$contract: ID
	) {
		register(
			input: {
				username: $username
				email: $email
				password: $password
				Name: $Name
				birthday: $birthday
				phone: $phone
				position: $position
				cls: $cls
				startdate: $startdate
				contract: $contract
				blocked: false
			}
		) {
			jwt
			user {
				id
			}
		}
	}
`
export const SendEmails = gql`
	mutation SendEmail($emailtype: Int, $toemail: String) {
		createSendEmail(input: { data: { emailtype: $emailtype, toemail: $toemail } }) {
			sendEmail {
				authcode
			}
		}
	}
`
export const SendSMS = gql`
	mutation SendSMS($phoneNumber: String!) {
		createSendSm(input: { data: { phonenumber: $phoneNumber } }) {
			sendSm {
				authcode
			}
		}
	}
`
export const createCalendar = gql`
	mutation createCalendar(
		$name: String!
		$start: Date
		$client: String
		$pmName: String
		$end: Date
		$content: String
		$chat: JSON
		$team: ID
	) {
		createCalendar(
			input: {
				data: { name: $name, start: $start, end: $end, content: $content, client: $client, pmName: $pmName, chat: $chat, team: $team }
			}
		) {
			calendar {
				id
				interID
			}
		}
	}
`
export const createInquiry = gql`
	mutation createInquiry(
		$name: String
		$company: String
		$html: String
		$email: String
		$phone: String
		$price: String
		$type: String
		$enddate: Date
		$password: String
		$url: String
		$content: String
	) {
		createInquiry(
			input: {
				data: {
					name: $name
					company: $company
					html: $html
					email: $email
					phone: $phone
					price: $price
					type: $type
					enddate: $enddate
					password: $password
					url: $url
					content: $content
				}
			}
		) {
			inquiry {
				id
			}
		}
	}
`
export const updateInquiry = gql`
	mutation updateInquiry(
		$name: String
		$company: String
		$html: String
		$email: String
		$phone: String
		$price: String
		$type: String
		$enddate: Date
		$password: String
		$url: String
		$id: ID!
	) {
		updateInquiry(
			input: {
				data: {
					name: $name
					company: $company
					html: $html
					email: $email
					phone: $phone
					price: $price
					type: $type
					enddate: $enddate
					password: $password
					url: $url
				}
				where: { id: $id }
			}
		) {
			inquiry {
				name
				company
				html
				email
				phone
				price
				type
				enddate
				password
				url
			}
		}
	}
`

// 포트폴리오 생성
export const createPortfolio = gql`
	mutation createPortfolio($Title: String, $client: String, $Contents: String, $madeby: String, $skills: String, $PFImages: [ID]) {
		createPortfolio(
			input: { data: { Title: $Title, client: $client, Contetns: $Contents, madeby: $madeby, skills: $skills, PFImages: $PFImages } }
		) {
			portfolio {
				Title
				client
				Contetns
				madeby
				skills
				CompletionDate
				PFImages {
					created_at
					url
					name
				}
				created_at
				updated_at
			}
		}
	}
`
export const updatePortfolio = gql`
	mutation updatePortfolio(
		$Title: String
		$client: String
		$Contents: String
		$madeby: String
		$skills: String
		$PFImages: [ID]
		$id: ID!
	) {
		updatePortfolio(
			input: {
				data: { Title: $Title, client: $client, Contetns: $Contents, madeby: $madeby, skills: $skills, PFImages: $PFImages }
				where: { id: $id }
			}
		) {
			portfolio {
				Title
				client
				Contetns
				madeby
				skills
				CompletionDate
				PFImages {
					created_at
					url
					name
				}
				created_at
				updated_at
			}
		}
	}
`
export const fileUpload = gql`
	mutation fileUpload($refId: ID, $ref: String, $field: String, $source: String, $file: Upload!) {
		upload(refId: $refId, ref: $ref, field: $field, source: $source, file: $file) {
			id
			created_at
			updated_at
			name
			hash
			ext
			mime
			size
			url
			provider
			provider_metadata
			related {
				__typename
			}
		}
	}
`
export const multifileUpload = gql`
	mutation multipleUpload($refId: ID, $ref: String, $field: String, $source: String, $files: [Upload]!) {
		multipleUpload(refId: $refId, ref: $ref, field: $field, source: $source, files: $files) {
			id
			created_at
			updated_at
			name
			hash
			ext
			mime
			size
			url
			provider
			provider_metadata
			related {
				__typename
			}
		}
	}
`
export const createAttendance = gql`
	mutation createAttendance(
		$Name: String
		$notes: String
		$interID: String
		$work: DateTime
		$position: String
		$phone: String
		$workday: String
	) {
		createAttendance(
			input: { data: { workday: $workday, Name: $Name, notes: $notes, interID: $interID, work: $work, position: $position, phone: $phone } }
		) {
			attendance {
				interID
				Name
				notes
				position
				phone
				work
				Leave
				workday
				created_at
				updated_at
			}
		}
	}
`
export const updateAttendance = gql`
	mutation updateAttendance($Leave: DateTime, $id: ID!, $work: DateTime) {
		updateAttendance(input: { data: { Leave: $Leave, work: $work }, where: { id: $id } }) {
			attendance {
				interID
				Name
				notes
				position
				phone
				work
				Leave
				created_at
				updated_at
			}
		}
	}
`
export const updateCalendar = gql`
	mutation updateCalendar(
		$name: String
		$start: Date
		$end: Date
		$content: String
		$client: String
		$pmName: String
		$id: ID!
		$chat: JSON
		$team: ID
		$status: Int
	) {
		updateCalendar(
			input: {
				data: {
					name: $name
					start: $start
					end: $end
					content: $content
					client: $client
					pmName: $pmName
					chat: $chat
					team: $team
					status: $status
				}
				where: { id: $id }
			}
		) {
			calendar {
				name
				start
				end
				content
				created_at
				updated_at
			}
		}
	}
`
// todoList create
export const createTodolist = gql`
	mutation(
		$interID: String
		$list: JSON
		$calendar: ID
		$version: String
		$content: String
		$url: String
		$type: String
		$status: ENUM_TODOLIST_STATUS
		$dev: Date
		$qa: Date
		$complete: Date
		$planning: Date
		$comment: JSON
		$plannerID: String
		$devID: String
		$completeID: String
		$devConfirm: JSON
		$qaConfirm: JSON
		$planningConfirm: JSON
	) {
		createTodolist(
			input: {
				data: {
					interID: $interID
					list: $list
					calendar: $calendar
					version: $version
					content: $content
					url: $url
					type: $type
					status: $status
					dev: $dev
					qa: $qa
					complete: $complete
					planning: $planning
					comment: $comment
					plannerID: $plannerID
					devID: $devID
					completeID: $completeID
					devConfirm: $devConfirm
					qaConfirm: $qaConfirm
					planningConfirm: $planningConfirm
				}
			}
		) {
			todolist {
				id
			}
		}
	}
`
// todoList update
export const updateTodolist = gql`
	mutation(
		$interID: String
		$list: JSON
		$calendar: ID
		$version: String
		$content: String
		$url: String
		$type: String
		$status: ENUM_TODOLIST_STATUS
		$dev: Date
		$qa: Date
		$complete: Date
		$planning: Date
		$comment: JSON
		$show: Boolean
		$plannerID: String
		$devID: String
		$completeID: String
		$id: ID!
		$devConfirm: JSON
		$qaConfirm: JSON
		$planningConfirm: JSON
	) {
		updateTodolist(
			input: {
				where: { id: $id }
				data: {
					devConfirm: $devConfirm
					qaConfirm: $qaConfirm
					planningConfirm: $planningConfirm
					interID: $interID
					list: $list
					calendar: $calendar
					version: $version
					content: $content
					url: $url
					type: $type
					status: $status
					dev: $dev
					qa: $qa
					complete: $complete
					planning: $planning
					comment: $comment
					show: $show
					plannerID: $plannerID
					devID: $devID
					completeID: $completeID
				}
			}
		) {
			todolist {
				id
			}
		}
	}
`
export const createPlan = gql`
	mutation createPlan(
		$type: String
		$deps: String
		$gui: String
		$public: String
		$function: String
		$api: String
		$explan: String
		$start: Date
		$end: Date
		$user: ID
		$interID: String
		$status: Int
		$client: String
		$index: Int
		$guiDate: String
		$publicDate: String
		$functionDate: String
	) {
		createPlan(
			input: {
				data: {
					type: $type
					deps: $deps
					gui: $gui
					public: $public
					function: $function
					api: $api
					explan: $explan
					start: $start
					end: $end
					users_permissions_user: $user
					interID: $interID
					status: $status
					client: $client
					index: $index
					guiDate: $guiDate
					publicDate: $publicDate
					functionDate: $functionDate
				}
			}
		) {
			plan {
				interID
			}
		}
	}
`
export const updatePlan = gql`
	mutation updatePlan(
		$type: String
		$deps: String
		$gui: String
		$public: String
		$function: String
		$api: String
		$explan: String
		$start: Date
		$end: Date
		$user: ID
		$interID: String
		$status: Int
		$client: String
		$index: Int
		$id: ID!
		$prove: ID
		$guiDate: String
		$publicDate: String
		$functionDate: String
	) {
		updatePlan(
			input: {
				where: { id: $id }
				data: {
					type: $type
					deps: $deps
					gui: $gui
					public: $public
					function: $function
					api: $api
					explan: $explan
					start: $start
					end: $end
					users_permissions_user: $user
					interID: $interID
					status: $status
					client: $client
					index: $index
					prove: $prove
					guiDate: $guiDate
					publicDate: $publicDate
					functionDate: $functionDate
				}
			}
		) {
			plan {
				interID
				prove {
					url
				}
			}
		}
	}
`

export const createCodeReview = gql`
	mutation createCodeReview($rate: Int, $review: String, $user: ID) {
		createCodeReview(input: { data: { rate: $rate, review: $review, users_permissions_user: $user } }) {
			codeReview {
				rate
				review
				updated_at
				id
			}
		}
	}
`
export const updateCodeReview = gql`
	mutation updateCodeReview($id: ID!, $rate: Int, $review: String, $user: ID) {
		updateCodeReview(input: { where: { id: $id }, data: { rate: $rate, review: $review, users_permissions_user: $user } }) {
			codeReview {
				rate
				review
				updated_at
				id
			}
		}
	}
`
export const createCodeShow = gql`
	mutation createCodeShow($title: String, $pdFile: ID, $mainImg: ID, $date: Date, $content: String, $user: ID) {
		createCodeShow(
			input: { data: { title: $title, pdFile: $pdFile, mainImg: $mainImg, date: $date, content: $content, users_permissions_user: $user } }
		) {
			codeShow {
				id
				pdFile {
					url
				}
				mainImg {
					url
				}
				title
				date
				content
				created_at
				updated_at
			}
		}
	}
`

export const updateCodeShow = gql`
	mutation updateCodeShow($title: String, $pdFile: ID, $mainImg: ID, $date: Date, $content: String, $user: ID, $id: ID!) {
		updateCodeShow(
			input: {
				where: { id: $id }
				data: { title: $title, pdFile: $pdFile, mainImg: $mainImg, date: $date, content: $content, users_permissions_user: $user }
			}
		) {
			codeShow {
				id
				pdFile {
					url
				}
				mainImg {
					url
				}
				title
				date
				content
				created_at
				updated_at
			}
		}
	}
`
export const deleteCodeShow = gql`
	mutation deleteCodeShow($id: ID!) {
		deleteCodeShow(input: { where: { id: $id } }) {
			codeShow {
				id
			}
		}
	}
`
export const createTeam = gql`
	mutation createTeam($users: [ID], $title: String, $teamPosition: JSON, $status: Int) {
		createTeam(input: { data: { users_permissions_users: $users, title: $title, teamPosition: $teamPosition, status: $status } }) {
			team {
				id
			}
		}
	}
`
export const updateTeam = gql`
	mutation updateTeam(
		$id: ID!
		$users: [ID]
		$title: String
		$status: Int
		$chat: JSON
		$teamLog: JSON
		$teamPosition: JSON
		$calendars: [ID]
	) {
		updateTeam(
			input: {
				where: { id: $id }
				data: {
					users_permissions_users: $users
					title: $title
					teamPosition: $teamPosition
					status: $status
					chat: $chat
					teamLog: $teamLog
					calendars: $calendars
				}
			}
		) {
			team {
				id
			}
		}
	}
`
export const createVacation = gql`
	mutation createVacation($user: ID, $vacation: DateTime, $content: String, $vacationEnd: DateTime, $halfDay: Float) {
		createVacation(
			input: {
				data: { users_permissions_user: $user, vacation: $vacation, vacationEnd: $vacationEnd, content: $content, halfDay: $halfDay }
			}
		) {
			vacation {
				id
			}
		}
	}
`
export const updateVacation = gql`
	mutation updateVacation(
		$id: ID!
		$vacation: DateTime
		$vacationEnd: DateTime
		$approval: String
		$approvalDate: DateTime
		$reject: String
		$status: Int
		$content: String
		$halfDay: Float
	) {
		updateVacation(
			input: {
				where: { id: $id }
				data: {
					vacation: $vacation
					approval: $approval
					approvalDate: $approvalDate
					reject: $reject
					status: $status
					content: $content
					vacationEnd: $vacationEnd
					halfDay: $halfDay
				}
			}
		) {
			vacation {
				id
			}
		}
	}
`
export const createMoney = gql`
	mutation createMoney($salary: Long, $wage: Long, $bonus: Long, $user: ID, $payment: Date, $tax: JSON) {
		createMoney(
			input: { data: { salary: $salary, wage: $wage, bonus: $bonus, users_permissions_user: $user, payment: $payment, tax: $tax } }
		) {
			money {
				id
			}
		}
	}
`
export const updateMoney = gql`
	mutation updateMoney($id: ID!, $salary: Long, $wage: Long, $bonus: Long, $user: ID, $payment: Date, $tax: JSON) {
		updateMoney(
			input: {
				where: { id: $id }
				data: { salary: $salary, wage: $wage, bonus: $bonus, users_permissions_user: $user, payment: $payment, tax: $tax }
			}
		) {
			money {
				id
			}
		}
	}
`
export const createRule = gql`
	mutation createRule($version: Float, $content: String, $creator: String, $show: Boolean, $type: String) {
		createRule(input: { data: { version: $version, content: $content, creator: $creator, show: $show, type: $type } }) {
			rule {
				id
			}
		}
	}
`

export const updateRule = gql`
	mutation updateRule($version: Float, $content: String, $creator: String, $show: Boolean, $id: ID!, $type: String) {
		updateRule(input: { where: { id: $id }, data: { version: $version, content: $content, creator: $creator, show: $show, type: $type } }) {
			rule {
				id
			}
		}
	}
`
export const createSystem = gql`
	mutation createSystem($color: JSON, $logo: ID, $ceoReport: Int, $teamReport: Int, $selfReport: Int) {
		createSystem(input: { data: { color: $color, logo: $logo, ceoReport: $ceoReport, teamReport: $teamReport, selfReport: $selfReport } }) {
			system {
				id
				color
				logo {
					url
				}
			}
		}
	}
`
export const updateSystem = gql`
	mutation updateSystem($color: JSON, $logo: ID, $id: ID!, $ceoReport: Int, $teamReport: Int, $selfReport: Int) {
		updateSystem(
			input: {
				data: { color: $color, logo: $logo, ceoReport: $ceoReport, teamReport: $teamReport, selfReport: $selfReport }
				where: { id: $id }
			}
		) {
			system {
				id
				color
				logo {
					url
				}
			}
		}
	}
`
export const createReport = gql`
	mutation createReport($data: JSON, $reportDay: Date, $rank: Int, $user: ID, $title: String, $creator: String, $content: String) {
		createReport(
			input: {
				data: {
					data: $data
					reportDay: $reportDay
					rank: $rank
					users_permissions_user: $user
					title: $title
					creator: $creator
					content: $content
				}
			}
		) {
			report {
				id
			}
		}
	}
`
export const updateReport = gql`
	mutation updateReport(
		$data: JSON
		$reportDay: Date
		$rank: Int
		$user: ID
		$title: String
		$creator: String
		$content: String
		$id: ID!
	) {
		updateReport(
			input: {
				where: { id: $id }
				data: {
					data: $data
					reportDay: $reportDay
					rank: $rank
					users_permissions_user: $user
					title: $title
					creator: $creator
					content: $content
				}
			}
		) {
			report {
				id
			}
		}
	}
`
export const createReportQus = gql`
	mutation createReportQus(
		$qusType: String
		$listorder: Int
		$type: String
		$show: Boolean
		$subtitle: String
		$title: String
		$data: JSON
		$code: String
	) {
		createReportQus(
			input: {
				data: {
					qusType: $qusType
					listorder: $listorder
					type: $type
					show: $show
					subtitle: $subtitle
					title: $title
					data: $data
					code: $code
				}
			}
		) {
			reportQus {
				id
			}
		}
	}
`
export const updateReportQus = gql`
	mutation updateReportQus(
		$listorder: Int
		$type: String
		$show: Boolean
		$subtitle: String
		$title: String
		$data: JSON
		$code: String
		$id: ID!
	) {
		updateReportQus(
			input: {
				where: { id: $id }
				data: { listorder: $listorder, type: $type, show: $show, subtitle: $subtitle, title: $title, data: $data, code: $code }
			}
		) {
			reportQus {
				id
			}
		}
	}
`

export const createTeamReport = gql`
	mutation createTeamReport(
		$team: ID
		$creator: String
		$data: JSON
		$user: ID
		$content: String
		$reportDay: Date
		$rank: Int
		$title: String
	) {
		createTeamReport(
			input: {
				data: {
					team: $team
					creator: $creator
					data: $data
					users_permissions_user: $user
					content: $content
					reportDay: $reportDay
					rank: $rank
					title: $title
				}
			}
		) {
			teamReport {
				id
			}
		}
	}
`

export const updateTeamReport = gql`
	mutation updateTeamReport(
		$team: ID
		$creator: String
		$data: JSON
		$user: ID
		$content: String
		$reportDay: Date
		$rank: Int
		$title: String
		$id: ID!
	) {
		updateTeamReport(
			input: {
				where: { id: $id }
				data: {
					team: $team
					creator: $creator
					data: $data
					users_permissions_user: $user
					content: $content
					reportDay: $reportDay
					rank: $rank
					title: $title
				}
			}
		) {
			teamReport {
				id
			}
		}
	}
`
export const createSelfReport = gql`
	mutation createSelfReport($data: JSON, $user: ID, $content: String, $reportDay: Date, $title: String) {
		createSelfReport(
			input: { data: { data: $data, users_permissions_user: $user, content: $content, reportDay: $reportDay, title: $title } }
		) {
			selfReport {
				id
			}
		}
	}
`
export const updateSelfReport = gql`
	mutation updateSelfReport($data: JSON, $user: ID, $content: String, $reportDay: Date, $title: String, $id: ID!) {
		updateSelfReport(
			input: {
				where: { id: $id }
				data: { data: $data, users_permissions_user: $user, content: $content, reportDay: $reportDay, title: $title }
			}
		) {
			selfReport {
				id
			}
		}
	}
`
export const createWeb = gql`
	mutation createWeb($name: String, $url: String, $show: Boolean, $type: String, $active: Boolean) {
		createWeb(input: { data: { name: $name, url: $url, show: $show, type: $type, active: $active } }) {
			web {
				id
			}
		}
	}
`
export const updateWeb = gql`
	mutation updateWeb($id: ID!, $name: String, $url: String, $show: Boolean, $type: String, $active: Boolean, $html: JSON) {
		updateWeb(input: { where: { id: $id }, data: { name: $name, url: $url, show: $show, type: $type, active: $active, html: $html } }) {
			web {
				id
			}
		}
	}
`
export const createComponent = gql`
	mutation createComponent(
		$background: [ID]
		$backgroundColor: String
		$titleColor: String
		$contentColor: String
		$width: String
		$height: String
		$align: String
		$justify: String
		$index: Int
		$overlay: String
		$type: String
		$typeNumber: Int
		$show: Boolean
		$web: ID
	) {
		createComponent(
			input: {
				data: {
					background: $background
					backgroundColor: $backgroundColor
					titleColor: $titleColor
					contentColor: $contentColor
					width: $width
					height: $height
					align: $align
					justify: $justify
					index: $index
					overlay: $overlay
					type: $type
					typeNumber: $typeNumber
					show: $show
					web: $web
				}
			}
		) {
			component {
				id
			}
		}
	}
`
export const updateComponent = gql`
	mutation updateComponent(
		$id: ID!
		$background: [ID]
		$backgroundColor: String
		$titleColor: String
		$contentColor: String
		$width: String
		$height: String
		$align: String
		$justify: String
		$index: Int
		$overlay: String
		$type: String
		$typeNumber: Int
		$show: Boolean
		$web: ID
	) {
		updateComponent(
			input: {
				where: { id: $id }
				data: {
					background: $background
					backgroundColor: $backgroundColor
					titleColor: $titleColor
					contentColor: $contentColor
					width: $width
					height: $height
					align: $align
					justify: $justify
					index: $index
					overlay: $overlay
					type: $type
					typeNumber: $typeNumber
					show: $show
					web: $web
				}
			}
		) {
			component {
				id
			}
		}
	}
`
export const createVoiceSystem = gql`
	mutation createVoiceSystem($voice: String, $type: String, $version: Float) {
		createVoiceSystem(input: { data: { voice: $voice, type: $type, version: $version } }) {
			voiceSystem {
				id
			}
		}
	}
`
export const updateVoiceSystem = gql`
	mutation updateVoiceSystem($voice: String, $type: String, $version: Float, $id: ID!, $show: Boolean) {
		updateVoiceSystem(input: { where: { id: $id }, data: { voice: $voice, type: $type, version: $version, show: $show } }) {
			voiceSystem {
				id
			}
		}
	}
`
export const createSendSm = gql`
	mutation createSendSm($message: String, $receiver: String) {
		createSendSm(input: { data: { receiver: $receiver, message: $message } }) {
			sendSm {
				id
			}
		}
	}
`

export const createMemo = gql`
	mutation createMemo($title: String, $cards: JSON, $userid: String, $users_permissions_user: ID) {
		createMemo(
			input: {
				data: {
					title: $title
					cards: $cards
					userid: $userid
					bgColor: "rgb(131, 140, 145)"
					users_permissions_user: $users_permissions_user
				}
			}
		) {
			memo {
				id
			}
		}
	}
`

export const updateMemo = gql`
	mutation updateMemo(
		$title: String
		$cards: JSON
		$userid: String
		$bgColor: String
		$blog: Boolean
		$users_permissions_user: ID
		$id: ID!
	) {
		updateMemo(
			input: {
				where: { id: $id }
				data: {
					title: $title
					cards: $cards
					userid: $userid
					bgColor: $bgColor
					blog: $blog
					users_permissions_user: $users_permissions_user
				}
			}
		) {
			memo {
				id
			}
		}
	}
`
