<template>
	<v-dialog v-model="data.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog" dark>
		<v-row justify="center" align="center" style="height:100vh;" class="ma-0">
			<v-card class="mx-2 pa-4 report-card" max-width="400" width="100%">
				<v-row class="ma-0" justify="end" align="center">
					<v-btn icon @click="data.dialog = false" small>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-row>
				<v-layout v-for="(qus, i) in data.qusList" :key="i" class="mb-2" wrap>
					<v-flex xs12>
						<h3 class="FontColor1--text">
							<span class="PointColor1--text"> Qus {{ i + 1 }}.</span> {{ qus.title }}
						</h3>
						<p class="FontColor2--text mb-1">{{ qus.subtitle }}</p>
						<v-radio-group
							hide-details
							style="margin-top:0px;"
							v-model="qus.answer"
							v-if="qus.type === 'radio'"
							:readonly="data.readOnly ? data.readOnly : $moment(data.reportDay).format('YYYY-MM-DD') !== $moment().format('YYYY-MM-DD')"
						>
							<v-radio
								v-for="(radio, radioIndex) in qus.data"
								:label="radio.name"
								:key="radioIndex"
								:ripple="false"
								class="mr-4"
								:value="radio.name"
								color="PointColor1"
							></v-radio>
						</v-radio-group>
						<v-textarea
							v-else-if="qus.type === 'textfiled'"
							v-model="qus.answer"
							hide-details
							solo
							:placeholder="qus.title"
							max-length="255"
							class="pa-0 ma-0"
							:clearable="data.readOnly ? false : $moment(data.reportDay).format('YYYY-MM-DD') === $moment().format('YYYY-MM-DD')"
							autocomplete="off"
							light
							noResize
							height="100"
							color="PointColor1"
							:readonly="data.readOnly ? data.readOnly : $moment(data.reportDay).format('YYYY-MM-DD') !== $moment().format('YYYY-MM-DD')"
						></v-textarea>
						<v-textarea
							v-else-if="qus.type === 'textarea'"
							v-model="qus.answer"
							hide-details
							solo
							height="100"
							autocomplete="off"
							:placeholder="qus.title"
							max-length="25"
							class="pa-0 ma-0"
							:clearable="data.readOnly ? false : $moment(data.reportDay).format('YYYY-MM-DD') === $moment().format('YYYY-MM-DD')"
							light
							noResize
							color="PointColor1"
							:readonly="data.readOnly ? data.readOnly : $moment(data.reportDay).format('YYYY-MM-DD') !== $moment().format('YYYY-MM-DD')"
						></v-textarea>
					</v-flex>
				</v-layout>
				<v-layout v-if="data.readOnly && data.type !== 'ceoReport'" v-show="data.type !== 'ceoReportAdmin'">
					<v-flex xs12>
						<h2>{{ data.reportDay }} ({{ data.Name }})</h2>
						<v-text-field
							autofocus
							v-model="data.content"
							hide-details
							solo
							max-length="255"
							autocomplete="off"
							placeholder="Comment"
							class="pa-0 ma-0 mt-2"
							clearable
							light
							color="PointColor1"
						></v-text-field>
					</v-flex>
				</v-layout>
				<div
					class="mt-4"
					v-show="data.type !== 'ceoReportAdmin'"
					v-if="
						data.readOnly && data.type !== 'ceoReport'
							? data.readOnly
							: $moment(data.reportDay).format('YYYY-MM-DD') === $moment().format('YYYY-MM-DD')
					"
				>
					<v-btn block color="PointColor2" @click="$emit('edit', $event, data)">{{ data.btnText ? data.btnText : 'Edit' }}</v-btn>
				</div>
			</v-card>
		</v-row>
	</v-dialog>
</template>
<script>
export default {
	props: {
		data: {
			type: Object,
		},
		submitBage: {
			type: String,
			default() {
				return '0'
			},
		},
	},
}
</script>
<style lang="scss">
.report-card {
	p {
		margin-bottom: 0px;
		font-weight: 500;
		font-size: 12px;
	}
}
</style>
