<template>
	<v-dialog v-model="data.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog" dark>
		<v-row justify="center" align="center" style="height:100vh;" class="ma-0">
			<div style="margin: 0 auto;">
				<v-row style="max-width:400px; height:auto;" class="ma-0" justify="center">
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<button_1_1 @click="data.dialog = false" />
							</v-row>
						</div>
					</v-col>
				</v-row>
				<v-row style="max-width:400px; height:auto;" class="ma-0" justify="center" v-for="(qus, index) in data.qusList" :key="index">
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0 mb-2" align="center">
								<h1 class="white--text">Qus {{ index + 1 }}</h1>
								<v-spacer></v-spacer>
								<v-btn v-if="index === data.qusList.length - 1" class="mr-2" @click="addQus(index)" color="success">add</v-btn>
								<v-btn v-if="data.qusList.length > 1" @click="deleteQus(index)" color="error">delete</v-btn>
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<selectBox_1_2 v-model="qus.type" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_3 v-model="qus.title" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textArea_1_4 v-model="qus.subtitle" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12" v-if="qus.type === 'radio'">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<div v-for="(question, i) in qus.data" :key="i" style="width: 100%;">
									<v-layout align-center class="mt-3">
										<v-flex xs1>
											<v-radio-group hide-details style="margin-top:0px;" v-model="qus.answer">
												<v-radio :ripple="false" class="mr-4" :value="i" color="PointColor2"></v-radio>
											</v-radio-group>
										</v-flex>
										<v-flex xs11>
											<v-text-field
												v-model="question.name"
												hide-details
												placeholder="input"
												max-length="25"
												class="pa-0 ma-0 "
												style="max-width:90%"
												clearable
											></v-text-field>
										</v-flex>
										<v-icon @click="clickDeleteEx(index, i)">mdi-close</v-icon>
									</v-layout>
								</div>
								<v-layout class="mt-2" align-center justify-center>
									<v-icon>mdi-plus</v-icon>
									<div class="white--text" style="cursor:pointer;" @click="clickAddEx(index)">add select</div>
								</v-layout>
							</v-row>
						</div>
					</v-col>
				</v-row>
				<v-row style="max-width:400px; height:auto;" class="ma-0 mb-4" justify="center">
					<v-col class="pa-0" cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="start" align="center">
								<button_2_1 @click="cancel" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<button_2_2 @click="$emit('create', $event)" />
							</v-row>
						</div>
					</v-col>
				</v-row>
			</div>
		</v-row>
	</v-dialog>
</template>
<script>
import { button_1_1, selectBox_1_2, textField_1_3, textArea_1_4, button_2_1, button_2_2 } from '@/components/create-reportQus/index'
export default {
	name: 'create-reportQus',
	components: { button_1_1, selectBox_1_2, textField_1_3, textArea_1_4, button_2_1, button_2_2 },
	props: {
		data: {
			type: Object,
		},
	},
	methods: {
		addQus() {
			this.data.qusList.push({
				type: '',
				title: '',
				subtitle: '',
				answer: null,
				data: [
					{
						name: '',
					},
				],
			})
		},
		deleteQus(index) {
			this.data.qusList.splice(index, 1)
		},
		clickAddEx(index) {
			this.data.qusList[index].data.push({ name: '' })
		},
		clickDeleteEx(index, i) {
			if (this.data.qusList[index].data.length === 1) {
				alert('At least one option must exist.')
				return
			} else {
				this.data.qusList[index].data.splice(i, 1)
			}
		},
		cancel() {
			this.data.dialog = false
		},
	},
}
</script>
<style lang="scss" scoped></style>
