<template>
	<v-select
		:label="selectBox.label"
		:items="selectBox.items"
		:style="'max-width: ' + selectBox.width + 'px;'"
		:height="selectBox.height"
		:append-icon="selectBox['append-icon']"
		:append-outer-icon="selectBox['append-outer-icon']"
		:color="selectBox.color"
		:autofocus="selectBox.autofocus"
		:clear-icon="selectBox['clear-icon']"
		:clearable="selectBox.clearable"
		:dark="selectBox.dark"
		:dense="selectBox.dense"
		:disabled="selectBox.disabled"
		:outlined="selectBox.outlined"
		:error="selectBox.error"
		:error-count="selectBox['error-count']"
		:error-messages="selectBox['error-messages']"
		:filled="selectBox.filled"
		:flat="selectBox.flat"
		:hide-details="selectBox['hide-details']"
		:hint="selectBox.hint"
		:id="selectBox.id"
		:light="selectBox.light"
		:loader-height="selectBox['loader-height']"
		:loading="selectBox.loading"
		:messages="selectBox.messages"
		:persistent-hint="selectBox['persistent-hint']"
		:placeholder="selectBox.placeholder"
		:prefix="selectBox.prefix"
		:prepend-icon="selectBox['prepend-icon']"
		:prepend-inner-icon="selectBox['prepend-inner-icon']"
		:readonly="selectBox.readonly"
		:reverse="selectBox.reverse"
		:rounded="selectBox.rounded"
		:rules="selectBox.rules"
		:shaped="selectBox.shaped"
		:single-line="selectBox['single-line']"
		:solo="selectBox.solo"
		:solo-inverted="selectBox['solo-inverted']"
		:success="selectBox.success"
		:success-messages="selectBox['success-messages']"
		:suffix="selectBox.suffix"
		:type="selectBox.type"
		:validate-on-blur="selectBox['validate-on-blur']"
		:counter="selectBox.counter"
		:counter-value="selectBox['counter-value']"
		:value="value"
		@blur="$emit('blur', $event)"
		@change="$emit('change', $event)"
		@click="$emit('click', $event)"
		@click:append="$emit('click:append', $event)"
		@click:append-outer="$emit('click:append-outer', $event)"
		@click:clear="$emit('click:clear', $event)"
		@click:prepend="$emit('click:prepend', $event)"
		@click:prepend-inner="$emit('click:prepend-inner', $event)"
		@focus="$emit('focus', $event)"
		@input="$emit('input', $event)"
		@keydown="$emit('keydown', $event)"
		@mousedown="$emit('mousedown', $event)"
		@mouseup="$emit('mouseup', $event)"
		@update:error="$emit('update:error', $event)"
		@update:list-index="$emit('update:list-index', $event)"
		@update:search-input="$emit('update:search-input', $event)"
	/>
</template>

<script>
export default {
	name: 'protect-selectBox',
	props: {
		value: {
			type: String,
		},
		selectBox: {
			type: Object,
			default() {
				return {
					width: '100%',
					items: [],
					['append-icon']: '',
					['append-outer-icon']: '',
					autofocus: false,
					['clear-icon']: undefined,
					clearable: false,
					color: '#5680e9ff',
					counter: undefined,
					['counter-value']: undefined,
					dark: false,
					dense: undefined,
					disabled: false,
					error: false,
					['error-count']: undefined,
					['error-messages']: '',
					filled: false,
					flat: false,
					['full-width']: undefined,
					height: 40,
					['hide-details']: false,
					hint: '',
					id: undefined,
					label: '담당PM',
					light: undefined,
					['loader-height']: undefined,
					loading: false,
					messages: '',
					outlined: true,
					['persistent-hint']: undefined,
					placeholder: undefined,
					prefix: undefined,
					['prepend-icon']: '',
					['prepend-inner-icon']: '',
					readonly: false,
					reverse: false,
					rounded: true,
					rules: undefined,
					shaped: false,
					['single-line']: false,
					solo: false,
					['solo-inverted']: undefined,
					success: false,
					['success-messages']: '',
					suffix: undefined,
					type: 'text',
					['validate-on-blur']: undefined,
				}
			},
		},
	},
}
</script>
<style scoped></style>
