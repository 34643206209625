<template>
	<v-textarea
		:style="'max-width: ' + textArea.width"
		:append-icon="textArea['append-icon']"
		:append-outer-icon="textArea['append-outer-icon']"
		:autofocus="textArea.autofocus"
		:clear-icon="textArea['clear-icon']"
		:clearable="textArea.clearable"
		:color="textArea.color"
		:background-color="textArea['background-color']"
		:counter="textArea.counter"
		:counter-value="textArea['counter-value']"
		:dark="textArea.dark"
		:dense="textArea.dense"
		:disabled="textArea.disabled"
		:error="textArea.error"
		:error-count="textArea['error-count']"
		:error-messages="textArea['error-messages']"
		:filled="textArea.filled"
		:flat="textArea.flat"
		:full-width="textArea['full-width']"
		:height="textArea.height"
		:hide-details="textArea['hide-details']"
		:hint="textArea.hint"
		:id="textArea.id"
		:label="textArea.label"
		:light="textArea.light"
		:loader-height="textArea['loader-height']"
		:loading="textArea.loading"
		:messages="textArea.messages"
		:outlined="textArea.outlined"
		:persistent-hint="textArea['persistent-hint']"
		:placeholder="textArea.placeholder"
		:prefix="textArea.prefix"
		:prepend-icon="textArea['prepend-icon']"
		:prepend-inner-icon="textArea['prepend-inner-icon']"
		:readonly="textArea.readonly"
		:reverse="textArea.reverse"
		:rounded="textArea.rounded"
		:rules="textArea.rules"
		:shaped="textArea.shaped"
		:single-line="textArea['single-line']"
		:solo="textArea.solo"
		:solo-inverted="textArea['solo-inverted']"
		:success="textArea.success"
		:success-messages="textArea['success-messages']"
		:suffix="textArea.suffix"
		:type="textArea.type"
		:validate-on-blur="textArea['validate-on-blur']"
		:value="value"
		autocomplete="off"
		:maxlength="textArea.maxlength"
		@blur="$emit('blur', $event)"
		@change="$emit('change', $event)"
		@click="$emit('click', $event)"
		@click:append="$emit('click:append', $event)"
		@click:append-outer="$emit('click:append-outer', $event)"
		@click:clear="$emit('click:clear', $event)"
		@click:prepend="$emit('click:prepend', $event)"
		@click:prepend-inner="$emit('click:prepend-inner', $event)"
		@focus="$emit('focus', $event)"
		@input="$emit('input', $event)"
		@keydown="$emit('keydown', $event)"
		@mousedown="$emit('mousedown', $event)"
		@mouseup="$emit('mouseup', $event)"
		@update:error="$emit('update:error', $event)"
		no-resize
	/>
</template>

<script>
export default {
	name: 'protect-textArea',
	props: {
		value: {
			type: String,
			default() {
				return ''
			},
		},
		textArea: {
			type: Object,
			default() {
				return {
					width: '100%',
					['append-icon']: '',
					['append-outer-icon']: '',
					autofocus: false,
					['clear-icon']: undefined,
					clearable: false,
					color: '#5680e9ff',
					['background-color']: undefined,
					counter: undefined,
					['counter-value']: undefined,
					dark: false,
					dense: undefined,
					disabled: false,
					error: false,
					['error-count']: undefined,
					['error-messages']: '',
					filled: false,
					flat: false,
					['full-width']: undefined,
					height: 180,
					['hide-details']: false,
					hint: '',
					id: undefined,
					label: 'Rule',
					light: undefined,
					['loader-height']: undefined,
					loading: false,
					messages: '',
					outlined: true,
					['persistent-hint']: undefined,
					placeholder: '',
					prefix: undefined,
					['prepend-icon']: '',
					['prepend-inner-icon']: '',
					readonly: false,
					reverse: false,
					rounded: true,
					rules: undefined,
					shaped: false,
					['single-line']: false,
					solo: false,
					['solo-inverted']: undefined,
					success: false,
					['success-messages']: '',
					suffix: undefined,
					type: 'text',
					['validate-on-blur']: undefined,
					maxlength: 3000,
				}
			},
		},
	},
}
</script>
<style scoped></style>
