<template>
	<div>
		<div class="home-title"></div>
		<div class="board-list" ref="boardList">
			<div class="board-item" v-for="(board, idx) in boards" :key="idx" :data-bgcolor="board.bgColor" ref="boardItem">
				<a @click.prevent="onClick(board.id)">
					<div class="board-item-title">{{ board.title }}</div>
				</a>
			</div>
			<div class="text-center">
				<v-dialog v-model="boardDialog" fullscreen>
					<v-card>
						<v-btn class="close-btn" color="white" text @click.prevent="boardDialog = false">
							&times;
						</v-btn>

						<v-card-text>
							<!-- <Board class="pt-20" ref="boardDetail" :id="id" @deleteBoard="deleteBoard" /> -->
						</v-card-text>
					</v-card>
				</v-dialog>
			</div>
			<div class="board-item board-item-new">
				<a href="" class="new-board-btn" @click.prevent="addBoard">
					Create new board...
				</a>
			</div>
		</div>
		<AddBoard v-if="$store.state.isModal" @submit="onAddBoard" />
	</div>
</template>
<script>
import AddBoard from '../common/AddBoard.vue'
import { mapMutations } from 'vuex'
// import Board from '../common/Board'

export default {
	components: {
		AddBoard,
		// Board,
	},
	data() {
		return {
			boards: [], //보드 정보
			isModal: false,
			boardDialog: false,
			id: '',
		}
	},
	created() {
		this.fetchData()
		this.SET_THEME()
	},
	updated() {
		if (this.$refs.boardItem) {
			this.$refs.boardItem.forEach(el => {
				el.style.background = el.dataset.bgcolor
			})
		}
	},
	methods: {
		...mapMutations(['SET_THEME']),
		// 보드 기본 정보
		fetchData() {
			const input = {
				userid: this.$store.state.myData.id,
			}
			this.$store
				.dispatch('USER_BOARD', input)
				.then(res => {
					this.boards = res
				})
				.catch(err => {
					console.log(err)
					this.error = err
				})
		},
		// 보드 추가 모달창 boolean
		addBoard() {
			this.$store.state.isModal = true
		},
		// 보드 추가
		onAddBoard(title) {
			this.$store
				.dispatch('ADDBOARD', title)
				.then(() => this.fetchData())
				.catch(err => console.log(err))
		},
		onClick(id) {
			this.id = id
			this.boardDialog = true
		},
		deleteBoard() {
			this.fetchData()
			this.boardDialog = false
		},
	},
}
</script>
<style>
.home-title {
	padding: 10px;
	font-size: 18px;
	font-weight: bold;
}
.board-list {
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
	padding-top: 30px;
}
.board-item {
	width: 100%;
	height: 35px;
	margin: 0 2% 5px 0;
	border-radius: 3px;
}
.board-item a {
	text-decoration: none;
	display: block;
	width: 100%;
	height: 100%;
}
.board-item a:hover,
.board-item a:focus {
	background-color: rgba(0, 0, 0, 0.1);
	color: #666;
}
.board-item-title {
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	padding: 10px;
}
.board-item a.new-board-btn {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	height: 100px;
	width: inherit;
	color: #888;
	font-weight: 700;
}
.close-btn {
	justify-content: center;
	/* position: absolute;
	right: 10px;
	top: 10px; */
	z-index: 1;
}
.v-application a {
	color: black !important
	;
}
</style>
