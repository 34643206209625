<template>
	<v-text-field
		:style="'max-width: ' + textField.width + 'px;'"
		:append-icon="textField['append-icon']"
		:append-outer-icon="textField['append-outer-icon']"
		:autofocus="textField.autofocus"
		:clear-icon="textField['clear-icon']"
		:clearable="textField.clearable"
		:color="textField.color"
		:background-color="textField['background-color']"
		:counter="textField.counter"
		:counter-value="textField['counter-value']"
		:dark="textField.dark"
		:dense="textField.dense"
		:disabled="textField.disabled"
		:error="textField.error"
		:error-count="textField['error-count']"
		:error-messages="textField['error-messages']"
		:filled="textField.filled"
		:flat="textField.flat"
		:full-width="textField['full-width']"
		:height="textField.height"
		:hide-details="textField['hide-details']"
		:hint="textField.hint"
		:id="textField.id"
		:label="textField.label"
		:light="textField.light"
		:loader-height="textField['loader-height']"
		:loading="textField.loading"
		:messages="textField.messages"
		:outlined="textField.outlined"
		:persistent-hint="textField['persistent-hint']"
		:placeholder="textField.placeholder"
		:prefix="textField.prefix"
		:prepend-icon="textField['prepend-icon']"
		:prepend-inner-icon="textField['prepend-inner-icon']"
		:readonly="textField.readonly"
		:reverse="textField.reverse"
		:rounded="textField.rounded"
		:rules="textField.rules"
		:shaped="textField.shaped"
		:single-line="textField['single-line']"
		:solo="textField.solo"
		:solo-inverted="textField['solo-inverted']"
		:success="textField.success"
		:success-messages="textField['success-messages']"
		:suffix="textField.suffix"
		:type="textField.type"
		:validate-on-blur="textField['validate-on-blur']"
		:value="value"
		autocomplete="off"
		:maxlength="textField.maxlength"
		:max="textField.max"
		:oninput="textField.type === 'Number' ? 'if(Number(this.value) > Number(this.max)) this.value = this.max' : ''"
		@blur="$emit('blur', $event)"
		@change="$emit('change', $event)"
		@click="$emit('click', $event)"
		@click:append="$emit('click:append', $event)"
		@click:append-outer="$emit('click:append-outer', $event)"
		@click:clear="$emit('click:clear', $event)"
		@click:prepend="$emit('click:prepend', $event)"
		@click:prepend-inner="$emit('click:prepend-inner', $event)"
		@focus="$emit('focus', $event)"
		@input="$emit('input', $event)"
		@keydown="$emit('keydown', $event)"
		@mousedown="$emit('mousedown', $event)"
		@mouseup="$emit('mouseup', $event)"
		@update:error="$emit('update:error', $event)"
	/>
</template>

<script>
export default {
	name: 'protect-textField',
	props: {
		value: {
			type: [Number, String],
			default() {
				return ''
			},
		},
		textField: {
			type: Object,
			default() {
				return {
					width: '100%',
					['append-icon']: '',
					['append-outer-icon']: '',
					autofocus: false,
					['clear-icon']: undefined,
					clearable: false,
					color: '#5680e9ff',
					['background-color']: undefined,
					counter: undefined,
					['counter-value']: undefined,
					dark: false,
					dense: undefined,
					disabled: false,
					error: false,
					['error-count']: undefined,
					['error-messages']: '',
					filled: false,
					flat: false,
					['full-width']: undefined,
					height: 40,
					['hide-details']: false,
					hint: '',
					id: undefined,
					label: '전화번호',
					light: undefined,
					['loader-height']: undefined,
					loading: false,
					messages: '',
					outlined: true,
					['persistent-hint']: undefined,
					placeholder: '직원 전화번호 입력',
					prefix: undefined,
					['prepend-icon']: '',
					['prepend-inner-icon']: '',
					readonly: false,
					reverse: false,
					rounded: true,
					rules: undefined,
					shaped: false,
					['single-line']: false,
					solo: false,
					['solo-inverted']: undefined,
					success: false,
					['success-messages']: '',
					suffix: undefined,
					type: 'text',
					['validate-on-blur']: undefined,
					maxlength: 13,
					max: 9999999,
				}
			},
		},
	},
}
</script>
<style scoped></style>
