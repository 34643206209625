<template>
	<Modal>
		<div slot="header">
			<h2>
				Create new board
				<a href="" class="modal-default-button" @click="onClose">&times;</a>
			</h2>
		</div>
		<div slot="body">
			<form id="add-board-form" @submit.prevent="addBoard">
				<input class="form-control" type="text" v-model="input" ref="input" />
			</form>
		</div>
		<div slot="footer">
			<button class="btn" :class="{ 'btn-success': vaild }" type="submit" form="add-board-form" :disabled="!vaild">
				Create Board
			</button>
		</div>
	</Modal>
</template>

<script>
import Modal from '@/components/memo/Modal.vue'
export default {
	components: {
		Modal,
	},
	data() {
		return {
			input: '',
			vaild: false,
		}
	},
	watch: {
		input(v) {
			this.vaild = v.trim().length > 0
		},
	},
	mounted() {
		// 인풋창으로 포커스
		this.$refs.input.focus()
	},
	methods: {
		// 보드 닫기 버튼
		onClose() {
			this.$store.state.isModal = false
		},
		// Home의 addBoard로 에밋보내기 submit
		addBoard() {
			this.$emit('submit', this.input)
		},
	},
}
</script>

<style></style>
