<template>
	<v-dialog v-model="data.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog" dark>
		<v-row justify="center" align="center" style="height:100vh;" class="ma-0">
			<div style="margin: 0 auto;">
				<v-row style="max-width:400px; padding: 0 12px; height:auto;" class="ma-0" justify="center">
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 16px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<button_1_1 @click="data.dialog = false" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_2 v-model="data.username" :disabled="data.edit" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_3 v-model="data.password" v-if="!data.edit" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_4 v-model="data.name" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_5 v-model="data.phone" @input="inputPhoneNumber" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_6 v-model="data.birthday" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_7 v-model="data.email" :disabled="data.edit" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_8 v-model="data.position" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 16px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<DatepickerRange :picker="data.startPicker" @input="dateInput"></DatepickerRange>
							</v-row>
						</div>
					</v-col>
					<!-- <v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 16px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<DatepickerRange :picker="data.endPicker" @input="dateInput"></DatepickerRange>
							</v-row>
						</div>
					</v-col> -->
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 16px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<v-btn color="PointColor2" v-if="data.edit" @click="$emit('resetPassword', $event)">password reset</v-btn>
							</v-row>
						</div>
					</v-col>
				</v-row>
				<v-row style="max-width:400px; padding: 0 12px; height:auto;" class="ma-0 mb-4" justify="center">
					<v-col class="pa-0" cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="start" align="center">
								<button_2_1 @click="cancel" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<button_2_2 @click="$emit('create', $event)" />
							</v-row>
						</div>
					</v-col>
				</v-row>
			</div>
		</v-row>
	</v-dialog>
</template>
<script>
import {
	button_1_1,
	textField_1_2,
	textField_1_3,
	textField_1_4,
	textField_1_5,
	textField_1_6,
	textField_1_7,
	textField_1_8,
	button_2_1,
	button_2_2,
} from './create-co-worker/index'
import DatepickerRange from './DatepickerRange'
export default {
	name: 'create-co-worker',
	components: {
		DatepickerRange,
		button_1_1,
		textField_1_2,
		textField_1_3,
		textField_1_4,
		textField_1_5,
		textField_1_6,
		textField_1_7,
		textField_1_8,
		button_2_1,
		button_2_2,
	},
	props: {
		data: {
			type: Object,
		},
	},
	methods: {
		dateInput() {
			this.data.startPicker.menu = false
			this.data.endPicker.menu = false
		},
		cancel() {
			this.data.dialog = false
			this.data.username = ''
			this.data.password = ''
			this.data.name = ''
			this.data.phone = ''
			this.data.email = ''
			this.data.position = ''
		},
		inputPhoneNumber(obj) {
			var number = obj.replace(/[^0-9]/g, '')
			var phone = ''
			if (number.length < 4) {
				return number
			} else if (number.length < 7) {
				phone += number.substr(0, 3)
				phone += '-'
				phone += number.substr(3)
			} else if (number.length < 11) {
				phone += number.substr(0, 3)
				phone += '-'
				phone += number.substr(3, 3)
				phone += '-'
				phone += number.substr(6)
			} else {
				phone += number.substr(0, 3)
				phone += '-'
				phone += number.substr(3, 4)
				phone += '-'
				phone += number.substr(7)
			}
			this.data.phone = phone
		},
	},
}
</script>
<style lang="scss"></style>
