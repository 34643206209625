import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMoment from 'vue-moment'
import VueMeta from 'vue-meta'
import i18n from './i18n'
Vue.config.productionTip = false

import io from 'socket.io-client'
const socket = io(process.env.VUE_APP_BACKEND_SOCKET, {
	reconnectionDelay: 500,
	reconnection: true,
	transports: ['websocket'],
})

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

Vue.prototype.$socket = socket

import VueApexCharts from 'vue-apexcharts'
Vue.component('apexchart', VueApexCharts)

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import VueQrcodeReader from 'vue-qrcode-reader'
Vue.use(VueQrcodeReader)

// memo 설정 (quill)
import VueQuillEditor from 'vue-quill-editor'
import Quill from 'quill'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
import QuillImageUploader from 'quill-image-uploader'

Quill.register('modules/image-uploader', QuillImageUploader)
Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)

Vue.use(VueQuillEditor /* { default global options } */)

// 뷰가드 설정
router.beforeEach(async (to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (sessionStorage.getItem('protect-t') === null) {
			router.push('/')
		} else {
			next()
		}
	} else {
		next()
	}
	if (to.matched.some(record => record.meta.admin)) {
		store
			.dispatch('me')
			.then(res => {
				if (res.cls !== '1') {
					router.push('/block')
					sessionStorage.removeItem('protect-t')
				} else {
					next()
				}
			})
			.catch(() => {
				sessionStorage.removeItem('protect-t')
				router.push('/block')
			})
	} else {
		next()
	}
})

Vue.use(VueMoment)
Vue.use(VueMeta)
new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: h => h(App),
}).$mount('#app')
