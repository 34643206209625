<template>
	<v-dialog ref="dialog" v-model="picker.menu" :return-value.sync="date" persistent width="290px">
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				class="min-height48"
				color="white"
				outlined
				v-model="picker.date"
				:class="picker.class"
				:label="picker.label"
				:error-messages="picker.errorMessage"
				append-icon="mdi-calendar"
				readonly
				:height="picker.height"
				:rounded="picker.rounded"
				v-bind="attrs"
				v-on="on"
				dark
				:placeholder="picker.placeholder"
				:background-color="picker.backgroundColor"
				:hide-details="picker.hideDetail"
				:disabled="disable"
				:style="'max-width: ' + picker.width + 'px;'"
				@click:append="picker.menu = true"
			></v-text-field>
		</template>

		<v-date-picker
			v-model="picker.date"
			:multiple="picker.multiple"
			:range="picker.range"
			:allowed-dates="allowed_dates"
			scrollable
			color="PointColor2"
			@input="$emit('input', $event)"
		>
			<v-spacer v-if="picker.range"></v-spacer>
			<v-btn text color="primary" @click=";(picker.menu = false), (picker.date = [])" v-if="picker.range">
				Cancel
			</v-btn>
			<v-btn text color="primary" @click="okRange(picker.date)" v-if="picker.range">
				OK
			</v-btn>
		</v-date-picker>
	</v-dialog>
</template>

<script>
export default {
	props: {
		picker: Object,
		disable: Boolean,
		allowed_dates: Function,
	},
	data() {
		return {
			date: new Date().toISOString().substr(0, 10),
			menu: false,
			off: 0,
		}
	},
	methods: {
		okRange(date) {
			if (date.length > 1) {
				this.$refs.dialog.save(date)
			} else {
				alert('select Range Date')
			}
		},
	},
}
</script>

<style lang="scss">
.whiteBack {
	background-color: white;
}
.min-height48 {
	.v-input__append-inner {
		margin-top: 13px !important;
	}
	.v-input__slot {
		min-height: 48px !important;
	}
}
</style>
