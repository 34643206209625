<template>
	<div class="board-menu">
		<div class="board-menu-header">
			<div class="header-title">Menu</div>
			<a class="header-close-btn" href="" @click.prevent="onClickClose">&times;</a>
		</div>
		<ul class="menu-list">
			<li><a>Change Ground</a></li>
			<div class="color-picker">
				<a href="" data-value="rgb(0,121,191)" @click.prevent="onChangeTheme"></a>
				<a href="" data-value="rgb(210,144,52)" @click.prevent="onChangeTheme"></a>
				<a href="" data-value="rgb(81, 152, 57)" @click.prevent="onChangeTheme"></a>
				<a href="" data-value="rgb(176, 70, 50)" @click.prevent="onChangeTheme"></a>
				<a href="" data-value="rgb(137, 96, 158)" @click.prevent="onChangeTheme"></a>
				<a href="" data-value="rgb(205, 90, 145)" @click.prevent="onChangeTheme"></a>
				<a href="" data-value="rgb(131, 140, 145)" @click.prevent="onChangeTheme"></a>
			</div>
		</ul>
	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
	data() {
		return {
			iscolor: false,
		}
	},
	computed: {
		...mapState({
			board: 'board',
		}),
	},
	// 배경색 변경
	mounted() {
		Array.from(this.$el.querySelectorAll('.color-picker a')).forEach(el => {
			el.style.background = el.dataset.value
		})
	},
	methods: {
		...mapMutations(['SET_IS_SHOW_BOARD_SETTINGS', 'SET_THEME']),
		// show menu 닫기
		onClickClose() {
			this.SET_IS_SHOW_BOARD_SETTINGS(false)
		},
		// board 배경색 변경
		async onChangeTheme(el) {
			const bgColor = el.target.dataset.value
			const data = {
				id: this.$store.state.board.id,
				bgColor: bgColor,
			}
			await this.$store.dispatch('UPDATEBOARD', data)
			this.SET_THEME(bgColor)
			const body = document.querySelector('.board-wrapper')
			if (body) body.style.backgroundColor = this.$store.state.bodyColor
		},
	},
}
</script>

<style scoped>
.board-menu {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	background-color: #edeff0;
	width: 300px;
	transition: all 0.3s;
	z-index: 10;
}
.board-menu-header {
	height: 46px;
	padding: 0 6px 0 12px;
	border-bottom: #bbb solid 1px;
}
.header-title {
	font-size: 18px;
	text-align: center;
	line-height: 46px;
	font-weight: 700;
}
.header-close-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	text-decoration: none;
	font-size: 24px;
	color: #999;
}
.menu-list {
	list-style: none;
	padding-left: 0px;
}
.menu-list li {
	height: auto;
	line-height: 18px;
	padding: 10px;
	margin: 5px;
	border-radius: 3px;
	font-size: 18px;
	font-weight: 700;
	color: #111;
	margin-bottom: 5px;
	cursor: pointer;
}
.menu-list li:hover,
.menu-list li:focus {
	background-color: rgba(0, 0, 0, 0.1);
}
.menu-list li a {
	text-decoration: none;
	color: inherit;
}
.color-picker {
	margin: 0 15px;
}
.color-picker a {
	display: inline-block;
	width: 49%;
	height: 100px;
	border-radius: 8px;
	margin: 0 1px;
}
</style>
