<template>
	<v-dialog v-model="dialog" width="320">
		<template v-slot:activator="{ on, attrs }">
			<div :class="color_picker.btnLayout">
				<div class="colorPicker-text1">
					{{ color_picker.text }}
				</div>
				<v-btn block class="colorPicker-btn1" v-bind="attrs" v-on="on" :color="value">
					{{ value }}
				</v-btn>
			</div>
		</template>

		<v-card>
			<v-card-title class="headline grey lighten-2">
				{{ color_picker.title }}
			</v-card-title>

			<v-card-text>
				<v-color-picker
					:value="value"
					class="ma-2"
					show-swatches
					swatches-max-height="200px"
					mode="hexa"
					@input="$emit('input', $event)"
				></v-color-picker>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="dialog = false"> ok </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	props: {
		color_picker: Object,
		value: {
			type: String,
			default: '#000000FF',
		},
	},
	data() {
		return { dialog: false }
	},
	watch: {},
}
</script>
<style lang="scss" scoped>
.colorPicker-text1 {
	text-align: left !important;
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 3px;
	color: white;
}
.colorPicker-btn1 {
	width: 100%;
	height: 48px !important;
}
</style>
