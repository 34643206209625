<template>
	<v-flex xs12 class="mb-4">
		<v-layout justify-center align-center class="router-Btns">
			<v-tooltip bottom v-for="(btn, btnIndex) in $store.state.op_navList" :key="btnIndex">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-show="$route.path.indexOf(btn.to) === -1"
						:class="$vuetify.breakpoint.xs ? 'mr-2' : 'mr-4'"
						v-bind="attrs"
						v-on="on"
						:to="btn.to"
					>
						<v-icon>
							{{ btn.icon }}
						</v-icon>
					</v-btn>
				</template>
				<span>{{ btn.name }}</span>
			</v-tooltip>
		</v-layout>
	</v-flex>
</template>
<script>
export default {}
</script>
<style lang="scss">
.router-Btns {
	height: 120px;
}
.router-title {
	font-size: 48px !important;
	color: white;
}
</style>
