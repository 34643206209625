<template>
	<div>
		<v-flex xs12 class="velog">
			<div>
				<Velog />
			</div>
		</v-flex>
		<div
			v-for="(element, i) in data"
			:key="i"
			class="px-4"
			:style="
				`${
					element.backgroundImg === ''
						? 'background-color:' + element.backgroundColor + ';'
						: 'background: url(' + element.backgroundImg + ') no-repeat center center/cover;'
				}`
			"
			:id="element.type"
		>
			<v-layout
				@click="$emit('click', $event, element.type)"
				class="cursor py-6"
				:style="
					`max-width:${element.maxWidth}px; margin:0 auto; ${
						element.type === 'Portfolio' ? 'height:auto;' : 'max-height:' + element.height + 'px;'
					}`
				"
				v-show="element.active"
			>
				<v-flex xs12 v-if="element.type === 'About'">
					<h1 class="text-center" :style="`color:${element.titleColor}`">
						{{ element.type }}
					</h1>
					<v-layout justify-center class="mt-4" wrap>
						<v-flex xs12 v-for="(card, cardIndex) in element.cards" :key="cardIndex">
							<h1 :style="`color:${card.titleColor}; text-align:${card.titleAlign}; font-size:${card.titleSize}px;`">
								{{ card.title }}
							</h1>
							<h3
								:style="
									`color:${card.subTitleColor}; text-align:${card.subTitleAlign}; font-size:${card.subTitleSize}px; white-space: pre-line;`
								"
							>
								{{ card.subTitle }}
							</h3>
						</v-flex>
						<v-flex>
							<div class="mt-2">
								<v-btn @click="pdfDownload">
									Protect 소개서 다운로드
								</v-btn>
								<a style="display:none;" id="pdfDownload" href="/protect_about_V1.0.pdf" download>소개서 다운로드</a>
							</div>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 v-if="element.type === 'Footer'">
					<h1 class="text-center" :style="`color:${element.titleColor}`">
						{{ element.type }}
					</h1>
					<v-layout justify-center class="mt-4" wrap>
						<v-flex xs12 v-for="(card, cardIndex) in element.cards" :key="cardIndex">
							<v-layout wrap>
								<v-flex xs12>
									<div>
										<v-img position="left" :src="card.headers.logo" contain :max-height="card.headers.LogoSize"></v-img>
									</div>
									<h3
										class="mr-12"
										:style="
											`color:${card.subTitleColor}; text-align:${card.subTitleAlign}; font-size:${card.subTitleSize}px; white-space: pre-line;`
										"
									>
										{{ card.subTitle }}
									</h3>
								</v-flex>
							</v-layout>
							<v-divider class="my-2"></v-divider>
							<v-layout align-center>
								<h5 :style="`color:${card.reservedColor};`">
									{{ card.title }}
								</h5>
								<v-spacer></v-spacer>
								<v-btn icon v-if="card.headers.sns.instagram" :color="card.menusColor">
									<v-icon>mdi-instagram</v-icon>
								</v-btn>
								<v-btn icon v-if="card.headers.sns.facebook" :color="card.menusColor">
									<v-icon>mdi-facebook</v-icon>
								</v-btn>
								<v-btn icon v-if="card.headers.sns.youtube" :color="card.menusColor">
									<v-icon>mdi-youtube</v-icon>
								</v-btn>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 v-if="element.type === 'Portfolio'">
					<h1 class="text-center" :style="`color:${element.titleColor}`">
						{{ element.type }}
					</h1>
					<v-layout justify-start class="ma-4" wrap>
						<v-flex v-for="(card, cardIndex) in element.cards" :key="cardIndex" :lg4="!mobile" :lg12="mobile" xs12>
							<v-card
								width="100%"
								:max-width="card.width"
								style="margin:8px auto;"
								:flat="card.flat"
								@click="PortfolioDialogopen(card.images)"
							>
								<v-carousel
									dark
									:height="card.height ? card.height : '200'"
									hide-delimiter-background
									show-arrows-on-hover
									hide-delimiters
									progress
								>
									<v-carousel-item v-for="(slide, i) in card.images" :key="i">
										<v-row class="fill-height" align="center" justify="center">
											<v-img position="center" contain :height="card.height ? card.height : '200'" width="100%" :src="slide"></v-img>
										</v-row>
									</v-carousel-item>
								</v-carousel>
								<div class="ml-2 pb-1">
									{{ card.title }}
								</div>
							</v-card>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 v-if="element.type === 'Service'">
					<h1 class="text-center" :style="`color:${element.titleColor}`">
						{{ element.type }}
					</h1>
					<v-layout justify-center class="mt-4" wrap>
						<v-flex :lg4="!mobile" :lg12="mobile" xs12 v-for="(card, cardIndex) in element.cards" :key="cardIndex">
							<v-card
								:color="card.backgroundColor ? card.backgroundColor : '#FFFFFF'"
								class="pa-4"
								style="margin:6px auto !important; white-space: pre-line !important;"
								:dark="card.dark ? card.dark : false"
								:width="card.width"
							>
								<div class="text-center">
									<v-icon v-if="card.icon">mdi-{{ card.icon.name }}</v-icon>
								</div>
								<v-card-title>
									{{ card.title }}
								</v-card-title>
								<v-card-subtitle class="pb-0" style="white-space: pre-line !important;">
									{{ card.subTitle }}
								</v-card-subtitle>
							</v-card>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 v-if="element.type === 'Team' || element.type === 'Client'">
					<h1 class="text-center" :style="`color:${element.titleColor}`">
						{{ element.type }}
					</h1>
					<v-layout class="mt-4" wrap :justify-start="mobile" :justify-center="!mobile">
						<v-flex :lg2="!mobile" :lg6="mobile" sm4 md3 xs12 v-for="(card, cardIndex) in element.cards" :key="cardIndex" class="px-2">
							<v-card
								:color="card.backgroundColor ? card.backgroundColor : '#FFFFFF'"
								class="pa-0"
								style="margin:6px auto !important;"
								:dark="card.dark ? card.dark : false"
								:width="card.width"
							>
								<div class="text-center">
									<v-img style="min-height:300px; border-radius: 4px 4px 0 0;" v-if="card.img" :src="card.img" width="100%" />
								</div>
								<v-card-title class="pt-2 pb-0 text-center" style="justify-content:center;">
									{{ card.title }}
								</v-card-title>
								<v-card-subtitle class="pt-4 text-center" style="white-space: pre-line !important;">
									{{ card.subTitle }}
								</v-card-subtitle>
							</v-card>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 v-if="element.type === 'Review'">
					<h1 class="text-center" :style="`color:${element.titleColor}`">
						{{ element.type }}
					</h1>
					<v-layout class="mt-4" wrap>
						<v-flex :lg2="!mobile" :lg6="mobile" sm4 md3 xs12 v-for="(card, cardIndex) in element.cards" :key="cardIndex">
							<v-card
								:color="card.backgroundColor ? card.backgroundColor : '#FFFFFF'"
								class="pa-0"
								style="margin:6px auto !important;"
								:dark="card.dark ? card.dark : false"
								:width="card.width"
							>
								<div class="text-center">
									<v-img style="border-radius: 4px; margin: 0 auto;" v-if="card.img" :src="card.img" width="48" height="48" contain />
								</div>
								<v-card-title class="pt-0 pb-0" style="justify-content: center !important;">
									{{ card.title }}
								</v-card-title>
								<v-card-subtitle class="pt-4 pb-1 text-center" style="white-space: pre-line !important;">
									{{ card.subTitle }}
								</v-card-subtitle>
								<div class="text-center pb-2">
									<v-rating v-model="card.rating" dense readonly background-color="orange lighten-3" color="orange" small></v-rating>
								</div>
							</v-card>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 v-if="element.type === 'Price'">
					<h1 class="text-center" :style="`color:${element.titleColor}`">
						{{ element.type }}
					</h1>
					<v-layout class="mt-4" wrap>
						<v-flex :lg4="!mobile" :lg12="mobile" xs12 v-for="(card, cardIndex) in element.cards" :key="cardIndex">
							<v-card
								:color="card.backgroundColor ? card.backgroundColor : '#FFFFFF'"
								class="pa-4"
								style="margin:6px auto !important;"
								:dark="card.dark ? card.dark : false"
								:width="card.width"
							>
								<h3
									:style="
										`color:${card.typeColor}; text-align:${card.typeAlign}; font-size:${card.typeSize}px; padding-bottom:${card.typePadding}px;`
									"
								>
									{{ card.type }}
								</h3>
								<h1
									:style="
										`color:${card.titleColor}; text-align:${card.titleAlign}; font-size:${card.titleSize}px; padding-bottom:${card.pricePadding}px;`
									"
								>
									{{ card.title }}
								</h1>
								<h3
									:style="
										`color:${card.subTitleColor}; text-align:${card.subTitleAlign}; font-size:${card.subTitleSize}px; padding-bottom:${card.subPricePadding}px;`
									"
								>
									{{ card.subTitle }}
								</h3>
								<div v-for="(list, listIndex) in card.list" :key="listIndex">
									<h1
										:style="
											`color:${list.labelColor}; text-align:${list.labelAlign}; font-size:${list.labelSize}px; padding-bottom:${list.labelPadding}px;`
										"
									>
										{{ list.label }}
									</h1>
									<h3
										:style="
											`color:${list.priceColor}; text-align:${list.priceAlign}; font-size:${list.priceSize}px; padding-bottom:${list.pricePadding}px;`
										"
									>
										{{ list.price }}
									</h3>
								</div>
								<v-btn
									block
									:color="card.btnColor"
									:dark="card.btnDark"
									:rounded="card.rounded"
									:outlined="card.outlined"
									@click="scrollingContact"
								>
									contact
								</v-btn>
							</v-card>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex xs12 v-if="element.type === 'Contact'">
					<h1 class="text-center" :style="`color:${element.titleColor}`">
						{{ element.type }}
					</h1>
					<v-layout justify-center class="ma-4" wrap>
						<v-flex xs12>
							<v-text-field
								autocomplete="off"
								:color="element.fields.color"
								outlined
								label="이름"
								hint="Required"
								persistent-hint
								v-model="element.fields.name"
								:dark="element.fields.fieldsDark"
								:rules="[rules.required]"
							></v-text-field>
							<v-text-field
								autocomplete="off"
								:color="element.fields.color"
								outlined
								label="이메일"
								hint="Required"
								persistent-hint
								v-model="element.fields.email"
								:dark="element.fields.fieldsDark"
								:rules="[rules.required, rules.email]"
							></v-text-field>
							<v-text-field
								autocomplete="off"
								:color="element.fields.color"
								outlined
								label="휴대전화"
								hint="Required"
								persistent-hint
								v-model="element.fields.phone"
								:dark="element.fields.fieldsDark"
								:rules="[rules.required]"
							></v-text-field>
							<v-textarea
								autocomplete="off"
								:color="element.fields.color"
								outlined
								label="내용"
								hint="Required"
								persistent-hint
								v-model="element.fields.content"
								:dark="element.fields.fieldsDark"
								:rules="[rules.required]"
							></v-textarea>
							<v-btn block :dark="element.fields.dark" :color="element.fields.btnColor" @click="createInquiry(i)">
								문의하기
							</v-btn>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</div>
		<PortfolioDialog :data="portfolio" />
	</div>
</template>
<script>
import PortfolioDialog from './PortfolioDialog'
import Velog from '../views/memo/common/Velog.vue'
export default {
	components: {
		PortfolioDialog,
		Velog,
	},
	props: {
		data: {
			type: Array,
		},
		mobile: {
			type: Boolean,
		},
	},
	data() {
		return {
			rules: {
				required: value => !!value || 'Required',
				counter: value => value.length <= 20 || 'Max 20 characters',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalid e-mail.'
				},
			},
			portfolio: {
				dialog: false,
				images: [],
			},
			server: process.env.VUE_APP_BACKEND_URL,
		}
	},
	methods: {
		pdfDownload() {
			document.getElementById('pdfDownload').click()
		},
		scrollingContact() {
			document.getElementById('Contact').scrollIntoView({ block: 'start', behavior: 'smooth' })
		},
		createInquiry(i) {
			if (this.data[i].fields.name === '' || !this.data[i].fields.name) {
				return alert('이름을 입력해주세요.')
			} else if (this.data[i].fields.email === '' || !this.data[i].fields.email) {
				return alert('이메일을 입력해주세요.')
			} else if (this.data[i].fields.phone === '' || !this.data[i].fields.phone) {
				return alert('휴대전화를 입력해주세요.')
			} else if (this.data[i].fields.content === '' || !this.data[i].fields.content) {
				return alert('내용을 입력해주세요.')
			}
			this.$store.state.loading = true
			const data = {
				name: this.data[i].fields.name,
				email: this.data[i].fields.email,
				phone: this.data[i].fields.phone,
				content: this.data[i].fields.content,
			}
			this.$store
				.dispatch('createInquiry', data)
				.then(() => {
					alert('문의가 완료되었습니다.(빠른 시일내로 연락 드리겠습니다.)')
					this.$store.state.loading = false
					this.createSendSm(data.name, data.phone)
				})
				.catch(err => {
					alert('이메일 형식을 확인해 주세요.')
					console.log({ err })
					this.$store.state.loading = false
				})
		},
		createSendSm(name, phone) {
			const message = `${name}님의 문의가 왔습니다. (${phone})`
			const data = {
				receiver: '010-5059-6778',
				message: message,
			}
			this.$store
				.dispatch('createSendSm', data)
				.then(res => {
					console.log(res)
				})
				.catch(err => {
					console.log({ err })
				})
		},
		PortfolioDialogopen(images) {
			this.portfolio.dialog = true
			this.portfolio.images = images
		},
	},
}
</script>
<style lang="scss">
.v-responsive {
	align-items: center !important;
}
.velog {
	// display: none;
}
</style>
