<template>
	<v-dialog v-model="data.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog" dark>
		<v-row justify="center" align="center" style="height:100vh;" class="ma-0">
			<div style="margin: 0 auto;">
				<v-row style="max-width:400px; padding: 0 12px; height:auto;" class="ma-0" justify="center">
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 16px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<button_1_1 @click="data.dialog = false" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_2 v-model="data.name" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_3 v-model="data.client" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textArea_1_4 v-model="data.content" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="6" xl="6" lg="6" xs="6" sm="6" md="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<datapicker :data="data.picker1" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="6" xl="6" lg="6" xs="6" sm="6" md="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<datapicker :data="data.picker2" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<selectBox_1_5 v-model="data.team" :selectBox="data.selectBox1" @change="$emit('selectBox1', $event)" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<selectBox_1_6 v-model="data.pmName" :selectBox="data.selectBox2" />
							</v-row>
						</div>
					</v-col>
				</v-row>
				<v-row style="max-width:400px; padding: 0 12px; height:auto;" class="ma-0 mb-4" justify="center">
					<v-col class="pa-0" cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="start" align="center">
								<button_2_1 @click="cancel" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<button_2_2 @click="$emit('create', $event)" />
							</v-row>
						</div>
					</v-col>
				</v-row>
			</div>
		</v-row>
	</v-dialog>
</template>
<script>
import {
	button_1_1,
	textField_1_2,
	textField_1_3,
	textArea_1_4,
	selectBox_1_5,
	selectBox_1_6,
	button_2_1,
	button_2_2,
} from '@/components/create-project/index'
import datapicker from './datapicker'
export default {
	name: 'create-project',
	components: { button_1_1, textField_1_2, textField_1_3, textArea_1_4, selectBox_1_5, selectBox_1_6, button_2_1, button_2_2, datapicker },
	props: {
		data: {
			type: Object,
		},
	},
	methods: {
		cancel() {
			this.data.dialog = false
			this.data.name = ''
			this.data.client = ''
			this.data.content = ''
			this.data.picker1 = {
				date: new Date().toISOString().substr(0, 10),
			}
			this.data.picker2 = {
				date: new Date().toISOString().substr(0, 10),
			}
			this.data.team = ''
			this.data.pmName = ''
		},
	},
}
</script>
<style lang="scss" scoped></style>
