<template>
	<div>
		<v-data-table
			v-model="datatable.select"
			dark
			backgroundColor="MainColor2"
			:headers="datatable.headers"
			:items="datatable.items"
			:hide-default-footer="datatable.hidedefaultfooter"
			:hide-default-header="datatable.hidedefaultheader"
			:items-per-page.sync="datatable.itemPerPage"
			:single-expand="datatable.singleExpand"
			:expanded.sync="datatable.expanded"
			:item-key="datatable.key"
			:single-select="datatable.singleSelect"
			:show-expand="datatable['show-expand']"
			:page.sync="datatable.page"
			@page-count="datatable.pageCount = $event"
			:show-select="datatable.showselect"
			:search="search"
			@click:row="$emit('click', $event)"
			@pagination="$emit('pagination', $event)"
			:height="datatable.height"
			class="main-data-table"
			:footer-props="{
				['items-per-page-text']: `• Total : ${
					datatable.total ? datatable.total : datatable.origin_items ? datatable.origin_items.length : datatable.items.length
				}`,
				['items-per-page-options']: [10, 20, 30, 40, 50, 100],
			}"
			:server-items-length="datatable.total"
		>
			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length" class="pt-2">
					<div>
						<h3>업무내용:</h3>
						<div style="white-space: pre-line;">{{ item.content }}</div>
					</div>
					<div v-for="(chat, chatIndex) in item.comment" :key="chatIndex">
						<v-card color="PointColor2" outlined tile v-if="chat.show">
							<v-layout align-center class="px-4" justify-end v-if="$store.state.myData.email === chat.email">
								<div class="chat-layout" style="text-align: -webkit-right;">
									<v-layout align-center justify-end>
										<div class="name-date my">{{ chat.user }}, {{ chat.created_at | moment('YYYY-MM-DD HH:mm:ss') }}</div>
										<v-btn class="ml-1" color="PointColor1" x-small @click="$emit('deleteComment', $event, item, chat)" icon>
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</v-layout>
									<div
										@click="downloadURL(chat.url, chat.comment)"
										class="myContent"
										:style="`background-color:${$store.state.MainColor1};`"
										v-if="chat.url"
									>
										<v-icon color="white">mdi-file</v-icon>{{ chat.comment }}
									</div>
									<div class="myContent" :style="`background-color:${$store.state.MainColor1};`" v-else>
										{{ chat.comment }}
									</div>
								</div>
							</v-layout>
							<v-layout align-center class="px-4" v-else>
								<v-avatar size="36" color="white" class="mr-2">
									<img :src="server + chat.face.url" alt="John" v-if="chat.face" />
									<img src="/image/img-profile-default-s.png" alt="John" v-else />
								</v-avatar>
								<div class="chat-layout">
									<div class="name-date">{{ chat.user }}, {{ chat.created_at | moment('YYYY-MM-DD HH:mm:ss') }}</div>
									<div @click="downloadURL(chat.url, chat.comment)" class="content" v-if="chat.url">
										<v-icon color="black">mdi-file</v-icon>{{ chat.comment }}
									</div>
									<div class="content" v-else>{{ chat.comment }}</div>
								</div>
							</v-layout>
						</v-card>
					</div>
					<v-layout class="my-2">
						<v-card color="white" class="my-2" v-show="$store.state.fileUploadProgress > 0">
							<v-layout align-center class="py-2 px-4" justify-center>
								<strong class="black--text mr-2">{{ Math.ceil($store.state.fileUploadProgress) }}%</strong>
								<v-progress-linear
									rounded
									v-model="$store.state.fileUploadProgress"
									color="PointColor1"
									height="6"
									style="max-width:100%;"
									class="mx-2"
								>
								</v-progress-linear>
								<div class="black--text ml-2" v-show="$store.state.fileUploadProgress < 100">Uploading!</div>
								<div class="PointColor1--text ml-2" v-show="$store.state.fileUploadProgress >= 100">
									<strong> Completed</strong>
								</div>
							</v-layout>
						</v-card>
						<textField
							class="mr-2"
							v-model="datatable.comment"
							:textField="commentTextField"
							@keydown.enter="$emit('updateComment', $event, item)"
						/>
						<btn class="ml-2" :btn="commentBtn" btn_txt="댓글등록" @click="$emit('updateComment', $event, item)" />
						<btn class="ml-2" :btn="commentBtn" btn_txt="upload" @click="fileClick(item)" />
						<input type="file" id="file-comment" @change="fileupload" style="display:none;" />
					</v-layout>
					<div v-if="item.list">
						<div class="versionDown" v-if="item.list.length > 0">
							<h3>이전버전:</h3>
							<v-data-table
								v-model="versionDown.select"
								dark
								backgroundColor="MainColor2"
								:headers="versionDown.headers"
								:items="item.list"
								:hide-default-footer="true"
								:hide-default-header="false"
								items-per-page.sync="100"
								:single-expand="false"
								:expanded.sync="versionDown.expanded"
								:item-key="versionDown.key"
								:single-select="datatable.singleSelect"
								:show-expand="versionDown['show-expand']"
								page.sync="1"
								@page-count="datatable.pageCount = $event"
								:show-select="false"
								class="main-data-table"
							>
								<template v-slot:[`item.workAction`]="{ item }">
									<v-layout>
										<v-btn @click="openURL(item.url)" x-small color="PointColor1">기획서</v-btn>
									</v-layout>
								</template>
								<template v-slot:[`item.content`]="{ item }">
									<div style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
										{{ item.content }}
									</div>
								</template>
								<template v-slot:[`item.confirm`]="{ item }">
									<div>
										<div>
											<v-btn
												x-small
												color="PointColor2"
												@click="$emit('confirm', $event, item)"
												v-if="
													item.status === 'planning'
														? item.planningConfirm.indexOf($store.state.myData.id) === -1 &&
														  (item.devID === $store.state.myData.id ||
																item.plannerID === $store.state.myData.id ||
																'1' === $store.state.myData.id)
														: item.status === 'dev'
														? item.devID === $store.state.myData.id && item.devConfirm.indexOf($store.state.myData.id) === -1
														: item.status === 'qa'
														? item.qaConfirm.indexOf($store.state.myData.id)
														: false
												"
												>{{ `${confirmText(item)} 컨펌` }}
											</v-btn>
											<v-btn v-else x-small color="success" v-show="item.status === 'dev' ? $store.state.myData.id === item.devID : false">
												{{ item.status === 'complete' ? '완료' : `${confirmText(item)}컨펌 완료` }}
											</v-btn>
										</div>
										<v-btn x-small color="error" v-if="noconfirmText(item) !== ''">{{ `${noconfirmText(item)} 컨펌필요` + '??' }} </v-btn>
										<v-btn x-small color="success" v-else>개발완료 ({{ item.complete }}) </v-btn>
									</div>
								</template>
								<template v-slot:[`item.status`]="{ item }">
									<div class="PointColor1--text" v-show="item.status === 'planning' && item.type !== '공지'">
										{{ workStatus(item.status) }}
									</div>
									<div class="white--text" v-show="item.status === 'dev'">
										{{ workStatus(item.status) }}
									</div>
									<div class="yellow--text" v-show="item.status === 'qa'">
										{{ workStatus(item.status) }}
									</div>
									<div class="success--text" v-show="item.status === 'complete'">
										{{ workStatus(item.status) }}
									</div>
								</template>
								<template v-slot:expanded-item="{ headers, item }">
									<td :colspan="headers.length" class="pt-2">
										<div>
											<h3>업무내용:</h3>
											<div style="white-space: pre-line;">{{ item.content }}</div>
										</div>
										<div v-for="(chat, chatIndex) in item.comment" :key="chatIndex">
											<v-card color="PointColor2" outlined tile v-if="chat.show">
												<v-layout align-center class="px-4" justify-end v-if="$store.state.myData.email === chat.email">
													<div class="chat-layout" style="text-align: -webkit-right;">
														<v-layout align-center>
															<div class="name-date my">{{ chat.user }}, {{ chat.created_at | moment('YYYY-MM-DD HH:mm:ss') }}</div>
														</v-layout>
														<div class="myContent" :style="`background-color:${$store.state.MainColor1};`">
															{{ chat.comment }}
														</div>
													</div>
												</v-layout>
												<v-layout align-center class="px-4" v-else>
													<v-avatar size="36" color="white" class="mr-2">
														<img :src="server + chat.face.url" alt="John" v-if="chat.face" />
														<img src="/image/img-profile-default-s.png" alt="John" v-else />
													</v-avatar>
													<div class="chat-layout">
														<div class="name-date">{{ chat.user }}, {{ chat.created_at | moment('YYYY-MM-DD HH:mm:ss') }}</div>
														<div class="content">
															{{ chat.comment }}
														</div>
													</div>
												</v-layout>
											</v-card>
										</div>
									</td>
								</template></v-data-table
							>
						</div>
					</div>
				</td>
			</template>
			<template v-slot:no-data>
				<div class="text-center no-data">
					no-data
				</div>
			</template>
			<template v-slot:[`item.No`]="{ index }">
				<div>
					{{ index + 1 }}
				</div>
			</template>
			<template v-slot:[`item.No_click_expand`]="{ index, item }">
				<div @click="expandItemRow(item)" style="height: 100%; padding-top: 14px;">
					{{ index + 1 }}
				</div>
			</template>
			<template v-slot:[`item.period`]="{ item }">
				<div>
					{{ item.start + ' ~ ' + item.end }}
				</div>
			</template>
			<template v-slot:[`item.workUrl`]="{ item }">
				<v-btn icon :to="'/plan/' + item.interID">
					<v-icon>mdi-link </v-icon>
				</v-btn>
			</template>
			<template v-slot:[`item.work`]="{ item }">
				<div>
					{{ item.work | moment('YYYY-MM-DD HH:mm:ss') }}
				</div>
			</template>
			<template v-slot:[`item.Leave`]="{ item }">
				<div>
					{{ item.Leave | moment('YYYY-MM-DD HH:mm:ss') }}
				</div>
			</template>
			<template v-slot:[`item.NameId`]="{ item }">
				<div>
					{{ item.Name + ' (' + item.username + ')' }}
				</div>
			</template>
			<template v-slot:[`item.confirm`]="{ item }">
				<div v-if="item.type === '공지'" />
				<div v-else>
					<div>
						<v-btn
							x-small
							color="PointColor2"
							@click="$emit('confirm', $event, item)"
							v-if="
								item.status === 'planning'
									? item.planningConfirm.indexOf($store.state.myData.id) === -1 &&
									  (item.devID === $store.state.myData.id || item.plannerID === $store.state.myData.id || '1' === $store.state.myData.id)
									: item.status === 'dev'
									? item.devID === $store.state.myData.id && item.devConfirm.indexOf($store.state.myData.id) === -1
									: item.status === 'qa'
									? item.qaConfirm.indexOf($store.state.myData.id)
									: false
							"
							>{{ `${confirmText(item)} 컨펌` }}
						</v-btn>
						<v-btn v-else x-small color="success" v-show="item.status === 'dev' ? $store.state.myData.id === item.devID : false">
							{{ item.status === 'complete' ? '완료' : `${confirmText(item)}컨펌 완료` }}
						</v-btn>
					</div>
					<v-btn x-small color="error" v-if="noconfirmText(item) !== ''">{{ `${noconfirmText(item)} 컨펌필요` }} </v-btn>
					<v-btn x-small color="success" v-else>개발완료 ({{ item.complete }}) </v-btn>
				</div>
			</template>
			<template v-slot:[`item.status`]="{ item }">
				<div class="FontColor1--text" v-show="item.type === '공지'">
					공지
				</div>
				<div class="PointColor1--text" v-show="item.status === 'planning' && item.type !== '공지'">
					{{ workStatus(item.status) }}
				</div>
				<div class="white--text" v-show="item.status === 'dev'">
					{{ workStatus(item.status) }}
					<span v-if="item.dev">({{ item.dev | moment('YYYY-MM-DD') }})</span>
					<div>
						<v-btn
							v-if="item.devID === $store.state.myData.id"
							x-small
							color="PointColor2"
							@click="
								() => {
									picker.dialog = true
									uploadItem = item
								}
							"
							>개발날짜</v-btn
						>
					</div>
				</div>
				<div class="yellow--text" v-show="item.status === 'qa'">
					{{ workStatus(item.status) }}
				</div>
				<div class="success--text" v-show="item.status === 'complete'">
					{{ workStatus(item.status) }}
				</div>
			</template>
			<template v-slot:[`item.WorkTime`]="{ item }">
				<div>
					{{ Timediff(item.work, item.Leave) + ' Hour' }}
				</div>
			</template>
			<template v-slot:[`item.devList`]="{ item }">
				<div>
					<v-btn readonly x-small v-if="$store.state.devItem.devID === item.id">담당자</v-btn>
					<v-btn v-else @click="$emit('devSelect', $event, item)" x-small color="PointColor2">담당자 지정</v-btn>
				</div>
			</template>
			<template v-slot:[`item.content`]="{ item }">
				<div style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
					{{ item.content }}
				</div>
			</template>
			<template v-slot:[`item.content_click_expand`]="{ item }">
				<div
					@click="expandItemRow(item)"
					style="height: 100%; padding-top: 14px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"
				>
					{{ item.content }}
				</div>
			</template>
			<template v-slot:[`item.workAction`]="{ item }">
				<v-layout v-if="item.type === '공지'">
					<v-btn
						v-if="$store.state.myData.id === item.plannerID"
						@click="$emit('deleteWork', $event, item)"
						x-small
						class="ml-1"
						color="error"
						icon
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-layout>
				<v-layout v-else>
					<v-btn @click="openURL(item.url)" x-small color="PointColor1">기획서</v-btn>
					<v-btn @click="$emit('devSetting', $event, item)" x-small class="ml-1" color="PointColor2">개발자</v-btn>
					<v-btn
						v-if="$store.state.myData.id === item.plannerID"
						@click="$emit('deleteWork', $event, item)"
						x-small
						class="ml-1"
						color="error"
						icon
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-btn
						v-if="item.status === 1 && item.email === $store.state.myData.email"
						@click="$emit('workAction', $event, item)"
						x-small
						color="PointColor2"
						>report</v-btn
					>
					<v-btn
						v-if="item.status === 1 && item.email === $store.state.myData.email"
						@click="$emit('workActionDelete', $event, item)"
						icon
						x-small
						class="ml-2"
						color="error"
						><v-icon>mdi-close</v-icon></v-btn
					>
					<v-btn v-else-if="item.status === 2 && item.email === $store.state.myData.email" x-small color="success">checking</v-btn>
					<!-- <v-btn
						v-else-if="item.status === 3 && item.email === $store.state.myData.email"
						@click="$emit('workAction', $event, item)"
						x-small
						color="white"
						class="black--text"
						>finish</v-btn
					> -->
					<div v-if="item.status === 3 && item.checker[item.checker.length - 1]">
						Checked: {{ item.checker[item.checker.length - 1].name }}
					</div>
				</v-layout>
			</template>
			<template v-slot:[`item.workCheckAction`]="{ item }">
				<v-layout>
					<v-btn
						v-if="item.status === 1 && item.email !== $store.state.myData.email"
						@click="$emit('workAction', $event, item)"
						x-small
						color="PointColor2"
						>report</v-btn
					>
					<v-btn
						v-if="item.status === 1 && item.email !== $store.state.myData.email"
						@click="$emit('workActionDelete', $event, item)"
						icon
						x-small
						class="ml-2"
						color="error"
						><v-icon>mdi-close</v-icon></v-btn
					>
					<v-btn
						v-else-if="item.status === 2 && item.email !== $store.state.myData.email"
						x-small
						color="success"
						@click="$emit('workCheckAction', $event, item)"
						>checking</v-btn
					>
					<v-btn
						v-else-if="item.status === 3 && item.email !== $store.state.myData.email"
						@click="$emit('workAction', $event, item)"
						x-small
						color="white"
						class="black--text"
						>finish</v-btn
					>
				</v-layout>
			</template>
			<template v-slot:[`item.join`]="{ item }">
				<v-layout align-center v-if="teamList">
					<v-spacer></v-spacer>
					<div v-if="teamList.map(x => x.username).indexOf(item.username) === -1" v-show="$store.state.myData.username !== item.username">
						<v-btn
							v-if="$store.state.allowLevel.includes(Number($store.state.myData.level))"
							fab
							x-small
							@click="$emit('join', $event, item, teamId)"
							color="PointColor2"
						>
							join{{ item.join }}
						</v-btn>
					</div>
					<v-btn v-else x-small color="PointColor4">
						already myTeam
					</v-btn>
				</v-layout>
			</template>
			<template v-slot:[`item.vacationStatus`]="{ item }">
				<div v-show="item.status === 1" class="PointColor1--text">
					{{ vacationStatus(item.status) }}
				</div>
				<div v-show="item.status === 2" class="error--text">
					{{ vacationStatus(item.status) }}
				</div>
				<div v-show="item.status === 3" class="white--text">
					{{ vacationStatus(item.status) }}
				</div>
			</template>
			<template v-slot:[`item.vacationDate`]="{ item }">
				<div>
					{{ item.vacation | moment('YYYY-MM-DD') }}
					<span v-show="item.halfDay === null"> ~ {{ item.vacationEnd | moment('YYYY-MM-DD') }} </span>
					<span v-show="item.halfDay >= 0 && item.halfDay !== null"> ({{ item.halfDay === 0 ? '0.5' : '1' }}) </span>
				</div>
			</template>
			<template v-slot:[`item.vacationAction`]="{ item }">
				<div>
					<v-btn
						small
						@click="$emit('vacationEdit', $event, item)"
						v-if="item.status === 1 && $store.state.myData.email === item.users_permissions_user.email"
						color="PointColor1"
					>
						edit
					</v-btn>
					<v-btn
						small
						@click="$emit('vacationReject', $event, item)"
						v-if="item.status === 2 && $store.state.myData.email === item.users_permissions_user.email"
						color="error"
					>
						reject
					</v-btn>
					<v-btn
						small
						@click="$emit('vacationCancel', $event, item)"
						v-if="
							item.status === 3 &&
								new Date() < new Date(item.vacationEnd) &&
								$store.state.myData.email === item.users_permissions_user.email
						"
						color="white"
						class="black--text"
					>
						cancel
					</v-btn>
				</div>
			</template>
			<template v-slot:[`item.vacationAlertAction`]="{ item }">
				<div>
					<v-btn @click="$emit('vacationReject', $event, item)" v-if="item.status === 1" color="error" x-small>
						reject
					</v-btn>
					<v-btn @click="$emit('vacationOk', $event, item)" v-if="item.status === 1" color="white" class="black--text ml-1" x-small>
						ok
					</v-btn>
				</div>
			</template>
			<template v-slot:[`item.pmNameStatistics`]="{ item }">
				<div>
					{{ item.pmName }}
					<span v-if="item.team"> ({{ item.team.users_permissions_users.length }})</span>
				</div>
			</template>
			<template v-slot:[`item.ProjectView`]="{ item }">
				<v-btn icon @click="$emit('detailProjectShow', $event, item)">
					<v-icon>mdi-link </v-icon>
				</v-btn>
			</template>
			<template v-slot:[`item.todolist1`]="{ item }">
				<div v-if="item.todolist">
					{{ item.todolist.list.filter(x => x.status === 1).length }}
				</div>
			</template>
			<template v-slot:[`item.todolist2`]="{ item }">
				<div v-if="item.todolist">
					{{ item.todolist.list.filter(x => x.status === 2).length }}
				</div>
			</template>
			<template v-slot:[`item.todolist3`]="{ item }">
				<div v-if="item.todolist">
					{{ item.todolist.list.filter(x => x.status === 3).length }}
				</div>
			</template>
			<template v-slot:[`item.coWorkerAction`]="{ item }">
				<div>
					<v-btn color="white" dark icon class="mr-2" @click="$emit('editUser', $event, item)">
						<v-icon>mdi-account-edit</v-icon>
					</v-btn>
					<v-btn color="white" dark icon class="mr-2" @click="$emit('moneyDialog', $event, item)">
						<v-icon>mdi-cash-100</v-icon>
					</v-btn>
				</div>
			</template>
			<template v-slot:[`item.taxAction`]="{ item }">
				<div>
					<v-btn color="white" dark icon class="mr-2" @click="$emit('editTax', $event, item)">
						<v-icon>mdi-clipboard-edit-outline</v-icon>
					</v-btn>
					<v-btn color="white" dark icon class="mr-2" @click="$emit('showTax', $event, item)">
						<v-icon>mdi-cash-100</v-icon>
					</v-btn>
				</div>
			</template>
			<template v-slot:[`item.ruleAction`]="{ item }">
				<div>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="white" v-bind="attrs" v-on="on" dark icon class="mr-2" @click="$emit('editRule', $event, item)">
								<v-icon>mdi-clipboard-edit-outline</v-icon>
							</v-btn>
						</template>
						<span>edit Rule</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="white" v-bind="attrs" v-on="on" dark icon class="mr-2" @click="$emit('showRule', $event, item)">
								<v-icon>mdi-comment-edit-outline</v-icon>
							</v-btn>
						</template>
						<span>show Rule</span>
					</v-tooltip>
				</div>
			</template>
			<template v-slot:[`item.reportAction`]="{ item }">
				<div>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="white" v-bind="attrs" v-on="on" dark icon class="mr-2" @click="$emit('showSelfReport', $event, item)">
								<v-icon>mdi-comment-edit-outline</v-icon>
							</v-btn>
						</template>
						<span>show {{ item.qusType }}</span>
					</v-tooltip>
				</div>
			</template>
			<template v-slot:[`item.voiceSystemAction`]="{ item }">
				<div>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="white" v-bind="attrs" v-on="on" dark icon class="mr-2" @click="$emit('showVoiceSystem', $event, item)">
								<v-icon>mdi-comment-edit-outline</v-icon>
							</v-btn>
						</template>
						<span>show {{ item.type }}</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn color="white" v-bind="attrs" v-on="on" dark icon class="mr-2" @click="$emit('soundVoiceSystem', $event, item)">
								<v-icon>mdi-surround-sound</v-icon>
							</v-btn>
						</template>
						<span>speak {{ item.type }}</span>
					</v-tooltip>
				</div>
			</template>
			<template v-slot:[`item.created_at`]="{ item }">
				<div>
					{{ item.created_at | moment('YYYY-MM-DD HH:mm:ss') }}
				</div>
			</template>
			<template v-slot:[`item.updated_at`]="{ item }">
				<div>
					{{ item.updated_at | moment('YYYY-MM-DD HH:mm:ss') }}
				</div>
			</template>
		</v-data-table>
		<v-dialog ref="dialog" v-model="picker.dialog" :return-value.sync="picker.date" persistent width="290px">
			<v-date-picker v-model="picker.date" scrollable color="#5680E9FF">
				<v-spacer></v-spacer>
				<v-btn text color="primary" @click="picker.dialog = false">
					Cancel
				</v-btn>
				<v-btn text color="primary" @click="saveDevDate">
					OK
				</v-btn>
			</v-date-picker>
		</v-dialog>
		<dialog1 :setDialog="setDialog" @cancel="setDialog.dialog = false" @ok="setDialog.dialog = false" />
	</div>
</template>

<script>
import textField from './textField'
import btn from './button.vue'
import dialog1 from './dialog-1'

export default {
	components: { textField, btn, dialog1 },
	data() {
		return {
			setDialog: {
				dialog: false,
				title: '',
				content: '',
				btnText: '',
				type: '',
			},
			picker: {
				dialog: false,
				date: new Date().toISOString().substr(0, 10),
				label: 'start',
			},
			uploadItem: null,
			versionDown: {
				headers: [
					{ text: '버전', value: 'version', sortable: true },
					{ text: '업무내용', value: 'content', sortable: false, width: '45%' },
					{ text: '기획자', value: 'user' },
					{ text: '개발자', value: 'devIDName' },
					{ text: '컨펌', value: 'confirm', width: '15%' },
					{ text: '상태', value: 'status' },
					{ text: '액션', value: 'workAction', sortable: false },
				],
				items: [],
				hidedefaultfooter: false,
				hidedefaultheader: false,
				singleExpand: false,
				expanded: [],
				['show-expand']: true,
				key: 'version',
				comment: '',
			},
			server: process.env.VUE_APP_BACKEND_URL,
			comment: '',
			commentTextField: {
				dark: false,
				dense: true,
				['background-color']: 'PointColor2',
				autofocus: false,
				width: '100%',
				['error-messages']: '',
				placeholder: 'comment Title',
				['prepend-inner-icon']: 'mdi-comment',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			commentBtn: {
				color: 'PointColor1',
				height: 38,
			},
		}
	},
	created() {},
	methods: {
		expandItemRow(item) {
			this.$emit('expandRow', item)
		},
		saveDevDate() {
			this.$store.state.loading = true
			const data = {
				id: this.uploadItem.id,
				dev: this.picker.date,
			}
			this.$store.dispatch('updateTodolist', data).then(() => {
				this.picker.dialog = false
				this.$store.state.loading = false
				document.getElementById('todoActive').click()
			})
		},
		downloadURL(url, name) {
			var link = document.createElement('a')
			link.download = name
			link.href = url
			link.target = '_blank'
			document.body.appendChild(link)
			link.click()
		},
		multifileUpload(file) {
			const data = {
				file: file,
			}
			this.$store
				.dispatch('fileUpload', data)
				.then(res => {
					const comments = this.uploadItem.comment
					comments.push({
						created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
						comment: res[0].name,
						url: process.env.VUE_APP_BACKEND_URL + res[0].url,
						user: this.$store.state.myData.Name,
						email: this.$store.state.myData.email,
						show: true,
						face: this.$store.state.myData.face,
					})
					const data = {
						id: this.uploadItem.id,
						comment: comments,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							document.getElementById('todoActive').click()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
					this.$store.state.fileUploadProgress = 0
				})
				.catch(err => {
					console.log({ err })
				})
		},
		fileupload(e) {
			this.multifileUpload(e.target.files[0])
		},
		fileClick(item) {
			this.uploadItem = item
			document.getElementById('file-comment').click()
		},
		noconfirmText(item) {
			let text = ''
			if (item.status === 'planning') {
				if (item.planningConfirm.indexOf(item.plannerID) === -1) text += '기획자 '
				if (item.planningConfirm.indexOf(item.devID) === -1) text += '개발자 '
				if (item.planningConfirm.indexOf(item.completeID) === -1) text += '담당자 '
			} else if (item.status === 'dev') {
				if (item.devConfirm.indexOf(item.devID) === -1) text += '개발자 '
			} else if (item.status === 'qa') {
				if (item.qaConfirm.indexOf(item.plannerID) === -1) text += '기획자 '
				if (item.qaConfirm.indexOf(item.devID) === -1) text += '개발자 '
				if (item.qaConfirm.indexOf(item.completeID) === -1) text += '담당자 '
			}
			return text
		},
		confirmText(item) {
			let text = ''
			if (item.status === 'planning' || item.status === 'qa') {
				if (this.$store.state.myData.id === item.plannerID) text += '기획자 '
				if (this.$store.state.myData.id === item.devID) text += '개발자 '
				if (this.$store.state.myData.id === item.completeID) text += '담당자 '
			} else if (item.status === 'dev') text = '개발자 '

			return text
		},
		openURL(url) {
			if (String(url).length === 0) {
				this.setDialog.title = '기획서'
				this.setDialog.content = '등록된 기획서가 없습니다.'
				this.setDialog.type = 'oneBtnType'
				this.setDialog.dialog = true
			} else window.open(url)
		},
		numberFormat(inputNumber) {
			return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		vacationStatus(data) {
			if (data === 1) {
				return 'request'
			} else if (data === 2) {
				return 'reject'
			} else if (data === 3) {
				return 'ok'
			}
		},
		Timediff(time1, time2) {
			let t2
			if (time2) {
				t2 = this.$moment(time2)
			} else {
				t2 = this.$moment(time1).add(6, 'hours')
			}
			const t1 = this.$moment(time1)
			return Math.round(t2.diff(t1, 'minutes') / 60)
		},
		workStatus(data) {
			if (data === 'planning') {
				return '기획'
			} else if (data === 'dev') {
				return '개발'
			} else if (data === 'qa') {
				return 'QA'
			} else if (data === 'complete') {
				return '완료'
			}
		},
	},
	props: {
		search: String,
		datatable: Object,
		teamList: Array,
		teamId: String,
	},
}
</script>

<style lang="scss">
.versionDown {
	border: 1px solid white;
	padding: 4px;
	margin-left: 36px;
	margin-bottom: 10px;
}
.main-data-table {
	td {
		cursor: pointer;
	}
}
.dt-align-set {
	margin-left: -16px;
	text-align: center;
}
.OkBtn {
	cursor: pointer;
	text-decoration: underline;
}
.datatablehover .v-data-table__wrapper {
	td {
		overflow: auto;
	}
	margin-top: 20px;
}
.cursorDefalut .v-data-table__wrapper {
	td {
		cursor: default;
	}
}
.overflow_common {
	overflow: auto;
}
.datatablehover .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
	cursor: pointer !important;
	background-color: #d1d1d1 !important;
}
.action_btn,
.password_btn {
	border: 1px solid #d1d1d1;
	border-radius: 5px 5px 5px 5px;
	font-weight: bold;
}
.whiteBtn {
	border: 1px solid #d1d1d1 !important;
	background-color: white !important;
	border-radius: 5px;
	font-weight: bold;
	width: 100px;
	height: 36px;
}
.font-green {
	color: #4caf50;
}
.font-red {
	color: #ff5252;
}
.nameEvent {
	color: #fdc85a;
	cursor: pointer !important;
	text-decoration: underline;
}
</style>
