<template>
	<v-dialog v-model="data.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog" dark>
		<v-row justify="center" align="center" style="height:100vh;" class="ma-0">
			<div style="margin: 0 auto;">
				<v-row style="max-width:400px; padding: 0 12px; height:auto;" class="ma-0" justify="center">
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 16px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<v-chip-group v-model="data.type" column @change="vacationType" active-class="PointColor1--text">
									<v-chip filter outlined>
										Vacation
									</v-chip>
									<v-chip filter outlined>
										Half Day
									</v-chip>
								</v-chip-group>
								<v-spacer></v-spacer>
								<button_1_1 @click="data.dialog = false" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_2 v-model="data.content" :textField="textField" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 16px 0px;">
							<v-row class="ma-0" justify="center" align="center" v-if="data.type === 0">
								<DatepickerRange :picker="data.picker" @input="dateInput"></DatepickerRange>
							</v-row>
							<v-row class="ma-0" justify="center" align="center" v-else-if="data.type === 1">
								<DatepickerRange :picker="data.halfPicker" @input="dateInput2"></DatepickerRange>
							</v-row>
							<v-chip-group v-show="data.type === 1" v-model="data.halfType" column active-class="PointColor3--text">
								<v-chip filter outlined>
									Half
								</v-chip>
								<v-chip filter outlined>
									All Day
								</v-chip>
							</v-chip-group>
						</div>
					</v-col>
				</v-row>
				<v-row style="max-width:400px; padding: 0 12px; height:auto;" class="ma-0 mb-4" justify="center">
					<v-col class="pa-0" cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="start" align="center">
								<button_2_1 @click="cancel" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<button_2_2 @click="$emit('create', $event)" />
							</v-row>
						</div>
					</v-col>
				</v-row>
			</div>
		</v-row>
	</v-dialog>
</template>
<script>
import { button_1_1, textField_1_2, button_2_1, button_2_2 } from './create-co-worker/index'
import DatepickerRange from './DatepickerRange'
export default {
	name: 'create-co-worker',
	components: {
		DatepickerRange,
		button_1_1,
		textField_1_2,
		button_2_1,
		button_2_2,
	},
	props: {
		data: {
			type: Object,
		},
	},
	data() {
		return {
			textField: {
				width: '100%',
				reverse: false,
				autofocus: false,
				clearable: true,
				color: '#5680e9ff',
				label: 'Vacation Content',
				outlined: true,
				rounded: true,
				['hide-details']: false,
				placeholder: '',
				type: 'text',
				maxlength: 255,
			},
		}
	},
	methods: {
		vacationType(val) {
			console.log(val)
		},
		dateInput() {
			this.data.picker.date.sort(function(a, b) {
				if (a > b) return 1
				if (a === b) return 0
				if (a < b) return -1
			})
		},
		dateInput2() {
			this.data.halfPicker.menu = false
		},
		cancel() {
			this.data.dialog = false
			this.data.content = ''
			this.data.picker.date = []
			this.data.halfType = 0
			this.data.halfPicker.date = null
		},
	},
}
</script>
<style lang="scss"></style>
