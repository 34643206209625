import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 에러 화면
import notFound from '../views/errorpage/NotFound.vue'
import notAuth from '../views/errorpage/NotAuthenticated.vue'

// 로그인 화면
import login from '../views/account/login.vue'

// 메인 화면
import main from '../views/main.vue'
import admin_layout from '../views/layout/admin-layout.vue'
const dashboard = () => import(/* webpackChunkName: "main" */ '../views/admin/dashboard.vue')
const codeReview = () => import(/* webpackChunkName: "main" */ '../views/admin/codeReview.vue')
const coWorket = () => import(/* webpackChunkName: "admin" */ '../views/admin/co-worker.vue')
const TeamBuilding = () => import(/* webpackChunkName: "admin" */ '../views/admin/TeamBuilding.vue')
const project = () => import(/* webpackChunkName: "main" */ '../views/admin/project.vue')
const Vacation = () => import(/* webpackChunkName: "main" */ '../views/admin/Vacation.vue')
const MarketingSite = () => import(/* webpackChunkName: "admin" */ '../views/admin/MarketingSite.vue')
const Statistics = () => import(/* webpackChunkName: "admin" */ '../views/admin/Statistics.vue')
const System = () => import(/* webpackChunkName: "admin" */ '../views/admin/System.vue')
const WorkList = () => import(/* webpackChunkName: "admin" */ '../views/admin/WorkList.vue')
// memos
import Memo from '../views/memo/layout/Memo.vue'
const Board = () => import(/* webpackChunkName: "main" */ '../views/memo/common/Board.vue')
const Velog = () => import(/* webpackChunkName: "main" */ '../views/memo/common/Velog.vue')
const CardReview = () => import(/* webpackChunkName: "main" */ '@/components/memo/CardReview.vue')

// QR 인증
import myQR from '../views/QR/myQR.vue'
import QRenter from '../views/QR/QRenter.vue'

const routes = [
	{ path: '*', name: 'notfound', component: notFound },
	{ path: '/block', name: 'block', component: notAuth },
	{
		path: '/',
		name: 'main',
		component: main,
	},
	{
		path: '/login',
		name: 'login',
		component: login,
	},
	{
		path: '/company',
		name: 'Company',
		component: admin_layout,
		meta: { requiresAuth: true },
		children: [
			{
				path: 'dashboard',
				component: dashboard,
				name: 'DashBoard',
			},
			{
				path: 'workList',
				component: WorkList,
				name: 'WorkList',
			},
			{
				path: 'codeReview',
				component: codeReview,
				name: 'CodeReview',
			},
			{
				path: 'Project',
				component: project,
				name: 'Project',
			},
			{
				path: 'Vacation',
				component: Vacation,
				name: 'Vacation',
			},
			{
				path: 'Operating/co-Worker',
				component: coWorket,
				name: 'Operating',
				meta: { admin: true },
			},
			{
				path: 'Operating/TeamBuilding',
				component: TeamBuilding,
				name: 'TeamBuilding',
				meta: { admin: true },
			},
			{
				path: 'Operating/Statistics',
				component: Statistics,
				name: 'Statistics',
				meta: { admin: true },
			},
			{
				path: 'Operating/MarketingSite',
				component: MarketingSite,
				name: 'MarketingSite',
				meta: { admin: true },
			},
			{
				path: 'Operating/System',
				component: System,
				name: 'System',
				meta: { admin: true },
			},
		],
	},
	{
		path: '/QR/:id',
		name: 'myQR',
		component: myQR,
	},
	{
		path: '/QRenter',
		name: 'QRenter',
		component: QRenter,
	},
	{ path: '/memo', components: { default: Memo }, name: 'memo' },
	{
		path: '/board/:boardid',
		name: 'board',
		components: { default: Board },
	},
	{
		path: '/velog',
		component: Velog,
		name: 'velog',
	},
	{
		path: '/velog/:velog',
		components: { default: CardReview },
		name: 'cardReview',
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
