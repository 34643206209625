<template>
	<div class="list" :data-list-title="list.title" :data-list-pos="list.pos">
		<div class="list-header">
			<input
				type="text"
				v-if="isEditTitle"
				class="form-control input-title"
				ref="inputTitle"
				v-model="inputTitle"
				@blur="onCancle"
				@keyup.esc="onCancle"
				@keyup.enter.prevent.stop="onSubmitTitle"
			/>

			<div class="list-header-title" v-else @click="onClickTitle">{{ list.title }}</div>

			<a href="" class="delete-list-btn" @click.prevent="onDeleteList">&times;</a>
		</div>
		<div class="card-list" :data-list-title="list.title">
			<CardItem v-for="(card, i) in carditems" :key="i" :card="card" @deleteCard="deleteCard" @updataCard="updataCard" />
		</div>
		<div v-if="isAddCard">
			<QuillEditor @onclose="isAddCard = false" :listTitle="list.title" @addCard="addCard" />
		</div>
		<div v-else>
			<a href="" class="add-card-btn" @click.prevent="isAddCard = true">
				&plus;Add a card
			</a>
		</div>
		<div class="list-share-bolck">
			<div class="list-share-text">공유</div>
			<v-switch class="list-share-btn" v-model="switch1" input-value @mousedown="insertBlog"></v-switch>
		</div>
	</div>
</template>
<script>
import CardItem from './CardItem.vue'
import QuillEditor from './QuillEditor.vue'
export default {
	name: 'list',
	components: {
		CardItem,
		QuillEditor,
	},
	props: {
		list: {
			type: Object,
		},
	}, // board 에서 받은 list 정보
	data() {
		return {
			isAddCard: false, // 카드 추가 boolean
			isEditTitle: false, // list 타이틀 수정 boolean
			inputTitle: '', // list 타이틀
			originalTitle: '', // 기존의 list 타이틀 (수정해도 변하지 않는)
			updata: '',
			upTitle: '',
			switch1: false,
			cards: '',
			loading: false,
			carditems: [],
		}
	},

	created() {
		this.inputTitle = this.list.title
		this.originalTitle = this.list.title
		this.switch1 = this.list.blog
		this.fetchData()
	},
	methods: {
		fetchData() {
			this.carditems = this.list.carditem
		},
		// 리스트 타이틀 클릭스 인풋창 변경과 포커스
		onClickTitle() {
			this.isEditTitle = true
			this.$nextTick(() => this.$refs.inputTitle.focus())
		},
		// 수정 취소 (blur, esc)
		onCancle() {
			this.isEditTitle = false
		},
		// 리스트 타이틀 수정, Board에 리스트 수정에밋 editLIST
		async onSubmitTitle() {
			this.$store.state.loading = true
			this.isEditTitle = false
			this.inputTitle = this.inputTitle.trim()
			if (!this.inputTitle) return

			await this.$store.dispatch('DETAILBOARD', this.$store.state.board.id).then(res => {
				var data = res.memos[0].cards

				for (var i = 0; i < data.length; i++) {
					if (data[i].title == this.originalTitle) {
						data[i].title = this.inputTitle
					}
				}
				this.upTitle = data
			})
			const input = {
				cards: this.upTitle,
				id: this.$store.state.board.id,
			}

			this.$store.dispatch('UPDATEBOARD', input).then(() => {
				this.$store.state.loading = false
				this.$emit('editLIST')
			})
		},
		// 리스트 삭제, Board에 리스트 삭제에밋 deleteList
		async onDeleteList() {
			this.$store.state.loading = true
			if (!window.confirm(`Delete ${this.list.title} list??`)) {
				this.$store.state.loading = false
				return
			}

			await this.$store.dispatch('DETAILBOARD', this.$store.state.board.id).then(res => {
				var data = res.memos[0].cards

				for (var i = 0; i < data.length; i++) {
					if (data[i].title == this.inputTitle) {
						data[i].islist = false
					}
				}
				this.updata = data
			})
			const input = {
				id: this.$store.state.board.id,
				cards: this.updata,
			}

			this.$store.dispatch('UPDATEBOARD', input).then(() => {
				this.$emit('deleteList')
				this.$store.state.loading = false
			})
		},
		// 카드 추가, board에 카드추가 에밋 addCard
		async addCard(emitData) {
			this.$store.state.loading = true

			await this.$store.dispatch('DETAILBOARD', this.$store.state.board.id).then(res => {
				var data = res.memos[0].cards
				for (var i = 0; i < data.length; i++) {
					if (data[i].title == this.inputTitle) {
						if (data[i].carditem) {
							data[i].carditem.push({
								title: emitData.title,
								content: emitData.content,
								pos: this.newCardPos(),
								position: emitData.myData.position,
								name: emitData.myData.Name,
								update_at: emitData.update_at,
								iscard: true,
							})
						} else {
							data[i].carditem = [
								{
									title: emitData.title,
									content: emitData.content,
									pos: this.newCardPos(),
									update_at: emitData.update_at,
									position: emitData.myData.position,
									name: emitData.myData.Name,
									iscard: true,
								},
							]
						}
					}
				}

				const input = {
					id: this.$store.state.board.id,
					cards: data,
				}
				this.$store
					.dispatch('UPDATEBOARD', input)
					.then(() => {
						this.fetchData()
					})
					.catch(err => {
						console.log(err)
					})
					.finally(() => {
						this.isAddCard = false
						this.inputTitle = ''
						this.$store.state.loading = false
						this.$emit('addCard')
					})
			})
		},
		// 카드의 pos(포지션)값
		newCardPos() {
			const curList = this.$store.state.board.cards.filter(l => l.title == this.list.title)[0].carditem
			if (!curList) return 65535
			const cards = curList
			if (!cards.length) return 65535
			return cards[cards.length - 1].pos * 2
		},
		// 블로그 적용
		async insertBlog() {
			if (!this.switch1) {
				if (window.confirm(`블로그에 ${this.list.title} 공유하시겠습니까??`)) {
					await this.$store.dispatch('DETAILBOARD', this.$store.state.board.id).then(res => {
						for (var i = 0; i < res.memos[0].cards.length; i++) {
							if (res.memos[0].cards[i].title == this.list.title) {
								res.memos[0].cards[i].blog = true
								this.cards = res.memos[0].cards
								break
							}
						}
					})
					this.switch1 = true
				} else {
					this.switch1 = false
				}
			} else {
				if (window.confirm(`블로그에 ${this.list.title} 공유 취소하시겠습니까??`)) {
					await this.$store.dispatch('DETAILBOARD', this.$store.state.board.id).then(res => {
						for (var i = 0; i < res.memos[0].cards.length; i++) {
							if (res.memos[0].cards[i].title == this.list.title) {
								res.memos[0].cards[i].blog = false
								this.cards = res.memos[0].cards
								break
							}
						}
					})
					this.switch1 = false
				} else {
					this.switch1 = true
				}
			}
			const data = {
				id: this.$store.state.board.id,
				cards: this.cards,
			}
			this.$store.dispatch('UPDATEBOARD', data).then(() => {})
		},
		deleteCard() {
			this.fetchData()
			this.$emit('deleteCard')
		},
		updataCard() {
			this.fetchData()
			this.$emit('updataCard')
		},
	},
}
</script>
<style>
.list {
	background-color: #e2e4e6;
	border-radius: 3px;
	margin-right: 10px;
	display: flex;
	flex-direction: column;
	vertical-align: top;
	width: 100%;
	max-height: 100%;
}
.list-header {
	flex: 0 0 auto;
	height: 45px;
	padding: 10px 8px 8px;
	position: relative;
}
.list-header-title {
	font-size: 16px;
	font-weight: 700;
	padding-left: 8px;
	line-height: 30px;
}
.input-title {
	width: 90%;
	color: black !important;
}
.delete-list-btn {
	position: absolute;
	right: 10px;
	top: 8px;
	text-decoration: none;
	color: #aaa;
	font-size: 24px;
}
.card-list {
	flex: 1 1 auto;
	overflow-y: scroll;
	min-height: 10px;
	max-height: 700px;
}
.empty-card-item {
	height: 10px;
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
}
.add-card-btn {
	flex: 0 0 auto;
	display: block;
	padding: 8px 10px;
	color: #8c8c8c;
	text-decoration: none;
}
.add-card-btn:focus,
.add-card-btn:hover {
	background-color: rgba(0, 0, 0, 0.1);
}
.list-share-btn {
	width: 50%;
}
.v-input--switch {
	margin-top: 0px;
}
.v-messages {
	min-height: 0px !important;
}
.list-share-bolck {
	display: flex;
	padding-left: 180px;
}
.list-share-text {
	font-size: 16px;
	font-weight: bold;
	align-self: center;
	padding-right: 10px;
	padding-bottom: 5px;
}
.loading-bar {
	position: absolute;
	top: 45%;
	right: 50%;
}
</style>
