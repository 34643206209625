<template>
	<v-dialog v-model="data.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog" dark>
		<v-row justify="center" align="center" style="height:100vh;" class="ma-0">
			<div style="margin: 0 auto;">
				<v-row style="width:100%; max-width:1800px; height:auto;" class="px-2 ma-0 mb-4" justify="center">
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 16px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<v-btn
									@click="$emit('deleteProject', $event)"
									color="error"
									:style="
										$store.state.allowLevel.includes(Number($store.state.myData.level))
											? 'display: inline-block !important;'
											: 'display: none !important;'
									"
									v-if="$store.state.allowLevel.includes(Number($store.state.myData.level))"
								>
									delete
								</v-btn>
								<v-btn
									@click="$emit('editProject', $event)"
									color="PointColor2"
									class="ml-2"
									:style="
										$store.state.allowLevel.includes(Number($store.state.myData.level))
											? 'display: inline-block !important;'
											: 'display: none !important;'
									"
									v-if="$store.state.allowLevel.includes(Number($store.state.myData.level))"
								>
									edit
								</v-btn>
								<v-spacer></v-spacer>
								<button_1_1 @click="data.dialog = false" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="6" lg="6" xs="12" sm="12" md="6">
						<v-card
							:min-width="$vuetify.breakpoint.smAndDown ? 300 : 400"
							:class="$vuetify.breakpoint.smAndDown ? 'cursor mb-4' : 'cursor mr-4'"
							color="PointColor2"
							dark
							height="240"
						>
							<v-card-title>
								<h3 class="text-overflow">
									<span class="text-h6 font-weight-light"> {{ data.project.name }}</span>
								</h3>
							</v-card-title>

							<v-card-text class="text-h5 font-weight-bold project-content">
								{{ data.project.content }}
							</v-card-text>

							<v-card-actions>
								<v-list-item class="grow">
									<v-list-item-avatar color="grey darken-3">
										<v-img
											class="elevation-6"
											alt=""
											v-if="
												data.project.team &&
													data.project.pmName !== null &&
													data.project.team.users_permissions_users.filter(x => x.Name === data.project.pmName)[0].face
											"
											:src="server + data.project.team.users_permissions_users.filter(x => x.Name === data.project.pmName)[0].face.url"
										></v-img>
										<v-img
											class="elevation-6"
											alt=""
											v-else
											src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
										></v-img>
									</v-list-item-avatar>

									<v-list-item-content>
										<v-list-item-title class="PointColor3--text"> pm: {{ data.project.pmName }}</v-list-item-title>
										<v-list-item-title>{{ data.project.start }} ~ {{ data.project.end }}</v-list-item-title>
									</v-list-item-content>
									<v-flex xs1>
										<v-row align="center" justify="end">
											<v-icon class="mr-1">
												mdi-account-group
											</v-icon>
											<span class="subheading">{{ data.project.team ? data.project.team.users_permissions_users.length : 0 }}</span>
										</v-row>
									</v-flex>
								</v-list-item>
							</v-card-actions>
						</v-card>
					</v-col>
					<v-col class="pa-0" cols="12" xl="6" lg="6" xs="12" sm="12" md="6">
						<v-card :min-width="$vuetify.breakpoint.smAndDown ? 300 : 400" dark height="240" style="overflow-y:auto; overflow-x:hidden">
							<v-card-title class="text-center justify-center py-6">
								<h1 class="white--text" v-if="data.team.title">
									{{ data.team.title }}
								</h1>
								<h1 class="white--text" v-else>
									No-Team
								</h1>
							</v-card-title>

							<v-tabs
								v-model="data.team.tab"
								dark
								grow
								:style="
									$store.state.allowLevel.includes(Number($store.state.myData.level))
										? 'display: inline-block !important;'
										: 'display: none !important;'
								"
								v-if="$store.state.allowLevel.includes(Number($store.state.myData.level))"
								v-show="data.project.pmName === $store.state.myData.Name"
							>
								<v-tab v-for="tab in data.team.tabs" :key="tab">
									{{ tab }}
								</v-tab>
							</v-tabs>

							<v-tabs-items v-model="data.team.tab" dark class="tab-layout">
								<v-tab-item>
									<v-card dark v-for="element in data.team.list" :key="element.name" class="pa-2 teamList-Card">
										<v-layout align-center class="ma-2">
											<v-avatar size="56" color="white" class="mr-2">
												<img :src="server + element.face.url" alt="John" v-if="element.face" />
												<img src="/image/img-profile-default-s.png" alt="John" v-else />
											</v-avatar>
											{{ element.Name }} ( Lv.{{ element.level }} )
											<v-spacer></v-spacer>
											<v-btn
												fab
												x-small
												@click="outDialog(data.team.id, element)"
												color="PointColor2"
												v-if="$store.state.myData.username !== element.username && data.project.pmName === $store.state.myData.Name"
											>
												out
											</v-btn>
										</v-layout>
									</v-card>
								</v-tab-item>
								<v-tab-item v-if="data.project.pmName === $store.state.myData.Name">
									<v-layout align-center class="ma-2">
										<textField v-model="search" :textField="SearchTextField" />
									</v-layout>
									<datatable
										:datatable="datatableUsers"
										:teamList="data.team.list"
										:teamId="data.team.id"
										:search="search"
										@join="joinDialog"
									>
									</datatable>
								</v-tab-item>
							</v-tabs-items>
						</v-card>
					</v-col>
					<v-col
						class="pa-0"
						cols="12"
						xl="6"
						lg="6"
						xs="12"
						sm="12"
						md="12"
						:style="
							$store.state.allowLevel.includes(Number($store.state.myData.level)) || auth
								? 'display: inline-block !important;'
								: 'display: none !important;'
						"
						v-if="$store.state.allowLevel.includes(Number($store.state.myData.level)) || auth"
					>
						<div :class="$vuetify.breakpoint.smAndDown ? '' : 'mx-2'">
							<v-layout align-center class="my-2">
								<h2 class="white--text">
									My WorkList
								</h2>
							</v-layout>
							<v-layout>
								<textField
									class="mr-2"
									v-model="work"
									:textField="workTextField"
									@input="workTextField['error-messages'] = ''"
									@keydown.enter="updateTodolist"
								/>
								<DatepickerRange :picker="endTimepicker" @input="endDate" :allowed_dates="deadLineDates"></DatepickerRange>
								<btn class="ml-2" :btn="workBtn" btn_txt="save" @click="updateTodolist" />
							</v-layout>
							<v-divider dark class="my-4"></v-divider>
							<v-layout align-center>
								<DatepickerRange :picker="workStartpicker" @input="workDate"></DatepickerRange>
								<span class="mx-3 white--text"> ~ </span>
								<DatepickerRange :picker="workEndpicker" :allowed_dates="workCouselor" @input="workDate"></DatepickerRange>
							</v-layout>
							<v-layout align-center class="my-2" justify-end>
								<btn :btn="workBtn" btn_txt="refresh" @click="todolists(interID)" />
								<v-spacer></v-spacer>
								<textField v-model="workSearch" :textField="SearchTextField" />
							</v-layout>
							<datatable
								:datatable="workDatatable"
								:search="workSearch"
								@workAction="workActionDialog"
								@workActionDelete="workActionDeleteDialog"
								@updateComment="updateComment"
								@deleteComment="deleteCommentDialog"
							>
							</datatable>
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="12"
						xl="6"
						lg="6"
						xs="12"
						sm="12"
						md="12"
						:style="
							$store.state.allowLevel.includes(Number($store.state.myData.level)) || auth
								? 'display: inline-block !important;'
								: 'display: none !important;'
						"
						v-if="$store.state.allowLevel.includes(Number($store.state.myData.level)) || auth"
					>
						<div :class="$vuetify.breakpoint.smAndDown ? '' : 'mx-2'">
							<v-layout align-center class="my-2">
								<h2 class="white--text">
									Work Check List
								</h2>
							</v-layout>
							<v-layout>
								<v-progress-linear
									v-model="checkProgress"
									rounded
									background-opacity="0.2"
									color="PointColor1"
									height="48"
									dark
									:value="checkProgress"
								>
									Work Check &nbsp;- &nbsp;<strong>{{ checkProgress ? Math.ceil(checkProgress) : 0 }}%</strong>
								</v-progress-linear>
							</v-layout>
							<v-divider dark class="my-4"></v-divider>
							<v-layout align-center>
								<DatepickerRange :picker="workCheckStartpicker" @input="workCheckDate"></DatepickerRange>
								<span class="mx-3 white--text"> ~ </span>
								<DatepickerRange :picker="workCheckEndpicker" :allowed_dates="workCouselor" @input="workCheckDate"></DatepickerRange>
							</v-layout>
							<v-layout align-center class="my-2" justify-end>
								<btn :btn="workBtn" btn_txt="refresh" @click="todolists(interID)" />
								<v-spacer></v-spacer>
								<textField v-model="workCheckSearch" :textField="SearchTextField" />
							</v-layout>
							<datatable
								:datatable="workCheckDatatable"
								:search="workCheckSearch"
								@workAction="workActionDialog"
								@workActionDelete="workActionDeleteDialog"
								@updateComment="updateComment"
								@deleteComment="deleteCommentDialog"
								@workCheckAction="workCheckActionDialog"
							>
							</datatable>
						</div>
					</v-col>
				</v-row>
			</div>
		</v-row>
		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" @ok="dialogEvents" />
	</v-dialog>
</template>
<script>
import dialog1 from './dialog-1'
import textField from './textField'
import datatable from './datatable'
import DatepickerRange from './DatepickerRange'
import btn from './button'
import { button_1_1 } from '@/components/create-project/index'
export default {
	name: 'detail-project',
	components: {
		dialog1,
		textField,
		datatable,
		button_1_1,
		DatepickerRange,
		btn,
	},
	props: {
		data: {
			type: Object,
		},
	},
	computed: {
		checkProgress() {
			let val = 0
			val = (this.workCheckDatatable.items.filter(x => x.status === 3).length / this.workCheckDatatable.items.length) * 100
			return val
		},
	},
	data() {
		return {
			workItem: {},
			workItemComment: {},
			todoListId: null,
			actionItem: {},
			setDialog1: {
				dialog: false,
				title: '',
				content: '',
				btnText: '',
				type: '',
			},
			workDatatable: {
				headers: [
					{ text: 'No', value: 'No', sortable: false },
					{ text: 'Title', value: 'title' },
					{ text: 'DeadLine', value: 'deadline' },
					{ text: 'User', value: 'user' },
					{ text: 'Status', value: 'status' },
					{ text: 'Action', value: 'workAction', sortable: false },
				],
				items: [],
				hidedefaultfooter: false,
				hidedefaultheader: false,
				singleExpand: true,
				expanded: [],
				['show-expand']: true,
				key: 'created_at',
				comment: '',
			},
			workCheckDatatable: {
				headers: [
					{ text: 'No', value: 'No', sortable: false },
					{ text: 'Title', value: 'title' },
					{ text: 'DeadLine', value: 'deadline' },
					{ text: 'User', value: 'user' },
					{ text: 'Status', value: 'status' },
					{ text: 'Action', value: 'workCheckAction', sortable: false },
				],
				items: [],
				hidedefaultfooter: false,
				hidedefaultheader: false,
				singleExpand: true,
				expanded: [],
				['show-expand']: true,
				key: 'created_at',
				comment: '',
			},
			server: process.env.VUE_APP_BACKEND_URL,
			search: '',
			workSearch: '',
			SearchTextField: {
				dark: false,
				width: '200',
				placeholder: 'Search',
				['prepend-inner-icon']: 'mdi-magnify',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			textField: {
				width: '100%',
				autofocus: false,
				clearable: true,
				color: '#f7f7f7ff',
				counter: true,
				dark: true,
				disabled: false,
				error: false,
				['error-messages']: '',
				height: 40,
				['hide-details']: false,
				placeholder: 'Team Name',
				solo: true,
				type: 'text',
				maxlength: 50,
			},
			createTeamName: '',
			createDialog: false,
			LevelList: {
				dialog: false,
			},
			teamsList: [],
			datatableUsers: {
				headers: [
					{ text: 'Name', value: 'Name' },
					{ text: 'position', value: 'position' },
					{ text: 'Join', value: 'join' },
				],
				items: [],
				hidedefaultfooter: true,
				hidedefaultheader: true,
			},
			joinUser: {},
			outUser: {},
			endTimepicker: {
				date: null,
				errorMessage: '',
				menu: false,
				backgroundColor: 'PointColor2',
				height: 48,
				placeholder: 'deadline',
				width: 150,
			},
			workStartpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'start',
				height: 48,
			},
			workEndpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'end',
				height: 48,
			},
			workCheckStartpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'start',
				height: 48,
			},
			workCheckEndpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'end',
				height: 48,
			},
			workBtn: {
				dark: true,
				color: 'PointColor1',
				height: 48,
			},
			work: '',
			workTextField: {
				['background-color']: 'PointColor2',
				dark: true,
				autofocus: false,
				width: '100%',
				['error-messages']: '',
				placeholder: 'Work Title',
				['prepend-inner-icon']: 'mdi-pencil',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			interID: null,
			workCheckSearch: '',
			auth: false,
		}
	},
	watch: {
		data: {
			deep: true,
			handler() {
				if (this.data.team.list) {
					this.auth = this.data.team.list.map(x => x.email).indexOf(this.$store.state.myData.email) > -1
					if (this.auth) {
						this.interID = this.data.project.interID
						this.todolists()
					}
				} else {
					this.auth = false
					this.workDatatable.items = []
					this.workCheckDatatable.items = []
					this.workStartpicker.date = this.$moment(new Date())
						.subtract(14, 'days')
						.format('YYYY-MM-DD')
					this.workEndpicker.date = this.$moment(new Date())
						.add(14, 'days')
						.format('YYYY-MM-DD')
					this.workCheckStartpicker.date = this.$moment(new Date())
						.subtract(14, 'days')
						.format('YYYY-MM-DD')
					this.workCheckEndpicker.date = this.$moment(new Date())
						.add(14, 'days')
						.format('YYYY-MM-DD')
				}
			},
		},
	},
	methods: {
		teams() {
			this.$store
				.dispatch('teams')
				.then(res => {
					this.setDialog1.dialog = false
					this.createDialog = false
					this.teamsList = []
					res.teams.forEach((element, index) => {
						this.teamsList.push({
							id: element.id,
							index: index,
							tab: 0,
							tabs: ['Team List', 'join team'],
							title: element.title,
							list: element.users_permissions_users,
							teamPosition: element.teamPosition,
						})
					})
					if (this.data.team.list) {
						this.data.team.list = this.teamsList[this.teamsList.map(x => x.id).indexOf(this.teamId)].list
					}
				})
				.catch(err => {
					console.log({ err })
				})
		},
		workActionDelete(item) {
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].list
					lists.splice(res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at), 1)
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		workAction(item) {
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].list
					if (lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status === 1) {
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status = 2
					} else if (lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status === 2) {
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status = 3
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].checker.push({
							name: this.$store.state.myData.Name,
							email: this.$store.state.myData.email,
						})
					} else if (lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status === 3) {
						lists[res.todolists[0].list.map(x => x.created_at).indexOf(item.created_at)].status = 1
					}
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		workCheckActionDialog(val, item) {
			val
			this.workItem = item
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Check the Work'
			this.setDialog1.type = 'Check-the-Work'
			this.setDialog1.content = 'Are you really sure to check this work? - "' + item.title + '"'
			this.setDialog1.btnText = 'check'
		},
		deadLineDates(val) {
			let start = this.$moment().format('YYMMDD')

			let startDate = new Date(this.workStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 60))
			let endDate = this.$moment(end).format('YYMMDD')

			val = this.$moment(val).format('YYMMDD') <= endDate && this.$moment(val).format('YYMMDD') >= start
			return val
		},
		workCheckDate() {
			this.workCheckStartpicker.menu = false
			this.workCheckEndpicker.menu = false
			this.workCheckDatatable.items = this.workList.filter(
				x =>
					new Date(x.deadline) >= new Date(this.workCheckStartpicker.date) &&
					new Date(x.deadline) <= new Date(this.workCheckEndpicker.date) &&
					x.status >= 2 &&
					x.email !== this.$store.state.myData.email,
			)
		},
		dialogEvents() {
			this.$store.state.loading = true
			if (this.setDialog1.type === 'workActionDelete') {
				this.workActionDelete(this.deleteItem)
			} else if (this.setDialog1.type === 'report' || this.setDialog1.type === 'return') {
				this.workAction(this.actionItem)
			} else if (this.setDialog1.type === 'no-show-comment') {
				this.deleteComment(this.workItem, this.workItemComment)
			} else if (this.setDialog1.type === 'Check-the-Work') {
				this.workAction(this.workItem)
			} else if (this.setDialog1.type === 'delete') {
				this.deleteTeam()
			} else if (this.setDialog1.type === 'join') {
				const users = this.teamsList[this.teamsList.map(x => x.id).indexOf(this.teamId)].list.map(y => y.id)
				const teamPosition = this.teamsList[this.teamsList.map(x => x.id).indexOf(this.teamId)].teamPosition
				teamPosition.push({
					level: this.joinUser.level,
					status: 'join',
					name: this.joinUser.Name,
					username: this.joinUser.username,
					joinDate: new Date(),
				})
				const data = {
					id: this.teamId,
					users: [...users, this.joinUser.id],
					teamPosition: teamPosition,
				}
				this.$store
					.dispatch('updateTeam', data)
					.then(() => {
						this.teams()
					})
					.catch(err => {
						console.log({ err })
					})
			} else if (this.setDialog1.type === 'out') {
				const users = this.teamsList[this.teamsList.map(x => x.id).indexOf(this.teamId)].list.map(y => y.id)
				const teamPosition = this.teamsList[this.teamsList.map(x => x.id).indexOf(this.teamId)].teamPosition
				users.splice(users.indexOf(this.outUser.id), 1)
				teamPosition.splice(teamPosition.map(x => x.username).indexOf(this.outUser.username), 1)
				const data = {
					id: this.teamId,
					users: users,
					teamPosition: teamPosition,
				}
				this.$store
					.dispatch('updateTeam', data)
					.then(() => {
						this.teams()
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		deleteCommentDialog(val, item, chat) {
			val
			this.workItem = item
			this.workItemComment = chat
			this.setDialog1.dialog = true
			this.setDialog1.title = 'No-show comment'
			this.setDialog1.type = 'no-show-comment'
			this.setDialog1.content = 'Are you really sure to no-show this comment? - "' + chat.comment + '"'
			this.setDialog1.btnText = 'no-show'
		},
		deleteComment(item, chat) {
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					const lists = res.todolists[0].list
					const listIndex = lists.map(x => x.created_at).indexOf(item.created_at)
					const commentIndex = lists[listIndex].comments.map(y => y.created_at).indexOf(chat.created_at)
					lists[listIndex].comments[commentIndex].show = !lists[listIndex].comments[commentIndex].show
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		updateComment(val, item) {
			if (this.workDatatable.comment !== '') {
				val
				this.$store.state.loading = true
				const data = { interID: this.interID }
				this.$store
					.dispatch('todolists', data)
					.then(res => {
						const lists = res.todolists[0].list
						const listIndex = lists.map(x => x.created_at).indexOf(item.created_at)
						lists[listIndex].comments.push({
							created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
							comment: this.workDatatable.comment,
							user: this.$store.state.myData.Name,
							email: this.$store.state.myData.email,
							show: true,
						})
						const data = {
							id: this.todoListId,
							list: lists,
						}
						this.$store
							.dispatch('updateTodolist', data)
							.then(() => {
								this.$store.state.loading = false
								this.workDatatable.comment = ''
								this.todolists()
							})
							.catch(err => {
								this.$store.state.loading = false
								console.log({ err })
							})
					})
					.catch(err => {
						this.$store.state.loading = false
						console.log({ err })
					})
			} else if (this.workCheckDatatable.comment !== '') {
				val
				this.$store.state.loading = true
				const data = { interID: this.interID }
				this.$store
					.dispatch('todolists', data)
					.then(res => {
						const lists = res.todolists[0].list
						const listIndex = lists.map(x => x.created_at).indexOf(item.created_at)
						lists[listIndex].comments.push({
							created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
							comment: this.workCheckDatatable.comment,
							user: this.$store.state.myData.Name,
							email: this.$store.state.myData.email,
							show: true,
						})
						const data = {
							id: this.todoListId,
							list: lists,
						}
						this.$store
							.dispatch('updateTodolist', data)
							.then(() => {
								this.$store.state.loading = false
								this.workCheckDatatable.comment = ''
								this.todolists()
							})
							.catch(err => {
								this.$store.state.loading = false
								console.log({ err })
							})
					})
					.catch(err => {
						this.$store.state.loading = false
						console.log({ err })
					})
			}
		},
		updateTodolist() {
			if (this.work === '') {
				return (this.workTextField['error-messages'] = 'check')
			}
			if (!this.endTimepicker.date) {
				return (this.endTimepicker.errorMessage = 'check')
			}
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					let lists
					if (res.todolists.length > 0) {
						lists = res.todolists[0].list
					} else {
						lists = []
					}
					lists.push({
						created_at: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
						title: this.work,
						deadline: this.endTimepicker.date,
						status: 1,
						user: this.$store.state.myData.Name,
						email: this.$store.state.myData.email,
						checker: [],
						comments: [],
					})
					const data = {
						id: this.todoListId,
						list: lists,
					}
					this.$store
						.dispatch('updateTodolist', data)
						.then(() => {
							this.$store.state.loading = false
							this.work = ''
							this.endTimepicker.date = null
							this.todolists()
						})
						.catch(err => {
							this.$store.state.loading = false
							console.log({ err })
						})
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		workCouselor(val) {
			let start = this.$moment(this.workStartpicker.date).format('YYMMDD')

			let startDate = new Date(this.workStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 60))
			let endDate = this.$moment(end).format('YYMMDD')

			val = this.$moment(val).format('YYMMDD') < endDate && this.$moment(val).format('YYMMDD') > start
			return val
		},
		workActionDialog(val, item) {
			val
			this.actionItem = item
			this.setDialog1.dialog = true
			if (item.status === 1) {
				this.setDialog1.title = 'report to Leader'
				this.setDialog1.type = 'report'
				this.setDialog1.content = 'Are you really sure to report to ' + item.title + '?'
				this.setDialog1.btnText = 'report'
			} else if (item.status === 3) {
				this.setDialog1.title = 'get back to working'
				this.setDialog1.type = 'return'
				this.setDialog1.content = 'Are you really sure to get back ' + item.title + '?'
				this.setDialog1.btnText = 'get back'
			}
		},
		workActionDeleteDialog(val, item) {
			val
			this.deleteItem = item
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Work List Delete'
			this.setDialog1.type = 'workActionDelete'
			this.setDialog1.content = 'Are you really sure to delete ' + item.title + '?'
			this.setDialog1.btnText = 'delete'
		},
		todolists() {
			if (!this.interID) {
				return alert('error - no inter ID')
			}
			this.$store.state.loading = true
			const data = { interID: this.interID }
			this.$store
				.dispatch('todolists', data)
				.then(res => {
					this.setDialog1.dialog = false
					this.$store.state.loading = false
					if (res.todolists.length > 0) {
						this.todoListId = res.todolists[0].id
						this.workList = JSON.parse(JSON.stringify(res.todolists[0].list))
						this.workDatatable.items = res.todolists[0].list
							.filter(
								x =>
									new Date(x.deadline) >= new Date(this.workStartpicker.date) && new Date(x.deadline) <= new Date(this.workEndpicker.date),
							)
							.reverse()
						this.workCheckDatatable.items = res.todolists[0].list
							.filter(
								x =>
									new Date(x.deadline) >= new Date(this.workStartpicker.date) &&
									new Date(x.deadline) <= new Date(this.workEndpicker.date) &&
									x.status >= 2 &&
									x.email !== this.$store.state.myData.email,
							)
							.reverse()
					} else {
						this.workDatatable.items = []
						this.workCheckDatatable.items = []
					}
					this.$store.state.loading = false
				})
				.catch(err => {
					this.$store.state.loading = false
					console.log({ err })
				})
		},
		endDate() {
			this.endTimepicker.errorMessage = ''
			this.endTimepicker.menu = false
		},
		workDate() {
			this.workStartpicker.menu = false
			this.workEndpicker.menu = false
			this.workDatatable.items = this.workList.filter(
				x => new Date(x.deadline) >= new Date(this.workStartpicker.date) && new Date(x.deadline) <= new Date(this.workEndpicker.date),
			)
		},
		joinDialog(event, item, teamId) {
			event
			this.joinUser = item
			this.setDialog1.type = 'join'
			this.teamId = teamId
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Join my Team'
			this.setDialog1.content = 'Are you really sure to thinking of recruiting ' + item.Name + ' to the team?'
			this.setDialog1.btnText = 'Ask Join'
		},
		outDialog(teamId, item) {
			this.setDialog1.type = 'out'
			this.teamId = teamId
			this.outUser = item
			this.setDialog1.dialog = true
			this.setDialog1.title = 'Out my Team'
			this.setDialog1.content = 'Are you really sure to Out ' + item.Name + ' to the team?'
			this.setDialog1.btnText = 'Out'
		},
		allUsers() {
			const data = {}
			this.$store
				.dispatch('allUsers', data)
				.then(res => {
					this.datatableUsers.items = res.users
				})
				.catch(err => {
					console.log({ err })
				})
		},
	},
	created() {
		this.workStartpicker.date = this.$moment(new Date())
			.subtract(14, 'days')
			.format('YYYY-MM-DD')
		this.workEndpicker.date = this.$moment(new Date())
			.add(14, 'days')
			.format('YYYY-MM-DD')
		this.workCheckStartpicker.date = this.$moment(new Date())
			.subtract(14, 'days')
			.format('YYYY-MM-DD')
		this.workCheckEndpicker.date = this.$moment(new Date())
			.add(14, 'days')
			.format('YYYY-MM-DD')
		this.allUsers()
		this.teams()
		console.log(this.data)
	},
}
</script>
<style lang="scss" scoped></style>
