<template>
	<v-dialog v-model="setDialog.dialog" persistent max-width="400">
		<v-card>
			<v-card-title class="text-h5">
				{{ setDialog.title }}
			</v-card-title>
			<v-card-text>{{ setDialog.content }}</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn v-if="setDialog.type !== 'oneBtnType'" color="PointColor2" text @click="setDialog.dialog = false">
					cancel
				</v-btn>
				<v-btn :color="setDialog.btnText === 'delete' ? 'error' : 'PointColor2'" @click="$emit('ok', $event)" dark>
					{{ setDialog.btnText ? setDialog.btnText : 'ok' }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	props: {
		setDialog: {
			type: Object,
		},
	},
}
</script>
