import { render, staticRenderFns } from "./selectBox_1_2.vue?vue&type=template&id=393a63e2&scoped=true&"
import script from "./selectBox_1_2.vue?vue&type=script&lang=js&"
export * from "./selectBox_1_2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "393a63e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
