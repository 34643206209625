<template>
	<div class="board-body">
		<div class="board-wrapper">
			<div class="board">
				<div class="board-header">
					<h2 class="form-control" type="text">{{ $store.state.myData.Name }}</h2>
					<v-btn color="dark" class="board-header-btn show-menu" @click.prevent="onShowSettings">Show Menu</v-btn>
				</div>
				<div class="list-section-wrapper">
					<div class="list-section">
						<div class="list-wrapper" v-for="(list, idx) in carditems" :key="idx" :data-list-title="list.title">
							<List :list="list" @addCard="reload" @deleteCard="reload" @editLIST="reload" @deleteList="reload" @updataCard="reload" />
						</div>
						<div class="list-wrapper">
							<AddList @addList="reload" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<BoardSettings v-if="isShowBoardSettings" />
		<SearchNav @updataCard="reload" />
	</div>
</template>

<script>
import List from '@/components/memo/List'
import AddList from './AddList'
import SearchNav from './SearchNav'
import dragger from '@/plugins/dragger'
import { mapMutations, mapState } from 'vuex'
import BoardSettings from './BoardSetting.vue'

export default {
	components: {
		List,
		BoardSettings,
		AddList,
		SearchNav,
	},
	props: {
		id: {
			type: String,
		},
	},
	data() {
		return {
			cDragger: null, // 카드 드래그 앤 드랍
			lDragger: null, // 리스트 드래그 앤 드랍
			isEditTitle: false, // 보드 타이틀 수정시 입풋창 boolean
			cards: [],
			carditems: [],
			loading: false,
		}
	},
	created() {
		this.fetchData()
	},
	computed: {
		...mapState({
			isShowBoardSettings: 'isShowBoardSettings',
		}),
	},
	mounted() {},
	updated() {
		this.setCardDra()
		this.setListDra()
	},
	watch: {},
	methods: {
		// 배경 색 설정, show 메뉴 보이기 설정
		...mapMutations(['SET_THEME', 'SET_IS_SHOW_BOARD_SETTINGS']),
		// 보드 정보 불러오기
		async fetchData() {
			this.$store.state.loading = true
			this.carditems = []
			await this.$store
				.dispatch('USER_BOARD')
				.then(res => {
					if (res == undefined) {
						const input = {
							users_permissions_user: this.$store.state.myData.id,
						}
						this.$store
							.dispatch('ADDBOARD', input)
							.then(() => {
								this.fetchData()
							})
							.catch(err => console.log(err))
					} else {
						if (res.cards) {
							this.cards = []
							for (var i = 0; i < res.cards.length; i++) {
								const data = []
								if (res.cards[i].islist == true) {
									this.cards[i] = res.cards[i]
									if (res.cards[i].carditem) {
										for (var j = 0; j < res.cards[i].carditem.length; j++) {
											if (res.cards[i].carditem[j].iscard == true) {
												data.push(res.cards[i].carditem[j])
											}
										}
										this.cards[i].carditem = data
									}
								}
							}
						}
					}
					this.SET_THEME(res.bgColor)
					this.updateTheme()
				})
				.catch(() => {})
				.finally(() => {
					for (var k = 0; k < this.cards.length; k++) {
						if (this.cards[k] != undefined) {
							this.carditems.push(this.cards[k])
						}
					}
					this.$store.state.loading = false
				})
		},
		// 보드 새로고침
		reload() {
			this.fetchData()
		},
		// 카드 드래그 앤 드랍
		async setCardDra() {
			if (this.cDragger) this.cDragger.destroy()
			const options = {
				invalid: (el, handle) => {
					el, handle
					return this.$store.state.isquill == true
				},
			}

			this.cDragger = dragger.init(Array.from(this.$el.querySelectorAll('.card-list')), options)
			await this.cDragger.on('drop', (el, wrapper, target, silblings) => {
				target, silblings
				const targetCard = {
					title: el.dataset.cardTitle,
					pos: 65535,
					listTitle: '',
				}

				const movelist = Array.from(this.$el.querySelectorAll('.list'))

				for (var i = 0; i < movelist.length; i++) {
					for (var j = 0; j < movelist[i].children[1].children.length; j++) {
						if (movelist[i].children[1].children[j].dataset.cardTitle == targetCard.title) {
							targetCard.listTitle = movelist[i].dataset.listTitle
						}
					}
				}

				const { prev, next } = dragger.silbling({
					el,
					wrapper,
					candidates: Array.from(wrapper.querySelectorAll('.card-item')),
					type: 'card',
				})
				if (!prev && next) targetCard.pos = next.pos / 2
				else if (!next && prev) targetCard.pos = prev.pos * 2
				else if (prev && next) targetCard.pos = (prev.pos + next.pos) / 2

				this.$store.dispatch('DETAILBOARD', this.$store.state.board.id).then(res => {
					var data = res.memos[0].cards
					for (var i = 0; i < data.length; i++) {
						if (data[i].carditem) {
							for (var j = 0; j < data[i].carditem.length; j++) {
								if (targetCard.title == data[i].carditem[j].title) {
									var del_data = data[i].carditem.splice(j, 1)
								}
							}
						}
					}

					for (var k = 0; k < data.length; k++) {
						if (targetCard.listTitle == data[k].title) {
							if (data[k].carditem) {
								data[k].carditem.push({
									content: del_data[0].content,
									title: targetCard.title,
									pos: targetCard.pos,
									position: del_data[0].position,
									name: del_data[0].name,
									update_at: del_data[0].update_at,
									iscard: true,
								})
							} else {
								var setData = data[k]
								setData['carditem'] = [
									{
										content: del_data[0].content,
										title: targetCard.title,
										pos: targetCard.pos,
										position: del_data[0].position,
										name: del_data[0].name,
										update_at: del_data[0].update_at,
										iscard: true,
									},
								]
							}
							break
						}
					}

					let input = {
						cards: data,
						id: this.$store.state.board.id,
					}

					this.$store
						.dispatch('UPDATEBOARD', input)
						.then(() => {})
						.catch(err => {
							console.log(err)
						})
				})
			})
		},
		// show menu 보이기
		onShowSettings() {
			this.SET_IS_SHOW_BOARD_SETTINGS(true)
		},
		// 리스트 드래그 앤 드랍
		async setListDra() {
			if (this.lDragger) this.lDragger.destroy()

			const options = {
				invalid: (el, handle) => !/^list/.test(handle.className),
			}

			this.lDragger = dragger.init(Array.from(this.$el.querySelectorAll('.list-section')), options)
			await this.lDragger.on('drop', (el, wrapper, target, silblings) => {
				target, silblings
				const targetList = {
					title: el.dataset.listTitle,
					pos: 65535,
				}

				const { prev, next } = dragger.silbling({
					el,
					wrapper,
					candidates: Array.from(wrapper.querySelectorAll('.list')),
					type: 'list',
				})
				if (!prev && next) targetList.pos = next.pos / 2
				else if (!next && prev) targetList.pos = prev.pos * 2
				else if (prev && next) targetList.pos = (prev.pos + next.pos) / 2

				var targetData = {
					title: targetList.title,
					pos: targetList.pos,
				}

				this.$store.dispatch('DETAILBOARD', this.$store.state.board.id).then(res => {
					var data = res.memos[0].cards

					for (var i = 0; i < data.length; i++) {
						if (targetData.title == data[i].title) {
							data[i].pos = targetData.pos
							break
						}
					}

					const input = {
						cards: data,
						id: this.$store.state.board.id,
					}
					this.$store
						.dispatch('UPDATEBOARD', input)
						.then(() => {})
						.catch(err => {
							console.log(err)
						})
				})
			})
		},
		// 테마 변경
		updateTheme() {
			const body = document.querySelector('.board-wrapper')
			if (body) body.style.backgroundColor = this.$store.state.bodyColor
		},
	},
}
</script>
<style>
.v-dialog.v-dialog--active.v-dialog--persistent.v-dialog--fullscreen {
	overflow: hidden;
}
.form-control {
	padding: 15px;
}
.board-wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}
.board {
	display: flex;
	flex-direction: column;
	height: 100%;
	white-space: nowrap;
}
.board-header {
	flex: none;
	padding: 8px 4px 8px 4rem;
	margin: 0;
	height: 45px;
	line-height: 32px;
	width: 90%;
}

.board-header input[type='text'] {
	width: 200px;
	border-radius: 5px;
}
.board-header-btn {
	border-radius: 4px;
	padding: 2px 10px;
	height: 36px;
	/* margin-bottom: 15px; */
	margin-top: 5px;
	display: inline-block;
	color: #fff;
}
.board-header-btn:hover,
.board-header-btn:focus {
	background-color: rgba(0, 0, 0, 0.15);
	cursor: pointer;
}
.board-title {
	font-weight: 700;
	font-size: 18px;
}
.show-menu {
	font-size: 14px;
	position: absolute;
	right: 100px;
}

.list-section-wrapper {
	flex-grow: 1;
	position: relative;
}
.list-section {
	position: absolute;
	top: 20px;
	left: 4rem;
	right: 0;
	bottom: 0;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: wrap;
	padding: 0 10px;
}
.list-wrapper {
	display: inline-block;
	height: 100%;
	width: 272px;
	vertical-align: top;
	margin-right: 5px;
}
.card-item.gu-transit {
	background-color: #555 !important;
}
.card-item.gu-mirror {
	opacity: 1 !important;
	background-color: #fff !important;
	transform: rotate(3deg) !important;
}
.board-header-close-btn {
	font-size: 14px;
	position: absolute;
	right: 20px;
	top: 13px;
}
.loading-bar {
	position: absolute;
	top: 45%;
	right: 50%;
}
.v-dialog > .v-card > .v-card__text.memo-card-text {
	padding: 0px;
}
</style>
