<template>
	<v-dialog v-model="data.dialog">
		<v-carousel
			:show-arrows="$vuetify.breakpoint.smAndDown ? false : true"
			height="auto"
			hide-delimiter-background
			show-arrows-on-hover
			hide-delimiters
			progress
		>
			<v-carousel-item v-for="(slide, i) in data.images" :key="i">
				<v-row class="fill-height" align="center" justify="center">
					<v-img
						style="align-items:center !important;"
						:src="slide"
						contain
						:max-height="$vuetify.breakpoint.xs ? '400' : 800"
						min-height="326"
					></v-img>
				</v-row>
			</v-carousel-item>
		</v-carousel>
		<v-btn @click="data.dialog = false">
			<v-icon>
				mdi-close
			</v-icon>
		</v-btn>
	</v-dialog>
</template>
<script>
export default {
	props: {
		data: { type: Object },
	},
	data() {
		return {}
	},
}
</script>
