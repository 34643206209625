<template>
	<v-btn
		:class="btn.class"
		:color="btn.color"
		depressed
		:dense="btn.dense"
		:width="btn.width"
		:outlined="btn.outlined"
		:fab="btn.fab"
		:small="btn.small"
		:block="btn.block"
		:large="btn.large"
		@click="$emit('click', $event)"
		:height="btn.height"
		:disabled="disabled"
		:dark="btn.dark"
		:ripple="false"
		>{{ btn_txt }}
		<v-icon v-if="btn.icon" :size="btn.iconSize">{{ btn_icon }}</v-icon>
	</v-btn>
</template>

<script>
export default {
	props: {
		btn: Object,
		btn_txt: String,
		btn_icon: String,
		click: Function,
		disabled: Boolean,
	},
}
</script>

<style lang="scss">
.saveBtn {
	background-color: #fdc85a !important;
	color: white !important;
	font-weight: bold;
	width: 100px;
	height: 38px;
}
.historyBtn {
	background-color: #fade0d !important;
	color: white !important;
	font-size: 12px !important;
	font-weight: bold;
	width: 90px;
	height: 30px !important;
}
.saveGreenBtn {
	background-color: green !important;
	color: white !important;
	font-weight: bold;
	width: 100px;
	height: 38px;
}
.testingBtn {
	background-color: gray !important;
	color: white !important;
	font-weight: bold;
	width: 100px;
	height: 38px;
}
.cancelBtn {
	background-color: #999999 !important;
	color: white !important;
	font-weight: bold;
	width: 100px;
	height: 38px;
}
.selectBtn {
	background-color: #f0f0f0 !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	width: 100px;
	height: 45px !important;
}
.searchBtn {
	color: white !important;
	font-weight: bold !important;
	min-width: 100px;
	height: 38px;
	background-color: #dc024e !important;
}
.addBtn {
	color: white !important;
	font-weight: bold !important;
	width: 100px;
	height: 38px;
	background-color: #fdc85a !important;
}
.addBtn2 {
	color: #196fc5 !important;
	font-weight: bold !important;
	width: 100px;
	height: 38px;
	background-color: white !important;
}

.whiteBtn {
	border: 1px solid #d1d1d1 !important;
	background-color: white !important;
	border-radius: 5px;
	font-weight: bold;
	width: 100px;
	height: 36px;
}

.actionBtn {
	border: 1px solid #d1d1d1 !important;
	background-color: white !important;
	border-radius: 5px;
	font-weight: bold;
	width: 130px;
	height: 36px;
}

.connectionBtn {
	border: 1px solid #d1d1d1;
	background-color: white !important;
	border-radius: 5px;
	font-weight: bold;
	height: 38px;
}
.bold {
	font-weight: bold;
}
.arrowBtn {
	background-color: white;
	font-weight: bold;
}

.publishBtn {
	color: white !important;
	font-weight: bold !important;
	width: 100px;
	height: 38px;
	background-color: green !important;
}
.nextBtn {
	background-color: #fade0d !important;
	font-size: 16px !important;
	font-weight: bold !important;
}
.resnextBtn {
	background-color: #fade0d !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	height: 45px !important;
}
.disabledBtn {
	background-color: #d1d1d1 !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	height: 45px !important;
}
.logoutBtn {
	background-color: #006a8e !important;
	color: white !important;
	font-size: 12px !important;
	font-weight: bold;
	width: 90px;
	height: 30px !important;
}
.sendBtn {
	background-color: #fade0d !important;
	font-size: 16px !important;
	// font-weight: bold !important;
}
.qrCapture {
	// color: white !important;
	font-weight: bold;
	font-size: 1.2rem !important;
}
.backwardBtn {
	background-color: #d1d1d1 !important;
	color: white !important;
	font-weight: bold;
	width: 100px;
	height: 38px;
}
.blueOkBtn {
	background-color: white !important;
	color: #047ee2 !important;
	font-size: 16px !important;
	padding: 0px !important;
	// font-weight: bold !important;
}
</style>
