<template>
	<v-dialog v-model="data.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog" dark>
		<v-layout justify-center style="max-width:1200px; margin:0 auto; height:100vh;" wrap align-center class="px-2 pb-2">
			<v-flex xs12>
				<div>
					<v-layout align-center class="my-2">
						<h2 class="white--text">Ceo Report - {{ data.item.Name }}</h2>
						<v-spacer></v-spacer>
						<v-btn icon dark @click="data.dialog = false" color="#5680e9ff">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-layout>
					<div class="white--text">
						<div>
							this Month Work:
							<strong class="PointColor1--text">
								{{ data.item.thisMonthTotalWorkDays }}
							</strong>
							(Day)
						</div>
						<div>
							this Month Work:
							<strong class="PointColor1--text">
								{{ Math.round(data.item.monthWorks[Number($moment().format('MM')) - 1][Number($moment().format('MM'))]) }}
							</strong>
							(Hour)
						</div>
						<h2 v-if="ceoReportAccess" class="white--text">
							Report [ {{ $store.state.systems.ceoReport }} / month ({{ ceoReportsMonth }}) ]
						</h2>
						<h3 v-if="ceoReportAccess" class="mb-1">
							ceoReport Period:
							<strong class="PointColor1--text">
								{{ ceoReportWorkDay[0] }} ~
								{{ ceoReportWorkDay[1] }}
							</strong>
						</h3>
					</div>
					<reportQus
						class="mb-2"
						:data="reportQus"
						:submitBage="String($store.state.systems.ceoReport - ceoReportsMonth)"
						@create="createReportDialog"
						v-if="ceoReportAccess"
					/>
					<v-card class="pa-1">
						<v-tabs v-model="reportTab" grow>
							<v-tabs-slider color="white"></v-tabs-slider>
							<v-tab>Self Report </v-tab>
							<v-tab>CEO Report </v-tab>
							<v-tab>Team Report </v-tab>
						</v-tabs>
						<v-tabs-items v-model="reportTab" dark>
							<v-tab-item>
								<div class="pa-1 mt-4">
									<h2 class="white--text mx-2">Write [ {{ $store.state.systems.selfReport }} / month ({{ selfReportsMonth }}) ]</h2>
									<h3 class="mb-1 mx-2">
										selfReport Period:
										<strong class="PointColor1--text">
											{{ selfReportWorkDay[0] }} ~
											{{ selfReportWorkDay[1] }}
										</strong>
									</h3>
									<v-divider class="my-4"></v-divider>
									<v-layout align-center class="mx-2">
										<DatepickerRange :picker="reportStartpicker" @input="reportStartpicker.menu = false"></DatepickerRange>
										<span class="mx-3 white--text"> ~ </span>
										<DatepickerRange
											:picker="reportEndpicker"
											:allowed_dates="reportCouselor"
											@input="reportDate(reportTab)"
										></DatepickerRange>
									</v-layout>
									<v-layout align-center class="ma-2" justify-end>
										<btn :btn="workBtn" btn_txt="refresh" @click="selfReports()" />
										<v-spacer></v-spacer>
										<textField v-model="reportSearch" :textField="SearchTextField" />
									</v-layout>
									<datatable class="mx-2" :datatable="reportDatatable" :search="reportSearch" @showSelfReport="showSelfReport">
									</datatable></div
							></v-tab-item>
							<v-tab-item
								><div class="pa-1 mt-4">
									<h2 class="white--text mx-2">Report [ {{ $store.state.systems.ceoReport }} / month ({{ selfReportsMonth }}) ]</h2>
									<h3 class="mb-1 mx-2">
										ceoReport Period:
										<strong class="PointColor1--text">
											{{ ceoReportWorkDay[0] }} ~
											{{ ceoReportWorkDay[1] }}
										</strong>
									</h3>
									<v-divider class="my-4"></v-divider>
									<v-layout align-center class="mx-2">
										<DatepickerRange :picker="reportStartpicker" @input="reportStartpicker.menu = false"></DatepickerRange>
										<span class="mx-3 white--text"> ~ </span>
										<DatepickerRange
											:picker="reportEndpicker"
											:allowed_dates="reportCouselor"
											@input="reportDate(reportTab)"
										></DatepickerRange>
									</v-layout>
									<v-layout align-center class="ma-2" justify-end>
										<btn :btn="workBtn" btn_txt="refresh" @click="reports()" />
										<v-spacer></v-spacer>
										<textField v-model="reportSearch" :textField="SearchTextField" />
									</v-layout>
									<datatable class="mx-2" :datatable="reportDatatable" :search="reportSearch" @showSelfReport="showCeoReport"> </datatable>
								</div>
							</v-tab-item>
							<v-tab-item
								><div class="pa-1 mt-4">
									<h2 class="white--text mx-2">Write [ {{ $store.state.systems.teamReport }} / month ({{ selfReportsMonth }}) ]</h2>
									<h3 class="mb-1 mx-2">
										teamReport Period:
										<strong class="PointColor1--text">
											{{ teamReportWorkDay[0] }} ~
											{{ teamReportWorkDay[1] }}
										</strong>
									</h3>
									<v-divider class="my-4"></v-divider>
									<v-layout align-center class="mx-2">
										<DatepickerRange :picker="reportStartpicker" @input="reportStartpicker.menu = false"></DatepickerRange>
										<span class="mx-3 white--text"> ~ </span>
										<DatepickerRange
											:picker="reportEndpicker"
											:allowed_dates="reportCouselor"
											@input="reportDate(reportTab)"
										></DatepickerRange>
									</v-layout>
									<v-layout align-center class="ma-2" justify-end>
										<btn :btn="workBtn" btn_txt="refresh" @click="teamReports()" />
										<v-spacer></v-spacer>
										<textField v-model="reportSearch" :textField="SearchTextField" />
									</v-layout>
									<datatable class="mx-2" :datatable="reportDatatable" :search="reportSearch" @showSelfReport="showTeamReport"> </datatable>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</v-card>
				</div>
			</v-flex>
		</v-layout>
		<reportQusDialog :data="reportQusDialog" @edit="editReport" />
		<dialog1 :setDialog="setDialog1" @cancel="setDialog1.dialog = false" @ok="createReport" />
	</v-dialog>
</template>
<script>
import datatable from './datatable.vue'
import textField from './textField'
import DatepickerRange from './DatepickerRange'
import reportQusDialog from './reportQusDialog'
import reportQus from './reportQus'
import dialog1 from './dialog-1'
import btn from './button'
export default {
	components: {
		datatable,
		textField,
		DatepickerRange,
		reportQusDialog,
		btn,
		reportQus,
		dialog1,
	},
	props: {
		data: {
			type: Object,
		},
	},
	computed: {
		ceoReportAccess() {
			let show = false
			const thisMonthData = this.ceoDatatable.items.filter(
				x => this.$moment(x.reportDay).format('YY-MM') === this.$moment().format('YY-MM'),
			)
			if (this.weekObjArray.length > 0) {
				if (this.$store.state.systems.ceoReport === 1) {
					if (thisMonthData.length === 0) {
						show = true
					} else {
						thisMonthData.forEach(element => {
							if (
								new Date(this.weekObjArray[this.weekObjArray.length - 1].weekStartDate) <= new Date(element.reportDay) &&
								new Date(this.weekObjArray[this.weekObjArray.length - 1].weekEndDate) >= new Date(element.reportDay)
							) {
								show = false
							} else {
								show = true
							}
						})
					}
				} else if (this.$store.state.systems.ceoReport === 2) {
					if (new Date(this.weekObjArray[1].weekStartDate) <= new Date() && new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						if (thisMonthData.length === 0) {
							show = true
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[1].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[1].weekEndDate) >= new Date(element.reportDay)
								) {
									show = false
								} else {
									show = true
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[3].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[3].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							show = true
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[3].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[3].weekEndDate) <= new Date(element.reportDay)
								) {
									show = false
								} else {
									show = true
								}
							})
						}
					}
				} else if (this.$store.state.systems.ceoReport === 3) {
					if (new Date(this.weekObjArray[1].weekStartDate) <= new Date() && new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						if (thisMonthData.length === 0) {
							show = true
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[1].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[1].weekEndDate) >= new Date(element.reportDay)
								) {
									show = false
								} else {
									show = true
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[2].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[2].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							show = true
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[2].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[2].weekEndDate) <= new Date(element.reportDay)
								) {
									show = false
								} else {
									show = true
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[3].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[3].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							show = true
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[3].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[3].weekEndDate) <= new Date(element.reportDay)
								) {
									show = false
								} else {
									show = true
								}
							})
						}
					}
				} else if (this.$store.state.systems.ceoReport === 4) {
					if (new Date(this.weekObjArray[0].weekStartDate) <= new Date() && new Date(this.weekObjArray[0].weekEndDate) >= new Date()) {
						if (thisMonthData.length === 0) {
							show = true
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[0].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[0].weekEndDate) >= new Date(element.reportDay)
								) {
									show = false
								} else {
									show = true
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[1].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[1].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							show = true
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[1].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[1].weekEndDate) >= new Date(element.reportDay)
								) {
									show = false
								} else {
									show = true
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[2].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[2].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							show = true
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[2].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[2].weekEndDate) <= new Date(element.reportDay)
								) {
									show = false
								} else {
									show = true
								}
							})
						}
					} else if (
						new Date(this.weekObjArray[3].weekStartDate) <= new Date() &&
						new Date(this.weekObjArray[3].weekEndDate) >= new Date()
					) {
						if (thisMonthData.length === 0) {
							show = true
						} else {
							thisMonthData.forEach(element => {
								if (
									new Date(this.weekObjArray[3].weekStartDate) <= new Date(element.reportDay) &&
									new Date(this.weekObjArray[3].weekEndDate) <= new Date(element.reportDay)
								) {
									show = false
								} else {
									show = true
								}
							})
						}
					}
				} else {
					show = false
				}
			}
			return show
		},
		teamReportWorkDay() {
			let show = []
			if (this.weekObjArray.length > 0) {
				if (this.$store.state.systems.teamReport === 1) {
					show.push(this.weekObjArray[this.weekObjArray.length - 1].weekStartDate)
					show.push(this.weekObjArray[this.weekObjArray.length - 1].weekEndDate)
				} else if (this.$store.state.systems.teamReport === 2) {
					if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[1].weekStartDate)
						show.push(this.weekObjArray[1].weekEndDate)
					} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[3].weekStartDate)
						show.push(this.weekObjArray[3].weekEndDate)
					}
				} else if (this.$store.state.systems.teamReport === 3) {
					if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[1].weekStartDate)
						show.push(this.weekObjArray[1].weekEndDate)
					} else if (new Date(this.weekObjArray[2].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[2].weekStartDate)
						show.push(this.weekObjArray[2].weekEndDate)
					} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[3].weekStartDate)
						show.push(this.weekObjArray[3].weekEndDate)
					}
				} else if (this.$store.state.systems.teamReport === 4) {
					if (new Date(this.weekObjArray[0].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[0].weekStartDate)
						show.push(this.weekObjArray[0].weekEndDate)
					} else if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[1].weekStartDate)
						show.push(this.weekObjArray[1].weekEndDate)
					} else if (new Date(this.weekObjArray[2].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[2].weekStartDate)
						show.push(this.weekObjArray[2].weekEndDate)
					} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[3].weekStartDate)
						show.push(this.weekObjArray[3].weekEndDate)
					}
				} else {
					show = false
				}
			}
			return show
		},
		ceoReportWorkDay() {
			let show = []
			if (this.weekObjArray.length > 0) {
				if (this.$store.state.systems.ceoReport === 1) {
					show.push(this.weekObjArray[this.weekObjArray.length - 1].weekStartDate)
					show.push(this.weekObjArray[this.weekObjArray.length - 1].weekEndDate)
				} else if (this.$store.state.systems.ceoReport === 2) {
					if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[1].weekStartDate)
						show.push(this.weekObjArray[1].weekEndDate)
					} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[3].weekStartDate)
						show.push(this.weekObjArray[3].weekEndDate)
					}
				} else if (this.$store.state.systems.ceoReport === 3) {
					if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[1].weekStartDate)
						show.push(this.weekObjArray[1].weekEndDate)
					} else if (new Date(this.weekObjArray[2].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[2].weekStartDate)
						show.push(this.weekObjArray[2].weekEndDate)
					} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[3].weekStartDate)
						show.push(this.weekObjArray[3].weekEndDate)
					}
				} else if (this.$store.state.systems.ceoReport === 4) {
					if (new Date(this.weekObjArray[0].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[0].weekStartDate)
						show.push(this.weekObjArray[0].weekEndDate)
					} else if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[1].weekStartDate)
						show.push(this.weekObjArray[1].weekEndDate)
					} else if (new Date(this.weekObjArray[2].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[2].weekStartDate)
						show.push(this.weekObjArray[2].weekEndDate)
					} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[3].weekStartDate)
						show.push(this.weekObjArray[3].weekEndDate)
					}
				} else {
					show = false
				}
			}
			return show
		},
		selfReportWorkDay() {
			let show = []
			if (this.weekObjArray.length > 0) {
				if (this.$store.state.systems.selfReport === 1) {
					show.push(this.weekObjArray[this.weekObjArray.length - 1].weekStartDate)
					show.push(this.weekObjArray[this.weekObjArray.length - 1].weekEndDate)
				} else if (this.$store.state.systems.selfReport === 2) {
					if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[1].weekStartDate)
						show.push(this.weekObjArray[1].weekEndDate)
					} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[3].weekStartDate)
						show.push(this.weekObjArray[3].weekEndDate)
					}
				} else if (this.$store.state.systems.selfReport === 3) {
					if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[1].weekStartDate)
						show.push(this.weekObjArray[1].weekEndDate)
					} else if (new Date(this.weekObjArray[2].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[2].weekStartDate)
						show.push(this.weekObjArray[2].weekEndDate)
					} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[3].weekStartDate)
						show.push(this.weekObjArray[3].weekEndDate)
					}
				} else if (this.$store.state.systems.selfReport === 4) {
					if (new Date(this.weekObjArray[0].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[0].weekStartDate)
						show.push(this.weekObjArray[0].weekEndDate)
					} else if (new Date(this.weekObjArray[1].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[1].weekStartDate)
						show.push(this.weekObjArray[1].weekEndDate)
					} else if (new Date(this.weekObjArray[2].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[2].weekStartDate)
						show.push(this.weekObjArray[2].weekEndDate)
					} else if (new Date(this.weekObjArray[3].weekEndDate) >= new Date()) {
						show.push(this.weekObjArray[3].weekStartDate)
						show.push(this.weekObjArray[3].weekEndDate)
					}
				} else {
					show = false
				}
			}
			return show
		},
		selfReportsMonth() {
			return this.reportDatatable.items.filter(x => this.$moment(x.reportDay).format('YY-MM') === this.$moment().format('YY-MM')).length
		},
	},
	data() {
		return {
			weekObjArray: [],
			ceoReportsMonth: 0,
			reportQus: {
				qusList: [],
			},
			tab: 0,
			reportQusDialog: {
				readOnly: true,
				btnText: 'Save Comment',
				dialog: false,
				qusList: [],
			},
			reportStartpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'start',
				height: 48,
			},
			reportEndpicker: {
				date: null,
				errorMessage: '',
				menu: false,
				placeholder: 'end',
				height: 48,
			},
			reportDatatable: {
				headers: [
					{ text: 'No', value: 'No', sortable: false },
					{ text: 'Report Day', value: 'reportDay' },
					{ text: 'Comment', value: 'content' },
					{ text: 'Action', value: 'reportAction', sortable: false },
				],
				items: [],
				hidedefaultfooter: false,
				hidedefaultheader: false,
				singleExpand: false,
			},
			ceoDatatable: {
				headers: [
					{ text: 'No', value: 'No', sortable: false },
					{ text: 'Report Day', value: 'reportDay' },
					{ text: 'Comment', value: 'content' },
					{ text: 'Action', value: 'reportAction', sortable: false },
				],
				items: [],
				hidedefaultfooter: false,
				hidedefaultheader: false,
				singleExpand: false,
			},
			reportTab: 0,
			workBtn: {
				dark: true,
				color: 'PointColor1',
				height: 48,
			},
			reportSearch: '',
			SearchTextField: {
				dark: false,
				width: 150,
				placeholder: 'Search',
				['prepend-inner-icon']: 'mdi-magnify',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			setDialog1: {
				dialog: false,
				title: '',
				content: '',
				btnText: '',
				type: '',
			},
		}
	},
	methods: {
		numberPad(num, width) {
			num = String(num)
			return num.length >= width ? num : new Array(width - num.length + 1).join('0') + num
		},
		searchPeriodCalculation() {
			let date = new Date()
			let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
			let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
			let weekObj = null
			let weekObjArray = new Array()
			let weekStand = 8
			let firstWeekEndDate = true
			let thisMonthFirstWeek = firstDay.getDay()
			for (var num = 1; num <= 6; num++) {
				if (lastDay.getMonth() != firstDay.getMonth()) {
					break
				}
				weekObj = new Object()
				if (firstDay.getDay() <= 1) {
					if (firstDay.getDay() == 0) {
						firstDay.setDate(firstDay.getDate() + 1)
					}
					weekObj.weekStartDate =
						firstDay.getFullYear().toString() +
						'-' +
						this.numberPad((firstDay.getMonth() + 1).toString(), 2) +
						'-' +
						this.numberPad(firstDay.getDate().toString(), 2)
				}
				if (weekStand > thisMonthFirstWeek) {
					if (firstWeekEndDate) {
						if (weekStand - firstDay.getDay() == 1) {
							firstDay.setDate(firstDay.getDate() + (weekStand - firstDay.getDay()) - 1)
						}

						if (weekStand - firstDay.getDay() > 1) {
							firstDay.setDate(firstDay.getDate() + (weekStand - firstDay.getDay()) - 1)
						}

						firstWeekEndDate = false
					} else {
						firstDay.setDate(firstDay.getDate() + 6)
					}
				} else {
					firstDay.setDate(firstDay.getDate() + (6 - firstDay.getDay()) + weekStand)
				}
				if (typeof weekObj.weekStartDate !== 'undefined') {
					weekObj.weekEndDate =
						firstDay.getFullYear().toString() +
						'-' +
						this.numberPad((firstDay.getMonth() + 1).toString(), 2) +
						'-' +
						this.numberPad(firstDay.getDate().toString(), 2)
					weekObjArray.push(weekObj)
				}
				firstDay.setDate(firstDay.getDate() + 1)
			}
			this.weekObjArray = weekObjArray
			console.log(this.weekObjArray)
		},
		RankText(data) {
			if (data === 0) {
				return 'D'
			} else if (data === 1) {
				return 'C'
			} else if (data === 2) {
				return 'B'
			} else if (data === 3) {
				return 'A'
			} else if (data === 4) {
				return 'S'
			} else {
				return ''
			}
		},
		createReport() {
			console.log(this.data)
			if (this.data.item.id) {
				const data = {
					data: this.reportQus.qusList,
					user: this.data.item.id,
					reportDay: this.$moment().format('YYYY-MM-DD'),
					rank: this.reportQus.rank,
				}
				this.$store
					.dispatch('createReport', data)
					.then(() => {
						this.ceoReports()
						if (this.reportTab === 1) {
							this.reports()
						}
						this.setDialog1.dialog = false
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		createReportDialog() {
			this.setDialog1.dialog = true
			this.setDialog1.type = 'ceoReportSumit'
			this.setDialog1.title = 'ceo Report Sumit'
			this.setDialog1.content = 'Are you really sure to sumit Ceo Report?'
			this.setDialog1.btnText = 'Sumit'
		},
		reportQuses() {
			const data = {
				qusType: 'ceoReport',
				show: true,
			}
			this.$store
				.dispatch('reportQuses', data)
				.then(res => {
					console.log(res.reportQuses)
					this.reportQus.qusList = res.reportQuses
					this.reportQus.tab = 2
				})
				.catch(err => {
					console.log({ err })
				})
		},
		ceoReports() {
			if (this.data.item.id) {
				const data = {
					user: this.data.item.id,
					reportDay_gte: this.$moment().format('YYYY-MM-') + '01',
					reportDay_lte: this.$moment().format('YYYY-MM-DD'),
				}
				this.$store
					.dispatch('reports', data)
					.then(res => {
						this.ceoDatatable.items = res.reports
						console.log(res)
						this.ceoReportsMonth = res.reports.length
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		reports() {
			if (this.data.item.id) {
				const data = {
					user: this.data.item.id,
					reportDay_gte: this.reportStartpicker.date,
					reportDay_lte: this.reportEndpicker.date,
				}
				this.$store
					.dispatch('reports', data)
					.then(res => {
						this.reportDatatable.headers = [
							{ text: 'No', value: 'No', sortable: false },
							{ text: 'Report Day', value: 'reportDay' },
							{ text: 'User', value: 'users_permissions_user.Name' },
							{ text: 'Rank', value: 'rankText' },
							{ text: 'Action', value: 'reportAction', sortable: false },
						]
						this.reportDatatable.items = []
						res.reports.forEach(element => {
							element.rankText = this.RankText(element.rank)
						})
						this.reportDatatable.items = res.reports
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		editReport(val, item) {
			val
			if (item.type === 'selfReport') {
				this.updateSelfReport(item.id)
			} else if (item.type === 'teamReport') {
				this.updateTeamReport(item.id)
			}
		},
		updateSelfReport(id) {
			if (this.data.item.id) {
				const data = {
					content: this.reportQusDialog.content,
					id: id,
				}
				this.$store
					.dispatch('updateSelfReport', data)
					.then(() => {
						this.selfReports()
						this.reportQusDialog.dialog = false
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		updateTeamReport(id) {
			if (this.data.item.id) {
				const data = {
					content: this.reportQusDialog.content,
					id: id,
				}
				this.$store
					.dispatch('updateTeamReport', data)
					.then(() => {
						this.teamReports()
						this.reportQusDialog.dialog = false
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		selfReports() {
			if (this.data.item.id) {
				const data = {
					user: this.data.item.id,
					reportDay_gte: this.reportStartpicker.date,
					reportDay_lte: this.reportEndpicker.date,
				}
				this.$store
					.dispatch('selfReports', data)
					.then(res => {
						this.reportDatatable.headers = [
							{ text: 'No', value: 'No', sortable: false },
							{ text: 'Report Day', value: 'reportDay' },
							{ text: 'Comment', value: 'content' },
							{ text: 'Action', value: 'reportAction', sortable: false },
						]
						this.reportDatatable.items = res.selfReports
						if (this.selfReportAccess) {
							this.selfReportBage = 1
						} else {
							this.selfReportBage = 0
						}
						console.log(res.selfReports)
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		teamReports() {
			if (this.$store.state.myData.email) {
				const data = {
					user: this.data.item.id,
					reportDay_gte: this.reportStartpicker.date,
					reportDay_lte: this.reportEndpicker.date,
				}
				this.$store
					.dispatch('teamReports', data)
					.then(res => {
						this.reportDatatable.headers = [
							{ text: 'No', value: 'No', sortable: false },
							{ text: 'Report Day', value: 'reportDay' },
							{ text: 'Team', value: 'team.title' },
							{ text: 'User', value: 'users_permissions_user.Name' },
							{ text: 'Rank', value: 'rankText' },
							{ text: 'Comment', value: 'content' },
							{ text: 'Action', value: 'reportAction', sortable: false },
						]
						this.reportDatatable.items = []
						res.teamReports.forEach(element => {
							element.rankText = this.RankText(element.rank)
						})
						this.reportDatatable.items = res.teamReports
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		showTeamReport(val, item) {
			val
			this.reportQusDialog.dialog = true
			this.reportQusDialog.type = 'teamReport'
			this.reportQusDialog.reportDay = item.reportDay
			this.reportQusDialog.id = item.id
			this.reportQusDialog.content = item.content
			this.reportQusDialog.qusList = item.data
			this.reportQusDialog.Name = item.users_permissions_user.Name
		},
		showSelfReport(val, item) {
			val
			this.reportQusDialog.dialog = true
			this.reportQusDialog.type = 'selfReport'
			this.reportQusDialog.reportDay = item.reportDay
			this.reportQusDialog.id = item.id
			this.reportQusDialog.qusList = item.data
			this.reportQusDialog.content = item.content
			this.reportQusDialog.Name = item.users_permissions_user.Name
		},
		showCeoReport(val, item) {
			val
			this.reportQusDialog.dialog = true
			this.reportQusDialog.type = 'ceoReport'
			this.reportQusDialog.reportDay = item.reportDay
			this.reportQusDialog.id = item.id
			this.reportQusDialog.qusList = item.data
			this.reportQusDialog.content = item.content
			this.reportQusDialog.Name = item.users_permissions_user.Name
		},
		reportCouselor(val) {
			let start = this.$moment(this.reportStartpicker.date).format('YYMMDD')
			let startDate = new Date(this.reportStartpicker.date)
			let end = new Date(startDate.setDate(startDate.getDate() + 90))
			let endDate = this.$moment(end).format('YYMMDD')
			val = this.$moment(val).format('YYMMDD') < endDate && this.$moment(val).format('YYMMDD') > start
			return val
		},
	},
	watch: {
		data: {
			deep: true,
			handler() {
				this.searchPeriodCalculation()
				this.ceoReports()
				this.reportQuses()
				this.selfReports()
			},
		},
		reportTab(val) {
			this.reportSearch = ''
			if (val === 0) {
				this.selfReports()
			} else if (val === 1) {
				this.reports()
			} else if (val === 2) {
				this.teamReports()
			}
			this.reportStartpicker.date = this.$moment(new Date())
				.subtract(45, 'days')
				.format('YYYY-MM-DD')
			this.reportEndpicker.date = this.$moment(new Date())
				.add(45, 'days')
				.format('YYYY-MM-DD')
		},
	},
	created() {
		this.reportStartpicker.date = this.$moment(new Date())
			.subtract(45, 'days')
			.format('YYYY-MM-DD')
		this.reportEndpicker.date = this.$moment(new Date())
			.add(45, 'days')
			.format('YYYY-MM-DD')
	},
}
</script>
