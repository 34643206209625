import gql from 'graphql-tag'

export const EmailCheck = gql`
	query emailcheck($email: String!) {
		users(where: { email: $email }) {
			id
		}
	}
`

export const UserNameCheck = gql`
	query usernamecheck($username: String!) {
		users(where: { username: $username }) {
			id
		}
	}
`
export const CheckEmails = gql`
	query sendemails($toemail: String!) {
		sendemails(where: { toemail: $toemail }, limit: 1, sort: "id:DESC") {
			authcode
		}
	}
`
// 폰체크 추가 예정
// export const CheckPhone = gql`
//   query phonecheck($phone:String!){
//       users(where:{phone:$phone}){
//         id
//       }
//   }
// `

export const CheckSMS = gql`
	query smssend($phoneNumber: String!) {
		sendsms(where: { phoneNumber: $phoneNumber }, limit: 1, sort: "id:DESC") {
			authcode
		}
	}
`

export const allUsers = gql`
	query allUsers($Name: String, $phone: String, $interID: String, $start: Int, $limit: Int, $id: ID) {
		users(where: { Name_contains: $Name, phone: $phone, interID: $interID, blocked_nin: true, id: $id }, start: $start, limit: $limit) {
			id
			cls
			Name
			username
			birthday
			phone
			position
			email
			level
			face {
				url
			}
			notes
			startdate
			retirement
			blocked
			contract {
				url
			}
			interID
			vacationsDays
			salaryPaymentDay
			wagePaymentDay
		}
	}
`
export const user = gql`
	query user($id: ID!) {
		user(id: $id) {
			id
			cls
			Name
			username
			birthday
			phone
			position
			email
			face {
				url
			}
			notes
			code_reviews {
				rate
				review
				updated_at
			}
		}
	}
`
export const me = gql`
	query me {
		me {
			id
			email
			username
			position
			phone
			cls
			blocked
			interID
			birthday
			vacationsDays
			Name
			level
			memos {
				id
				cards
				bgColor
			}
			face {
				url
			}
			contract {
				url
			}
			startdate
			retirement
			code_reviews {
				rate
				review
				updated_at
			}
			teams {
				id
				created_at
				title
				status
				interID
				teamPosition
				users_permissions_users {
					id
					username
					face {
						url
					}
					Name
				}
				calendars(sort: "created_at:desc") {
					id
					name
					interID
					updated_at
					created_at
				}
			}
		}
	}
`
// 등록된 스케쥴 불러옴
export const allSchedules = gql`
	query allSchedules {
		calendars {
			period1
			period2
			Title
			created_at
		}
	}
`
// 등록된 문의사항 불러옴
export const allInquiries = gql`
	query allInquiries($name: String, $start: Int, $limit: Int) {
		inquiries(where: { name: $name }, start: $start, limit: $limit) {
			id
			created_at
			updated_at
			name
			company
			html
			email
			phone
			price
			type
			enddate
			password
			url
		}
	}
`
// 문의사항 카운트
export const inquiryCount = gql`
	query inquiryCount($where: JSON) {
		inquiryCount(where: $where)
	}
`
// 등록된 포트폴리오 불러옴
export const allPortfolios = gql`
	query allPortfolios($madeby: String, $Title: String) {
		portfolios(where: { madeby: $madeby, Title: $Title }) {
			id
			Title
			client
			madeby
			skills
			Contetns
			CompletionDate
			created_at
			updated_at
			PFImages {
				id
				mime
				name
				size
				url
			}
		}
	}
`
export const Attendancelist = gql`
	query searchattendance($workday_lte: String, $workday_gte: String, $workday: String, $Name: String, $phone: String, $interID: String) {
		attendances(
			where: { workday_lte: $workday_lte, workday_gte: $workday_gte, workday: $workday, Name: $Name, phone: $phone, interID: $interID }
			limit: 1000000
		) {
			id
			Name
			interID
			phone
			position
			notes
			work
			Leave
			workday
			updated_at
			created_at
		}
	}
`
export const calendars = gql`
	query calendars($start: Date, $end: Date, $name: String) {
		calendars(sort: "created_at:desc", where: { start_gte: $start, end_lte: $end, name_contains: $name, status_ne: 99 }, limit: 100000) {
			id
			name
			start
			end
			interID
			content
			client
			pmName
			updated_at
			created_at
			team {
				id
				title
				teamLog
				teamPosition
				users_permissions_users {
					face {
						url
					}
					level
					position
					phone
					email
					cls
					birthday
					Name
					username
				}
			}
		}
	}
`
export const calendarsStatistics = gql`
	query calendars($start: Date, $end: Date, $name: String) {
		calendars(sort: "created_at:desc", where: { start_gte: $start, end_lte: $end, name_contains: $name }, limit: 100000) {
			id
			name
			start
			end
			interID
			content
			client
			pmName
			updated_at
			created_at
			team {
				id
				title
				teamLog
				teamPosition
				users_permissions_users {
					face {
						url
					}
					level
					position
					phone
					email
					cls
					birthday
					Name
					username
				}
			}
		}
	}
`
export const calendar = gql`
	query calendar($id: ID!) {
		calendar(id: $id) {
			id
			chat
		}
	}
`
export const team = gql`
	query team($id: ID!) {
		team(id: $id) {
			id
			chat
		}
	}
`
export const todolists = gql`
	query todolists(
		$interID: String
		$status: ENUM_TODOLIST_STATUS
		$plannerID: String
		$devID: String
		$completeID: String
		$complete: Date
		$dev: Date
		$qa: Date
		$id: ID
		$limit: Int
		$start: Int
		$content_contains: String
		$type: String
	) {
		todolists(
			where: {
				id: $id
				interID: $interID
				status: $status
				plannerID: $plannerID
				devID: $devID
				completeID: $completeID
				dev: $dev
				qa: $qa
				complete: $complete
				content_contains: $content_contains
				show: true
				type: $type
			}
			limit: $limit
			start: $start
			sort: "updated_at:desc"
		) {
			id
			interID
			list
			calendar {
				id
				interID
			}
			devConfirm
			qaConfirm
			planningConfirm
			version
			content
			url
			status
			dev
			qa
			complete
			planning
			comment
			plannerID
			devID
			completeID
			type
		}
		todolistsConnection(
			where: {
				id: $id
				interID: $interID
				status: $status
				plannerID: $plannerID
				devID: $devID
				completeID: $completeID
				dev: $dev
				qa: $qa
				complete: $complete
				content_contains: $content_contains
				show: true
			}
		) {
			aggregate {
				count
			}
		}
	}
`
export const plans = gql`
	query plans($interID: String) {
		plans(where: { interID: $interID, status_ne: 99 }) {
			id
			guiDate
			publicDate
			functionDate
			created_at
			updated_at
			type
			deps
			gui
			public
			function
			api
			explan
			start
			end
			user {
				username
				email
			}
			interID
			status
			client
			index
			prove {
				url
			}
		}
	}
`
export const notices = gql`
	query notices {
		notices(limit: 1, sort: "created_at:desc") {
			id
			title
			content
			updated_at
		}
	}
`
export const codeShows = gql`
	query codeShows($user: ID) {
		codeShows(where: { users_permissions_user: $user }, sort: "id:DESC") {
			id
			created_at
			updated_at
			title
			date
			pdFile {
				url
				name
			}
			users_permissions_user {
				username
			}
			mainImg {
				url
			}
			content
		}
	}
`
export const codeShow = gql`
	query codeShow($id: ID!) {
		codeShow(id: $id) {
			id
			created_at
			updated_at
			title
			date
			pdFile {
				url
			}
			user {
				username
			}
			mainImg {
				url
			}
			content
		}
	}
`
export const teams = gql`
	query teams {
		teams(where: { status_ne: 99 }, sort: "updated_at:desc") {
			id
			updated_at
			teamLog
			title
			teamPosition
			status
			users_permissions_users {
				face {
					url
				}
				id
				notes
				level
				position
				phone
				email
				cls
				birthday
				Name
				username
			}
		}
	}
`
export const vacations = gql`
	query vacations($vacation_gte: DateTime, $vacation_lte: DateTime, $vacationEnd_gte: DateTime, $vacationEnd_lte: DateTime, $status: Int) {
		vacations(
			sort: "created_at:desc"
			where: {
				vacation_gte: $vacation_gte
				vacation_lte: $vacation_lte
				vacationEnd_gte: $vacationEnd_gte
				vacationEnd_lte: $vacationEnd_lte
				status: $status
				status_ne: 99
			}
			limit: 1000000
		) {
			id
			created_at
			updated_at
			users_permissions_user {
				id
				email
				Name
				vacationsDays
			}
			vacation
			approval
			approvalDate
			reject
			status
			content
			halfDay
			vacationEnd
		}
	}
`

export const vacationsCount = gql`
	query vacationsCount($status: Int) {
		vacationsCount(where: { status: $status })
	}
`
export const usersCount = gql`
	query usersCount {
		usersCount(where: { blocked_nin: true })
	}
`
export const monies = gql`
	query monies($user: ID, $payment: Date, $payment_gte: Date, $payment_lte: Date) {
		monies(
			where: { users_permissions_user: $user, payment: $payment, payment_gte: $payment_gte, payment_lte: $payment_lte }
			sort: "created_at:desc"
			limit: 1000000
		) {
			id
			created_at
			updated_at
			salary
			wage
			bonus
			users_permissions_user {
				username
				Name
				id
			}
			payment
			tax
		}
	}
`
export const rules = gql`
	query rules($show: Boolean, $version: Float, $limit: Int, $type: String) {
		rules(sort: "created_at:desc", where: { show: $show, version: $version, type: $type }, limit: $limit) {
			id
			created_at
			updated_at
			content
			version
			creator
			type
			show
		}
	}
`
export const systems = gql`
	query systems {
		systems(limit: 1, sort: "created_at:desc") {
			id
			color
			logo {
				url
			}
			ceoReport
			selfReport
			teamReport
		}
	}
`
export const reportQuses = gql`
	query reportQuses($qusType: String, $show: Boolean, $code: String) {
		reportQuses(sort: "created_at:desc", where: { qusType: $qusType, show: $show, code: $code }) {
			qusType
			id
			listorder
			type
			show
			subtitle
			title
			data
			code
		}
	}
`

export const reports = gql`
	query reports($user: ID, $reportDay: Date, $reportDay_lte: Date, $reportDay_gte: Date) {
		reports(
			where: { users_permissions_user: $user, reportDay: $reportDay, reportDay_gte: $reportDay_gte, reportDay_lte: $reportDay_lte }
			limit: 1000000
			sort: "created_at:desc"
		) {
			id
			created_at
			updated_at
			data
			reportDay
			rank
			users_permissions_user {
				id
				email
				Name
			}
			title
			creator
			content
		}
	}
`

export const teamReports = gql`
	query teamReports($team: ID, $user: ID, $creator: String, $reportDay: Date, $reportDay_lte: Date, $reportDay_gte: Date) {
		teamReports(
			where: {
				team: $team
				users_permissions_user: $user
				creator: $creator
				reportDay: $reportDay
				reportDay_gte: $reportDay_gte
				reportDay_lte: $reportDay_lte
			}
			limit: 1000000
			sort: "created_at:desc"
		) {
			id
			created_at
			updated_at
			team {
				id
				users_permissions_users {
					id
					username
				}
				title
			}
			creator
			data
			users_permissions_user {
				id
				email
				Name
				username
			}
			content
			reportDay
			rank
			title
		}
	}
`
export const selfReports = gql`
	query selfReports($user: ID, $reportDay: Date, $reportDay_lte: Date, $reportDay_gte: Date) {
		selfReports(
			where: { users_permissions_user: $user, reportDay: $reportDay, reportDay_gte: $reportDay_gte, reportDay_lte: $reportDay_lte }
			limit: 1000000
			sort: "created_at:desc"
		) {
			id
			created_at
			updated_at
			data
			users_permissions_user {
				id
				email
				Name
			}
			content
			reportDay
			title
		}
	}
`
export const components = gql`
	query components {
		components {
			id
			created_at
			updated_at
			backgroundColor
			titleColor
			contentColor
			width
			height
			align
			justify
			index
			overlay
			type
			typeNumber
			show
			published_at
			background {
				url
			}
		}
	}
`
export const webs = gql`
	query webs($show: Boolean) {
		webs(where: { show: $show }) {
			id
			created_at
			updated_at
			name
			url
			show
			type
			active
			html
		}
	}
`
export const showWeb = gql`
	query webs($show: Boolean) {
		webs(where: { show: $show }, limit: 1) {
			id
			created_at
			updated_at
			name
			url
			show
			type
			active
			html
		}
	}
`
export const voiceSystems = gql`
	query voiceSystems($show: Boolean) {
		voiceSystems(where: { show: $show }, sort: "created_at:desc") {
			id
			type
			voice
			show
			version
			created_at
		}
	}
`

export const Memos = gql`
	query memos {
		memos {
			title
			userid
			cards
			id
			blog
		}
	}
`
export const LimitMemos = gql`
	query memos($start: Int, $limit: Int) {
		memos(start: $start, limit: $limit) {
			title
			userid
			cards
			id
			blog
		}
	}
`

export const fetchUserMemos = gql`
	query memos($userid: String) {
		memos(where: { userid: $userid }) {
			title
			userid
			cards
			id
			bgColor
		}
	}
`

export const detailBoard = gql`
	query memos($id: String) {
		memos(where: { id: $id }) {
			title
			userid
			cards
			id
			bgColor
		}
	}
`
