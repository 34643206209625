<template>
	<v-layout align-center class="user-layout px-4" :style="`background-color:${$store.state.PointColor2};`">
		<div class="cursor" @click="userDetail = true">
			<v-avatar size="56" color="white">
				<img :src="server + $store.state.myData.face.url" alt="John" v-if="$store.state.myData.face" />
				<img src="/image/img-profile-default-s.png" alt="John" v-else />
			</v-avatar>
		</div>
		<div class="ml-4 cursor">
			<h3 class="FontColor1--text">{{ $store.state.myData.Name }}</h3>
			<h5 class="FontColor2--text">Lv.{{ $store.state.myData.level }}</h5>
		</div>
		<v-spacer></v-spacer>
		<div>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="PointColor2" dark v-bind="attrs" v-on="on" class="mr-2" @click="editAttendance">
						<v-icon>mdi-calendar-edit</v-icon>
					</v-btn>
				</template>
				<span> {{ LeaveStatus ? '퇴근' : '출근' }} 수정</span>
			</v-tooltip>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="PointColor2" dark v-bind="attrs" v-on="on" class="mr-2" @click.once="Attendancelist">
						<v-icon>mdi-home-account</v-icon>
					</v-btn>
				</template>
				<span> work from home</span>
			</v-tooltip>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="attrs"
						v-on="on"
						class="mr-2"
						color="PointColor2"
						dark
						@click="$router.push({ name: 'myQR', params: { id: $store.state.myData.interID } })"
					>
						<v-icon>mdi-qrcode</v-icon>
					</v-btn>
				</template>
				<span> My Qrcode </span>
			</v-tooltip>
			<Money :data="money" />
		</div>
		<!-- qr 인증성공 메세지 -->
		<div class="confirmationUser" justify-center v-show="alertCard1">
			<v-card class="alertCard1" color="yellow"> 출근 되었습니다.</v-card>
		</div>
		<!-- 이미 인증됨 메세지 -->
		<div class="confirmationUser" justify-center v-show="alertCard2">
			<v-card class="alertCard2" color="error"> 이미 출근 되었습니다.</v-card>
		</div>
		<!-- qr 인증 실패 -->
		<div class="confirmationUser" justify-center v-show="alertCard3">
			<v-card class="alertCard1" color="yellow"> 퇴근 되었습니다.</v-card>
		</div>
		<!-- 이미 퇴근됨 메세지 -->
		<div class="confirmationUser" justify-center v-show="alertCard4">
			<v-card class="alertCard2" color="error"> 이미 퇴근 되었습니다.</v-card>
		</div>
		<div class="confirmationUser" justify-center v-show="alertCard5">
			<v-card class="alertCard1" color="yellow"> 출근이 수정 되었습니다.</v-card>
		</div>
		<div class="confirmationUser" justify-center v-show="alertCard6">
			<v-card class="alertCard1" color="yellow"> 퇴근이 수정 되었습니다.</v-card>
		</div>
		<v-dialog v-model="workFromHome.dialog" max-width="500px">
			<v-card>
				<v-card-title>
					Work From Home
				</v-card-title>
				<v-layout justify-center class="mb-2">
					<textField v-model="workFromHome.notes" :textField="notesTextField" class="mx-6" />
				</v-layout>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="PointColor2" text @click="workFromHome.dialog = false">
						cancel
					</v-btn>
					<v-btn color="PointColor2" @click="createAttendance($store.state.myData)" dark>
						Work Start
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="changeFromHome.dialog" max-width="500px">
			<v-card>
				<v-card-title> {{ LeaveStatus ? '퇴근' : '출근' }} 시간수정 </v-card-title>
				<v-layout justify-center class="mb-2">
					<v-time-picker :max="$moment().format('HH:mm')" ampm-in-title v-model="changeFromHome.time" color="primary"></v-time-picker>
				</v-layout>
				<div class="text-center">
					{{ changeStatus ? '출/퇴근 수정은 1시간이내에 가능합니다.' : '' }}
				</div>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="PointColor2" text @click="changeFromHome.dialog = false">
						cancel
					</v-btn>
					<v-btn color="PointColor2" @click="updateChangeAttendance()" dark v-if="!changeStatus">
						수정
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="userDetail" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog" dark>
			<v-row justify="center" align="center" style="height:100vh;" class="ma-0">
				<div style="margin: 0 auto;">
					<v-row style="max-width:400px; padding: 0 12px; height:auto; min-width:320px;" class="ma-0" justify="center">
						<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
							<v-list three-line subheader>
								<div style="padding: 4px 4px 0px 0px; margin: 0px 0px 4px 0px;">
									<v-row class="ma-0" justify="end" align="center">
										<button_1_1 @click="userDetail = false" />
									</v-row>
								</div>
								<v-subheader>My profile</v-subheader>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Face Pic</v-list-item-title>
										<div class="cursor mt-2" @click="faceUpload">
											<v-avatar size="56" color="white">
												<img :src="server + $store.state.myData.face.url" alt="John" v-if="$store.state.myData.face" />
												<img src="/image/img-profile-default-s.png" alt="John" v-else />
											</v-avatar>
										</div>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>ID</v-list-item-title>
										<v-list-item-subtitle>{{ $store.state.myData.username }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Name</v-list-item-title>
										<v-list-item-subtitle>{{ $store.state.myData.Name }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Phone</v-list-item-title>
										<v-list-item-subtitle>{{ $store.state.myData.phone }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>BirthDay</v-list-item-title>
										<v-list-item-subtitle>{{ $store.state.myData.birthday }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>E-mail</v-list-item-title>
										<v-list-item-subtitle>{{ $store.state.myData.email }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Position</v-list-item-title>
										<v-list-item-subtitle>{{ $store.state.myData.position }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>Level</v-list-item-title>
										<v-list-item-subtitle>
											<v-layout>
												{{ $store.state.myData.level }}
												<v-spacer></v-spacer>
												<v-btn small @click="LevelList.dialog = true">level List</v-btn>
											</v-layout>
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>My Money</v-list-item-title>
										<v-list-item-subtitle>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn color="PointColor2" dark v-bind="attrs" v-on="on" class="mr-2" @click="moneyDialog">
														<v-icon>mdi-cash-100</v-icon>
													</v-btn>
												</template>
												<span> money </span>
											</v-tooltip></v-list-item-subtitle
										>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-col>
					</v-row>
				</div>
			</v-row>
		</v-dialog>
		<input type="file" style="display:none;" id="pic-upload" @change="fileupload" accept="image/*" />
		<LevelList :LevelList="LevelList" />
	</v-layout>
</template>

<script>
import { button_1_1 } from '@/components/create-co-worker/index'
import { LevelList, textField, Money } from '@/components'
export default {
	components: {
		button_1_1,
		LevelList,
		textField,
		Money,
	},
	name: 'user',
	data() {
		return {
			LeaveStatus: false,
			alertCard1: false,
			alertCard2: false,
			alertCard3: false,
			alertCard4: false,
			alertCard5: false,
			alertCard6: false,
			notesTextField: {
				dark: false,
				autofocus: false,
				placeholder: 'Reason',
				solo: true,
				type: 'text',
				maxlength: 255,
			},
			changeFromHome: {
				dialog: false,
				time: '',
				notes: '',
				data: null,
			},
			changeStatus: false,
			workFromHome: {
				dialog: false,
				type: 'notes',
				notes: '',
			},
			LevelList: {
				dialog: false,
			},
			userDetail: false,
			server: process.env.VUE_APP_BACKEND_URL,
			money: {
				dialog: false,
				btnText: 'work List',
				class: 'mr-2',
				interID: '',
			},
		}
	},
	methods: {
		updateUser(users) {
			const data = {
				id: users.id,
				vacationsDays: users.vacationsDays + 1,
			}
			this.$store
				.dispatch('updateUser', data)
				.then(() => {})
				.catch(err => {
					console.log({ err })
				})
		},
		moneyDialog() {
			this.money.interID = this.$store.state.myData.interID
			this.money.user = this.$store.state.myData
			this.money.dialog = true
		},
		updateChangeAttendance() {
			if (this.changeFromHome.time.indexOf('00:') > -1) {
				this.changeFromHome.time = this.changeFromHome.time.replace('00:', '12:')
			}
			if (this.LeaveStatus) {
				const data = {
					Leave: this.$moment(this.changeFromHome.data.workday + ' ' + this.changeFromHome.time),
					id: this.changeFromHome.data.id,
				}
				this.$store
					.dispatch('updateAttendance', data)
					.then(() => {
						this.changeFromHome.dialog = false
						this.alertCard6 = true
						setTimeout(() => {
							this.alertCard6 = false
						}, 3000)
					})
					.catch(err => {
						console.log({ err })
					})
			} else {
				const data = {
					work: this.$moment(this.changeFromHome.data.workday + ' ' + this.changeFromHome.time)._d,
					id: this.changeFromHome.data.id,
				}
				this.$store
					.dispatch('updateAttendance', data)
					.then(() => {
						this.changeFromHome.dialog = false
						this.alertCard5 = true
						setTimeout(() => {
							this.alertCard5 = false
						}, 3000)
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		updateAttendance(id) {
			if (id) {
				const data = {
					Leave: new Date(Date.now()),
					id: id,
				}
				this.$store
					.dispatch('updateAttendance', data)
					.then(() => {
						this.alertCard3 = true
						setTimeout(() => {
							this.alertCard3 = false
						}, 3000)
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		editAttendance() {
			this.$store.state.loading = true
			this.LeaveStatus = false
			this.changeStatus = false
			const data = {
				Name: this.$store.state.myData.Name,
				workday_gte: this.$moment(new Date()).format('YYYY-MM-DD'),
			}
			this.$store.dispatch('Attendancelist', data).then(res => {
				if (res.attendances.length > 0) {
					if (res.attendances[0].Leave) this.LeaveStatus = true
					else this.LeaveStatus = false

					if (this.$moment.duration(this.$moment().diff(this.$moment(res.attendances[0].updated_at))).asHours() > 1) {
						this.changeStatus = true
					}
					this.changeFromHome.data = res.attendances[0]
					this.changeFromHome.dialog = true
					if (this.LeaveStatus) {
						this.changeFromHome.time = this.$moment(res.attendances[0].Leave).format('HH:mm')
					} else {
						this.changeFromHome.time = this.$moment(res.attendances[0].work).format('HH:mm')
					}
				} else {
					alert('출근 데이터가 없습니다.')
				}
				this.$store.state.loading = false
			})
		},
		Attendancelist() {
			if (this.$store.state.myData.Name) {
				const data = {
					Name: this.$store.state.myData.Name,
					workday_gte: this.$moment(new Date()).format('YYYY-MM-') + '01',
				}
				this.$store
					.dispatch('Attendancelist', data)
					.then(res => {
						let totalHour = 0
						let totalDay = 0
						let monthWorkTime = 168
						let ok = 0
						if (res.attendances.length === 0) {
							this.workFromHome.dialog = true
						} else {
							res.attendances.forEach((attendance, index) => {
								if (this.$moment(attendance.work).day() !== 0 && this.$moment(attendance.work).day() !== 6) {
									let t2
									if (attendance.Leave) {
										t2 = this.$moment(attendance.Leave)
									} else {
										t2 = this.$moment(attendance.work).add(6, 'hours')
									}
									const t1 = this.$moment(attendance.work)
									totalHour += t2.diff(t1, 'minutes') / 60
									totalDay += 1
								}
								if (this.$moment(attendance.work).format('YYYY-MM-DD') === this.$moment().format('YYYY-MM-DD')) {
									if (Date.now() > new Date(attendance.work).getTime() + 1000 * 60) {
										if (attendance.Leave === null) {
											this.updateAttendance(attendance.id)
										} else {
											this.alertCard4 = true
											setTimeout(() => {
												this.alertCard4 = false
											}, 3000)
										}
									} else {
										this.alertCard2 = true
										setTimeout(() => {
											this.alertCard2 = false
										}, 3000)
									}
									ok += 1
									this.workFromHome.dialog = false
								}
								if (res.attendances.length - 1 === index) {
									if (ok === 0) {
										this.workFromHome.dialog = true
									}
								}
							})
						}
						const notYears = this.$moment(this.$moment().format('YYYY-MM-DD')).isBetween(
							this.$moment(this.$store.state.myData.startdate).format('YYYY-MM-DD'),
							this.$moment(this.$store.state.myData.startdate)
								.add(1, 'years')
								.format('YYYY-MM-DD'),
							undefined,
							'[]',
						)
						if (monthWorkTime * 0.8 <= totalHour && totalDay >= 18 && notYears) {
							this.updateUser(this.$store.state.myData)
						}
					})
					.catch(err => {
						console.log({ err })
					})
			} else {
				alert('잠시후 다시 시도해주세요.')
			}
		},
		createAttendance(users) {
			const data = {
				interID: users.interID,
				Name: users.Name,
				work: new Date(Date.now()),
				workday: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
				position: users.position,
				phone: users.phone,
				notes: this.workFromHome.notes,
			}
			this.$store
				.dispatch('createAttendance', data)
				.then(() => {
					this.alertCard1 = true
					setTimeout(() => {
						this.alertCard1 = false
					}, 3000)
					this.workFromHome.dialog = false
				})
				.catch(err => {
					console.log({ err })
				})
		},
		faceUpload() {
			document.getElementById('pic-upload').click()
		},
		fileupload(event) {
			if (event.target.files.length > 0) {
				const data = {
					refId: Number(this.$store.state.myData.id),
					field: 'face',
					ref: 'user', // Model name.
					source: 'users-permissions', // Plugin name.
					file: event.target.files[0],
				}
				this.$store
					.dispatch('fileUpload', data)
					.then(() => {
						this.$store.dispatch('me')
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
	},
}
</script>
<style lang="scss">
.confirmationUser {
	z-index: 1;
	position: absolute;
	top: 40px;
	width: 100%;
	text-align: -webkit-center;
	padding-top: 4vh;
}
.user-inset {
	margin-top: 18px !important;
	min-height: 0 !important;
	max-height: calc(100% - 40px) !important;
}
</style>
<style lang="scss" scoped>
.user-layout {
	height: 100px;
}
</style>
