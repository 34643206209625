<template>
	<v-app class="v-app">
		<v-row justify="center">
			<v-dialog
				class="card-review-dialog"
				:fullscreen="$vuetify.breakpoint.smAndDown"
				width="60%"
				v-model="dialog"
				@click:outside="cancleClick"
				@keydown="cancleClick"
			>
				<v-card class="card-review">
					<v-card-title>
						<a class="delete-card-btn" @click="cancleClick">&times;</a>
						<span class="card-review-title mt-10 mb-10">{{ cardreview.title }}</span>
					</v-card-title>
					<div class="card-review-mydata">
						<div class="card-review-name">by {{ cardreview.position }} {{ cardreview.name }} •</div>
						<div class="card-review-update">
							{{ this.$moment(cardreview.update_at).format('lll') }}
						</div>
					</div>
					<v-card-text class="card-review-text" v-html="cardreview.content"></v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn class="card-review-btn" color="primary" @click="cancleClick">
							닫기
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
	</v-app>
</template>

<script>
export default {
	data() {
		return {
			dialog: true,
			active: false,
			ismobile: false,
			wid: '',
		}
	},
	props: {
		cardreview: {
			type: Object,
		},
	},
	methods: {
		cancleClick() {
			this.dialog = false
			this.$nextTick(() => this.$emit('cancleClick'))
		},
	},
}
</script>

<style>
.card-review {
	min-height: auto;
	padding: 0 15% 0 15%;
	width: auto;
	font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕', 나눔고딕,
		'Nanum Gothic', 'Noto Sans KR', 'Noto Sans CJK KR', arial, 돋움, Dotum, Tahoma, Geneva, sans-serif;
}
.v-card__text.card-review-text > p > img {
	width: 90%;
}
.v-card__text.card-review-text > pre {
	white-space: pre-line;
}

.ql-align-center {
	text-align: center;
}

.card-review-title {
	font-size: 3rem;
	line-height: 1.3em;
}
@media (max-width: 1024px) {
	.card-review-title {
		font-size: 1.5rem;
		line-height: 1.3em;
	}
}
.card-review-mydata {
	display: flex;
	margin-top: 10px;
	margin-bottom: 2rem;
	padding: 0 24px 0px;
	font-size: 1rem;
}
@media (max-width: 1024px) {
	.card-review-mydata {
		display: flex;
		margin-top: 10px;
		margin-bottom: 0rem;
		padding: 0 24px 0px;
	}
}
.card-review-name {
	font-size: 16px;
	font-weight: bold;
}
@media (max-width: 1024px) {
	.card-review-name {
		font-size: 0.5rem;
		font-weight: bold;
	}
}
.card-review-update {
	font-size: 16px;

	margin-left: 10px;
}
@media (max-width: 1024px) {
	.card-review-update {
		font-size: 0.3rem;

		margin-left: 0.2rem;
	}
}
.delete-card-btn {
	font-size: 40px;
	position: absolute;
	right: 0.8em;
	top: 0.5em;
}

.card-review-btn {
	right: -0.5em;
}

.v-dialog > .v-card > .v-card__text.card-review-text {
	padding: 1rem;
}
</style>
