// import Axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import apollo from '../apollo/config'
import axios from 'axios'
import {
	login,
	Register,
	updateUser,
	createAttendance,
	updateAttendance,
	updateTeam,
	createTeam,
	createCalendar,
	updateCalendar,
	createTodolist,
	updateTodolist,
	createVacation,
	updateVacation,
	updateMoney,
	createMoney,
	createRule,
	updateRule,
	createSystem,
	updateSystem,
	createReport,
	updateReport,
	createReportQus,
	updateReportQus,
	createTeamReport,
	updateTeamReport,
	createSelfReport,
	updateSelfReport,
	createWeb,
	updateWeb,
	createComponent,
	updateComponent,
	createVoiceSystem,
	updateVoiceSystem,
	createCodeShow,
	updateCodeShow,
	createCodeReview,
	updateCodeReview,
	deleteCodeShow,
	createSendSm,
	createInquiry,
	createMemo,
	updateMemo,
} from '../apollo/mutation'
import {
	me,
	Attendancelist,
	calendars,
	allUsers,
	codeShows,
	user,
	teams,
	calendar,
	team,
	todolists,
	vacations,
	vacationsCount,
	usersCount,
	calendarsStatistics,
	monies,
	rules,
	systems,
	selfReports,
	teamReports,
	reports,
	reportQuses,
	webs,
	components,
	voiceSystems,
	showWeb,
	detailBoard,
	LimitMemos,
} from '../apollo/query'
const tokenName = 'protect-t'
Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		devItem: {},
		loading: false,
		ResetColor: {
			MainColor1: '#222326',
			MainColor2: '#04C4D9',
			PointColor1: '#D92B7C',
			PointColor2: '#4C428C',
			PointColor3: '#05F2F2',
			FontColor1: '#F0F1F2',
			FontColor2: '#A8AEBF',
		},
		systems: {
			color: {},
			logo: {},
		},
		systemsNew: false,
		fileUploadProgress: 0,
		leftNavDrawer: true,
		myData: {},
		workList: [],
		allowLevel: [3, 5, 6],
		MainColor1: '#222326',
		MainColor2: '#04C4D9',
		PointColor1: '#D92B7C',
		PointColor2: '#4C428C',
		PointColor3: '#05F2F2',
		FontColor1: '#F0F1F2',
		FontColor2: '#A8AEBF',
		navList: [
			{ name: 'DashBoard', icon: 'mdi-monitor-dashboard', to: 'DashBoard' },
			{ name: 'WorkList', icon: 'mdi-account-supervisor-circle', to: 'WorkList' },
			{ name: 'CodeReview', icon: 'mdi-application-brackets', to: 'CodeReview' },
			{ name: 'Project', icon: 'mdi-projector-screen-outline', to: 'Project' },
			{ name: 'Vacation', icon: 'mdi-calendar-account', to: 'Vacation' },
			{ name: 'QRenter', icon: 'mdi-qrcode-scan', to: 'QRenter' },
			{ name: 'Logout', icon: 'mdi-logout', to: 'login' },
		],
		op_navList: [
			{ name: 'co-Worker', icon: 'mdi-account-circle', to: 'co-Worker' },
			{ name: 'Team Building', icon: 'mdi-account-group', to: 'TeamBuilding' },
			{ name: 'Marketing Site', icon: 'mdi-page-layout-header-footer', to: 'MarketingSite' },
			{ name: 'Statistics', icon: 'mdi-chart-bar', to: 'Statistics' },
			{ name: 'System', icon: 'mdi-cog', to: 'System' },
		],
		isModal: false, //board 추가시 모달
		isAuth: false, // 로그인 여부
		board: {}, // 보드 저장
		bodyColor: '#ffffff', // 색상
		// navbarColor: '#026aa7',
		isShowBoardSettings: false, // show menu
	},
	mutations: {
		fileUploadProgress(state, payload) {
			state.fileUploadProgress = payload
		},
		//memos
		SET_ISMODAL(state) {
			state.isModal = false
		},

		SET_ISAUTH(state) {
			state.isAuth = true
		},
		SET_LOGOUT(state) {
			state.isAuth = false
			state.bodyColor = '#ffffff'
			state.navbarColor = '#026aa7'
			delete localStorage.token
		},
		SET_BOARD(state, payload) {
			state.board = payload
		},
		SET_THEME(state, color) {
			// console.log(color)
			state.bodyColor = color || '#ffffff'
			// state.navbarColor = color ? 'rgba(0,0,0,.15)' : '#026aa7'
		},
		SET_IS_SHOW_BOARD_SETTINGS(state, toggle) {
			state.isShowBoardSettings = toggle
		},

		SET_USERNAME(state, payload) {
			state.userName = payload
		},
	},
	actions: {
		// ------------------------------------ Common Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		fileUpload({}, input) {
			return new Promise((resolve, reject) => {
				console.log(input)
				var form = new FormData()
				form.append('files', input.file)
				let config = {
					headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + sessionStorage.getItem(tokenName) },
				}
				axios
					.post(process.env.VUE_APP_BACKEND_URL + '/upload', form, config)
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		multifileUpload({}, input) {
			return new Promise((resolve, reject) => {
				var form = new FormData()
				form.append('files', input.files)
				let config = {
					headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + sessionStorage.getItem(tokenName) },
				}
				axios
					.post(process.env.VUE_APP_BACKEND_URL + '/upload', form, config)
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		},
		// ------------------------------------ Common Controller ------------------------------------
		// ------------------------------------ Account Controller ------------------------------------
		login({ dispatch }, data) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({ mutation: login, variables: data })
					.then(({ data }) => {
						resolve(data)
						sessionStorage.setItem(tokenName, data.login.jwt)
						dispatch('me')
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		},
		me({ state }) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: me,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data.me)
						state.myData = data.me
						const workList = []
						data.me.teams.forEach(team => {
							if (team.calendars.length > 0) {
								team.calendars.forEach(calendar => {
									workList.push(calendar.name)
								})
							}
						})
						state.workList = workList
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ Account Controller ------------------------------------
		// ------------------------------------ Operating Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		user({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: user,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		allUsers({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: allUsers,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		QRUser({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: allUsers,
						variables: input,
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		Register({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: Register,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateUser({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateUser,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		},
		// ------------------------------------ Operating Controller ------------------------------------
		// ------------------------------------ DashBoard Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		Attendancelist({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: Attendancelist,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		calendars({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: calendars,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ DashBoard Controller ------------------------------------
		// ------------------------------------ CodeReview Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		codeShows({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: codeShows,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createCodeReview({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createCodeReview,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(data => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateCodeReview({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateCodeReview,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createCodeShow({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createCodeShow,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(data => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateCodeShow({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateCodeShow,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		deleteCodeShow({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: deleteCodeShow,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ CodeReview Controller ------------------------------------
		// ------------------------------------ QRenter Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		Attendancelist_NoAuth({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: Attendancelist,
						variables: input,
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateUser_NoAuth({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateUser,
						variables: input,
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createAttendance({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createAttendance,
						variables: input,
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateAttendance({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateAttendance,
						variables: input,
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ QRenter Controller ------------------------------------
		// ------------------------------------ Team Building Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		createTeam({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createTeam,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateTeam({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateTeam,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		teams({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: teams,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ Team Building Controller ------------------------------------
		// ------------------------------------ Project Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		updateCalendar({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateCalendar,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createCalendar({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createCalendar,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		calendar({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: calendar,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createTodolist({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createTodolist,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateTodolist({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateTodolist,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ Project Controller ------------------------------------
		// ------------------------------------ Chatting Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		team({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: team,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		todolists({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: todolists,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ Chatting Controller ------------------------------------
		// ------------------------------------ Vacation Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		vacations({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: vacations,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createVacation({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createVacation,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateVacation({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateVacation,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		vacationsCount({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: vacationsCount,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ Vacation Controller ------------------------------------
		// ------------------------------------ Statistics Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		usersCount({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: usersCount,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		calendarsStatistics({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: calendarsStatistics,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ Statistics Controller ------------------------------------
		// ------------------------------------ Money Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		monies({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: monies,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateMoney({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateMoney,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createMoney({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createMoney,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ Money Controller ------------------------------------
		// ------------------------------------ System Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		updateVoiceSystem({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateVoiceSystem,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createVoiceSystem({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createVoiceSystem,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		voiceSystems({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: voiceSystems,
						variables: input,
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		rules({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: rules,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateRule({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateRule,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createRule({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createRule,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createSystem({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createSystem,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateSystem({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateSystem,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		systems({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: systems,
						variables: input,
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ System Controller ------------------------------------
		// ------------------------------------ Report Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		reportQuses({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: reportQuses,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		reports({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: reports,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		teamReports({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: teamReports,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		selfReports({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: selfReports,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createReport({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createReport,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateReport({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateReport,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createReportQus({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createReportQus,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateReportQus({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateReportQus,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createSelfReport({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createSelfReport,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateSelfReport({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateSelfReport,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createTeamReport({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createTeamReport,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateTeamReport({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateTeamReport,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ Report Controller ------------------------------------
		// ------------------------------------ Marketing Site Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		webs({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: webs,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		components({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: components,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createWeb({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createWeb,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateWeb({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateWeb,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createComponent({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createComponent,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		updateComponent({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: updateComponent,
						variables: input,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// ------------------------------------ Marketing Site Controller ------------------------------------
		// ------------------------------------ Main Controller ------------------------------------
		// eslint-disable-next-line no-empty-pattern
		showWeb({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.query({
						query: showWeb,
						variables: input,
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createSendSm({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createSendSm,
						variables: input,
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		createInquiry({}, input) {
			return new Promise((resolve, reject) => {
				apollo.defaultClient
					.mutate({
						mutation: createInquiry,
						variables: input,
					})
					.then(({ data }) => {
						resolve(data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// eslint-disable-next-line no-empty-pattern
		Boards({}) {
			return new Promise((resolve, reject) => {
				apollo.clients['defaultClient']
					.query({
						query: LimitMemos,
					})
					.then(res => {
						resolve(res.data.memos)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// 사용자의 보드 리스트 불러오기
		USER_BOARD({ commit }) {
			return new Promise((resolve, reject) => {
				apollo.clients['defaultClient']
					.query({
						query: me,
						context: {
							headers: {
								Authorization: 'Bearer ' + sessionStorage.getItem(tokenName),
							},
						},
					})
					.then(res => {
						if (res.data.me.memos[0]) {
							// 리스트 포지션값으로 정렬
							if (res.data.me.memos[0].cards && res.data.me.memos[0].cards.length > 1) {
								res.data.me.memos[0].cards.sort((a, b) => {
									return a.pos - b.pos
								})
							}
							//카드 포지션값으로 정렬
							if (res.data.me.memos[0].cards) {
								for (var i = 0; i < res.data.me.memos[0].cards.length; i++) {
									if (res.data.me.memos[0].cards[i].carditem && res.data.me.memos[0].cards[i].islist == true) {
										res.data.me.memos[0].cards[i].carditem.sort((c, d) => {
											return c.pos - d.pos
										})
									}
								}
								// console.log('카드')
							}
						}
						commit('SET_BOARD', res.data.me.memos[0])
						resolve(res.data.me.memos[0])
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// 보드 추가
		ADDBOARD({ commit }, data) {
			return new Promise((resolve, reject) => {
				const input = {
					userid: this.state.myData.id,
					users_permissions_user: data.users_permissions_user,
				}

				apollo.clients['defaultClient']
					.mutate({ mutation: createMemo, variables: input })
					.then(res => {
						commit('SET_ISMODAL')

						resolve(res)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		//보드 상세정보
		// eslint-disable-next-line no-empty-pattern
		DETAILBOARD({}, data) {
			return new Promise((resolve, reject) => {
				const input = {
					id: data,
				}
				apollo.clients['defaultClient']
					.query({
						query: detailBoard,
						variables: input,
					})
					.then(res => {
						// 리스트 포지션값으로 정렬
						if (res.data.memos[0].cards && res.data.memos[0].cards.length > 1) {
							res.data.memos[0].cards.sort((a, b) => {
								return a.pos - b.pos
							})
						}
						//카드 포지션값으로 정렬
						if (res.data.memos[0].cards) {
							for (var i = 0; i < res.data.memos[0].cards.length; i++) {
								if (res.data.memos[0].cards[i].carditem && res.data.memos[0].cards[i].islist == true) {
									res.data.memos[0].cards[i].carditem.sort((c, d) => {
										return c.pos - d.pos
									})
								}
							}
						}

						resolve(res.data)
					})
					.catch(err => {
						reject(err)
					})
			})
		},
		// 보드 수정
		// eslint-disable-next-line no-empty-pattern
		UPDATEBOARD({}, input) {
			return new Promise((resolve, reject) => {
				apollo.clients['defaultClient']
					.mutate({ mutation: updateMemo, variables: input })
					.then(res => {
						resolve(res)
					})
					.catch(err => {
						reject(err)
					})
			})
		},

		// ------------------------------------ Main Controller ------------------------------------
	},
	modules: {},
})
