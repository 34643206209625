<template>
	<v-dialog ref="dialog" v-model="data.dialog" :return-value.sync="data.date" persistent width="290px">
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="data.date"
				:label="data.label"
				prepend-inner-icon="mdi-calendar"
				readonly
				rounded
				outlined
				v-bind="attrs"
				v-on="on"
				:class="data.class"
				@click:prepend-inner="data.dialog = true"
			></v-text-field>
		</template>
		<v-date-picker v-model="data.date" scrollable color="#5680E9FF">
			<v-spacer></v-spacer>
			<v-btn text color="primary" @click="data.dialog = false">
				Cancel
			</v-btn>
			<v-btn text color="primary" @click="$refs.dialog.save(data.date)">
				OK
			</v-btn>
		</v-date-picker>
	</v-dialog>
</template>
<script>
export default {
	props: {
		data: {
			type: Object,
		},
	},
}
</script>
