<template>
	<v-dialog v-model="data.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog" dark>
		<v-row justify="center" align="center" style="height:100vh;" class="ma-0">
			<div style="margin: 0 auto;">
				<v-row style="width:400px; height:auto;" class="ma-0" justify="center">
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 16px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<button_1_1 @click="data.dialog = false" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<selectBox_1_2 v-model="data.type" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textField_1_3 v-model="data.version" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<textArea_1_4 v-model="data.content" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12" v-show="data.createType === 'edit'">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="center" align="center">
								<v-switch v-model="data.show" :label="`show: ${data.show}`" color="PointColor1"></v-switch>
							</v-row>
						</div>
					</v-col>
				</v-row>
				<v-row style="width:400px; height:auto;" class="ma-0" justify="center">
					<v-col class="pa-0" cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="start" align="center">
								<button_2_1 @click="cancel" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<button_2_2 @click="$emit('create', $event)" />
							</v-row>
						</div>
					</v-col>
				</v-row>
			</div>
		</v-row>
	</v-dialog>
</template>
<script>
import { button_1_1, selectBox_1_2, textField_1_3, textArea_1_4, button_2_1, button_2_2 } from '@/components/create-rule/index'
export default {
	name: 'create-rule',
	components: { button_1_1, selectBox_1_2, textField_1_3, textArea_1_4, button_2_1, button_2_2 },
	props: {
		data: {
			type: Object,
		},
	},
	methods: {
		cancel() {
			this.data.dialog = false
			this.data.version = ''
			this.data.type = 'Vacation'
			this.data.content = ''
		},
	},
}
</script>
<style lang="scss" scoped></style>
