<template>
	<v-dialog v-model="LevelList.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" content-class="full-dialog" dark>
		<v-row justify="center" align="center" style="height:100vh;" class="ma-0">
			<div style="margin: 0 auto;">
				<v-row style="max-width:400px; padding: 0 12px; height:auto;" class="ma-0" justify="center">
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 16px 0px;">
							<v-row class="ma-0" justify="end" align="center">
								<button_1_1 @click="LevelList.dialog = false" />
							</v-row>
						</div>
					</v-col>
					<v-col class="pa-0" cols="12" xl="12" lg="12" xs="12" sm="12" md="12">
						<v-list three-line subheader>
							<v-subheader>Level List</v-subheader>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Lv. 1</v-list-item-title>
									<v-list-item-subtitle>프론트 엔드 개발자 (vue) / 백엔드 개발자 (node)</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Lv. 2</v-list-item-title>
									<v-list-item-subtitle>풀스텍 개발자 (node, vue)</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Lv. 3</v-list-item-title>
									<v-list-item-subtitle>팀 리더 (미팅 능력자)</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Lv. 4</v-list-item-title>
									<v-list-item-subtitle>개발 스페셜 리스트 (vue, node, server)</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Lv. 5</v-list-item-title>
									<v-list-item-subtitle>프로젝트 매니저 (영업, 관리)</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>Lv. 6</v-list-item-title>
									<v-list-item-subtitle>크리에이터 (자체 서비스 개발 + 팀 리더)</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-col>
				</v-row>
			</div>
		</v-row>
	</v-dialog>
</template>
<script>
import { button_1_1 } from '@/components/create-co-worker/index'
export default {
	components: {
		button_1_1,
	},
	props: {
		LevelList: Object,
	},
}
</script>
