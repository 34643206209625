<template>
	<div class="text-center">
		<v-dialog v-model="data.dialog" width="500">
			<template v-slot:activator="{ on, attrs }">
				<v-btn outlined rounded v-bind="attrs" v-on="on" :class="data.class">
					{{ data.btnText }}
				</v-btn>
			</template>

			<v-card>
				<v-card-title class="text-h5 white--text PointColor1">
					{{ data.title }}
				</v-card-title>

				<v-card-text class="pt-6" style="white-space: pre-line;">
					{{ data.content }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="data.dialog = false" color="PointColor1">
						{{ data.btnText ? data.btnText : 'ok' }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
export default {
	props: {
		data: {
			type: Object,
		},
	},
	watch: {
		data: {
			deep: true,
			handler() {
				if (this.data.active) {
					this.rules()
				}
			},
		},
	},
	methods: {
		rules() {
			const data = { limit: 1, type: 'Vacation' }
			this.$store
				.dispatch('rules', data)
				.then(res => {
					this.data.content = res.rules[0].content
				})
				.catch(err => {
					console.log({ err })
				})
		},
	},
}
</script>
