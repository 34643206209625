<template>
	<v-card class="blog-card" width="300" height="330" @click="CardReview">
		<v-img class="thumbnail-img" v-if="Thumbnail" height="165" :src="Thumbnail"></v-img>
		<v-img class="thumbnail-img" v-else height="165" src="@/assets/noimage.png"></v-img>

		<v-card-title class="blog-card-title">{{ card.title }}</v-card-title>
		<v-card-text>
			<v-row align="center"> </v-row>
		</v-card-text>
		<div class="name-text" align="end">by {{ card.position }} {{ card.name }}</div>
		<v-divider class="mx-4 line"></v-divider>

		<v-card-text class="date-text">
			<div>{{ this.$moment(card.update_at).format('lll') }}</div>
		</v-card-text>
	</v-card>
</template>

<script>
import 'animate.css'

export default {
	name: 'velogcard',
	components: {},
	data() {
		return {
			Thumbnail: '',
			content: '',
		}
	},
	props: {
		card: {
			type: Object,
		},
	},
	created() {
		this.Thumbnail = ''
		const img = this.card.content.split('src="')
		if (img[1]) {
			const imgURL = img[1].split('"')
			this.Thumbnail = imgURL[0]
		}

		const date = new Date(this.card.update_at)
		this.card.update_at = date
	},
	watch: {
		card() {
			this.Thumbnail = ''
			const img = this.card.content.split('src="')
			if (img[1]) {
				const imgURL = img[1].split('"')
				this.Thumbnail = imgURL[0]
			}
		},
	},
	methods: {
		CardReview() {
			this.$emit('onClick', this.card)
		},
	},
}
</script>
<style>
.blog-card {
	margin: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 1rem;
}
.blog-card:hover {
	animation: pulse;
	animation-duration: 3s;
}

.blog-card-content {
	display: block;
	height: 2.4rem;
}
.date-text {
	/* text-align: end; */
	padding-top: 10px;
	padding-bottom: 0px;
	display: flex;
	justify-content: space-between;
}

.name-text {
	padding: 0px 16px 0px 16px;
	font-size: small;
}
.line {
	margin-top: 6px;
}
.blog-card-title {
	text-overflow: ellipsis;
	overflow: hidden;
	height: 80px;
}
</style>
