import dragula from 'dragula'
import 'dragula/dist/dragula.css'

const dragger = {
	init(container, options) {
		return dragula([...container], options)
	},
	silbling({ el, wrapper, candidates, type }) {
		// 드래그할 대상의 타입
		wrapper
		const curTitle = el.dataset[type + 'Title']

		let prev = null // 앞의 카드
		let next = null // 뒤의 카드

		candidates.forEach((el, idx, arr) => {
			// 배열을 뽑아낸다
			const title = el.dataset[type + 'Title']

			if (title == curTitle) {
				prev =
					idx > 0
						? {
								id: arr[idx - 1].dataset[type + 'Title'],
								pos: arr[idx - 1].dataset[type + 'Pos'] * 1,
						  }
						: null
				next =
					idx < arr.length - 1
						? {
								id: arr[idx + 1].dataset[type + 'Title'],
								pos: arr[idx + 1].dataset[type + 'Pos'] * 1,
						  }
						: null
			}
		})
		return { prev, next }
	},
}

export default dragger
