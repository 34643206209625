<template>
	<div>
		<div class="combobox-1-text">
			{{ text }}
		</div>
		<v-combobox
			solo
			:value="value"
			:items="items"
			label="Compoent 이름을 입력후 Enter를 해주시면 추가됩니다."
			multiple
			chips
			hideDetails
			@input="$emit('input', $event)"
		></v-combobox>
	</div>
</template>
<script>
export default {
	props: { value: Array, text: String },
	data() {
		return {
			items: [],
		}
	},
}
</script>
<style lang="scss">
.combobox-1-text {
	color: white;
	text-align: left !important;
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 3px;
}
</style>
