<template>
	<v-row justify="center" align="center" style="height:100vh;" class="ma-0">
		<div style="margin: 0 auto;">
			<v-row style="max-width:400px; padding: 0 12px; height:auto;" class="ma-0" justify="center">
				<v-col class="pa-0" cols="12" xl="12" xs="12"
					><v-row class="mb-12 ml-0" justify="start" align="center">
						<button_1_1 @click="$router.push('/')" />
					</v-row>
				</v-col>
				<v-col class="pa-0" cols="12" xl="12" xs="12">
					<div style="padding: 0px 0px 0px 0px; margin: 0px 0px 0px 0px;">
						<div
							style="
                font-size:36px; font-weight: 400;
                font-style: normal; color: #5680E9; 
                text-align: center;
              "
						>
							Login Page
						</div>
						<v-row class="ma-0" justify="center" align="center">
							<textField_1_2
								:textField="userIdTextField"
								v-model="userId"
								@input="userIdTextField['error-messages'] = ''"
								@keydown.enter="login"
							/>
						</v-row>
					</div>
				</v-col>
				<v-col class="pa-0" cols="12" xl="12" xs="12"
					><v-row class="ma-0" justify="start" align="center">
						<textField_1_3
							:textField="passwordTextField"
							v-model="password"
							@keydown.enter="login"
							@input="passwordTextField['error-messages'] = ''"
						/>
					</v-row>
				</v-col>
				<v-col class="pa-0" cols="12" xl="12" xs="12"
					><v-row class="ma-0" justify="start" align="center">
						<checkBox_1_4 @change="saveIdCheck" v-model="saveId" />
					</v-row>
				</v-col>
				<v-col class="pa-0" cols="12" xl="12" xs="12"
					><v-row class="ma-0" justify="center" align="center">
						<button_1_5 @click="login" />
					</v-row>
				</v-col>
			</v-row>
			<v-row style="max-width:400px; padding: 0 12px; height:auto;" class="mt-4 ml-0 mr-0" justify="center" v-show="false">
				<v-col class="pa-0" cols="12" xs="4" xl="4" sm="4" md="4"
					><v-row class="ma-0" justify="center" align="center">
						<button_2_1 />
					</v-row>
				</v-col>
				<v-col class="pa-0" cols="12" xs="4" xl="4" sm="4" md="4"
					><v-row class="ma-0" justify="center" align="center">
						<button_2_2 />
					</v-row>
				</v-col>
				<v-col class="pa-0" cols="12" xs="4" xl="4" sm="4" md="4"
					><v-row class="ma-0" justify="center" align="center">
						<button_2_3 />
					</v-row>
				</v-col>
			</v-row>
		</div>
	</v-row>
</template>
<script>
import {
	button_1_1,
	textField_1_2,
	textField_1_3,
	checkBox_1_4,
	button_1_5,
	button_2_1,
	button_2_2,
	button_2_3,
} from '@/components/LoginPage-1'
export default {
	name: 'codeMachine-layout',
	components: { button_1_1, textField_1_2, textField_1_3, checkBox_1_4, button_1_5, button_2_1, button_2_2, button_2_3 },
	data() {
		return {
			userId: '',
			password: '',
			saveId: false,
			userIdTextField: {
				width: '100%',
				dark: false,
				disabled: false,
				error: false,
				['error-count']: undefined,
				['error-messages']: '',
				height: 40,
				['hide-details']: false,
				solo: true,
				placeholder: 'Email or ID',
				success: false,
				['success-messages']: '',
				type: 'text',
				maxlength: 255,
			},
			passwordTextField: {
				width: '100%',
				dark: false,
				disabled: false,
				error: false,
				['error-count']: undefined,
				['error-messages']: '',
				height: 40,
				['hide-details']: false,
				solo: true,
				placeholder: 'Password',
				success: false,
				['success-messages']: '',
				type: 'password',
				maxlength: 255,
			},
		}
	},
	methods: {
		saveIdCheck(val) {
			this.saveId = val
		},
		login() {
			if (this.saveId) {
				localStorage.setItem('userId', this.userId)
			}
			if (!this.userId) {
				return (this.userIdTextField['error-messages'] = 'Please enter your ID')
			}
			if (!this.password) {
				return (this.passwordTextField['error-messages'] = 'Please enter your Password')
			}
			const data = {
				identifier: this.userId,
				password: this.password,
			}
			this.$store
				.dispatch('login', data)
				.then(() => {
					this.$router.push({ name: 'DashBoard' })
				})
				.catch(err => {
					this.userIdTextField['error-messages'] = 'The Id or password do not match'
					this.passwordTextField['error-messages'] = 'The Id or password do not match'
					console.log({ err })
				})
		},
	},
	created() {
		if (localStorage.getItem('userId')) {
			this.saveId = true
			this.userId = localStorage.getItem('userId')
		}
	},
}
</script>
<style lang="scss" scoped></style>
