<template>
	<div>
		<Modal class="modal-card">
			<div slot="header" class="modal-card-header">
				<div class="modal-card-header-title">
					<v-text-field class="form-control" ref="inputTitle" v-model="inputTitle"></v-text-field>
				</div>
				<a class="modal-close-btn" href="" @click.prevent="onclose">&times;</a>
			</div>
			<div slot="body" class="card-body">
				<h3>Description</h3>
				<quill-editor slot="body" ref="myQuillEditor" :options="defaultOptions" v-model="newContent" />
			</div>
			<div slot="footer" class="btn-position">
				<v-btn elevation="2" v-if="card || propdata" class="btn btn-success" type="submit" @click="updateCard"> Update Card</v-btn>
				<v-btn v-else class="btn btn-success" type="submit" @click="addCard"> Add Card</v-btn>
			</div>
		</Modal>
	</div>
</template>

<script>
import Modal from './Modal.vue'
import axios from 'axios'

export default {
	components: { Modal },
	data() {
		return {
			loading: false,
			content: '', // card의 content
			inputTitle: '', // card의 title
			newContent: '', // card의 수정후 content
			updataCard: '', // 수정후 card의 데이터
			// quill의 옵션
			defaultOptions: {
				theme: 'snow',
				modules: {
					toolbar: {
						container: [
							['bold', 'italic', 'strike'],
							['code-block'],
							[{ list: 'bullet' }],
							[{ size: ['small', false, 'large', 'huge'] }],
							[{ color: [] }],
							[{ align: [] }],
							['link', 'image'],
						],
						handlers: {
							image: this.uploadFunction,
						},
					},
					imageResize: {},
				},

				placeholder: 'Insert text here ...',
			},
		}
	},
	props: {
		card: {
			type: Object,
		},
		propdata: {
			type: Array,
		},
	},
	watch: {
		card() {
			this.fetchCard()
		},
	},
	created() {
		if (this.card || this.propdata) {
			this.fetchCard()
		}
	},
	// quill 에디터의 v-html 적요
	mounted() {
		const quill = document.querySelector('.ql-editor')
		quill.innerHTML = this.content
	},
	methods: {
		// 기본정보 넣기
		fetchCard() {
			this.$store.state.isquill = true
			if (this.propdata) {
				this.inputTitle = this.propdata[0]
				this.content = this.propdata[1]
			} else {
				this.inputTitle = this.card.title
				this.content = this.card.content
			}
		},
		// 카드 추가, List에 카드 추가에밋 addCard
		addCard() {
			var nowDate = new Date()
			const emitdata = {
				title: this.inputTitle,
				content: this.newContent,
				update_at: nowDate,
				myData: this.$store.state.myData,
			}
			this.$store.state.isquill = false
			this.$emit('addCard', emitdata)
		},
		// 카드 정보 수정, List 카드 수정에밋 updataCard, search 카드수정
		async updateCard() {
			this.$store.state.loading = true
			await this.$store.dispatch('DETAILBOARD', this.$store.state.board.id).then(res => {
				var data = res.memos[0].cards
				for (var i = 0; i < data.length; i++) {
					if (data[i].carditem) {
						for (var j = 0; j < data[i].carditem.length; j++) {
							if (this.card) {
								if (data[i].carditem[j].title == this.card.title) {
									data[i].carditem[j].title = this.inputTitle
									data[i].carditem[j].content = this.newContent
									let nowDate = new Date()
									data[i].carditem[j].update_at = nowDate
									data[i].carditem[j].position = this.$store.state.myData.position
									data[i].carditem[j].name = this.$store.state.myData.Name
									break
								}
							} else {
								if (data[i].carditem[j].title == this.propdata[0]) {
									data[i].carditem[j].title = this.inputTitle
									data[i].carditem[j].content = this.newContent
									let nowDate = new Date()
									data[i].carditem[j].update_at = nowDate
									data[i].carditem[j].position = this.$store.state.myData.position
									data[i].carditem[j].name = this.$store.state.myData.Name
									break
								}
							}
						}
					}
				}
				this.updataCard = data
			})
			const input = {
				id: this.$store.state.board.id,
				cards: this.updataCard,
			}
			this.$store
				.dispatch('UPDATEBOARD', input)
				.then(() => {
					this.$store.state.isquill = false
					this.$store.state.loading = false
					this.$emit('updataCard')
				})
				.catch(err => {
					console.log(err)
				})
		},
		// 사진 업로드
		uploadFunction() {
			this.loading = true
			const input = document.createElement('input')
			input.setAttribute('type', 'file')
			input.setAttribute('accept', 'image/*')
			input.click()

			input.onchange = async e => {
				var form = new FormData()
				form.append('files', e.path[0].files[0])
				let config = { headers: { 'Content-Type': 'multipart/form-data' } }
				axios
					.post(process.env.VUE_APP_BACKEND_URL + '/upload', form, config)
					.then(({ data }) => {
						const qlTag = document.querySelector('.ql-editor')
						const imageTag = document.createElement('img')
						const url = process.env.VUE_APP_BACKEND_URL + data[0].url
						imageTag.src = url
						qlTag.appendChild(imageTag)
						this.loading = false
					})
					.catch(err => {
						console.log({ err })
					})
			}
		},
		onclose() {
			this.$store.state.isquill = false
			this.$emit('onclose')
		},
	},
}
</script>

<style>
.modal-card-header-title {
	display: contents;
}
.form-control {
	width: 95%;
	display: inline-flex;
}
.ql-container {
	height: 60vh !important;
}
.btn-position {
	text-align: end;
}
.modal-card .modal-container {
	min-width: 300px;
	max-width: 1200px;
	width: 100%;
}
.ql-toolbar.ql-snow {
	white-space: pre-wrap;
}
.modal-close-btn {
	margin: 20px 30px;
	font-size: 35px;
}
.loading-bar {
	position: absolute;
	top: 45%;
	right: 50%;
}
</style>
