export default [
	{ 1060: [1040, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1065: [1110, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1070: [1180, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1075: [1250, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1080: [1320, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1085: [1390, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1090: [1460, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1095: [1530, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1100: [1600, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1105: [1670, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1110: [1740, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1115: [1810, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1120: [1880, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1125: [1950, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1130: [2020, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1135: [2090, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1140: [2160, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1145: [2230, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1150: [2300, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1155: [2370, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1160: [2440, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1165: [2500, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1170: [2570, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1175: [2640, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1180: [2710, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1185: [2780, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1190: [2850, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1195: [2920, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1200: [2990, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1205: [3060, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1210: [3130, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1215: [3200, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1220: [3270, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1225: [3340, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1230: [3410, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1235: [3480, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1240: [3550, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1245: [3620, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1250: [3700, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1255: [3810, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1260: [3910, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1265: [4010, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1270: [4120, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1275: [4220, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1280: [4320, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1285: [4430, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1290: [4530, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1295: [4630, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1300: [4740, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1305: [4840, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1310: [4940, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1315: [5050, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1320: [5150, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1325: [5250, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1330: [5360, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1335: [5460, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1340: [5560, 1060, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1345: [5670, 1170, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1350: [5770, 1270, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1355: [5870, 1370, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1360: [5980, 1480, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1365: [6080, 1580, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1370: [6180, 1680, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1375: [6290, 1790, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1380: [6390, 1890, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1385: [6490, 1990, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1390: [6600, 2100, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1395: [6700, 2200, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1400: [6800, 2300, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1405: [6910, 2410, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1410: [7010, 2510, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1415: [7110, 2610, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1420: [7210, 2710, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1425: [7320, 2820, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1430: [7420, 2920, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1435: [7520, 3020, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1440: [7630, 3130, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1445: [7730, 3230, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1450: [7830, 3330, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1455: [7940, 3440, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1460: [8040, 3540, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1465: [8140, 3640, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1470: [8250, 3750, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1475: [8350, 3850, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1480: [8450, 3950, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1485: [8560, 4060, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1490: [8660, 4160, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1495: [8760, 4260, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1500: [8920, 4420, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1510: [9120, 4620, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1520: [9330, 4830, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1530: [9540, 5040, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1540: [9740, 5240, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1550: [9950, 5450, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1560: [10160, 5660, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1570: [10360, 5860, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1580: [10570, 6070, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1590: [10780, 6280, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1600: [10980, 6480, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1610: [11190, 6690, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1620: [11400, 6900, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1630: [11600, 7100, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1640: [11810, 7310, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1650: [12020, 7520, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1660: [12220, 7720, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1670: [12430, 7930, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1680: [12640, 8140, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1690: [12840, 8340, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1700: [13050, 8550, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1710: [13260, 8760, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1720: [13460, 8960, 1040, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1730: [13670, 9170, 1240, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1740: [13880, 9380, 1440, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1750: [14080, 9580, 1640, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1760: [14290, 9790, 1830, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1770: [14500, 10000, 2030, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1780: [14700, 10200, 2230, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1790: [14910, 10410, 2430, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1800: [15110, 10610, 2630, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1810: [15320, 10820, 2830, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1820: [15530, 11030, 3020, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1830: [15730, 11230, 3220, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1840: [15940, 11440, 3420, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1850: [16150, 11650, 3620, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1860: [16350, 11850, 3820, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1870: [16560, 12060, 4020, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1880: [16770, 12270, 4220, 0, 0, 0, 0, 0, 0, 0, 0] },
	{ 1890: [16970, 12470, 4410, 1040, 0, 0, 0, 0, 0, 0, 0] },
	{ 1900: [17180, 12680, 4610, 1240, 0, 0, 0, 0, 0, 0, 0] },
	{ 1910: [17390, 12890, 4810, 1440, 0, 0, 0, 0, 0, 0, 0] },
	{ 1920: [17590, 13090, 5010, 1630, 0, 0, 0, 0, 0, 0, 0] },
	{ 1930: [17800, 13300, 5210, 1830, 0, 0, 0, 0, 0, 0, 0] },
	{ 1940: [18010, 13510, 5410, 2030, 0, 0, 0, 0, 0, 0, 0] },
	{ 1950: [18210, 13710, 5600, 2230, 0, 0, 0, 0, 0, 0, 0] },
	{ 1960: [18420, 13920, 5800, 2430, 0, 0, 0, 0, 0, 0, 0] },
	{ 1970: [18630, 14130, 6000, 2630, 0, 0, 0, 0, 0, 0, 0] },
	{ 1980: [18880, 14330, 6200, 2820, 0, 0, 0, 0, 0, 0, 0] },
	{ 1990: [19200, 14540, 6400, 3020, 0, 0, 0, 0, 0, 0, 0] },
	{ 2000: [19520, 14750, 6600, 3220, 0, 0, 0, 0, 0, 0, 0] },
	{ 2010: [19850, 14950, 6800, 3420, 0, 0, 0, 0, 0, 0, 0] },
	{ 2020: [20170, 15160, 6990, 3620, 0, 0, 0, 0, 0, 0, 0] },
	{ 2030: [20490, 15370, 7190, 3820, 0, 0, 0, 0, 0, 0, 0] },
	{ 2040: [20810, 15570, 7390, 4020, 0, 0, 0, 0, 0, 0, 0] },
	{ 2050: [21130, 15780, 7590, 4210, 0, 0, 0, 0, 0, 0, 0] },
	{ 2060: [21450, 15990, 7790, 4410, 1040, 0, 0, 0, 0, 0, 0] },
	{ 2070: [21770, 16190, 7990, 4610, 1240, 0, 0, 0, 0, 0, 0] },
	{ 2080: [22090, 16400, 8180, 4810, 1430, 0, 0, 0, 0, 0, 0] },
	{ 2090: [22420, 16600, 8380, 5010, 1630, 0, 0, 0, 0, 0, 0] },
	{ 2100: [22740, 16810, 8580, 5210, 1830, 0, 0, 0, 0, 0, 0] },
	{ 2110: [23060, 17020, 8780, 5400, 2030, 0, 0, 0, 0, 0, 0] },
	{ 2120: [23380, 17220, 8980, 5600, 2230, 0, 0, 0, 0, 0, 0] },
	{ 2130: [23700, 17430, 9180, 5800, 2430, 0, 0, 0, 0, 0, 0] },
	{ 2140: [24020, 17640, 9380, 6000, 2630, 0, 0, 0, 0, 0, 0] },
	{ 2150: [24340, 17840, 9570, 6200, 2820, 0, 0, 0, 0, 0, 0] },
	{ 2160: [24660, 18050, 9770, 6400, 3020, 0, 0, 0, 0, 0, 0] },
	{ 2170: [24990, 18260, 9970, 6600, 3220, 0, 0, 0, 0, 0, 0] },
	{ 2180: [25310, 18460, 10170, 6790, 3420, 0, 0, 0, 0, 0, 0] },
	{ 2190: [25630, 18670, 10370, 6990, 3620, 0, 0, 0, 0, 0, 0] },
	{ 2200: [25950, 18950, 10570, 7190, 3820, 0, 0, 0, 0, 0, 0] },
	{ 2210: [26270, 19270, 10760, 7390, 4010, 0, 0, 0, 0, 0, 0] },
	{ 2220: [26590, 19590, 10960, 7590, 4210, 0, 0, 0, 0, 0, 0] },
	{ 2230: [26910, 19910, 11160, 7790, 4410, 1040, 0, 0, 0, 0, 0] },
	{ 2240: [27240, 20240, 11360, 7980, 4610, 1230, 0, 0, 0, 0, 0] },
	{ 2250: [27560, 20560, 11560, 8180, 4810, 1430, 0, 0, 0, 0, 0] },
	{ 2260: [27880, 20880, 11760, 8380, 5010, 1630, 0, 0, 0, 0, 0] },
	{ 2270: [28200, 21200, 11960, 8580, 5210, 1830, 0, 0, 0, 0, 0] },
	{ 2280: [28520, 21520, 12150, 8780, 5400, 2030, 0, 0, 0, 0, 0] },
	{ 2290: [28840, 21840, 12350, 8980, 5600, 2230, 0, 0, 0, 0, 0] },
	{ 2300: [29160, 22160, 12550, 9180, 5800, 2430, 0, 0, 0, 0, 0] },
	{ 2310: [29480, 22480, 12750, 9370, 6000, 2620, 0, 0, 0, 0, 0] },
	{ 2320: [29810, 22810, 12950, 9570, 6200, 2820, 0, 0, 0, 0, 0] },
	{ 2330: [30130, 23130, 13150, 9770, 6400, 3020, 0, 0, 0, 0, 0] },
	{ 2340: [30450, 23450, 13340, 9970, 6590, 3220, 0, 0, 0, 0, 0] },
	{ 2350: [30770, 23770, 13540, 10170, 6790, 3420, 0, 0, 0, 0, 0] },
	{ 2360: [31090, 24090, 13740, 10370, 6990, 3620, 0, 0, 0, 0, 0] },
	{ 2370: [31410, 24410, 13940, 10560, 7190, 3810, 0, 0, 0, 0, 0] },
	{ 2380: [31970, 24730, 14140, 10760, 7390, 4010, 0, 0, 0, 0, 0] },
	{ 2390: [32770, 25050, 14340, 10960, 7590, 4210, 0, 0, 0, 0, 0] },
	{ 2400: [33570, 25380, 14530, 11160, 7780, 4410, 1030, 0, 0, 0, 0] },
	{ 2410: [34380, 25700, 14730, 11360, 7980, 4610, 1230, 0, 0, 0, 0] },
	{ 2420: [35180, 26020, 14930, 11560, 8180, 4810, 1430, 0, 0, 0, 0] },
	{ 2430: [35980, 26340, 15130, 11760, 8380, 5010, 1630, 0, 0, 0, 0] },
	{ 2440: [36790, 26660, 15330, 11950, 8580, 5200, 1830, 0, 0, 0, 0] },
	{ 2450: [37590, 26980, 15530, 12150, 8780, 5400, 2030, 0, 0, 0, 0] },
	{ 2460: [38390, 27300, 15730, 12350, 8980, 5600, 2230, 0, 0, 0, 0] },
	{ 2470: [39200, 27630, 15920, 12550, 9170, 5800, 2420, 0, 0, 0, 0] },
	{ 2480: [40000, 27950, 16120, 12750, 9370, 6000, 2620, 0, 0, 0, 0] },
	{ 2490: [40800, 28270, 16320, 12950, 9570, 6200, 2820, 0, 0, 0, 0] },
	{ 2500: [41630, 28600, 16530, 13150, 9780, 6400, 3030, 0, 0, 0, 0] },
	{ 2510: [42490, 28940, 16740, 13360, 9990, 6610, 3240, 0, 0, 0, 0] },
	{ 2520: [43340, 29280, 16950, 13580, 10200, 6830, 3450, 0, 0, 0, 0] },
	{ 2530: [44200, 29630, 17160, 13790, 10410, 7040, 3660, 0, 0, 0, 0] },
	{ 2540: [45050, 29970, 17370, 14000, 10620, 7250, 3870, 0, 0, 0, 0] },
	{ 2550: [45910, 30310, 17590, 14210, 10840, 7460, 4090, 0, 0, 0, 0] },
	{ 2560: [46770, 30650, 17800, 14420, 11050, 7670, 4300, 0, 0, 0, 0] },
	{ 2570: [47620, 31000, 18010, 14630, 11260, 7880, 4510, 1130, 0, 0, 0] },
	{ 2580: [48480, 31340, 18220, 14850, 11470, 8100, 4720, 1350, 0, 0, 0] },
	{ 2590: [49330, 31830, 18430, 15060, 11680, 8310, 4930, 1560, 0, 0, 0] },
	{ 2600: [50190, 32690, 18650, 15270, 11900, 8520, 5150, 1770, 0, 0, 0] },
	{ 2610: [51040, 33540, 18920, 15480, 12110, 8730, 5360, 1980, 0, 0, 0] },
	{ 2620: [51900, 34400, 19250, 15690, 12320, 8940, 5570, 2190, 0, 0, 0] },
	{ 2630: [52760, 35260, 19580, 15910, 12530, 9160, 5780, 2410, 0, 0, 0] },
	{ 2640: [53610, 36110, 19910, 16120, 12740, 9370, 5990, 2620, 0, 0, 0] },
	{ 2650: [54470, 36970, 20240, 16330, 12960, 9580, 6210, 2830, 0, 0, 0] },
	{ 2660: [55320, 37820, 20570, 16540, 13170, 9790, 6420, 3040, 0, 0, 0] },
	{ 2670: [56180, 38680, 20900, 16750, 13380, 10000, 6630, 3250, 0, 0, 0] },
	{ 2680: [57040, 39540, 21230, 16970, 13590, 10220, 6840, 3470, 0, 0, 0] },
	{ 2690: [57890, 40390, 21560, 17180, 13800, 10430, 7050, 3680, 0, 0, 0] },
	{ 2700: [58750, 41250, 21890, 17390, 14020, 10640, 7270, 3890, 0, 0, 0] },
	{ 2710: [59600, 42100, 22220, 17600, 14230, 10850, 7480, 4100, 0, 0, 0] },
	{ 2720: [60460, 42960, 22550, 17810, 14440, 11060, 7690, 4310, 0, 0, 0] },
	{ 2730: [61310, 43810, 22880, 18030, 14650, 11280, 7900, 4530, 1150, 0, 0] },
	{ 2740: [62170, 44670, 23210, 18240, 14860, 11490, 8110, 4740, 1360, 0, 0] },
	{ 2750: [63030, 45530, 23540, 18450, 15070, 11700, 8320, 4950, 1570, 0, 0] },
	{ 2760: [63880, 46380, 23870, 18660, 15290, 11910, 8540, 5160, 1790, 0, 0] },
	{ 2770: [64740, 47240, 24200, 18950, 15500, 12120, 8750, 5370, 2000, 0, 0] },
	{ 2780: [65590, 48090, 24520, 19270, 15710, 12340, 8960, 5590, 2210, 0, 0] },
	{ 2790: [66450, 48950, 24850, 19600, 15920, 12550, 9170, 5800, 2420, 0, 0] },
	{ 2800: [67300, 49800, 25180, 19930, 16130, 12760, 9380, 6010, 2630, 0, 0] },
	{ 2810: [68160, 50660, 25510, 20260, 16350, 12970, 9600, 6220, 2850, 0, 0] },
	{ 2820: [69020, 51520, 25840, 20590, 16560, 13180, 9810, 6430, 3060, 0, 0] },
	{ 2830: [69870, 52370, 26170, 20920, 16770, 13400, 10020, 6650, 3270, 0, 0] },
	{ 2840: [70730, 53230, 26500, 21250, 16980, 13610, 10230, 6860, 3480, 0, 0] },
	{ 2850: [71580, 54080, 26830, 21580, 17190, 13820, 10440, 7070, 3690, 0, 0] },
	{ 2860: [72440, 54940, 27160, 21910, 17410, 14030, 10660, 7280, 3910, 0, 0] },
	{ 2870: [73290, 55790, 27490, 22240, 17620, 14240, 10870, 7490, 4120, 0, 0] },
	{ 2880: [74150, 56650, 27820, 22570, 17830, 14460, 11080, 7710, 4330, 0, 0] },
	{ 2890: [75010, 57510, 28150, 22900, 18040, 14670, 11290, 7920, 4540, 1170, 0] },
	{ 2900: [75860, 58360, 28480, 23230, 18250, 14880, 11500, 8130, 4750, 1380, 0] },
	{ 2910: [76720, 59220, 28810, 23560, 18470, 15090, 11720, 8340, 4970, 1590, 0] },
	{ 2920: [77570, 60070, 29140, 23890, 18680, 15300, 11930, 8550, 5180, 1800, 0] },
	{ 2930: [78430, 60930, 29470, 24220, 18970, 15510, 12140, 8760, 5390, 2010, 0] },
	{ 2940: [79280, 61780, 29800, 24550, 19300, 15730, 12350, 8980, 5600, 2230, 0] },
	{ 2950: [80140, 62640, 30130, 24880, 19630, 15940, 12560, 9190, 5810, 2440, 0] },
	{ 2960: [81000, 63500, 30460, 25210, 19960, 16150, 12780, 9400, 6030, 2650, 0] },
	{ 2970: [81850, 64350, 30790, 25540, 20290, 16360, 12990, 9610, 6240, 2860, 0] },
	{ 2980: [82710, 65210, 31120, 25870, 20620, 16570, 13200, 9820, 6450, 3070, 0] },
	{ 2990: [83560, 66060, 31450, 26200, 20950, 16790, 13410, 10040, 6660, 3290, 0] },
	{ 3000: [84850, 67350, 32490, 26690, 21440, 17100, 13730, 10350, 6980, 3600, 0] },
	{ 3020: [86560, 69060, 34140, 27350, 22100, 17530, 14150, 10780, 7400, 4030, 0] },
	{ 3040: [88270, 70770, 35790, 28010, 22760, 17950, 14580, 11200, 7830, 4450, 1080] },
	{ 3060: [89980, 72480, 37440, 28670, 23420, 18380, 15000, 11630, 8250, 4880, 1500] },
	{ 3080: [91690, 74190, 39080, 29330, 24080, 18830, 15430, 12050, 8680, 5300, 1930] },
	{ 3100: [93400, 75900, 40730, 29990, 24740, 19490, 15850, 12470, 9100, 5720, 2350] },
	{ 3120: [95760, 77620, 42380, 30650, 25400, 20150, 16270, 12900, 9520, 6150, 2770] },
	{ 3140: [98210, 79330, 44030, 31310, 26060, 20810, 16700, 13320, 9950, 6570, 3200] },
	{ 3160: [100650, 81040, 45680, 32550, 26720, 21470, 17120, 13750, 10370, 7000, 3620] },
	{ 3180: [103100, 82750, 47330, 34200, 27380, 22130, 17540, 14170, 10790, 7420, 4040] },
	{ 3200: [105540, 84460, 48980, 35850, 28040, 22790, 17970, 14590, 11220, 7840, 4470] },
	{ 3220: [107990, 86170, 50620, 37500, 28700, 23450, 18390, 15020, 11640, 8270, 4890] },
	{ 3240: [110430, 87880, 52270, 39150, 29360, 24110, 18860, 15440, 12070, 8690, 5320] },
	{ 3260: [112880, 89600, 53920, 40800, 30020, 24770, 19520, 15870, 12490, 9120, 5740] },
	{ 3280: [115320, 91310, 55570, 42440, 30670, 25420, 20170, 16290, 12910, 9540, 6160] },
	{ 3300: [117770, 93020, 57220, 44090, 31330, 26080, 20830, 16710, 13340, 9960, 6590] },
	{ 3320: [120210, 95210, 58870, 45740, 32620, 26740, 21490, 17140, 13760, 10390, 7010] },
	{ 3340: [122660, 97660, 60440, 47320, 34190, 27370, 22120, 17540, 14170, 10790, 7420] },
	{ 3360: [125100, 100100, 62010, 48880, 35760, 28000, 22750, 17950, 14570, 11200, 7820] },
	{ 3380: [127550, 102550, 63570, 50450, 37320, 28630, 23380, 18350, 14970, 11600, 8220] },
	{ 3400: [129990, 104990, 65140, 52010, 38890, 29250, 24000, 18750, 15370, 12000, 8620] },
	{ 3420: [132440, 107440, 66700, 53580, 40450, 29880, 24630, 19380, 15780, 12400, 9030] },
	{ 3440: [134880, 109880, 68270, 55140, 42020, 30500, 25250, 20000, 16180, 12800, 9430] },
	{ 3460: [137330, 112330, 69830, 56710, 43580, 31130, 25880, 20630, 16580, 13210, 9830] },
	{ 3480: [139770, 114770, 71400, 58270, 45150, 32020, 26510, 21260, 16980, 13610, 10230] },
	{ 3500: [142220, 117220, 72960, 59840, 46710, 33590, 27130, 21880, 17390, 14010, 10640] },
	{ 3520: [144660, 119660, 74530, 61400, 48280, 35150, 27760, 22510, 17790, 14410, 11040] },
	{ 3540: [147110, 122110, 76090, 62960, 49840, 36710, 28380, 23130, 18190, 14820, 11440] },
	{ 3560: [149550, 124550, 77650, 64530, 51400, 38280, 29010, 23760, 18590, 15220, 11840] },
	{ 3580: [152000, 127000, 79220, 66090, 52970, 39840, 29630, 24380, 19130, 15620, 12250] },
	{ 3600: [154440, 129440, 80780, 67660, 54530, 41410, 30260, 25010, 19760, 16020, 12650] },
	{ 3620: [156890, 131890, 82350, 69220, 56100, 42970, 30890, 25640, 20390, 16420, 13050] },
	{ 3640: [159330, 134330, 83910, 70790, 57660, 44540, 31510, 26260, 21010, 16830, 13450] },
	{ 3660: [161780, 136780, 85480, 72350, 59230, 46100, 32980, 26890, 21640, 17230, 13850] },
	{ 3680: [164220, 139220, 87040, 73920, 60790, 47670, 34540, 27510, 22260, 17630, 14260] },
	{ 3700: [166670, 141670, 88610, 75480, 62360, 49230, 36110, 28140, 22890, 18030, 14660] },
	{ 3720: [169110, 144110, 90170, 77050, 63920, 50800, 37670, 28770, 23520, 18440, 15060] },
	{ 3740: [171560, 146560, 91730, 78610, 65480, 52360, 39230, 29390, 24140, 18890, 15460] },
	{ 3760: [178920, 151090, 95250, 81630, 68510, 55380, 42260, 30600, 25350, 20100, 16240] },
	{ 3780: [181590, 153740, 97700, 83350, 70220, 57100, 43970, 31290, 26040, 20790, 16680] },
	{ 3800: [184260, 156400, 100140, 85060, 71930, 58810, 45680, 32560, 26720, 21470, 17120] },
	{ 3820: [186930, 159050, 102590, 86770, 73640, 60520, 47390, 34270, 27400, 22150, 17560] },
	{ 3840: [189600, 161710, 105030, 88480, 75350, 62230, 49100, 35980, 28090, 22840, 18000] },
	{ 3860: [192270, 164360, 107480, 90190, 77070, 63940, 50820, 37690, 28770, 23520, 18440] },
	{ 3880: [194940, 167020, 109920, 91900, 78780, 65650, 52530, 39400, 29460, 24210, 18960] },
	{ 3900: [197610, 169670, 112370, 93620, 80490, 67360, 54240, 41110, 30140, 24890, 19640] },
	{ 3920: [200280, 172330, 114810, 96060, 82200, 69080, 55950, 42830, 30830, 25580, 20330] },
	{ 3940: [202950, 174980, 117260, 98510, 83910, 70790, 57660, 44540, 31510, 26260, 21010] },
	{ 3960: [205620, 177640, 119700, 100950, 85620, 72500, 59370, 46250, 33120, 26950, 21700] },
	{ 3980: [208290, 180290, 122150, 103400, 87340, 74210, 61090, 47960, 34840, 27630, 22380] },
	{ 4000: [210960, 182950, 124590, 105840, 89050, 75920, 62800, 49670, 36550, 28320, 23070] },
	{ 4020: [213630, 185600, 127040, 108290, 90760, 77630, 64510, 51380, 38260, 29000, 23750] },
	{ 4040: [216300, 188260, 129480, 110730, 92470, 79340, 66220, 53090, 39970, 29680, 24430] },
	{ 4060: [218970, 190910, 131930, 113180, 94430, 81060, 67930, 54810, 41680, 30370, 25120] },
	{ 4080: [221640, 193570, 134370, 115620, 96870, 82770, 69640, 56520, 43390, 31050, 25800] },
	{ 4100: [224310, 196220, 136820, 118070, 99320, 84480, 71350, 58230, 45100, 31980, 26490] },
	{ 4120: [226980, 198880, 139260, 120510, 101760, 86190, 73070, 59940, 46820, 33690, 27170] },
	{ 4140: [229650, 201530, 141710, 122960, 104210, 87900, 74780, 61650, 48530, 35400, 27860] },
	{ 4160: [232320, 204190, 144150, 125400, 106650, 89610, 76490, 63360, 50240, 37110, 28540] },
	{ 4180: [234990, 206840, 146600, 127850, 109100, 91330, 78200, 65080, 51950, 38830, 29230] },
	{ 4200: [237660, 209500, 149040, 130290, 111540, 93040, 79910, 66790, 53660, 40540, 29910] },
	{ 4220: [240330, 212150, 151490, 132740, 113990, 95240, 81620, 68500, 55370, 42250, 30600] },
	{ 4240: [243000, 214810, 153930, 135180, 116430, 97680, 83330, 70210, 57080, 43960, 31280] },
	{ 4260: [245670, 217460, 156380, 137630, 118880, 100130, 85050, 71920, 58800, 45670, 32550] },
	{ 4280: [248340, 220120, 158820, 140070, 121320, 102570, 86760, 73630, 60510, 47380, 34260] },
	{ 4300: [251010, 222770, 161270, 142520, 123770, 105020, 88470, 75340, 62220, 49090, 35970] },
	{ 4320: [253680, 225430, 163710, 144960, 126210, 107460, 90180, 77060, 63930, 50810, 37680] },
	{ 4340: [256350, 228080, 166160, 147410, 128660, 109910, 91890, 78770, 65640, 52520, 39390] },
	{ 4360: [259020, 230740, 168600, 149850, 131100, 112350, 93600, 80480, 67350, 54230, 41100] },
	{ 4380: [261690, 233390, 171050, 152300, 133550, 114800, 96050, 82190, 69070, 55940, 42820] },
	{ 4400: [264360, 236050, 173490, 154740, 135990, 117240, 98490, 83900, 70780, 57650, 44530] },
	{ 4420: [267030, 238700, 175940, 157190, 138440, 119690, 100940, 85610, 72490, 59360, 46240] },
	{ 4440: [269700, 241360, 178380, 159630, 140880, 122130, 103380, 87320, 74200, 61070, 47950] },
	{ 4460: [272370, 244010, 180830, 162080, 143330, 124580, 105830, 89040, 75910, 62790, 49660] },
	{ 4480: [275040, 246670, 183270, 164520, 145770, 127020, 108270, 90750, 77620, 64500, 51370] },
	{ 4500: [277840, 249460, 185850, 167100, 148350, 129600, 110850, 92550, 79430, 66300, 53180] },
	{ 4520: [280650, 252250, 188430, 169680, 150930, 132180, 113430, 94680, 81230, 68110, 54980] },
	{ 4540: [283450, 255040, 191010, 172260, 153510, 134760, 116010, 97260, 83040, 69920, 56790] },
	{ 4560: [286260, 257830, 193590, 174840, 156090, 137340, 118590, 99840, 84850, 71720, 58600] },
	{ 4580: [291560, 263120, 198670, 179920, 161170, 142420, 123670, 104920, 86650, 73530, 60400] },
	{ 4600: [294370, 265910, 201250, 182500, 163750, 145000, 126250, 107500, 88750, 75330, 62210] },
	{ 4620: [297170, 268700, 203830, 185080, 166330, 147580, 128830, 110080, 91330, 77140, 64010] },
	{ 4640: [299980, 271490, 206410, 187660, 168910, 150160, 131410, 112660, 93910, 78950, 65820] },
	{ 4660: [302780, 274280, 208990, 190240, 171490, 152740, 133990, 115240, 96490, 80750, 67630] },
	{ 4680: [305590, 277070, 211570, 192820, 174070, 155320, 136570, 117820, 99070, 82560, 69430] },
	{ 4700: [308390, 279860, 214150, 195400, 176650, 157900, 139150, 120400, 101650, 84360, 71240] },
	{ 4720: [311200, 282650, 216730, 197980, 179230, 160480, 141730, 122980, 104230, 86170, 73040] },
	{ 4740: [314000, 285440, 219310, 200560, 181810, 163060, 144310, 125560, 106810, 88060, 74850] },
	{ 4760: [316810, 288230, 221890, 203140, 184390, 165640, 146890, 128140, 109390, 90640, 76660] },
	{ 4780: [319610, 291020, 224470, 205720, 186970, 168220, 149470, 130720, 111970, 93220, 78460] },
	{ 4800: [322420, 293810, 227050, 208300, 189550, 170800, 152050, 133300, 114550, 95800, 80270] },
	{ 4820: [325220, 296600, 229630, 210880, 192130, 173380, 154630, 135880, 117130, 98380, 82070] },
	{ 4840: [328030, 299390, 232210, 213460, 194710, 175960, 157210, 138460, 119710, 100960, 83880] },
	{ 4860: [330830, 302180, 234790, 216040, 197290, 178540, 159790, 141040, 122290, 103540, 85690] },
	{ 4880: [333640, 304970, 237370, 218620, 199870, 181120, 162370, 143620, 124870, 106120, 87490] },
	{ 4900: [336440, 307760, 239950, 221200, 202450, 183700, 164950, 146200, 127450, 108700, 89950] },
	{ 4920: [339250, 310550, 242530, 223780, 205030, 186280, 167530, 148780, 130030, 111280, 92530] },
	{ 4940: [342050, 313340, 245110, 226360, 207610, 188860, 170110, 151360, 132610, 113860, 95110] },
	{ 4960: [344860, 316130, 247690, 228940, 210190, 191440, 172690, 153940, 135190, 116440, 97690] },
	{ 4980: [347660, 318920, 250270, 231520, 212770, 194020, 175270, 156520, 137770, 119020, 100270] },
	{ 5000: [350470, 321710, 252850, 234100, 215350, 196600, 177850, 159100, 140350, 121600, 102850] },
	{ 5020: [353270, 324500, 255430, 236680, 217930, 199180, 180430, 161680, 142930, 124180, 105430] },
	{ 5040: [356080, 327290, 258010, 239260, 220510, 201760, 183010, 164260, 145510, 126760, 108010] },
	{ 5060: [358880, 330080, 260590, 241840, 223090, 204340, 185590, 166840, 148090, 129340, 110590] },
	{ 5080: [361690, 332870, 263170, 244420, 225670, 206920, 188170, 169420, 150670, 131920, 113170] },
	{ 5100: [364490, 335660, 265750, 247000, 228250, 209500, 190750, 172000, 153250, 134500, 115750] },
	{ 5120: [367300, 338450, 268330, 249580, 230830, 212080, 193330, 174580, 155830, 137080, 118330] },
	{ 5140: [370100, 341240, 270910, 252160, 233410, 214660, 195910, 177160, 158410, 139660, 120910] },
	{ 5160: [372910, 344030, 273490, 254740, 235990, 217240, 198490, 179740, 160990, 142240, 123490] },
	{ 5180: [375710, 346820, 276070, 257320, 238570, 219820, 201070, 182320, 163570, 144820, 126070] },
	{ 5200: [378520, 349610, 278650, 259900, 241150, 222400, 203650, 184900, 166150, 147400, 128650] },
	{ 5220: [381320, 352400, 281230, 262480, 243730, 224980, 206230, 187480, 168730, 149980, 131230] },
	{ 5240: [384130, 355190, 283810, 265060, 246310, 227560, 208810, 190060, 171310, 152560, 133810] },
	{ 5260: [386930, 357980, 286390, 267640, 248890, 230140, 211390, 192640, 173890, 155140, 136390] },
	{ 5280: [389740, 360770, 288970, 270220, 251470, 232720, 213970, 195220, 176470, 157720, 138970] },
	{ 5300: [392540, 363560, 291550, 272800, 254050, 235300, 216550, 197800, 179050, 160300, 141550] },
	{ 5320: [395350, 366350, 294130, 275380, 256630, 237880, 219130, 200380, 181630, 162880, 144130] },
	{ 5340: [398150, 369140, 296710, 277960, 259210, 240460, 221710, 202960, 184210, 165460, 146710] },
	{ 5360: [400960, 371930, 299290, 280540, 261790, 243040, 224290, 205540, 186790, 168040, 149290] },
	{ 5380: [403760, 374720, 301870, 283120, 264370, 245620, 226870, 208120, 189370, 170620, 151870] },
	{ 5400: [406570, 377510, 304450, 285700, 266950, 248200, 229450, 210700, 191950, 173200, 154450] },
	{ 5420: [409370, 380300, 307030, 288280, 269530, 250780, 232030, 213280, 194530, 175780, 157030] },
	{ 5440: [412180, 383090, 309610, 290860, 272110, 253360, 234610, 215860, 197110, 178360, 159610] },
	{ 5460: [414980, 385880, 312190, 293440, 274690, 255940, 237190, 218440, 199690, 180940, 162190] },
	{ 5480: [417790, 388670, 314770, 296020, 277270, 258520, 239770, 221020, 202270, 183520, 164770] },
	{ 5500: [420590, 391460, 317350, 298600, 279850, 261100, 242350, 223600, 204850, 186100, 167350] },
	{ 5520: [423400, 394250, 319930, 301180, 282430, 263680, 244930, 226180, 207430, 188680, 169930] },
	{ 5540: [426200, 397040, 322510, 303760, 285010, 266260, 247510, 228760, 210010, 191260, 172510] },
	{ 5560: [429010, 399830, 325090, 306340, 287590, 268840, 250090, 231340, 212590, 193840, 175090] },
	{ 5580: [431810, 402620, 327670, 308920, 290170, 271420, 252670, 233920, 215170, 196420, 177670] },
	{ 5600: [435890, 405410, 330250, 311500, 292750, 274000, 255250, 236500, 217750, 199000, 180250] },
	{ 5620: [440380, 408200, 332830, 314080, 295330, 276580, 257830, 239080, 220330, 201580, 182830] },
	{ 5640: [444860, 410990, 335410, 316660, 297910, 279160, 260410, 241660, 222910, 204160, 185410] },
	{ 5660: [449350, 413780, 337990, 319240, 300490, 281740, 262990, 244240, 225490, 206740, 187990] },
	{ 5680: [453840, 416570, 340570, 321820, 303070, 284320, 265570, 246820, 228070, 209320, 190570] },
	{ 5700: [458330, 419360, 343150, 324400, 305650, 286900, 268150, 249400, 230650, 211900, 193150] },
	{ 5720: [462820, 422150, 345730, 326980, 308230, 289480, 270730, 251980, 233230, 214480, 195730] },
	{ 5740: [467300, 424940, 348310, 329560, 310810, 292060, 273310, 254560, 235810, 217060, 198310] },
	{ 5760: [471790, 427730, 350890, 332140, 313390, 294640, 275890, 257140, 238390, 219640, 200890] },
	{ 5780: [476280, 430520, 353470, 334720, 315970, 297220, 278470, 259720, 240970, 222220, 203470] },
	{ 5800: [480770, 433800, 356050, 337300, 318550, 299800, 281050, 262300, 243550, 224800, 206050] },
	{ 5820: [485260, 438260, 358630, 339880, 321130, 302380, 283630, 264880, 246130, 227380, 208630] },
	{ 5840: [512120, 465100, 387100, 368350, 349600, 330850, 312100, 293350, 274600, 255850, 237100] },
	{ 5860: [519150, 472110, 392240, 373490, 354740, 335990, 317240, 298490, 279740, 260990, 242240] },
	{ 5880: [523690, 476620, 394880, 376130, 357380, 338630, 319880, 301130, 282380, 263630, 244880] },
	{ 5900: [528220, 481130, 397520, 378770, 360020, 341270, 322520, 303770, 285020, 266270, 247520] },
	{ 5920: [532760, 485640, 400160, 381410, 362660, 343910, 325160, 306410, 287660, 268910, 250160] },
	{ 5940: [537300, 490160, 402800, 384050, 365300, 346550, 327800, 309050, 290300, 271550, 252800] },
	{ 5960: [541830, 494670, 405440, 386690, 367940, 349190, 330440, 311690, 292940, 274190, 255440] },
	{ 5980: [546370, 499180, 408080, 389330, 370580, 351830, 333080, 314330, 295580, 276830, 258080] },
	{ 6000: [550900, 503690, 410720, 391970, 373220, 354470, 335720, 316970, 298220, 279470, 260720] },
	{ 6020: [555440, 508200, 413360, 394610, 375860, 357110, 338360, 319610, 300860, 282110, 263360] },
	{ 6040: [559980, 512720, 416000, 397250, 378500, 359750, 341000, 322250, 303500, 284750, 266000] },
	{ 6060: [564510, 517230, 418640, 399890, 381140, 362390, 343640, 324890, 306140, 287390, 268640] },
	{ 6080: [569050, 521740, 421280, 402530, 383780, 365030, 346280, 327530, 308780, 290030, 271280] },
	{ 6100: [573580, 526250, 423920, 405170, 386420, 367670, 348920, 330170, 311420, 292670, 273920] },
	{ 6120: [578120, 530760, 426560, 407810, 389060, 370310, 351560, 332810, 314060, 295310, 276560] },
	{ 6140: [582660, 535280, 429200, 410450, 391700, 372950, 354200, 335450, 316700, 297950, 279200] },
	{ 6160: [587190, 539790, 431840, 413090, 394340, 375590, 356840, 338090, 319340, 300590, 281840] },
	{ 6180: [591730, 544300, 434480, 415730, 396980, 378230, 359480, 340730, 321980, 303230, 284480] },
	{ 6200: [596260, 548810, 437120, 418370, 399620, 380870, 362120, 343370, 324620, 305870, 287120] },
	{ 6220: [600800, 553320, 439760, 421010, 402260, 383510, 364760, 346010, 327260, 308510, 289760] },
	{ 6240: [605340, 557840, 442400, 423650, 404900, 386150, 367400, 348650, 329900, 311150, 292400] },
	{ 6260: [609870, 562350, 446060, 426290, 407540, 388790, 370040, 351290, 332540, 313790, 295040] },
	{ 6280: [614410, 566860, 450280, 428930, 410180, 391430, 372680, 353930, 335180, 316430, 297680] },
	{ 6300: [618940, 571370, 454510, 431570, 412820, 394070, 375320, 356570, 337820, 319070, 300320] },
	{ 6320: [623480, 575880, 458730, 434210, 415460, 396710, 377960, 359210, 340460, 321710, 302960] },
	{ 6340: [628020, 580400, 462960, 436850, 418100, 399350, 380600, 361850, 343100, 324350, 305600] },
	{ 6360: [632550, 584910, 467180, 439490, 420740, 401990, 383240, 364490, 345740, 326990, 308240] },
	{ 6380: [637090, 589420, 471400, 442130, 423380, 404630, 385880, 367130, 348380, 329630, 310880] },
	{ 6400: [641620, 593930, 475630, 445630, 426020, 407270, 388520, 369770, 351020, 332270, 313520] },
	{ 6420: [646160, 598440, 479850, 449850, 428660, 409910, 391160, 372410, 353660, 334910, 316160] },
	{ 6440: [650700, 602960, 484080, 454080, 431300, 412550, 393800, 375050, 356300, 337550, 318800] },
	{ 6460: [655230, 607470, 488300, 458300, 433940, 415190, 396440, 377690, 358940, 340190, 321440] },
	{ 6480: [659770, 611980, 492520, 462520, 436580, 417830, 399080, 380330, 361580, 342830, 324080] },
	{ 6500: [664300, 616490, 496750, 466750, 439220, 420470, 401720, 382970, 364220, 345470, 326720] },
	{ 6520: [668840, 621000, 500970, 470970, 441860, 423110, 404360, 385610, 366860, 348110, 329360] },
	{ 6540: [673380, 625520, 505200, 475200, 445200, 425750, 407000, 388250, 369500, 350750, 332000] },
	{ 6560: [677910, 630030, 509420, 479420, 449420, 428390, 409640, 390890, 372140, 353390, 334640] },
	{ 6580: [682450, 634540, 513640, 483640, 453640, 431030, 412280, 393530, 374780, 356030, 337280] },
	{ 6600: [686980, 639050, 517870, 487870, 457870, 433670, 414920, 396170, 377420, 358670, 339920] },
	{ 6620: [691520, 643560, 522090, 492090, 462090, 436310, 417560, 398810, 380060, 361310, 342560] },
	{ 6640: [696060, 648080, 526320, 496320, 466320, 438950, 420200, 401450, 382700, 363950, 345200] },
	{ 6660: [700590, 652590, 530540, 500540, 470540, 441590, 422840, 404090, 385340, 366590, 347840] },
	{ 6680: [705130, 657100, 534760, 504760, 474760, 444760, 425480, 406730, 387980, 369230, 350480] },
	{ 6700: [709660, 661610, 538990, 508990, 478990, 448990, 428120, 409370, 390620, 371870, 353120] },
	{ 6720: [714200, 666120, 543210, 513210, 483210, 453210, 430760, 412010, 393260, 374510, 355760] },
	{ 6740: [718740, 670640, 547440, 517440, 487440, 457440, 433400, 414650, 395900, 377150, 358400] },
	{ 6760: [723270, 675150, 551660, 521660, 491660, 461660, 436040, 417290, 398540, 379790, 361040] },
	{ 6780: [727810, 679660, 555880, 525880, 495880, 465880, 438680, 419930, 401180, 382430, 363680] },
	{ 6800: [732340, 684170, 560110, 530110, 500110, 470110, 441320, 422570, 403820, 385070, 366320] },
	{ 6820: [736880, 688680, 564330, 534330, 504330, 474330, 444330, 425210, 406460, 387710, 368960] },
	{ 6840: [741420, 693200, 568560, 538560, 508560, 478560, 448560, 427850, 409100, 390350, 371600] },
	{ 6860: [745950, 697710, 572780, 542780, 512780, 482780, 452780, 430490, 411740, 392990, 374240] },
	{ 6880: [750490, 702220, 577000, 547000, 517000, 487000, 457000, 433130, 414380, 395630, 376880] },
	{ 6900: [755020, 706730, 581230, 551230, 521230, 491230, 461230, 435770, 417020, 398270, 379520] },
	{ 6920: [759560, 711240, 585450, 555450, 525450, 495450, 465450, 438410, 419660, 400910, 382160] },
	{ 6940: [764100, 715760, 589680, 559680, 529680, 499680, 469680, 441050, 422300, 403550, 384800] },
	{ 6960: [768630, 720270, 593900, 563900, 533900, 503900, 473900, 443900, 424940, 406190, 387440] },
	{ 6980: [773170, 724780, 598120, 568120, 538120, 508120, 478120, 448120, 427580, 408830, 390080] },
	{ 7000: [777700, 729290, 602350, 572350, 542350, 512350, 482350, 452350, 430220, 411470, 392720] },
	{ 7020: [782240, 733800, 606570, 576570, 546570, 516570, 486570, 456570, 432860, 414110, 395360] },
	{ 7040: [786780, 738320, 610800, 580800, 550800, 520800, 490800, 460800, 435500, 416750, 398000] },
	{ 7060: [791310, 742830, 615020, 585020, 555020, 525020, 495020, 465020, 438140, 419390, 400640] },
	{ 7080: [795850, 747340, 619240, 589240, 559240, 529240, 499240, 469240, 440780, 422030, 403280] },
	{ 7100: [800380, 751850, 623470, 593470, 563470, 533470, 503470, 473470, 443470, 424670, 405920] },
	{ 7120: [804920, 756360, 627690, 597690, 567690, 537690, 507690, 477690, 447690, 427310, 408560] },
	{ 7140: [809460, 760880, 631920, 601920, 571920, 541920, 511920, 481920, 451920, 429950, 411200] },
	{ 7160: [813990, 765390, 636140, 606140, 576140, 546140, 516140, 486140, 456140, 432590, 413840] },
	{ 7180: [818530, 769900, 640360, 610360, 580360, 550360, 520360, 490360, 460360, 435230, 416480] },
	{ 7200: [823060, 774410, 644590, 614590, 584590, 554590, 524590, 494590, 464590, 437870, 419120] },
	{ 7220: [827600, 778920, 648810, 618810, 588810, 558810, 528810, 498810, 468810, 440510, 421760] },
	{ 7240: [832140, 783440, 653040, 623040, 593040, 563040, 533040, 503040, 473040, 443150, 424400] },
	{ 7260: [836670, 787950, 657260, 627260, 597260, 567260, 537260, 507260, 477260, 447260, 427040] },
	{ 7280: [841210, 792460, 661480, 631480, 601480, 571480, 541480, 511480, 481480, 451480, 429680] },
	{ 7300: [845740, 796970, 665710, 635710, 605710, 575710, 545710, 515710, 485710, 455710, 432320] },
	{ 7320: [850280, 801480, 669930, 639930, 609930, 579930, 549930, 519930, 489930, 459930, 434960] },
	{ 7340: [854820, 806000, 674160, 644160, 614160, 584160, 554160, 524160, 494160, 464160, 437600] },
	{ 7360: [859350, 810510, 678380, 648380, 618380, 588380, 558380, 528380, 498380, 468380, 440240] },
	{ 7380: [863890, 815020, 682600, 652600, 622600, 592600, 562600, 532600, 502600, 472600, 442880] },
	{ 7400: [868420, 819530, 686830, 656830, 626830, 596830, 566830, 536830, 506830, 476830, 446830] },
	{ 7420: [872960, 824040, 691050, 661050, 631050, 601050, 571050, 541050, 511050, 481050, 451050] },
	{ 7440: [877500, 828560, 695280, 665280, 635280, 605280, 575280, 545280, 515280, 485280, 455280] },
	{ 7460: [882030, 833070, 699500, 669500, 639500, 609500, 579500, 549500, 519500, 489500, 459500] },
	{ 7480: [886570, 837580, 703720, 673720, 643720, 613720, 583720, 553720, 523720, 493720, 463720] },
	{ 7500: [891100, 842090, 707950, 677950, 647950, 617950, 587950, 557950, 527950, 497950, 467950] },
	{ 7520: [895640, 846600, 712170, 682170, 652170, 622170, 592170, 562170, 532170, 502170, 472170] },
	{ 7540: [900180, 851120, 716400, 686400, 656400, 626400, 596400, 566400, 536400, 506400, 476400] },
	{ 7560: [904710, 855630, 720620, 690620, 660620, 630620, 600620, 570620, 540620, 510620, 480620] },
	{ 7580: [909250, 860140, 724840, 694840, 664840, 634840, 604840, 574840, 544840, 514840, 484840] },
	{ 7600: [913780, 864650, 729070, 699070, 669070, 639070, 609070, 579070, 549070, 519070, 489070] },
	{ 7620: [918320, 869160, 733290, 703290, 673290, 643290, 613290, 583290, 553290, 523290, 493290] },
	{ 7640: [922860, 873680, 737520, 707520, 677520, 647520, 617520, 587520, 557520, 527520, 497520] },
	{ 7660: [927390, 878190, 741740, 711740, 681740, 651740, 621740, 591740, 561740, 531740, 501740] },
	{ 7680: [931930, 882700, 745960, 715960, 685960, 655960, 625960, 595960, 565960, 535960, 505960] },
	{ 7700: [936460, 887210, 750190, 720190, 690190, 660190, 630190, 600190, 570190, 540190, 510190] },
	{ 7720: [941000, 891720, 754410, 724410, 694410, 664410, 634410, 604410, 574410, 544410, 514410] },
	{ 7740: [945540, 896240, 758640, 728640, 698640, 668640, 638640, 608640, 578640, 548640, 518640] },
	{ 7760: [950070, 900750, 762860, 732860, 702860, 672860, 642860, 612860, 582860, 552860, 522860] },
	{ 7780: [954610, 905260, 767080, 737080, 707080, 677080, 647080, 617080, 587080, 557080, 527080] },
	{ 7800: [959140, 909770, 771310, 741310, 711310, 681310, 651310, 621310, 591310, 561310, 531310] },
	{ 7820: [963680, 914280, 775530, 745530, 715530, 685530, 655530, 625530, 595530, 565530, 535530] },
	{ 7840: [968220, 918800, 779760, 749760, 719760, 689760, 659760, 629760, 599760, 569760, 539760] },
	{ 7860: [972750, 923310, 783980, 753980, 723980, 693980, 663980, 633980, 603980, 573980, 543980] },
	{ 7880: [977290, 927820, 788200, 758200, 728200, 698200, 668200, 638200, 608200, 578200, 548200] },
	{ 7900: [981820, 932330, 792430, 762430, 732430, 702430, 672430, 642430, 612430, 582430, 552430] },
	{ 7920: [986360, 936840, 796650, 766650, 736650, 706650, 676650, 646650, 616650, 586650, 556650] },
	{ 7940: [990900, 941360, 800880, 770880, 740880, 710880, 680880, 650880, 620880, 590880, 560880] },
	{ 7960: [995430, 945870, 805100, 775100, 745100, 715100, 685100, 655100, 625100, 595100, 565100] },
	{ 7980: [999970, 950380, 809320, 779320, 749320, 719320, 689320, 659320, 629320, 599320, 569320] },
	{ 8000: [1004500, 954890, 813550, 783550, 753550, 723550, 693550, 663550, 633550, 603550, 573550] },
	{ 8020: [1009040, 959400, 817770, 787770, 757770, 727770, 697770, 667770, 637770, 607770, 577770] },
	{ 8040: [1013580, 963920, 822000, 792000, 762000, 732000, 702000, 672000, 642000, 612000, 582000] },
	{ 8060: [1018110, 968430, 826220, 796220, 766220, 736220, 706220, 676220, 646220, 616220, 586220] },
	{ 8080: [1022650, 972940, 830440, 800440, 770440, 740440, 710440, 680440, 650440, 620440, 590440] },
	{ 8100: [1027180, 977450, 834670, 804670, 774670, 744670, 714670, 684670, 654670, 624670, 594670] },
	{ 8120: [1031720, 981960, 838890, 808890, 778890, 748890, 718890, 688890, 658890, 628890, 598890] },
	{ 8140: [1036260, 986480, 843120, 813120, 783120, 753120, 723120, 693120, 663120, 633120, 603120] },
	{ 8160: [1040790, 990990, 847340, 817340, 787340, 757340, 727340, 697340, 667340, 637340, 607340] },
	{ 8180: [1045330, 995500, 851560, 821560, 791560, 761560, 731560, 701560, 671560, 641560, 611560] },
	{ 8200: [1049860, 1000010, 855790, 825790, 795790, 765790, 735790, 705790, 675790, 645790, 615790] },
	{ 8220: [1054400, 1004520, 860010, 830010, 800010, 770010, 740010, 710010, 680010, 650010, 620010] },
	{ 8240: [1058940, 1009040, 864240, 834240, 804240, 774240, 744240, 714240, 684240, 654240, 624240] },
	{ 8260: [1063470, 1013550, 868460, 838460, 808460, 778460, 748460, 718460, 688460, 658460, 628460] },
	{ 8280: [1068010, 1018060, 872680, 842680, 812680, 782680, 752680, 722680, 692680, 662680, 632680] },
	{ 8300: [1072540, 1022570, 876910, 846910, 816910, 786910, 756910, 726910, 696910, 666910, 636910] },
	{ 8320: [1077080, 1027080, 881130, 851130, 821130, 791130, 761130, 731130, 701130, 671130, 641130] },
	{ 8340: [1081740, 1031720, 885480, 855480, 825480, 795480, 765480, 735480, 705480, 675480, 645480] },
	{ 8360: [1086420, 1036370, 889840, 859840, 829840, 799840, 769840, 739840, 709840, 679840, 649840] },
	{ 8380: [1091100, 1041030, 894210, 864210, 834210, 804210, 774210, 744210, 714210, 684210, 654210] },
	{ 8400: [1095780, 1045680, 898580, 868580, 838580, 808580, 778580, 748580, 718580, 688580, 658580] },
	{ 8420: [1100460, 1050340, 902950, 872950, 842950, 812950, 782950, 752950, 722950, 692950, 662950] },
	{ 8440: [1105140, 1055000, 907320, 877320, 847320, 817320, 787320, 757320, 727320, 697320, 667320] },
	{ 8460: [1109820, 1059650, 911680, 881680, 851680, 821680, 791680, 761680, 731680, 701680, 671680] },
	{ 8480: [1114500, 1064310, 916050, 886050, 856050, 826050, 796050, 766050, 736050, 706050, 676050] },
	{ 8500: [1119180, 1068960, 920420, 890420, 860420, 830420, 800420, 770420, 740420, 710420, 680420] },
	{ 8520: [1123860, 1073620, 924790, 894790, 864790, 834790, 804790, 774790, 744790, 714790, 684790] },
	{ 8540: [1128540, 1078280, 929160, 899160, 869160, 839160, 809160, 779160, 749160, 719160, 689160] },
	{ 8560: [1133220, 1082930, 933520, 903520, 873520, 843520, 813520, 783520, 753520, 723520, 693520] },
	{ 8580: [1137900, 1087590, 937890, 907890, 877890, 847890, 817890, 787890, 757890, 727890, 697890] },
	{ 8600: [1142580, 1092240, 942260, 912260, 882260, 852260, 822260, 792260, 762260, 732260, 702260] },
	{ 8620: [1147260, 1096900, 946630, 916630, 886630, 856630, 826630, 796630, 766630, 736630, 706630] },
	{ 8640: [1151940, 1101560, 951000, 921000, 891000, 861000, 831000, 801000, 771000, 741000, 711000] },
	{ 8660: [1156620, 1106210, 955360, 925360, 895360, 865360, 835360, 805360, 775360, 745360, 715360] },
	{ 8680: [1161300, 1110870, 959730, 929730, 899730, 869730, 839730, 809730, 779730, 749730, 719730] },
	{ 8700: [1165980, 1115520, 964100, 934100, 904100, 874100, 844100, 814100, 784100, 754100, 724100] },
	{ 8720: [1170660, 1120180, 968470, 938470, 908470, 878470, 848470, 818470, 788470, 758470, 728470] },
	{ 8740: [1175340, 1124840, 972840, 942840, 912840, 882840, 852840, 822840, 792840, 762840, 732840] },
	{ 8760: [1180020, 1129490, 977200, 947200, 917200, 887200, 857200, 827200, 797200, 767200, 737200] },
	{ 8780: [1184700, 1134150, 981570, 951570, 921570, 891570, 861570, 831570, 801570, 771570, 741570] },
	{ 8800: [1189380, 1138800, 985940, 955940, 925940, 895940, 865940, 835940, 805940, 775940, 745940] },
	{ 8820: [1194060, 1143460, 990310, 960310, 930310, 900310, 870310, 840310, 810310, 780310, 750310] },
	{ 8840: [1198740, 1148120, 994680, 964680, 934680, 904680, 874680, 844680, 814680, 784680, 754680] },
	{ 8860: [1203420, 1152770, 999040, 969040, 939040, 909040, 879040, 849040, 819040, 789040, 759040] },
	{ 8880: [1208100, 1157430, 1003410, 973410, 943410, 913410, 883410, 853410, 823410, 793410, 763410] },
	{ 8900: [1212780, 1162080, 1007780, 977780, 947780, 917780, 887780, 857780, 827780, 797780, 767780] },
	{ 8920: [1217460, 1166740, 1012150, 982150, 952150, 922150, 892150, 862150, 832150, 802150, 772150] },
	{ 8940: [1222140, 1171400, 1016520, 986520, 956520, 926520, 896520, 866520, 836520, 806520, 776520] },
	{ 8960: [1226820, 1176050, 1020880, 990880, 960880, 930880, 900880, 870880, 840880, 810880, 780880] },
	{ 8980: [1231500, 1180710, 1025250, 995250, 965250, 935250, 905250, 875250, 845250, 815250, 785250] },
	{ 9000: [1236180, 1185360, 1029620, 999620, 969620, 939620, 909620, 879620, 849620, 819620, 789620] },
	{ 9020: [1240860, 1190020, 1033990, 1003990, 973990, 943990, 913990, 883990, 853990, 823990, 793990] },
	{ 9040: [1245540, 1194680, 1038360, 1008360, 978360, 948360, 918360, 888360, 858360, 828360, 798360] },
	{ 9060: [1250220, 1199330, 1042720, 1012720, 982720, 952720, 922720, 892720, 862720, 832720, 802720] },
	{ 9080: [1254900, 1203990, 1047090, 1017090, 987090, 957090, 927090, 897090, 867090, 837090, 807090] },
	{ 9100: [1259580, 1208640, 1051460, 1021460, 991460, 961460, 931460, 901460, 871460, 841460, 811460] },
	{ 9120: [1264260, 1213300, 1055830, 1025830, 995830, 965830, 935830, 905830, 875830, 845830, 815830] },
	{ 9140: [1268940, 1217960, 1060200, 1030200, 1000200, 970200, 940200, 910200, 880200, 850200, 820200] },
	{ 9160: [1273620, 1222610, 1064560, 1034560, 1004560, 974560, 944560, 914560, 884560, 854560, 824560] },
	{ 9180: [1278300, 1227270, 1068930, 1038930, 1008930, 978930, 948930, 918930, 888930, 858930, 828930] },
	{ 9200: [1282980, 1231920, 1073300, 1043300, 1013300, 983300, 953300, 923300, 893300, 863300, 833300] },
	{ 9220: [1289640, 1236580, 1077670, 1047670, 1017670, 987670, 957670, 927670, 897670, 867670, 837670] },
	{ 9240: [1296470, 1241240, 1082040, 1052040, 1022040, 992040, 962040, 932040, 902040, 872040, 842040] },
	{ 9260: [1303290, 1245890, 1086400, 1056400, 1026400, 996400, 966400, 936400, 906400, 876400, 846400] },
	{ 9280: [1310120, 1250550, 1090770, 1060770, 1030770, 1000770, 970770, 940770, 910770, 880770, 850770] },
	{ 9300: [1316940, 1255200, 1095140, 1065140, 1035140, 1005140, 975140, 945140, 915140, 885140, 855140] },
	{ 9320: [1323770, 1259860, 1099510, 1069510, 1039510, 1009510, 979510, 949510, 919510, 889510, 859510] },
	{ 9340: [1330590, 1264520, 1103880, 1073880, 1043880, 1013880, 983880, 953880, 923880, 893880, 863880] },
	{ 9360: [1337420, 1269170, 1108240, 1078240, 1048240, 1018240, 988240, 958240, 928240, 898240, 868240] },
	{ 9380: [1344240, 1273830, 1112610, 1082610, 1052610, 1022610, 992610, 962610, 932610, 902610, 872610] },
	{ 9400: [1351070, 1278480, 1116980, 1086980, 1056980, 1026980, 996980, 966980, 936980, 906980, 876980] },
	{ 9420: [1357890, 1283140, 1121350, 1091350, 1061350, 1031350, 1001350, 971350, 941350, 911350, 881350] },
	{ 9440: [1364720, 1289840, 1125720, 1095720, 1065720, 1035720, 1005720, 975720, 945720, 915720, 885720] },
	{ 9460: [1371540, 1296630, 1130080, 1100080, 1070080, 1040080, 1010080, 980080, 950080, 920080, 890080] },
	{ 9480: [1378370, 1303420, 1134450, 1104450, 1074450, 1044450, 1014450, 984450, 954450, 924450, 894450] },
	{ 9500: [1385190, 1310210, 1138820, 1108820, 1078820, 1048820, 1018820, 988820, 958820, 928820, 898820] },
	{ 9520: [1392020, 1317000, 1143190, 1113190, 1083190, 1053190, 1023190, 993190, 963190, 933190, 903190] },
	{ 9540: [1398840, 1323790, 1147560, 1117560, 1087560, 1057560, 1027560, 997560, 967560, 937560, 907560] },
	{ 9560: [1405670, 1330580, 1151920, 1121920, 1091920, 1061920, 1031920, 1001920, 971920, 941920, 911920] },
	{ 9580: [1412490, 1337370, 1156290, 1126290, 1096290, 1066290, 1036290, 1006290, 976290, 946290, 916290] },
	{ 9600: [1419320, 1344160, 1160660, 1130660, 1100660, 1070660, 1040660, 1010660, 980660, 950660, 920660] },
	{ 9620: [1426140, 1350950, 1165030, 1135030, 1105030, 1075030, 1045030, 1015030, 985030, 955030, 925030] },
	{ 9640: [1432970, 1357740, 1169400, 1139400, 1109400, 1079400, 1049400, 1019400, 989400, 959400, 929400] },
	{ 9660: [1439790, 1364530, 1173760, 1143760, 1113760, 1083760, 1053760, 1023760, 993760, 963760, 933760] },
	{ 9680: [1446620, 1371320, 1178130, 1148130, 1118130, 1088130, 1058130, 1028130, 998130, 968130, 938130] },
	{ 9700: [1453440, 1378110, 1182500, 1152500, 1122500, 1092500, 1062500, 1032500, 1002500, 972500, 942500] },
	{ 9720: [1460270, 1384900, 1186870, 1156870, 1126870, 1096870, 1066870, 1036870, 1006870, 976870, 946870] },
	{ 9740: [1467090, 1391690, 1191240, 1161240, 1131240, 1101240, 1071240, 1041240, 1011240, 981240, 951240] },
	{ 9760: [1473920, 1398480, 1195600, 1165600, 1135600, 1105600, 1075600, 1045600, 1015600, 985600, 955600] },
	{ 9780: [1480740, 1405270, 1199970, 1169970, 1139970, 1109970, 1079970, 1049970, 1019970, 989970, 959970] },
	{ 9800: [1487570, 1412060, 1204340, 1174340, 1144340, 1114340, 1084340, 1054340, 1024340, 994340, 964340] },
	{ 9820: [1494390, 1418850, 1208710, 1178710, 1148710, 1118710, 1088710, 1058710, 1028710, 998710, 968710] },
	{ 9840: [1501220, 1425640, 1213080, 1183080, 1153080, 1123080, 1093080, 1063080, 1033080, 1003080, 973080] },
	{ 9860: [1508040, 1432430, 1217440, 1187440, 1157440, 1127440, 1097440, 1067440, 1037440, 1007440, 977440] },
	{ 9880: [1514870, 1439220, 1221810, 1191810, 1161810, 1131810, 1101810, 1071810, 1041810, 1011810, 981810] },
	{ 9900: [1521690, 1446010, 1226180, 1196180, 1166180, 1136180, 1106180, 1076180, 1046180, 1016180, 986180] },
	{ 9920: [1528520, 1452800, 1230550, 1200550, 1170550, 1140550, 1110550, 1080550, 1050550, 1020550, 990550] },
	{ 9940: [1535340, 1459590, 1234920, 1204920, 1174920, 1144920, 1114920, 1084920, 1054920, 1024920, 994920] },
	{ 9960: [1542170, 1466380, 1239280, 1209280, 1179280, 1149280, 1119280, 1089280, 1059280, 1029280, 999280] },
	{ 9980: [1548990, 1473170, 1243650, 1213650, 1183650, 1153650, 1123650, 1093650, 1063650, 1033650, 1003650] },
	{ 10000: [1552400, 1476570, 1245840, 1215840, 1185840, 1155840, 1125840, 1095840, 1065840, 1035840, 1005840] },
]
