<template>
	<v-btn
		:block="btn.block"
		:min-width="btn['min-width']"
		:min-height="btn['min-height']"
		:color="btn.color"
		:icon="btn.icon"
		:absolute="btn.absolute"
		:active-class="btn['active-class']"
		:append="btn.append"
		:bottom="btn.bottom"
		:dark="btn.dark"
		:depressed="btn.depressed"
		:disabled="btn.disabled"
		:elevation="btn.elevation"
		:exact="btn.exact"
		:exact-active-class="btn['exact-active-class']"
		:fab="btn.fab"
		:fixed="btn.fixed"
		:href="btn.href"
		:input-value="btn['input-value']"
		:left="btn.left"
		:light="btn.light"
		:link="btn.link"
		:loading="btn.loading"
		:outlined="btn.outlined"
		:plain="btn.plain"
		:replace="btn.replace"
		:retain-focus-on-click="btn['retain-focus-on-click']"
		:right="btn.right"
		:ripple="btn.ripple"
		:rounded="btn.rounded"
		:shaped="btn.shaped"
		:tag="btn.tag"
		:target="btn.target"
		:text="btn.text"
		:tile="btn.tile"
		:to="btn.to"
		:top="btn.top"
		:medium="btn.medium"
		:large="btn.large"
		:small="btn.small"
		:x-large="btn['x-large']"
		:x-small="btn['x-small']"
		@click="$emit('click', $event)"
	>
		<v-icon v-if="btn.icon">{{ btn['mdi-icon'] }}</v-icon>
		<v-icon v-if="btn.textIcon && btn['icon-postion'] === 'left'">{{ btn['mdi-icon'] }}</v-icon>
		<span v-if="btn.textIcon">{{ btn.textValue }}</span>
		<span v-if="!btn.textIcon && !btn.icon">{{ btn.textValue }}</span>
		<v-icon v-if="btn.textIcon && btn['icon-postion'] === 'right'">{{ btn['mdi-icon'] }}</v-icon>
	</v-btn>
</template>

<script>
export default {
	name: 'protect-button',
	props: {
		btn: {
			type: Object,
			default() {
				return {
					textValue: '취소',
					['min-width']: '',
					['min-height']: '',
					['active-class']: undefined,
					['exact-active-class']: undefined,
					['input-value']: undefined,
					['retain-focus-on-click']: undefined,
					color: '#5680e9ff',
					block: false,
					icon: false,
					absolute: false,
					append: undefined,
					bottom: undefined,
					dark: false,
					depressed: undefined,
					disabled: false,
					elevation: 0,
					exact: undefined,
					fab: false,
					fixed: false,
					href: undefined,
					left: undefined,
					link: undefined,
					loading: undefined,
					outlined: false,
					plain: false,
					replace: undefined,
					right: undefined,
					ripple: true,
					rounded: true,
					shaped: false,
					tag: undefined,
					target: undefined,
					text: true,
					tile: false,
					to: undefined,
					top: undefined,
					medium: true,
					large: false,
					small: false,
					['x-large']: false,
					['x-small']: false,
					['mdi-icon']: 'undefined',
					['icon-postion']: 'left',
					textIcon: false,
				}
			},
		},
	},
}
</script>
<style scoped></style>
