<template>
	<v-card class="mx-auto mb-2" :max-width="$vuetify.breakpoint.xs ? '290' : '330'" outlined>
		<v-list-item three-line>
			<v-list-item-content>
				<div class="text-overline">
					Work Card - {{ data.Name }}
					<v-btn
						icon
						@click="$emit('editVacation', $event, data)"
						v-if="$store.state.myData.cls === '1' && $route.name === 'Statistics'"
						x-small
						class="ml-1"
						color="PointColor2"
					>
						<v-icon>mdi-calendar-edit</v-icon>
					</v-btn>
				</div>
				<v-list-item-title class="text-h5 mb-1">
					My Vacation Days
					<span class="PointColor1--text">{{ data.totalVacationDays }}({{ data.vacationsDays }})</span>
				</v-list-item-title>
				<v-progress-linear class="mb-2" rounded background-opacity="0.2" color="PointColor1" height="28" dark :value="vacationDays">
					remaining Days &nbsp;- &nbsp;<strong>{{ data.vacationsDays }}</strong>
				</v-progress-linear>
				<v-list-item-subtitle
					>Start Work:
					<strong>
						{{ data.startdate }}
					</strong></v-list-item-subtitle
				>
				<v-list-item-subtitle
					>years total Work Day:
					<strong>
						{{ data.thisYearTotalWorkDays }}
					</strong>
					(Days)</v-list-item-subtitle
				>
				<v-list-item-subtitle
					>years total Work Time: <strong>{{ data.thisYearTotalWorkTime }}</strong> (Hours)</v-list-item-subtitle
				>
				<v-list-item-subtitle
					>years total Work holiayDay:
					<strong>
						{{ data.holiayDay }}
					</strong>
					(Days)</v-list-item-subtitle
				>
				<v-list-item-subtitle
					>years total holiayDay Time: <strong>{{ data.holiayDayTime }}</strong> (Hours)</v-list-item-subtitle
				>
			</v-list-item-content>
		</v-list-item>
		<v-card-actions class="pt-0">
			<Attendancelist :data="data.Attendancelist" />
			<v-badge overlap right color="PointColor1" light rounded :content="data.bage" :value="data.bage" v-if="$route.name === 'Statistics'">
				<v-btn dark color="PointColor2" @click="$emit('ceoReport', $event, data)" rounded>
					ceoReport
				</v-btn>
			</v-badge>
			<ruleDialog :data="rule" v-if="$route.name !== 'Statistics'" />
		</v-card-actions>
	</v-card>
</template>
<script>
import Attendancelist from './Attendancelist'
import ruleDialog from './rule-dialog'
export default {
	components: {
		Attendancelist,
		ruleDialog,
	},
	props: {
		data: {
			type: Object,
		},
	},
	data() {
		return {
			Attendancelist: {
				dialog: false,
				btnText: 'work List',
				class: 'mr-2',
				interID: '',
			},
			rule: {
				dialog: false,
				title: 'Vaction Rule',
				content: '',
				btnText: 'Vaction Rule',
				active: true,
			},
		}
	},
	computed: {
		vacationDays() {
			let val = 0
			val = (this.data.vacationsDays / this.data.totalVacationDays) * 100
			return val
		},
	},
}
</script>
