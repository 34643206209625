<template>
	<v-flex xs12 class="mb-4">
		<v-layout justify-center align-center class="router-Btns">
			<v-tooltip bottom v-for="(btn, btnIndex) in $store.state.navList" :key="btnIndex">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						fab
						v-show="btn.to !== $route.name && btn.name !== 'QRenter' && btn.name !== 'Logout'"
						v-if="$route.path.indexOf(btn.name) === -1"
						large
						class="mr-4"
						v-bind="attrs"
						v-on="on"
						@click="$router.push({ name: btn.to })"
					>
						<v-icon>
							{{ btn.icon }}
						</v-icon>
					</v-btn>
				</template>
				<span>{{ btn.name }}</span>
			</v-tooltip>
		</v-layout>
		<v-divider color="white"></v-divider>
		<div class="router-title mt-6 text-center">
			{{ $route.name }}
		</div>
	</v-flex>
</template>
<script>
export default {
	data() {
		return {
			disAllowRouterNames: ['TeamBuilding', 'MarketingSite', 'Authorization', 'Statistics'],
		}
	},
}
</script>
<style lang="scss">
.router-Btns {
	height: 120px;
}
.router-title {
	font-size: 48px !important;
	color: white;
}
</style>
